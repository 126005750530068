import { ImageryLayer, Math as CMath, Rectangle, Resource, UrlTemplateImageryProvider, WebMercatorTilingScheme } from 'cesium';
import { BandRenderType, BandsSettings, RasterSourceType, SourceData, SourceDataId } from '@/models';
import { Polygon } from '@/utils/geometry';
import { getAccessToken, prepareParams } from '@/cmd/Api';
import { TILE_SERVER_DEFAULTS } from '@/consts';
import { BandHistogramData, mapApi } from '@/cmd/MapApi';
import { dataSourceApi } from '@/cmd/DataSourceApi';
import { ColorRanges, getBandsLevels, getDefaultBandsSettings } from '@/components/Map/utils';

export interface Options {
  bandsSettings?: BandsSettings;
  histogram?: BandHistogramData;
}

export class RasterLayer extends ImageryLayer {
  readonly id: SourceDataId;
  readonly name: string;
  readonly sourceData: SourceData;
  readonly geometry: Polygon;
  readonly isTemporal = false;

  protected histogram?: BandHistogramData[];
  protected _bandSettings?: BandsSettings;

  constructor(sourceData: SourceData) {
    const { id, boundary, name } = sourceData;
    const geometry = Polygon.ParseFromWKT(boundary);
    const { minX, minY, maxX, maxY } = geometry;
    const Authorization = getAccessToken();
    const headers = Authorization ? { Authorization } : undefined;
    const url = mapApi().getTileUrl(id);
    const maximumLevel = sourceData.rasterProperties?.maxZoomLevel;
    const imageryOptions = {
      url: new Resource({ url, headers }),
      tilingScheme: new WebMercatorTilingScheme(),
      rectangle: new Rectangle(CMath.toRadians(minX), CMath.toRadians(minY), CMath.toRadians(maxX), CMath.toRadians(maxY)),
      ...TILE_SERVER_DEFAULTS,
      ...(!!maximumLevel && { maximumLevel }),
    };

    super(new UrlTemplateImageryProvider(imageryOptions), {});

    this.id = id;
    this.name = name;
    this.sourceData = sourceData;
    this.geometry = geometry;

    this.setShow(false);
    Promise.all([dataSourceApi().getBandsSettings(id), this.loadHistogram()])
      .then(this.applyBandSettings)
      .then(this.updateTileUrl)
      .finally(this.setShow);
  }

  applyBandSettings = (value: [BandsSettings | undefined, BandHistogramData[] | undefined]) => {
    const [bandSettings, histogram] = value;
    this._bandSettings =
      bandSettings ?? getDefaultBandsSettings(this.sourceData.rasterProperties?.bands, this.sourceData.sourceType, histogram);
  };

  loadHistogram = () =>
    mapApi()
      .getHistogram(this.id)
      .then((histogram) => (this.histogram = histogram));

  protected getTileUrl() {
    let url, band, bandMapping, colors;
    let bandLevels = this._bandSettings?.levels || getBandsLevels(this._bandSettings, this.histogram);
    if (this._bandSettings) {
      if (this._bandSettings.renderType === BandRenderType.MULTIBAND) {
        bandMapping = this._bandSettings.mapping;
        url = mapApi().getTileUrl(this.id);
      } else {
        url = mapApi().getTileColorRampUrl(this.id);
        const { mapping, colorRamp, colorRampValues, colorRampInverted } = this._bandSettings;
        const colorRange = colorRampValues || ColorRanges[colorRamp];
        band = mapping && mapping[0];
        colors = colorRange && (colorRampInverted ? [...colorRange].reverse() : colorRange).map((c) => c.substring(1));
        // Todo: BE should handle 6 numbers
        bandLevels = bandLevels.slice(0, 2) as any;
      }
    }
    return bandLevels || bandMapping ? url + prepareParams({ bandMapping, bandLevels, colors, band }, '&') : url;
  }

  protected updateTileUrl = () => ((this.imageryProvider as any)._resource.url = this.getTileUrl());

  get bandSettings(): BandsSettings | undefined {
    return this._bandSettings;
  }

  set bandSettings(value: BandsSettings | undefined) {
    this._bandSettings = value;
    this.updateTileUrl();
  }

  setShow = (show: boolean = true) => {
    this.show = show;
  };
}
