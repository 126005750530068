import {
  InferenceJobStatus,
  IngestionJobId,
  IngestionJobWithSourceData,
  ModelId,
  SourceData,
  SourceDataId,
  UnixTime,
  UploadedFileId,
} from '@/models';
import { keepPreviousData, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Filter, ingestionApi } from '@/cmd/IngestionApi';
import { dataSourceApi } from '@/cmd/DataSourceApi';
import { PageableResponse } from '@/cmd/Api';
import { inferenceApi } from '@/cmd/InferenceApi';
import { getInfinitePlaceholder, getNextPageParam, initialPageParam } from '@/hooks/queries/utils';
import useVirtuosoInfiniteQuery from '@/hooks/useVirtuosoInfiniteQuery';
import { minutesToMilliseconds } from 'date-fns';

export const ingestionJobsKeys = {
  all: ['ingestion_jobs'] as const,
  list: (filter?: Filter) => [...ingestionJobsKeys.all, filter] as const,
};
export const inferenceJobsKeys = {
  all: ['inference_jobs'] as const,
  list: (sourceId?: SourceDataId) => [...inferenceJobsKeys.all, sourceId] as const,
};

export const useInfiniteIngestionJobs = (filter?: Filter, enabled?: boolean) => {
  return useVirtuosoInfiniteQuery({
    queryKey: ingestionJobsKeys.list(filter),
    queryFn: async ({ pageParam }) => {
      const ingestionResponse = await ingestionApi().getList(filter, pageParam);
      const ids = ingestionResponse.content.filter((f) => !!f.sourceId).map((f) => f.sourceId!);
      let sourceResponse: SourceData[] = [];
      try {
        if (ids.length > 0) {
          sourceResponse = await dataSourceApi().find(ids);
        }
      } catch (e) {
        console.error(e);
      }

      const content: IngestionJobWithSourceData[] = ingestionResponse.content.map((ingestionJob) => {
        const sourceData = sourceResponse.find((sourceData) => sourceData.id === ingestionJob.sourceId);

        return { ...ingestionJob, sourceData };
      });

      return {
        ...ingestionResponse,
        content,
      } as PageableResponse<IngestionJobWithSourceData>;
    },
    getNextPageParam,
    initialPageParam,
    refetchInterval: minutesToMilliseconds(1),
    placeholderData: getInfinitePlaceholder<IngestionJobWithSourceData>(18, true),
    enabled,
  });
};

export const useIngestionJobFromFileId = (fileId?: UploadedFileId, enabled = true) => {
  const { data } = useInfiniteIngestionJobs({ fileIds: (fileId && [fileId]) || undefined }, enabled);
  return data?.pages[0].content[0];
};

export const useInfiniteInferenceJobs = (sourceId?: SourceDataId) =>
  useInfiniteQuery({
    queryKey: inferenceJobsKeys.list(sourceId),
    queryFn: ({ pageParam }) => inferenceApi().getJobs(sourceId!, pageParam),
    getNextPageParam,
    initialPageParam,
    placeholderData: keepPreviousData,
    enabled: !!sourceId,
    refetchInterval: (query) => {
      if (query.state.data?.pages.some((p) => p.content.some((j) => j.status === InferenceJobStatus.RUNNING))) {
        return minutesToMilliseconds(1);
      }
      return false;
    },
  });

export const useRunModel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: { modelId: ModelId; sourceId: SourceDataId }) => inferenceApi().runManual(request.modelId, request.sourceId),
    onSuccess: (_, { sourceId }) => {
      queryClient.invalidateQueries({ queryKey: inferenceJobsKeys.list(sourceId) });
    },
  });
};

export const useDeleteIngestionJob = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (fileId: IngestionJobId) => ingestionApi().delete(fileId),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ingestionJobsKeys.all }),
  });
};

export const useRetryIngestionJob = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (fileId: IngestionJobId) => ingestionApi().retryIngestion(fileId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ingestionJobsKeys.all });
    },
  });
};

export const useUpdateAcquisitionDate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: { sourceId: SourceDataId; acquisitionStartDate: UnixTime }) =>
      dataSourceApi().update({ id: request.sourceId, acquisitionStartDate: request.acquisitionStartDate }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ingestionJobsKeys.all }),
    // TODO possibly invalidate data sources query also in the future
  });
};
