import { Callback } from '@/models';

export interface BaseEvent {
  type: string;
  data?: any;
}

export type EventDispatcher<T = BaseEvent> = (e: T) => void;

export type EventListener = <T = BaseEvent>(type: string, callback: EventDispatcher<T>) => Callback;

export const dispatchEvent: EventDispatcher = (e) => document.dispatchEvent(new CustomEvent(e.type, { detail: e.data }));

export const addEventListener: EventListener = <T = BaseEvent>(type: string, callback: EventDispatcher<T>) => {
  const eventCallback = (e: any) => callback({ type, data: e.detail } as any);
  document.addEventListener(type, eventCallback);
  return () => document.removeEventListener(type, eventCallback);
};
