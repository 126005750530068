import { singleton } from '@/utils/decorators/singleton';
import { AnnotationFeature, CategoryAggregation, ConvertRequestDTO, FeatureId, ModelId, SourceData, SourceDataId } from '@/models';
import { Api, METHOD, PageableRequest, PageableResponse, prepareParams, RequestPromise } from './Api';
import { downloadFromBackend } from '@/cmd/download';
import { AggregationFilter, AnnotationFilter } from '@/cmd/DataSourceApi';
import { removeEmpty } from '@/utils';

@singleton
class AnnotationApi extends Api {
  endpoint: string = '/api/annotation';
  static getInstance: () => AnnotationApi;

  getAnnotationsList = (
    sourceId: SourceDataId,
    filter?: AnnotationFilter,
    pagination?: PageableRequest,
  ): RequestPromise<PageableResponse<AnnotationFeature>> => {
    return this.request(METHOD.GET, '', { sourceId, ...removeEmpty(filter), sort: 'id', ...pagination });
  };

  getAnnotation = (featureId: FeatureId): RequestPromise<AnnotationFeature> => this.request(METHOD.GET, '/' + featureId);

  createAnnotation = (feature: Partial<AnnotationFeature>): RequestPromise<AnnotationFeature> =>
    this.request(METHOD.POST, '/' + feature.sourceId, feature);

  editAnnotation = (feature: Partial<AnnotationFeature>): RequestPromise<AnnotationFeature> =>
    this.request(METHOD.PATCH, '/' + feature.id, feature);

  deleteAnnotation = (featureId: FeatureId): RequestPromise<any> => this.request(METHOD.DELETE, '/' + featureId);

  getCategoriesAggregation(sourceDataId: SourceDataId, filter?: AggregationFilter): RequestPromise<CategoryAggregation[]> {
    return this.request(METHOD.GET, '/' + sourceDataId + '/categories/aggregation', { ...filter });
  }

  // TODO: DRAG-2290
  copyDetections = (destinationSourceId: SourceDataId, modelIds?: ModelId[]): RequestPromise =>
    this.request(METHOD.POST, '/' + destinationSourceId + '/copy/inference', modelIds);

  downloadAnnotations(sourceDataId: SourceDataId, fileName: string) {
    return downloadFromBackend('/api/annotation/' + sourceDataId + '/download' + prepareParams({ fileName }), {
      name: fileName,
    });
  }

  convert(request: ConvertRequestDTO): RequestPromise<SourceData> {
    return this.request(METHOD.POST, '/convert', request);
  }
}

export const annotationApi = () => AnnotationApi.getInstance();
