import { ChangeEvent, PropsWithChildren, PureComponent, ReactNode } from 'react';
import { Divider, styled, TextField, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import AnimatedIcon from '@/components/AnimatedIcon';
import { WithTranslation, withTranslation } from 'next-i18next';
import Map, { Controller, DEFAULT_CONTAINER_ID } from '@/components/Cesium/Map';
import { Geometry, Polygon } from '@/utils/geometry';
import { getWKTType, parseFromWKT } from '@/utils/geometry/Geometry';
import { getGeometryByTool, isValidGeometry } from '@/components/Cesium/utils';
import { WGS } from '@/consts';
import { PopperDialog } from '@/components/PopperDialog';
import Button from '@/components/Button';
import { numRange, random } from '@/utils';
import { getBaseLayer } from '@/components/Cesium/tileServer';
import { Notification } from '@/components/Notification';
import { coordinatesToPoint } from '@/utils/coordinates';
import { MapTool } from '@/models';
import { Cartesian3, Viewer } from 'cesium';
import { DrawInteraction, EventType, geometryToTool } from '@/components/Cesium/Map/DrawInteraction';
import { copyToClipboard, readClipboard } from '@/utils/clipboard';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      whiteSpace: 'pre-line',
      fontSize: theme.typography.body2.fontSize,
    },
  }),
);

const StyledPopperDialog = styled(PopperDialog, { shouldForwardProp: (prop) => prop !== 'editMode' })<{ editMode: boolean }>`
  .map-layout {
    cursor: ${({ editMode }) => (editMode ? 'crosshair' : 'auto')};
  }
  .tools {
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(3)};

    button {
      padding: 3px 4px;
      min-width: auto;
    }
  }
`;

const Layout = styled('div', { shouldForwardProp: (prop) => prop !== 'editMode' })<{ editMode: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};

  .map-layout {
    cursor: ${({ editMode }) => (editMode ? 'crosshair' : 'auto')};
  }
`;

const VIEWER_OPTIONS = { showFPS: false, requestRenderMode: false /*, sceneMode: SceneMode.SCENE2D */ };

interface Props {
  header?: ReactNode;
  tool?: MapTool;
  geometry?: Geometry | null;
  onSelect?: (geometry?: Geometry) => void;
  onClose?: () => void;
  readonly?: boolean;
  isGlobal?: boolean;
  mode?: 'modal' | 'static';
  onReset?: () => void;
  onNameChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  nameLabel?: string;
  nameValue?: string;
}

interface State {
  editMode: boolean;
  changed: boolean;
  geometry?: Geometry | null;
}

class Selector extends PureComponent<WithTranslation & PropsWithChildren<Props>, State> {
  state: State = {
    editMode: !this.props.geometry,
    changed: false,
    geometry: this.props.geometry,
  };

  protected tool = this.props.tool || geometryToTool(this.props.geometry);
  protected viewer?: Viewer;
  protected mapCtl?: Controller;
  protected interaction?: DrawInteraction;
  protected baseLayer = getBaseLayer();
  protected containerId = DEFAULT_CONTAINER_ID + new Date().getTime();

  loadedHandler = (c: Controller) => {
    const { geometry, t } = this.props;
    this.mapCtl = c;
    this.viewer = c.viewer;
    const entities = geometry && isValidGeometry(geometry) ? [geometry] : undefined;
    this.interaction = new DrawInteraction(c, { features: entities, enabled: !geometry, tool: this.tool });
    this.interaction.event.addEventListener(this.drawEventHandler);
    if (this.viewer && this.viewer.cesiumWidget) {
      if (geometry) {
        let polygon = geometry.clone();
        if (this.tool === MapTool.POLYGON) {
          polygon.points.forEach((p) => {
            p.y = numRange(p.y, -85, 85);
          });
        } else if (this.tool === MapTool.POINT) {
          polygon = Polygon.CreateBySide(1, polygon.center);
        }
        void this.mapCtl.flyTo(polygon, 0);
      }
    }
    if (geometry && !isValidGeometry(geometry)) {
      Notification.warning(t('common:cannot_show_boundary'));
    }
  };

  drawEventHandler = (type: EventType, points?: Cartesian3[]) => {
    switch (type) {
      case EventType.DRAW_FINISHED:
        const geometry = getGeometryByTool(this.interaction!.tool!, points!);
        this.setState({ geometry, editMode: false, changed: true }, () => {
          if (this.props.mode === 'static') {
            this.saveHandler();
          }
        });
        break;
    }
  };

  switchTool = (tool: MapTool) => {
    this.reset();
    this.tool = tool;
    this.interaction!.tool = tool;
  };

  saveHandler = () => {
    const { onClose, onSelect, mode } = this.props;
    if (!onSelect) {
      return;
    }
    onSelect(this.state.geometry || undefined);
    if (onClose && (!mode || mode === 'modal')) {
      onClose();
    }
  };

  reset = () => {
    if (this.interaction) {
      this.interaction.clear();
      this.interaction.reset();
      this.interaction.enabled = true;
    }
    this.setState({ editMode: true, geometry: undefined });
    this.props.onReset?.();
  };

  getActions = () => {
    const { onSelect, readonly, onNameChange, nameValue, t } = this.props;
    const { editMode, changed } = this.state;
    return (
      <>
        <Button onClick={this.props.onClose}>{t('close')}</Button>
        {!readonly && (
          <Button onClick={this.saveHandler} disabled={!changed || !onSelect || editMode || (onNameChange && !nameValue)}>
            {t('save')}
          </Button>
        )}
      </>
    );
  };

  getHeader = () => {
    return {
      title: this.props.header || ' ',
      ...(!this.props.readonly &&
        this.tool !== MapTool.POINT && {
          action: (
            <StyledTooltip title={this.props.t('common:edit_boundary_info')} placement="right-start">
              <AnimatedIcon>info</AnimatedIcon>
            </StyledTooltip>
          ),
        }),
    };
  };

  copyToClipboardHandler = () => {
    const { t } = this.props;
    const { geometry } = this.state;
    const wkt = geometry?.toWKT();
    wkt && copyToClipboard(wkt).then(() => Notification.success(t('common:copied_to_clipboard')));
  };

  pasteFromClipboardHandler = () => {
    readClipboard().then((text) => {
      let geometry = getWKTType(text) && parseFromWKT(text, WGS);
      geometry = geometry || coordinatesToPoint(text);
      if (geometry) {
        this.interaction?.clear();
        this.interaction?.addFeatures([geometry]);
        this.mapCtl?.flyTo(geometry, 0);
        this.setState({ changed: true, editMode: false, geometry });
      }
    });
  };

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onNameChange?.(e);
    this.setState({ changed: true });
  };

  render() {
    const { t } = this.props;
    if (!this.props.mode || this.props.mode === 'modal') {
      return (
        <StyledPopperDialog
          open
          editMode={this.state.editMode}
          isModal
          header={this.getHeader()}
          actions={this.getActions()}
          DialogProps={{ fullWidth: true, maxWidth: 'lg' }}
        >
          {this.props.onNameChange && (
            <TextField
              required
              label={this.props.nameLabel ?? t('common:name')}
              value={this.props.nameValue}
              onChange={this.handleNameChange}
              sx={{ marginBlockStart: 2, marginBlockEnd: 4, width: '33%' }}
            />
          )}
          {this.props.children}
          {!this.props.readonly && (
            <div className="tools">
              {[MapTool.POLYGON, MapTool.RECTANGLE].includes(this.tool) && (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    color={this.tool === MapTool.RECTANGLE ? 'primary' : 'secondary'}
                    onClick={() => this.switchTool(MapTool.RECTANGLE)}
                    disabled={!this.state.editMode}
                  >
                    <AnimatedIcon>square</AnimatedIcon>
                  </Button>

                  <Button
                    size="small"
                    variant="outlined"
                    color={this.tool === MapTool.POLYGON ? 'primary' : 'secondary'}
                    onClick={() => this.switchTool(MapTool.POLYGON)}
                    disabled={!this.state.editMode}
                  >
                    <AnimatedIcon>hexagon</AnimatedIcon>
                  </Button>
                  <Divider orientation="vertical" variant="middle" flexItem />
                </>
              )}
              <Tooltip title={t('common:paste_from_clipboard')}>
                <Button size="small" variant="outlined" onClick={this.pasteFromClipboardHandler}>
                  <AnimatedIcon>content_paste</AnimatedIcon>
                </Button>
              </Tooltip>
              <Tooltip title={t('common:copy_to_clipboard')}>
                <div>
                  <Button size="small" variant="outlined" onClick={this.copyToClipboardHandler} disabled={this.state.editMode}>
                    <AnimatedIcon>content_copy</AnimatedIcon>
                  </Button>
                </div>
              </Tooltip>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Tooltip title={t('common:delete')}>
                <Button color="error" size="small" variant="outlined" onClick={this.reset}>
                  <AnimatedIcon>delete</AnimatedIcon>
                </Button>
              </Tooltip>
            </div>
          )}
          <Map onLoaded={this.loadedHandler} baseLayer={this.baseLayer} viewerOptions={VIEWER_OPTIONS} container={this.containerId} />
        </StyledPopperDialog>
      );
    }
    return (
      <Layout editMode={this.state.editMode}>
        <Map onLoaded={this.loadedHandler} baseLayer={this.baseLayer} viewerOptions={VIEWER_OPTIONS} />
      </Layout>
    );
  }

  componentWillUnmount() {
    this.interaction?.event.removeEventListener(this.drawEventHandler);
    this.interaction?.destroy();
    this.mapCtl?.destroy();
  }
}

export default withTranslation('common')(Selector);
