import { PageableRequest, PageableResponse } from '@/cmd/Api';
import { GetNextPageParamFunction, InfiniteData, PlaceholderDataFunction } from '@tanstack/react-query';
import { useMemo } from 'react';

const PLACEHOLDER = 'placeholder-';
const PLACEHOLDER_0 = PLACEHOLDER + '0';

export type GetFlatInfiniteContent = <T>(data: InfiniteData<PageableResponse<T>> | undefined) => T[];

export const getFlatInfiniteContent: GetFlatInfiniteContent = (data) => data?.pages.flatMap((p) => p.content) ?? [];

export const getNextPageParam: GetNextPageParamFunction<PageableRequest, PageableResponse<unknown>> = (lastPage) =>
  !lastPage.last ? { page: lastPage.number + 1, pageSize: lastPage.size } : undefined;

export const initialPageParam: PageableRequest = {
  page: 0,
};

export const getPlaceholder =
  <T>(length: number, keepPreviousData: boolean = false): PlaceholderDataFunction<T[]> =>
  (previousData) =>
    keepPreviousData && !!previousData ? previousData : Array.from({ length }, (_, i) => ({ id: PLACEHOLDER + i }) as T);
export const getPlaceholderStrings =
  <T>(length: number, keepPreviousData: boolean = false): PlaceholderDataFunction<T[]> =>
  (previousData) =>
    keepPreviousData && !!previousData ? previousData : Array.from({ length }, (_, i) => (PLACEHOLDER + i) as T);

export const getPageablePlaceholder =
  <T>(
    length: number,
    type: 'object' | 'string' = 'object',
    keepPreviousData: boolean = false,
  ): PlaceholderDataFunction<PageableResponse<T>> =>
  (previousData) => ({
    content: (keepPreviousData && !!previousData
      ? previousData
      : type === 'object'
        ? getPlaceholder<T>(length)(undefined, undefined)!
        : getPlaceholderStrings<T>(length)(undefined, undefined)!) as T[],
    empty: !length,
    first: true,
    last: true,
    size: length,
    numberOfElements: length,
    totalElements: 0,
    number: 0,
    totalPages: 0,
    sort: { unsorted: false, sorted: false, empty: false },
    pageable: { offset: 0, pageNumber: 0, pageSize: 0 },
  });

export const getInfinitePlaceholder =
  <T>(
    length: number,
    keepPreviousData: boolean = false,
    type: 'object' | 'string' = 'object',
  ): PlaceholderDataFunction<InfiniteData<PageableResponse<T>, PageableRequest>> =>
  (previousData) => {
    if (keepPreviousData && !!previousData) return previousData;
    return {
      pages: [
        {
          content:
            type === 'object' ? getPlaceholder<T>(length)(undefined, undefined)! : getPlaceholderStrings<T>(length)(undefined, undefined)!,
          empty: !length,
          first: true,
          last: true,
          size: length,
          numberOfElements: length,
          totalElements: 0,
          number: 0,
          totalPages: 0,
          sort: { unsorted: false, sorted: false, empty: false },
          pageable: { offset: 0, pageNumber: 0, pageSize: 0 },
        } satisfies PageableResponse<T>,
      ],
      pageParams: [{ page: 0 }],
    };
  };

const checkItemPlaceholder = (item: Record<string, unknown> | string): boolean => {
  if (!item) return false;
  if (typeof item === 'string') return item === PLACEHOLDER_0;
  if ('id' in item) return item.id === PLACEHOLDER_0;
  return false;
};

export const useIsPlaceholderData = <T>(data: T[] | PageableResponse<T> | InfiniteData<PageableResponse<T>> | undefined): boolean => {
  return useMemo(() => {
    if (!data) return false;
    let item;
    if (Array.isArray(data)) {
      item = data[0];
    } else if ('content' in data) {
      item = data.content[0];
    } else {
      item = data.pages[0].content[0];
    }
    return checkItemPlaceholder(item as any);
  }, [data]);
};
