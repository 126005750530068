import { Api, METHOD } from '@/cmd/Api';
import { getObjectKeys, isEmptyObject } from '@/utils';

interface ShaderList {
  calculateSpeedShader: string;
  updatePositionShader: string;
  postProcessingPositionShader: string;
  segmentDrawVert: string;
  fullscreenVert: string;
  screenDrawFrag: string;
  segmentDrawFrag: string;
  trailDrawFrag: string;
}

const list: ShaderList = {
  calculateSpeedShader: 'calculateSpeed.frag',
  updatePositionShader: 'updatePosition.frag',
  postProcessingPositionShader: 'postProcessingPosition.frag',
  segmentDrawVert: 'segmentDraw.vert',
  fullscreenVert: 'fullscreen.vert',
  screenDrawFrag: 'screenDraw.frag',
  segmentDrawFrag: 'segmentDraw.frag',
  trailDrawFrag: 'trailDraw.frag',
};

const path = globalThis.location?.origin + '/glsl';

export const shaders: ShaderList = {} as ShaderList;
const api = new Api();
const loadShader = (url: string) => api.request(METHOD.GET, url);
export const getShaders = async (): Promise<ShaderList> => {
  if (isEmptyObject(shaders)) {
    const promises = getObjectKeys(list).map((key) => loadShader(path + '/' + list[key]).then((res) => (shaders[key] = res.data)));
    return Promise.all(promises).then(() => shaders);
  } else {
    return Promise.resolve(shaders);
  }
};
