import { CzmlDataSource } from 'cesium';
import type { SourceData } from '@/models';
import { Controller } from '@/components/Cesium/Map/Controller';
import { getConfig } from '@/config';

const config = getConfig();

export class CzmlVectorLayer extends CzmlDataSource {
  readonly id?: string;
  // TODO: DRAG-3350
  readonly isTemporal = true;
  readonly sourceData: SourceData;
  protected mapCtl: Controller;

  constructor(source: SourceData, mapCtl: Controller) {
    super();
    this.id = source.id;
    this.sourceData = source;
    this.mapCtl = mapCtl;
    if (source.spatialProperties?.rootS3Path) {
      this.load(config.minioUrl + '/' + source.spatialProperties.rootS3Path).then((res) => {
        // TODO: DRAG-3351
        void mapCtl.viewer.flyTo(res);
      });
    }
  }
}
