import {
  ArcType,
  CallbackProperty,
  Cartesian2,
  Cartesian3,
  Cartographic,
  Color,
  ConstantPositionProperty,
  ConstantProperty,
  EllipseGraphics,
  Ellipsoid,
  EllipsoidRhumbLine,
  Entity,
  ImageryLayer,
  JulianDate,
  LabelGraphics,
  Math as CMath,
  PointGraphics,
  PolygonGraphics,
  PolygonHierarchy,
  PolylineGraphics,
  PolylineOutlineMaterialProperty,
  PropertyBag,
  Rectangle,
  RectangleGraphics,
  Viewer,
} from 'cesium';
import { Geometry, GeometryType, isRectangle, Line, Point, Polygon } from '@/utils/geometry';
import {
  AnnotationSourceType,
  FeatureStyle,
  HexColor,
  KeyValue,
  MapTool,
  SourceData,
  SourceDataType,
  SpatialSourceType,
  UserLayer,
} from '@/models';
import { DEFAULT_STYLES, getEntityGraphicsFromStyle } from '@/components/Cesium/utils/styles';
import { RectangleType } from '@/components/Cesium/DataSource/BaseVectorLayer';
import { Controller } from '@/components/Cesium/Map/Controller';
import { createCanvas } from '@/utils/canvas';
import { RasterLayer } from '@/components/Cesium/Layers/RasterLayer';
import { getObjectKeys, round } from '@/utils';
import { AnnotationsVectorLayer } from '@/components/Cesium/DataSource/AnnotationsVectorLayer';
import { SourceDataLayer, SourceDataLayerOptions } from '@/components/Cesium/DataSource/SourceDataLayer';
import { SourceData3DTilesLayer } from '@/components/Cesium/Layers/SourceData3DTilesLayer';
import { DEFAULT_ALPHA, WGS } from '@/consts';
import { DrawingLayer } from '@/components/Cesium/DataSource/DrawingLayer';
import { CzmlVectorLayer } from '@/components/Cesium/DataSource/CzmlVectorLayer';
import { WindLayer } from '@/components/Cesium/Layers/WindLayer';
import { WKTType } from '@/utils/geometry/Geometry';

export const julianDate = new JulianDate();

export const COORDINATE_TOLERANCE = CMath.EPSILON3;

export const DEFAULT_ADJUSTMENTS: RasterAdjustment = {
  alpha: DEFAULT_ALPHA,
  brightness: ImageryLayer.DEFAULT_BRIGHTNESS,
  contrast: ImageryLayer.DEFAULT_CONTRAST,
  hue: ImageryLayer.DEFAULT_HUE,
  saturation: ImageryLayer.DEFAULT_SATURATION,
  gamma: ImageryLayer.DEFAULT_GAMMA,
};

export interface RasterAdjustment {
  alpha?: number;
  brightness?: number;
  contrast?: number;
  hue?: number;
  saturation?: number;
  gamma?: number;
}

export enum RasterAdjustmentParameter {
  ALPHA = 'alpha',
  BRIGHTNESS = 'brightness',
  HUE = 'hue',
  CONTRAST = 'contrast',
  SATURATION = 'saturation',
  GAMMA = 'gamma',
}

export interface EntityGraphics {
  rectangle?: RectangleGraphics;
  point?: PointGraphics;
  polygon?: PolygonGraphics;
  polyline?: PolylineGraphics;
  label?: LabelGraphics;
}

export const cartesianToPoint = (p: Cartesian3 | Cartesian2): Point => {
  if (p instanceof Cartesian2) {
    return new Point(p.x, p.y);
  }
  const { latitude, longitude, height } = Ellipsoid.WGS84.cartesianToCartographic(p);
  return new Point(CMath.toDegrees(longitude), CMath.toDegrees(latitude), round(height, 2)).setProjection(WGS);
};

export const pointToCartesian3 = (p: Point): Cartesian3 => Cartesian3.fromDegrees(p.x, p.y, p.z);

export const rectangleToPolygon = (r: Rectangle): Polygon => {
  const [west, north, east, south] = [r.west, r.north, r.east, r.south].map(CMath.toDegrees);
  const points = [new Point(west, north), new Point(east, north), new Point(east, south), new Point(west, south)];
  const poly = new Polygon(points);

  return poly.close().setProjection(WGS);
};

export const polygonToRectangle = (p: Polygon): Rectangle => {
  const north = p.maxY;
  const south = p.minY;
  const west = p.minX;
  const east = p.maxX;
  return Rectangle.fromDegrees(west, south, east, north);
};

export const getPolygonHierarchy = (polygon: Polygon): PolygonHierarchy =>
  new PolygonHierarchy(
    getPositions(polygon.points),
    polygon.holes.map(
      (h: Polygon) =>
        new PolygonHierarchy(
          Cartesian3.fromDegreesArrayHeights(h.points.reduce((a: number[], k: Point) => [...a, ...k.getCoordinates(true)], [])),
        ),
    ),
  );

export const getPositions = (points: Point[]) =>
  Cartesian3.fromDegreesArrayHeights(points.reduce((a: number[], k: Point) => [...a, ...k.getCoordinates(true)], []));

type PointsEquals = {
  (point1: Cartesian3, point2: Cartesian3, tolerance?: number): boolean;
  (point1: Cartesian2, point2: Cartesian2, tolerance?: number): boolean;
};

export const coordinateEquals: PointsEquals = (
  point1: Cartesian3 | Cartesian2,
  point2: Cartesian3 | Cartesian2,
  tolerance = COORDINATE_TOLERANCE,
) => point1?.equalsEpsilon(point2 as any, 0, tolerance);

export const createPropertyBag = (properties: KeyValue): PropertyBag => {
  const propertyBag = new PropertyBag();
  Object.keys(properties).forEach((key) => {
    propertyBag.addProperty(key, properties[key], propertyBagConstructor);
  });

  return propertyBag;
};

export const createEntity = (geometry: Geometry, styles?: FeatureStyle, options?: KeyValue): Entity | undefined => {
  const { properties, entityGraphics, useRectangle = true, ...restOptions } = options || {};
  const propertyBag = createPropertyBag({ ...properties, geometry });
  let graphics;
  if (styles) {
    const type = useRectangle && isRectangle(geometry) ? RectangleType.RECTANGLE : geometry.type;
    graphics = getEntityGraphicsFromStyle(type, styles, options);
  }
  const entity = new Entity({ ...graphics, ...entityGraphics, ...restOptions, properties: propertyBag });
  setEntityPosition(entity, geometry);
  return entity;
};

export const setEntityPosition = (entity: Entity, geometry: Geometry) => {
  switch (geometry.type) {
    case GeometryType.POLYGON:
      {
        const hierarchy = getPolygonHierarchy(geometry);
        entity.rectangle && (entity.rectangle.coordinates = new ConstantProperty(polygonToRectangle(geometry.extent)));
        entity.polygon && (entity.polygon.hierarchy = new ConstantProperty(hierarchy));
        entity.polyline && (entity.polyline.positions = new ConstantProperty(hierarchy.positions));
      }
      break;

    case GeometryType.LINESTRING:
      entity.polyline && (entity.polyline.positions = new ConstantProperty(getPositions(geometry.points)));
      break;

    case GeometryType.POINT:
      // Nothing to do?
      break;
  }
  entity.position = new ConstantPositionProperty(pointToCartesian3(geometry.center));
};

export const cloneEntity = (entity: Entity, options?: KeyValue): Entity => {
  const { polygon, polyline, point, rectangle, position, properties, ellipse, label } = entity;

  const newEntity = {
    polygon: polygon && polygon.clone(),
    polyline: polyline && polyline.clone(),
    point: point && point.clone(),
    ellipse: ellipse && ellipse.clone(),
    rectangle: rectangle && rectangle.clone(),
    position: position && position.getValue(julianDate)!.clone(),
    label: label && label.clone(),
    properties,
  };
  if (newEntity.polygon?.hierarchy instanceof CallbackProperty) {
    newEntity.polygon.hierarchy = newEntity.polygon.hierarchy.getValue(julianDate);
  }
  if (newEntity.polyline?.positions instanceof CallbackProperty) {
    newEntity.polyline.positions = newEntity.polyline.positions.getValue(julianDate);
  }
  if (newEntity.rectangle?.coordinates instanceof CallbackProperty) {
    newEntity.rectangle.coordinates = newEntity.rectangle.coordinates.getValue(julianDate);
  }
  if (newEntity.ellipse?.semiMajorAxis instanceof CallbackProperty) {
    newEntity.ellipse.semiMajorAxis = newEntity.ellipse.semiMajorAxis.getValue(julianDate);
  }
  if (newEntity.ellipse?.semiMinorAxis instanceof CallbackProperty) {
    newEntity.ellipse.semiMinorAxis = newEntity.ellipse.semiMinorAxis.getValue(julianDate);
  }
  if (newEntity.label?.text instanceof CallbackProperty) {
    newEntity.label.text = newEntity.label.text.getValue(julianDate);
  }
  return new Entity({ ...newEntity, ...options });
};
export const removeCallbackFromEntity = (entity: Entity) => {
  if (entity.position instanceof CallbackProperty) {
    entity.position = entity.position.getValue(julianDate) as any;
  }
  if (entity.polygon?.hierarchy instanceof CallbackProperty) {
    entity.polygon.hierarchy = entity.polygon.hierarchy.getValue(julianDate);
  }
  if (entity.polyline?.positions instanceof CallbackProperty) {
    entity.polyline.positions = entity.polyline.positions.getValue(julianDate);
  }
  if (entity.rectangle?.coordinates instanceof CallbackProperty) {
    entity.rectangle.coordinates = entity.rectangle.coordinates.getValue(julianDate);
  }
  if (entity.ellipse?.semiMajorAxis instanceof CallbackProperty) {
    entity.ellipse.semiMajorAxis = entity.ellipse.semiMajorAxis.getValue(julianDate);
  }
  if (entity.ellipse?.semiMinorAxis instanceof CallbackProperty) {
    entity.ellipse.semiMinorAxis = entity.ellipse.semiMinorAxis.getValue(julianDate);
  }
  if (entity.label?.text instanceof CallbackProperty) {
    entity.label.text = entity.label.text.getValue(julianDate);
  }
};
export const getColor = (color?: HexColor | Color): Color | undefined =>
  typeof color === 'string' ? Color.fromCssColorString(color) : color;

// Todo: Check tolerance
export const getCoordinatesTolerance = (distance?: number): number | undefined => {
  if (!distance) {
    return undefined;
  }
  switch (true) {
    case distance < 1000:
      return CMath.EPSILON12;

    case distance < 3000:
      return CMath.EPSILON6;

    case distance < 2000:
      return CMath.EPSILON8;
  }
};

export const getZoomDistance = (viewer: Viewer): number | undefined => {
  if (viewer && viewer.cesiumWidget) {
    const cameraPosition = viewer.scene.camera.positionWC;
    const ellipsoidPosition = viewer.scene.globe.ellipsoid.scaleToGeodeticSurface(cameraPosition);
    return Cartesian3.magnitude(Cartesian3.subtract(cameraPosition, ellipsoidPosition, new Cartesian3()));
  }
};

export const isValidGeometry = (geometry: Geometry) => {
  if (geometry && typeof geometry === 'object' && geometry.type === GeometryType.POLYGON) {
    const len = geometry.points.length;
    for (let idx = 0; idx < len - 1; idx++) {
      if (geometry.p(idx).distance(geometry.p(idx + 1)) >= 170) {
        return false;
      }
    }
  }
  return true;
};

export const getGeometryByTool = (tool: MapTool, points: Cartesian3[] | Point[]): Geometry | undefined => {
  let geometry;
  if (points.length) {
    const geoPoints: Point[] =
      points[0] instanceof Point ? (points as Point[]) : (points as Cartesian3[]).map((p) => cartesianToPoint(p).removeDepth());

    switch (tool) {
      case MapTool.RECTANGLE:
        geometry = new Polygon(geoPoints);
        break;
      case MapTool.POLYGON:
        geometry = new Polygon(geoPoints);
        break;
      case MapTool.LINESTRING:
        geometry = new Line(geoPoints);
        break;
      case MapTool.POINT:
        geometry = geoPoints[0];
        break;
    }
  }

  return geometry?.setProjection(WGS);
};

const propertyBagConstructor = (e: any) => e;

export const getCanvas = (mapCtl: Controller) => {
  // make a 2d copy
  const { height, width } = mapCtl.viewer.canvas;
  const flatCanvas = createCanvas(width, height);
  mapCtl.renderScene();
  mapCtl.viewer.render();
  flatCanvas.ctx?.drawImage(mapCtl.viewer.canvas, 0, 0);
  return flatCanvas;
};

export const setRasterAdjustment = (layers: RasterLayer[], adjustments: RasterAdjustment) =>
  layers.forEach((layer) =>
    getObjectKeys(adjustments).forEach((key) => {
      layer[key] !== adjustments[key] && adjustments[key] !== undefined && (layer[key] = adjustments[key]!);
    }),
  );

export const getRasterAdjustment = (layers: RasterLayer[]): Required<RasterAdjustment>[] =>
  layers.map((layer) => ({
    alpha: layer.alpha,
    brightness: layer.brightness,
    contrast: layer.contrast,
    hue: layer.hue,
    saturation: layer.saturation,
    gamma: layer.gamma,
  }));

export const canCreateLayer = (sourceData: SourceData): boolean =>
  [
    SourceDataType.RASTER,
    SourceDataType.VECTOR,
    SourceDataType.ANNOTATION,
    SourceDataType.SPATIAL,
    SourceDataType.DRAWING,
    SourceDataType.NETCDF,
  ].includes(sourceData.type);

export const createLayer = (sourceData: SourceData, mapCtl: Controller, options?: KeyValue): UserLayer => {
  switch (sourceData.type) {
    case SourceDataType.VECTOR:
    case SourceDataType.ANNOTATION:
      return sourceData.sourceType === AnnotationSourceType.ANNOTATION
        ? new AnnotationsVectorLayer(sourceData, mapCtl)
        : new SourceDataLayer(sourceData, { mapCtl, ...options } as SourceDataLayerOptions);
    case SourceDataType.DRAWING:
      return new DrawingLayer(sourceData, mapCtl);

    case SourceDataType.SPATIAL: {
      switch (sourceData.sourceType) {
        case SpatialSourceType.CZML:
          return new CzmlVectorLayer(sourceData, mapCtl);
        default:
          return new SourceData3DTilesLayer(sourceData);
      }
    }

    case SourceDataType.NETCDF:
      return new WindLayer(sourceData, { mapCtl, ...(options as any) });

    default:
      return new RasterLayer(sourceData);
  }
};

export const getEntityFromPointsOrCallback = (
  tool: MapTool,
  pointsOrCallback: Cartesian3[] | CallbackProperty,
  styles: any = DEFAULT_STYLES,
): Entity | undefined => {
  let polygon, rectangle, polyline, point, ellipse, position;
  switch (tool) {
    case MapTool.LINESTRING:
    case MapTool.POLYGON:
      if (tool === MapTool.POLYGON) {
        polygon = new PolygonGraphics({
          hierarchy: Array.isArray(pointsOrCallback)
            ? (getPolygonHierarchyFromTool(tool, pointsOrCallback) as any)
            : getFeaturePolygonCallbackFromPointsCallback(pointsOrCallback, tool),
          material: styles[GeometryType.POLYGON].color,
          arcType: ArcType.RHUMB,
        });
      }

      polyline = new PolylineGraphics({
        positions: Array.isArray(pointsOrCallback)
          ? getLinePosition(tool, pointsOrCallback)
          : getFeatureLineCallbackFromPointsCallback(pointsOrCallback, tool),
        width: styles[GeometryType.LINESTRING].width,
        material: getLineMaterial(styles),
        arcType: ArcType.RHUMB,
      });
      break;

    case MapTool.RECTANGLE:
      rectangle = new RectangleGraphics({
        coordinates: Array.isArray(pointsOrCallback)
          ? (getPolygonHierarchyFromTool(tool, pointsOrCallback) as any)
          : getFeaturePolygonCallbackFromPointsCallback(pointsOrCallback, tool),
        material: styles[GeometryType.POLYGON].color,
      });
      polyline = new PolylineGraphics({
        positions: Array.isArray(pointsOrCallback)
          ? getLinePosition(tool, pointsOrCallback)
          : getFeatureLineCallbackFromPointsCallback(pointsOrCallback, tool),
        width: styles[GeometryType.LINESTRING].width,
        material: getLineMaterial(styles),
        arcType: ArcType.RHUMB,
      });
      break;

    case MapTool.POINT:
      position = Array.isArray(pointsOrCallback)
        ? getPointPosition(pointsOrCallback[0])
        : (getFeaturePointCallbackFromPointsCallback(pointsOrCallback) as any);
      point = new PointGraphics({
        pixelSize: styles[GeometryType.POINT].pixelSize,
        color: styles[GeometryType.POINT].color,
        outlineColor: styles[GeometryType.POINT].outlineColor,
        outlineWidth: styles[GeometryType.POINT].outlineWidth,
      });
      break;
    case MapTool.CIRCLE:
      position = Array.isArray(pointsOrCallback)
        ? getPointPosition(pointsOrCallback[0])
        : (getFeaturePointCallbackFromPointsCallback(pointsOrCallback) as any);

      ellipse = new EllipseGraphics({
        semiMinorAxis: Array.isArray(pointsOrCallback)
          ? getCircleRadius(pointsOrCallback)
          : getFeatureCircleRadiusCallbackFromPointsCallback(pointsOrCallback, tool),
        semiMajorAxis: Array.isArray(pointsOrCallback)
          ? getCircleRadius(pointsOrCallback)
          : getFeatureCircleRadiusCallbackFromPointsCallback(pointsOrCallback, tool),
        material: styles[GeometryType.POLYGON].color,
        outlineColor: styles[GeometryType.LINESTRING].outlineColor,
      });
      break;
  }
  return new Entity({ polygon, polyline, rectangle, point, position, ellipse });
};

export const getPolygonHierarchyFromTool = (tool: MapTool, points: Cartesian3[]) => {
  const length = points.length;
  switch (tool) {
    case MapTool.RECTANGLE:
      if (length < 1) {
        return undefined;
      }
      return Rectangle.fromCartesianArray(points);

    case MapTool.POLYGON:
      if (length < 2) {
        return undefined;
      }

      return new PolygonHierarchy(points);
  }
};

export const getLinePosition = (tool: MapTool, points: Cartesian3[]) => {
  const length = points.length;
  switch (tool) {
    case MapTool.RECTANGLE:
      if (length < 1) {
        return undefined;
      }
      return getRectanglePoints(points);

    default:
      if (length < 2) {
        return undefined;
      }
      return points;
  }
};

export const getRectanglePoints = (points: Cartesian3[]) =>
  new Polygon(points.map(cartesianToPoint)).extent.points.map((p) => pointToCartesian3(p));

export const getLineMaterial = (styles: any) =>
  new PolylineOutlineMaterialProperty({
    color: styles[GeometryType.LINESTRING].color,
    outlineColor: styles[GeometryType.LINESTRING].outlineColor,
    outlineWidth: styles[GeometryType.LINESTRING].outlineWidth,
  });
export const getPointPosition = (point: Cartesian3) => point;
export const getCircleRadius = (points: Cartesian3[]) => {
  if (points.length < 2) {
    return 0;
  }
  return new EllipsoidRhumbLine(Cartographic.fromCartesian(points[0]), Cartographic.fromCartesian(points[1])).surfaceDistance;
};

const getFeaturePointCallbackFromPointsCallback = (pointsCallback: CallbackProperty) =>
  new CallbackProperty(() => getPointPosition(pointsCallback.getValue(julianDate)[0]), pointsCallback.isConstant);
const getFeaturePolygonCallbackFromPointsCallback = (pointsCallback: CallbackProperty, tool: MapTool) =>
  new CallbackProperty(() => getPolygonHierarchyFromTool(tool!, pointsCallback.getValue(julianDate)), pointsCallback.isConstant);
const getFeatureLineCallbackFromPointsCallback = (pointsCallback: CallbackProperty, tool: MapTool) =>
  new CallbackProperty(() => getLinePosition(tool!, pointsCallback.getValue(julianDate)), pointsCallback.isConstant);
const getFeatureCircleRadiusCallbackFromPointsCallback = (pointsCallback: CallbackProperty, tool: MapTool) =>
  new CallbackProperty(() => getCircleRadius(pointsCallback.getValue(julianDate)), pointsCallback.isConstant);

export const getMiddelPointBetweenTwoPoints = (point1: Cartesian3, point2: Cartesian3) => {
  return new Cartesian3((point1.x + point2.x) / 2, (point1.y + point2.y) / 2, (point1.z + point2.z) / 2);
};

export const getPointsFromEntity = (type: WKTType, entity: Entity) => {
  switch (type) {
    case WKTType.LINESTRING:
      return entity.polyline?.positions?.getValue(julianDate);
    case WKTType.POLYGON:
      return entity.polygon?.hierarchy?.getValue(julianDate)?.positions;
    case WKTType.POINT:
      return [entity.position?.getValue(julianDate)];
    default:
      return [];
  }
};
