import { singleton } from '@/utils/decorators/singleton';
import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, RequestPromise, SearchFilter } from './Api';
import type {
  MarketplaceApplicationItem,
  MarketplaceItem,
  MarketplaceItemId,
  MarketplaceModelItem,
  SubscriptionItem,
  UserId,
  WorkspaceId,
} from '@/models';
import { FormattedDateTime, MarketplaceItemSubscription, ModelId } from '@/models';
import { setDataProcessor } from '@/utils/CustomPromise';

export interface Filter extends SearchFilter {
  archived?: boolean;
  createdBy?: UserId[];
  pinned?: boolean;
  maxCreationDate?: FormattedDateTime;
  minCreationDate?: FormattedDateTime;
  types?: string | string[];
  tenantIds?: string | string[];
  tags?: string | string[];
}

export interface FilterList {
  type: string[];
  tenantIds: string[];
  tags: string[];
}

export interface ManageNavigationList {
  publishedItems: number | null;
  applications: number | null;
  models: number | null;
  subscriptionRequests: number | null;
  mySubscriptions: number | null;
}

export type MarketplaceItemUpdate = MarketplaceApplicationItem | MarketplaceModelItemUpdate;
export interface MarketplaceModelItemUpdate extends Omit<MarketplaceModelItem, 'model'> {
  modelId: ModelId;
}

export interface ApplicationData {
  views: number;
  cta: number;
}

export interface ModelData {
  views: number;
  runs: number;
  subs: number;
}

export type CombinedData = ApplicationData | ModelData;

export type PinMarketplaceItemRequestDto = {
  id: string;
  pin: boolean;
};

@singleton
class MarketplaceApi extends Api {
  endpoint: string = '/api/marketplace';
  static getInstance: () => MarketplaceApi;

  getById(id: string): RequestPromise<MarketplaceItem> {
    return this.request(METHOD.GET, '/' + id);
  }

  getList(filter?: Filter, pagination?: PageableRequest): RequestPromise<PageableResponse<MarketplaceItem>> {
    return this.request(METHOD.GET, '', { ...filter, ...DEFAULT_PAGINATION, ...pagination });
  }

  getFilterList(): RequestPromise<FilterList> {
    return this.request(METHOD.GET, '/filter-parameters');
  }

  getNavigationListNumbers(): RequestPromise<ManageNavigationList> {
    return this.request(METHOD.GET, '/seller-central');
  }

  getMarketplaceItemStatistics(id: string, query?: string): RequestPromise<CombinedData> {
    return this.request(METHOD.GET, `/${id}/statistics${query}`);
  }

  getCount(filter?: Filter): RequestPromise<number> {
    return setDataProcessor(this.getList(filter, { pageSize: 1, sort: 'createdAt' }), (res) => res.totalElements);
  }

  getByModelVersionId = (modelVersionId: ModelId): RequestPromise<MarketplaceItem> => this.request(METHOD.GET, '/model/' + modelVersionId);

  create = (marketplace: Partial<MarketplaceItemUpdate>): RequestPromise<MarketplaceItem> => this.request(METHOD.POST, '', marketplace);

  update = (marketplace: Partial<MarketplaceItemUpdate>): RequestPromise<MarketplaceItem> =>
    this.request(METHOD.PATCH, '/' + marketplace.id, marketplace);

  delete = (id: string): RequestPromise<any> => this.request(METHOD.DELETE, '/' + id);

  createOrUpdate = (marketplace: Partial<MarketplaceItemUpdate>) => (marketplace.id ? this.update(marketplace) : this.create(marketplace));

  uploadFeaturedImage = (file: FormData, id: string): RequestPromise<MarketplaceItem> => this.request(METHOD.POST, `/${id}/logo`, file);

  pinMarketplaceItem = ({ id, pin }: PinMarketplaceItemRequestDto): RequestPromise =>
    this.request(METHOD.POST, `/${id}/pin`, { pin }, {}, false);

  deleteFeaturedImage = (id: string): RequestPromise<MarketplaceItem> => this.request(METHOD.DELETE, `/${id}/logo`);

  getFeaturedImage = (id: string): RequestPromise<MarketplaceItem> => this.request(METHOD.GET, `/${id}/logo`);

  subscribe = (marketplaceItemId: MarketplaceItemId): RequestPromise<MarketplaceItemSubscription> =>
    this.request(METHOD.POST, '/' + marketplaceItemId + '/subscribe');

  unsubscribe = (marketplaceItemId: MarketplaceItemId) => this.request(METHOD.POST, '/' + marketplaceItemId + '/unsubscribe');

  postCTA = (marketplaceItemId: MarketplaceItemId) => this.request(METHOD.POST, `/${marketplaceItemId}/cta`);

  getSubscriptions(id: string, pagination?: PageableRequest): RequestPromise<PageableResponse<SubscriptionItem>> {
    const { page, pageSize, direction } = DEFAULT_PAGINATION;
    const sort = 'subscribedDate';
    return this.request(METHOD.GET, `/${id}/subscriptions`, { ...{ page, pageSize, sort, direction }, ...pagination });
  }

  getMySubscriptionsList(pagination?: PageableRequest) {
    return this.request(METHOD.GET, '/my-subscriptions', { ...DEFAULT_PAGINATION, ...pagination });
  }

  getSubscribers(pagination?: PageableRequest) {
    return this.request(METHOD.GET, '/subscribers', { ...DEFAULT_PAGINATION, ...pagination });
  }
}

export const marketplaceApi = () => MarketplaceApi.getInstance();
