import * as React from 'react';
import { Api, HTTP_CODE, METHOD, Response } from './Api';
import { Notification } from '@/components/Notification';

type LinkProps = React.HTMLProps<HTMLAnchorElement>;

export const download = (urlOrBlob: string | Blob, props?: LinkProps): void => {
  const element = document.createElement('a');
  element.href = urlOrBlob instanceof Blob ? URL.createObjectURL(urlOrBlob) : urlOrBlob;
  element.setAttribute('download', props?.name ?? '');
  element.style.display = 'none';
  if (props) {
    const attributes = props as any;
    Object.keys(attributes).forEach((key) => {
      if (key === 'name') {
        return;
      }
      if (typeof attributes[key] === 'object') {
        Object.keys(attributes[key]).forEach((k) => {
          (element as any)[key][k] = attributes[key][k];
        });
      } else {
        (element as any)[key] = attributes[key];
      }
    });
  }
  document.body.appendChild(element);
  element.click();
  if (urlOrBlob instanceof Blob) {
    URL.revokeObjectURL(element.href);
  }
  document.body.removeChild(element);
};

export const downloadFromBackend = (url: string, props?: LinkProps) => {
  const api = new Api();
  return api
    .rawRequest<Blob>(METHOD.GET, url, undefined, { responseType: 'blob' })
    .then((res) => {
      switch (res.status) {
        case HTTP_CODE.OK:
          download(res.data, props);
          break;
        case HTTP_CODE.NO_CONTENT:
          Notification.error('No data. Nothing to download');
          break;
      }
      return res;
    })
    .catch((e: Response<any>) => Notification.error(e.status === HTTP_CODE.NOT_FOUND ? 'File not found' : 'Download error'));
};
