import { Api, METHOD, prepareParams } from './Api';
import { singleton } from '@/utils/decorators/singleton';
import { getConfig } from '@/config';
import { TokenResponse } from '@/cmd/UserApi';

export const ENDPOINT = getConfig().uaePassEndpoint || 'https://stg-id.uaepass.ae';
export const CLIENT_ID = getConfig().uaePassClient || 'sandbox_stage';

@singleton
class UaePassApi extends Api {
  endpoint: string = '/oauth';
  clearCache!: (key?: string) => void;
  static getInstance: () => UaePassApi;

  getLoginUrl(returnUrl: string): string {
    const path = '/idshub/authorize';
    const scope = 'urn:uae:digitalid:profile:general';
    const acr = 'urn:safelayer:tws:policies:authentication:level:low';

    const request = {
      response_type: 'code',
      client_id: CLIENT_ID,
      scope,
      state: 'HnlHOJTkTb66Y5H',
      redirect_uri: returnUrl,
      acr_values: acr,
    };

    return ENDPOINT + path + prepareParams(request);
  }

  login = (organization: string, code: string, returnUrl: string): Promise<TokenResponse> =>
    this.request(
      METHOD.POST,
      '/token',
      prepareParams({ grant_type: 'external', type: 'UAE_PASS', username: organization + '/' + code, return_url: returnUrl }, ''),
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        auth: {
          username: process.env.CLIENT_ID ?? '',
          password: process.env.CLIENT_SECRET ?? '',
        },
      },
      false,
    );

  getLogoutUrl = (returnUrl: string) => ENDPOINT + '/idshub/logout?redirect_uri=' + encodeURIComponent(returnUrl);
}

export const uaePassApi = () => UaePassApi.getInstance();
