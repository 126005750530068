import { useCallback } from 'react';
import {
  DefaultError,
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { PageableRequest, PageableResponse } from '@/cmd/Api';

const useVirtuosoInfiniteQuery = <
  TQueryFnData = PageableResponse<unknown>,
  TError = DefaultError,
  TData = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = PageableRequest,
>(
  options: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey, TPageParam>,
): UseInfiniteQueryResult<TData, TError> & { loadMore: () => void } => {
  const queryResult = useInfiniteQuery(options);
  const { isFetchingNextPage, hasNextPage, fetchNextPage } = queryResult;
  const loadMore = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      void fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  return {
    ...queryResult,
    loadMore,
  };
};

export default useVirtuosoInfiniteQuery;
