import { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import { m } from 'framer-motion';
import { EASING } from '@/theme';
import AidLogo from '@/assets/svg/aid_logo.svg';
import GiqLogo from '@/assets/svg/logo_white.svg';
import Link from '@/components/Link';
import { getConfig } from '@/config';

const variants = {
  hidden: { x: -73 },
  enter: { x: 0, transition: { ease: EASING.EMPHASIZED_DECELERATE, duration: 0.4 } },
  exit: { x: -73, transition: { ease: EASING.EMPHASIZED_ACCELERATE, duration: 0.2 } },
};

const Layout = styled(m.div)`
  height: inherit;
  background-color: ${({ theme }) => theme.vars.palette.background.paper};
  background-image: ${({ theme }) => theme.vars.overlays[7]}; // elevation 2
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(3, 2)};
  gap: ${({ theme }) => theme.spacing(3)};
  z-index: ${({ theme }) => theme.zIndex.fab};
  border-inline-end: 1px solid ${({ theme }) => theme.vars.palette.divider};

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    nav {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${({ theme }) => theme.spacing(2)};
      @container (height <= 1000px) {
        gap: ${({ theme }) => theme.spacing(1)};
      }

      #adminNav {
        margin-top: auto;
      }
    }
  }

  .logo-holder {
    //padding: 0 16px;
    display: flex;
    align-items: center;
  }

  .logo {
    width: 48px;
    height: 26px;

    &_svg__color-stop {
      ${({ theme }) => theme.getColorSchemeSelector('light')} {
        stop-color: ${({ theme }) => theme.vars.palette.primary.main};
      }
      ${({ theme }) => theme.getColorSchemeSelector('dark')} {
        stop-color: ${({ theme }) => theme.vars.palette.secondary.main};
      }
      ${({ theme }) => theme.getColorSchemeSelector('uae_federal')} {
        stop-color: ${({ theme }) => theme.vars.palette.secondary.main};
      }
      ${({ theme }) => theme.getColorSchemeSelector('aid')} {
        stop-color: ${({ theme }) => theme.vars.palette.secondary.main};
      }
    }
  }
`;

const config = getConfig();

const NavigationRail: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Layout variants={variants} initial="hidden" animate="enter" exit="hidden">
      <Link href="/" underline="none">
        <div className="logo-holder">
          {config.defaultProduct === 'aid' ? <AidLogo viewBox="0 0 48 28" /> : <GiqLogo className="logo" viewBox="0 0 120 65" />}
        </div>
      </Link>
      <div className="container">
        <nav>{children}</nav>
      </div>
    </Layout>
  );
};

export default NavigationRail;
