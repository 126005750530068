export const stopEvent = (e: React.SyntheticEvent) => {
  e.preventDefault();
  e.stopPropagation();
  return true;
};

export const getVirtualReference = (def?: any) => ({
  getBoundingClientRect() {
    return { top: 0, left: 0, bottom: 0, right: 0, width: 0, height: 0, ...def };
  },
});

export const isElementInViewport = (element: HTMLElement, viewport: Window | HTMLElement = window) => {
  const elementRect = element.getBoundingClientRect();

  let viewportRect;

  if (viewport instanceof Window) {
    viewportRect = {
      top: window.scrollY,
      left: window.scrollX,
      right: window.scrollX + window.innerWidth,
      bottom: window.scrollY + window.innerHeight,
    };
  } else {
    viewportRect = viewport.getBoundingClientRect();
  }

  return (
    elementRect.bottom >= viewportRect.top &&
    elementRect.top <= viewportRect.bottom &&
    elementRect.right >= viewportRect.left &&
    elementRect.left <= viewportRect.right
  );
};
