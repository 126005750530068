import { singleton } from '@/utils/decorators/singleton';
import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, RequestPromise } from './Api';
import type { MarketplaceItem } from '@/models';
import { Filter, FilterList } from '@/cmd/MarketplaceApi';

@singleton
class MarketplacePublicApi extends Api {
  endpoint: string = '/api/marketplace/public';
  protected skipAuthorization = true;
  static getInstance: () => MarketplacePublicApi;

  getList(filter?: Filter, pagination?: PageableRequest): RequestPromise<PageableResponse<MarketplaceItem>> {
    return this.request(METHOD.GET, '', { ...filter, ...DEFAULT_PAGINATION, ...pagination });
  }

  getById(id: string): RequestPromise<MarketplaceItem> {
    return this.request(METHOD.GET, '/' + id);
  }

  getFilterList(): RequestPromise<FilterList> {
    return this.request(METHOD.GET, '/filter-parameters');
  }
}

export const marketplacePublicApi = () => MarketplacePublicApi.getInstance();
