// noinspection JSUnusedGlobalSymbols

import { Coordinate, GeometryType, IGeometry, ParseProps, Point, Polygon, Projection } from '.';
import { PSEUDO_MERCATOR, WORLD_GEODETIC_SYSTEM } from '@/consts';
import { KeyValue } from '@/models';

export class Line implements IGeometry {
  properties: KeyValue = {};
  protected _points: Point[] = [];
  protected _projection?: Projection;
  readonly type = GeometryType.LINESTRING;

  constructor(points: Point[] = []) {
    this._points = points;
  }

  clone(): Line {
    const line = new Line([...this.points.map((r: Point) => r.clone())]);
    line.properties = { ...this.properties };
    line.setProjection(this.projection);
    return line;
  }

  toString() {
    return `(${this._points.map((r: Point) => r.toString()).join(', ')})`;
  }

  toWKT(): string {
    return `LINESTRING (${this._points.map((r: Point) => `${r.x} ${r.y}`).join(', ')})`;
  }

  transformTo(to: Projection) {
    if (!this._projection) {
      throw Error('Source projection is not specified');
    }
    return this.transform(this._projection, to);
  }

  transform(from: Projection | ParseProps = PSEUDO_MERCATOR, to: Projection = WORLD_GEODETIC_SYSTEM) {
    if (typeof from === 'object') {
      to = from.featureProjection;
      from = from.dataProjection;
    }
    if (this._projection) {
      if (from !== this._projection) {
        throw Error('Source projection mismatch');
      }
      if (to === this._projection) {
        return this;
      }
    }
    this._projection = to;
    this._points = this._points.map((r: Point) => r.transform(from, to));
    return this;
  }

  transformBy(transformer: (p: Point) => Point) {
    this._points = this._points.map((r: Point) => transformer(r));
    return this;
  }

  move(x: number | Point = 0, y?: number) {
    this._points = this._points.map((p: Point) => p.move(x, y));
    return this;
  }

  multiply(x: number | Point = 0, y?: number) {
    this._points = this._points.map((p: Point) => p.multiply(x, y));
    return this;
  }

  divide(x: number | Point = 0, y?: number) {
    this._points = this._points.map((p: Point) => p.divide(x, y));
    return this;
  }

  mirrorY() {
    return this.multiply(1, -1);
  }

  round(): Line {
    this._points = this._points.map((p: Point) => p.round());
    return this;
  }

  getCoordinates(): Coordinate[] {
    return this._points.map((p: Point) => p.getCoordinates());
  }

  p(index: number): Point {
    return this._points[index];
  }

  get points(): Point[] {
    return this._points;
  }

  get center(): Point {
    let totalDistance = 0;
    const distanceMap = [];
    const len = this._points.length;
    if (len < 2) {
      return this.p(0).clone();
    }
    for (let idx = 1; idx < len; idx++) {
      const distance = this.p(idx - 1).distance(this.p(idx));
      totalDistance += distance;
      distanceMap.push(totalDistance);
    }
    const middleDistance = totalDistance / 2;
    const pointIdx = distanceMap.findIndex((d) => d >= middleDistance);
    const point = this.p(pointIdx).clone();
    return this.p(pointIdx + 1).moveTo(point, distanceMap[pointIdx] - middleDistance);
  }
  get extent(): Polygon {
    const { minX, minY, maxX, maxY } = this;
    const points = [new Point(minX, minY), new Point(maxX, minY), new Point(maxX, maxY), new Point(minX, maxY), new Point(minX, minY)];
    return new Polygon(points).setProjection(this.projection);
  }

  get distance(): number {
    let totalDistance = 0;
    const len = this._points.length;
    for (let idx = 1; idx < len; idx++) {
      const distance = this.p(idx - 1).distance(this.p(idx));
      totalDistance += distance;
    }
    return totalDistance;
  }

  get maxX(): number {
    return this._points.reduce((a: number, r: Point) => Math.max(a, r.x), -Infinity);
  }

  get minX(): number {
    return this._points.reduce((a: number, r: Point) => Math.min(a, r.x), Infinity);
  }

  get maxY(): number {
    return this._points.reduce((a: number, r: Point) => Math.max(a, r.y), -Infinity);
  }

  get minY(): number {
    return this._points.reduce((a: number, r: Point) => Math.min(a, r.y), Infinity);
  }

  get projection(): Projection | undefined {
    return this._projection;
  }

  setProjection(value: Projection | undefined) {
    this._projection = value;
    return this;
  }

  static ParseFromWKT(wkt: string, optProps?: ParseProps | Projection): Line {
    const data = wkt.trim().toUpperCase();
    let res;
    if (data === 'LINESTRING EMPTY') {
      return new Line();
    }
    if (data.startsWith('LINESTRING')) {
      const regexp = new RegExp('LINESTRING\\s?[Z|M]{0,2}\\s?\\(((?:(?!\\)$).)*?)\\)$', 'mi');
      const reg = regexp.exec(data) as RegExpExecArray;
      const isM = data.startsWith('LINESTRING M');
      const points = (reg[1] as string).split(',').map((c: string) => {
        const coordinate = c.trim().split(' ').map(Number);
        const point = isM ? [coordinate[0], coordinate[1], undefined, coordinate[2]] : coordinate;
        return new Point(...point);
      });
      res = new Line(points);
    }
    if (!res) {
      throw Error('Cannot parse WKT');
    }
    if (optProps) {
      res = typeof optProps === 'string' ? res.setProjection(optProps) : res.transform(optProps);
    }
    return res;
  }

  static ParseFromCoords(coords: Coordinate[] = []): Line {
    return new Line(coords.map((r) => Point.Parse(r)));
  }
}
