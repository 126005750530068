import { DataMappingPreset, ErrorResponse, FileUpload, RasterSourceType, UploadStatus, WorkspaceId } from '@/models';
import { extractFileExtension } from '@/utils/formatters';
import { Geometry } from '@/utils/geometry';
import { uploaderApi } from '@/cmd/UploaderApi';
import { QueryClient } from '@tanstack/react-query';
import { AllowedFileExtension, GRIB_FORMATS, MANUAL_TYPE_SELECTION_FORMATS } from '@/consts';

let filesUpload: FileUpload[] = [];
let state: {
  filesUpload: FileUpload[];
  isValidFileUpload: boolean;
  availableToUpdate: boolean;
  uploadInProgress: boolean;
  pendingFiles: boolean;
  isAllSuccess: boolean;
  isUploadComplete: boolean;
} = {
  filesUpload,
  isValidFileUpload: false,
  availableToUpdate: false,
  uploadInProgress: false,
  pendingFiles: false,
  isAllSuccess: false,
  isUploadComplete: false,
};
const listeners: Set<() => void> = new Set();

function emitChange() {
  generateSnapshot();
  for (const listener of listeners) {
    listener();
  }
}

const getDefaultFileSourceType = (file: File) => {
  const ext = extractFileExtension(file.name) as AllowedFileExtension;
  if (GRIB_FORMATS.includes(ext)) {
    return RasterSourceType.MULTISPECTRAL;
  }
  return undefined;
};

export const fileUploadStore = {
  getFileIndex(file: FileUpload) {
    return filesUpload.findIndex((f) => f.name === file.name);
  },
  addFilesToUploader(files: File[]) {
    const newFiles = files
      .filter((f) => !filesUpload.find((ff) => ff.name === f.name))
      .map((f) => ({
        name: f.name,
        size: f.size,
        status: UploadStatus.NOT_STARTED,
        progress: 0,
        ext: extractFileExtension(f.name),
        file: f,
        sourceType: getDefaultFileSourceType(f),
      }));
    filesUpload = [...filesUpload, ...newFiles];
    emitChange();
  },
  updateStatus(file: FileUpload, status: UploadStatus, details?: string) {
    const data: Partial<FileUpload> = { status };
    if (status === UploadStatus.QUEUED) {
      data.progress = 0;
      data.statusDetails = '';
    }
    details && (data.statusDetails = details);
    fileUploadStore.updateFileData(file, data);
  },
  updateProgress(file: FileUpload, progress: number) {
    fileUploadStore.updateFileData(file, { progress: Math.round(progress) });
  },
  setSourceType(file: FileUpload, sourceType: RasterSourceType) {
    fileUploadStore.updateFileData(file, { sourceType });
  },
  addToWorkspace(file: FileUpload, workspaceId: WorkspaceId | undefined) {
    fileUploadStore.updateFileData(file, { layerProperties: { ...file.layerProperties, workspaceId } });
  },
  setGeometry(file: FileUpload, geometry: Geometry | undefined) {
    fileUploadStore.updateFileData(file, { geometry });
  },
  setDataMapping(file: FileUpload, dataMappingPreset: Partial<DataMappingPreset>) {
    const index = fileUploadStore.getFileIndex(file);
    if (index > -1) {
      const copy = [...filesUpload];
      copy[index].dataMappingInfo = dataMappingPreset;
      filesUpload = copy;
      emitChange();
    }
  },
  removeFileUpload(file: FileUpload) {
    const index = fileUploadStore.getFileIndex(file);
    if (index > -1) {
      const copy = [...filesUpload];
      copy.splice(index, 1);
      filesUpload = copy;
      emitChange();
    }
  },
  prepareUpload() {
    filesUpload = filesUpload.map((file) => ({
      ...file,
      status: file.status === UploadStatus.NOT_STARTED ? UploadStatus.QUEUED : file.status,
    }));
    emitChange();
  },
  updateFileData(file: FileUpload, data: Partial<FileUpload>) {
    const index = fileUploadStore.getFileIndex(file);
    if (index > -1) {
      filesUpload = filesUpload.with(index, { ...filesUpload[index], ...data });
      emitChange();
    }
  },
  checkFileStatus(file: FileUpload) {
    const index = fileUploadStore.getFileIndex(file);
    return filesUpload[index].status;
  },
  async uploadFiles(queryClient: QueryClient) {
    if (filesUpload.every((f) => f.status !== UploadStatus.UPLOADING)) {
      for (let i = 0; i < filesUpload.length; i++) {
        const file = filesUpload[i];
        if (filesUpload[i].status === UploadStatus.QUEUED) {
          try {
            fileUploadStore.updateStatus(file, UploadStatus.UPLOADING);
            const result = await uploaderApi().uploadFile(file, fileUploadStore);
            result && fileUploadStore.updateStatus(file, UploadStatus.UPLOADED);
          } catch (e) {
            fileUploadStore.updateStatus(file, UploadStatus.FAILED, (e as ErrorResponse)?.message);
          } finally {
            void queryClient.invalidateQueries({ queryKey: ['ingestion_jobs'] });
          }
        }
      }
      if (filesUpload.some((f) => f.status === UploadStatus.QUEUED)) {
        void fileUploadStore.uploadFiles(queryClient);
      }
    }
  },
  subscribe(listener: () => void) {
    listeners.add(listener);
    return () => {
      listeners.delete(listener);
    };
  },
  getSnapshot() {
    return state;
  },
  getServerSnapshot() {
    return state;
  },
};

const generateSnapshot = () => {
  state = {
    filesUpload,
    ...getUploadStatuses(filesUpload),
  };
};

const getUploadStatuses = (filesUpload: FileUpload[]) => {
  const availableToUpdate = filesUpload.some((file) => file.status === UploadStatus.NOT_STARTED);
  const uploadInProgress = filesUpload.some((file) => file.status === UploadStatus.UPLOADING);
  const pendingFiles = filesUpload.some((file) => file.status === UploadStatus.QUEUED);
  const isAllSuccess = filesUpload.every((file) => file.status === UploadStatus.UPLOADED);
  const isUploadComplete = filesUpload.every((f) =>
    [UploadStatus.UPLOADED, UploadStatus.FAILED, UploadStatus.CANCELLED, UploadStatus.SKIPPED].includes(f.status),
  );
  const isValidFileUpload = filesUpload
    .filter((file) => MANUAL_TYPE_SELECTION_FORMATS.includes(file.ext))
    .every((file) => !!file.sourceType);

  return {
    availableToUpdate,
    uploadInProgress,
    pendingFiles,
    isAllSuccess,
    isUploadComplete,
    isValidFileUpload,
  };
};

export type FileUploaderStore = typeof fileUploadStore;
