import {
  Color,
  Entity,
  PointGraphics,
  PolygonGraphics,
  PolylineGraphics,
  PolylineOutlineMaterialProperty,
  RectangleGraphics,
} from 'cesium';
import { FeatureStyle, GeometryStyles, KeyValue, PropertyStyle, StylesMode, VectorStyles } from '@/models';
import { GeometryType } from '@/utils/geometry';
import { getObjectKeys } from '@/utils';
import { EntityGraphics, cartesianToPoint, getColor, pointToCartesian3 } from './index';
import { ExtendedGeometryType, RectangleType } from '@/components/Cesium/DataSource/BaseVectorLayer';

export const DEFAULT_STYLES: GeometryStyles = {
  mode: StylesMode.Geometry,
  rules: {
    [GeometryType.POLYGON]: {
      fill: '#07a1ff50',
      stroke: '#07a1ff',
      strokeWidth: 2,
      fillDisabled: true,
    },
    [GeometryType.LINESTRING]: {
      fill: '#00d1fa50',
      stroke: '#00d1fa',
      strokeWidth: 2,
      fillDisabled: true,
    },
    [GeometryType.POINT]: {
      fill: '#00b92950',
      stroke: '#00b929',
      strokeWidth: 10,
      fillDisabled: true,
    },
  },
};

export const DEFAULT_PROPERTY_STYLE: PropertyStyle = {
  mode: StylesMode.Property,
  property: '',
  rules: [],
};

export const DEFAULT_FEATURE_STYLE = DEFAULT_STYLES.rules[GeometryType.POLYGON]!;

const KeepKeysOnMerge = ['hierarchy', 'position', 'positions', 'coordinates'];
export const setEntityStyles = (entity: Entity, entityGraphics: EntityGraphics) => {
  getObjectKeys(entityGraphics).forEach((key) => {
    const graphics = entity[key];
    const keepData: any = {};
    KeepKeysOnMerge.forEach((k) => (keepData[k as any] = graphics && k in graphics ? (graphics as any)[k] : undefined));
    entity[key] = entityGraphics[key] as any;
    KeepKeysOnMerge.forEach((k) => {
      const entityGraphicsItem = (entity as any)[key];
      if (entityGraphicsItem && keepData[k]) {
        entityGraphicsItem[k] = keepData[k];
      }
    });
  });
};

export const getCurrentStyle = (styles: VectorStyles, valueOrGeometryType?: GeometryType | string) => {
  let style;
  if (styles && valueOrGeometryType) {
    if (styles.mode === StylesMode.Geometry) {
      style = (styles.rules as any)[valueOrGeometryType];
    } else if (styles.mode === StylesMode.Property) {
      style = styles.rules.find((r) => r.value === valueOrGeometryType)?.style || {
        ...DEFAULT_FEATURE_STYLE,
        strokeWidth: styles.strokeWidth,
      };
    } else {
      style = styles.rules[valueOrGeometryType];
    }
  }

  return style || DEFAULT_FEATURE_STYLE;
};

// https://github.com/CesiumGS/cesium/issues/11388
export const TRANSPARENT_COLOR_FIX = Color.fromCssColorString('#00000001');

export const getEntityGraphicsFromStyle = (geometryType: ExtendedGeometryType, style: FeatureStyle, options?: KeyValue): EntityGraphics => {
  const width = style.strokeWidth;
  const outlineWidth = style.outlineWidth;
  const fill = style.fill && !style.fillDisabled ? getColor(style.fill) : TRANSPARENT_COLOR_FIX;
  const color = style.stroke && !style.strokeDisabled ? getColor(style.stroke) : Color.TRANSPARENT;
  const outlineColor = style.outlineColor && !style.strokeDisabled ? getColor(style.outlineColor) : Color.TRANSPARENT;

  switch (geometryType) {
    case RectangleType.RECTANGLE: {
      const material = outlineWidth ? new PolylineOutlineMaterialProperty({ color, outlineColor, outlineWidth }) : color;
      const rectangle = new RectangleGraphics({
        outline: color !== Color.TRANSPARENT,
        outlineColor: color,
        material: fill,
        outlineWidth,
        extrudedHeight: options?.extrudedHeight,
      });
      const polyline = new PolylineGraphics({ clampToGround: options?.clampToGround, width, material });
      return { polyline, rectangle };
    }
    case GeometryType.POLYGON: {
      const material = outlineWidth ? new PolylineOutlineMaterialProperty({ color, outlineColor, outlineWidth }) : color;
      const polygon = new PolygonGraphics({
        outline: color !== Color.TRANSPARENT,
        outlineColor: color,
        material: fill,
        outlineWidth,
        perPositionHeight: options?.perPositionHeight,
        extrudedHeight: options?.extrudedHeight,
      });
      const polyline = new PolylineGraphics({ clampToGround: options?.clampToGround, width, material });
      return { polygon, polyline };
    }

    case GeometryType.LINESTRING: {
      const material = outlineWidth ? new PolylineOutlineMaterialProperty({ color, outlineColor, outlineWidth }) : color;
      const polyline = new PolylineGraphics({ clampToGround: options?.clampToGround, zIndex: options?.zIndex, width, material });
      return { polyline };
    }

    case GeometryType.POINT: {
      const point = new PointGraphics({ color, pixelSize: width, outlineColor, outlineWidth });

      return { point };
    }

    default:
      throw Error('Not supported');
  }
};
