import { Entity } from 'cesium';
import { BaseVectorLayer } from './BaseVectorLayer';
import { Geometry, GeometryType, parseFromWKT } from '@/utils/geometry';
import { cloneEntity, EntityGraphics } from '@/components/Cesium/utils';
import { AnnotationFeature, AnnotationRules, AnnotationStyles, Category, FeatureId, KeyValue, SourceData, StylesMode } from '@/models';
import { PageableResponse } from '@/cmd/Api';
import { categoryApi } from '@/cmd/CategoryApi';
import { annotationApi } from '@/cmd/AnnotationApi';
import { Controller } from '@/components/Cesium/Map/Controller';

const BATCH_SIZE = 5000;
export const ACTIVE_FEATURE_ID = 'active-feature';

const DEFAULT_STYLE = {
  fill: '#5C5D5E50',
  fillDisabled: true,
  stroke: '#5C5D5E',
  strokeWidth: 2,
};

export class AnnotationsVectorLayer extends BaseVectorLayer {
  readonly sourceData: SourceData;
  protected mapCtl: Controller;
  protected _features: { [key: string]: AnnotationFeature } = {};
  protected _styles!: AnnotationStyles;
  protected _active?: FeatureId;

  constructor(source: SourceData, mapCtl: Controller) {
    super(source.id, source.name);
    this.sourceData = source;
    this.mapCtl = mapCtl;
    this.entities.collectionChanged.addEventListener(() => this.mapCtl.renderScene());

    categoryApi()
      .getAll((page) => categoryApi().getList({ page }))
      .then((res) => (this.categories = res))
      .then(this.loadAllFeatures);
  }

  getEntityGraphics(entity: Entity, styles: AnnotationStyles = this._styles): EntityGraphics {
    const categoryId = this.getFeatureByEntity(entity)?.categoryId;
    const geometryType = entity.properties?.geometry || GeometryType.POLYGON;
    const style = (categoryId && this._styles.rules[categoryId]) || DEFAULT_STYLE;
    return this.getEntityGraphicsByGeometryType(geometryType, style);
  }

  getGeometryGraphics(geometry: Geometry, style?: any, options?: KeyValue): EntityGraphics {
    const categoryId = geometry.properties?.categoryId;
    const categoryStyle = (categoryId && (style || this._styles).rules[categoryId]) || DEFAULT_STYLE;
    return this.getEntityGraphicsByGeometryType(geometry.type, categoryStyle, options);
  }

  createEntity(feature: AnnotationFeature): Entity | Entity[] | undefined {
    return super.createEntity(feature, { id: feature.id });
  }

  getFeatureGeometry(feature: AnnotationFeature): Geometry | undefined {
    const geometry = parseFromWKT(feature.geometry);
    if (geometry) {
      geometry.properties = { featureId: feature.id, categoryId: feature.categoryId };
    }
    return geometry;
  }

  getFeatureByEntity(entity: Entity): AnnotationFeature | undefined {
    return this._features[entity.id];
  }

  getFeatureById(featureId: FeatureId): AnnotationFeature | undefined {
    return this._features[featureId];
  }

  set categories(categories: Category[]) {
    const rules: AnnotationRules = categories.reduce((acc, current) => {
      return {
        ...acc,
        [current.id]: {
          fill: current.color + '66', // 20% alpha
          fillDisabled: true,
          stroke: current.color,
          strokeWidth: 2,
        },
      };
    }, {});

    this.setStyles({ mode: StylesMode.Annotation, rules });
  }

  set active(selected: FeatureId | undefined) {
    if (selected !== this._active) {
      this._active = selected;
      if (this.viewer && this.viewer.cesiumWidget) {
        this.viewer.entities.removeById(ACTIVE_FEATURE_ID);
        if (selected) {
          const entity = this.entities.getById(selected);
          if (entity) {
            const selectedFeature = cloneEntity(entity, { id: ACTIVE_FEATURE_ID });
            if (selectedFeature.point?.outlineWidth) {
              (selectedFeature.point.outlineWidth as any) += 2;
            }
            if (selectedFeature.polyline?.width) {
              (selectedFeature.polyline.width as any) += 2;
            }
            this.viewer.entities.add(selectedFeature);
          }
        }
        this.changedEvent.raiseEvent(this as any);
      }
    }
  }

  protected loadAllFeatures = async (): Promise<any> => {
    let page = 0;
    let res = { last: false } as PageableResponse<AnnotationFeature>;
    while (!this.destroyed && res && !res.last) {
      res = await annotationApi().getAnnotationsList(this.id!, undefined, { page: page++, pageSize: BATCH_SIZE });
      if (this.destroyed) {
        break;
      }
      await this.addFeatures(res.content);
    }
  };

  updateFeature = (feature: AnnotationFeature) => {
    this.removeFeature(feature.id);
    return this.addFeatures([feature]);
  };

  removeFeature(idOrEntity: string | Entity) {
    const id = idOrEntity instanceof Entity ? idOrEntity.id : idOrEntity;
    if (this._features[id]) {
      delete this._features[id];
    }
    return super.removeFeature(idOrEntity);
  }

  addFeatures(list: AnnotationFeature[], suspendEvents = true) {
    const newFeatures = list.filter((feature) => {
      if (this._features[feature.id]) {
        console.error('Annotation already exists', feature.id);
        return false;
      }
      this._features[feature.id] = feature;
      return true;
    });
    return super.addFeatures(newFeatures);
  }
}
