import React, { FC } from 'react';
import { Box, BoxProps, styled, useTheme } from '@mui/material';

const Layout = styled(Box)`
  position: absolute;
  text-align: left;
  bottom: 0;
  inset-inline-end: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
  }

  &,
  &:before,
  &:after {
    width: 4px;
    height: 4px;
  }

  & {
    transform: rotate(90deg);
  }

  &:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }

  &:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }

  &[dir='rtl']:before {
    transform: rotate(135deg) skewX(45deg) scale(1.414, 0.707) translate(0, 50%);
  }

  &[dir='rtl']:after {
    transform: rotate(-135deg) skewY(45deg) scale(0.707, 1.414) translate(-50%);
  }
`;

interface Props extends BoxProps {
  color?: string;
}

export const CornerIcon: FC<Props> = (props) => {
  const theme = useTheme();
  const { color, ...restProps } = props;
  return <Layout dir={theme.direction} bgcolor={color || theme.palette.secondary.main} {...restProps} />;
};
