import { FC, useRef, useState, memo, useEffect } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { MotionComponents } from '@/components/Virtuoso';
import { Notification, NotificationId } from '@/models';
import { arrayUnique } from '@/utils';
import { LoadingNotification, NotificationListItem } from '@/components/Notifications/NotificationListItem';
import { notificationsApi } from '@/cmd/NotificationsApi';
import { debounce } from '@/hooks/debounce';

export const MAX_HEIGHT = 320;

interface Props {
  items: Notification[];
  loadMore: () => void;
  updateList: () => void;
  updateCount: () => void;
}

const NotificationList: FC<Props> = ({ items, loadMore, updateList, updateCount }) => {
  const [minHeight, setMinHeight] = useState(100);
  const readRef = useRef<NotificationId[]>([]);
  const setMinHeightDebounce = debounce(setMinHeight, 50);
  const onRead = (item: Notification) => readRef.current.push(item.id);

  const getNotificationItem = (idx: number, item: Notification | LoadingNotification) => (
    <NotificationListItem onRead={onRead} idx={idx} item={item} />
  );

  useEffect(() => {
    return () => {
      const list = arrayUnique(readRef.current);
      if (list.length) {
        notificationsApi().markRead(list).finally(updateCount);
      }
    };
  }, []);

  return (
    <div style={{ height: minHeight }}>
      <Virtuoso
        style={{ height: '100%', overflow: 'hidden auto' }}
        data={items}
        components={MotionComponents}
        endReached={loadMore}
        itemContent={getNotificationItem}
        totalListHeightChanged={(h) => setMinHeightDebounce(Math.min(MAX_HEIGHT, h))}
      />
    </div>
  );
};

export default memo(NotificationList);
