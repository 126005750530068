import { Box, BoxProps, LinearProgress, LinearProgressProps, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

interface Props extends LinearProgressProps {
  label?: string;
  labelColor?: TypographyProps['color'];
  ContainerProps?: BoxProps;
}
const LinearProgressWithLabel: FC<Props> = ({ label, labelColor = 'text.secondary', ContainerProps, ...props }) => {
  return (
    <Box
      {...ContainerProps}
      sx={[
        { display: 'flex', alignItems: 'center', gap: 2 },
        ...(ContainerProps?.sx ? (Array.isArray(ContainerProps?.sx) ? ContainerProps.sx : [ContainerProps.sx]) : []),
      ]}
    >
      <Box sx={{ flex: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      {(label !== undefined || props.value !== undefined) && (
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color={labelColor} sx={{ whiteSpace: 'nowrap' }}>
            {label ?? `${Math.round(props.value!)}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default LinearProgressWithLabel;
