import { forwardRef } from 'react';
import { HTMLMotionProps, m } from 'framer-motion';
import { EASING } from '@/theme';

const container = {
  hidden: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.3, easing: EASING.LEGACY } },
  exit: { opacity: 0, transition: { duration: 0.2, easing: EASING.LEGACY } },
};

export const MotionScroller = forwardRef<HTMLDivElement, HTMLMotionProps<'div'>>(function MotionScroller(props, ref) {
  return <m.div ref={ref} {...props} variants={container} initial="hidden" animate="animate" exit="exit" />;
});
