import {
  findPerpendicularPointsByDistance,
  findPointInSameLine,
  Geometry,
  GeometryType,
  Line,
  parseFromWKT,
  Point,
} from '@/utils/geometry';
import { cartesianToPoint, createEntity, EntityGraphics, getColor, pointToCartesian3, setEntityPosition } from '@/components/Cesium/utils';
import {
  AnnotationFeature,
  DrawingFeature,
  DrawingFeatureLinePointer,
  DrawingFeatureProperties,
  DrawingFeatureStrokeStyle,
  DrawingFeatureStyleType,
  FeatureId,
  KeyValue,
  SourceData,
} from '@/models';
import { PageableResponse } from '@/cmd/Api';
import { Controller } from '@/components/Cesium/Map/Controller';
import { dataSourceApi } from '@/cmd/DataSourceApi';
import { FeaturesLayer } from '@/components/Cesium/DataSource/FeaturesLayer';
import { setEntityStyles, TRANSPARENT_COLOR_FIX } from '@/components/Cesium/utils/styles';
import { removeEmpty } from '@/utils';
import {
  BillboardGraphics,
  Cartesian2,
  Cartesian3,
  Color,
  Entity,
  JulianDate,
  LabelGraphics,
  LabelStyle,
  PointGraphics,
  PolygonGraphics,
  PolylineDashMaterialProperty,
  PolylineGraphics,
} from 'cesium';
import {
  DEFAULT_LABEL_FONT,
  DEFAULT_LABEL_FONT_SIZE,
  DEFAULT_STROKE_COLOR,
  DEFAULT_STROKE_WIDTH,
  getSymbol,
} from '@/components/Map/RightSidebar/Drawing/utils';
import { blobToCanvas, createCanvas } from '@/utils/canvas';
import { MERCATOR_TO_WGS, WGS_TO_MERCATOR } from '@/consts';

export const LINE_STYLE_ARROW_SCALE = 40;

const BATCH_SIZE = 5000;
const SYMBOL_SIZE_MULTIPLIER = 10;
export const ACTIVE_FEATURE_ID = 'active-feature';

export class DrawingLayer extends FeaturesLayer {
  readonly sourceData: SourceData;
  protected mapCtl: Controller;
  protected features: Record<FeatureId, DrawingFeature> = {};
  protected featureChildrenEntities: Record<FeatureId, Entity[]> = {};
  protected _active?: DrawingFeature;

  constructor(source: SourceData, mapCtl: Controller) {
    super(source.id, source.name);
    this.sourceData = source;
    this.mapCtl = mapCtl;
    this.entities.collectionChanged.addEventListener(() => this.mapCtl.renderScene());
    void this.loadAllFeatures();
  }

  protected loadAllFeatures = async (): Promise<any> => {
    let page = 0;
    let res = { last: false } as PageableResponse<AnnotationFeature>;
    while (!this.destroyed && res && !res.last) {
      res = await dataSourceApi().getFeaturesList(this.id!, undefined, { page: page++, pageSize: BATCH_SIZE });
      if (this.destroyed) {
        break;
      }
      res.content.forEach((f) => this.addFeature(f));
    }
  };

  // TODO
  protected getEntityGraphics(geometry: Geometry): EntityGraphics {
    const featureProperties = geometry.properties.feature.properties as DrawingFeatureProperties;
    const {
      label,
      labelFont = DEFAULT_LABEL_FONT,
      labelStrokeColor,
      labelStrokeWidth,
      labelFillColor,
      labelFontSize = DEFAULT_LABEL_FONT_SIZE,
      labelFillDisabled,
      labelStrokeDisabled,
      labelBackground,
      labelBackgroundPadding,
    } = featureProperties;
    let labelGraphics;
    if (label) {
      const text = label.substring(0, 255);
      labelGraphics = new LabelGraphics({
        text,
        show: true,
        font: labelFontSize + 'px ' + labelFont,
        fillColor: labelFillColor && !labelFillDisabled ? Color.fromCssColorString(labelFillColor) : TRANSPARENT_COLOR_FIX,
        outlineColor: labelStrokeColor && !labelStrokeDisabled ? Color.fromCssColorString(labelStrokeColor) : TRANSPARENT_COLOR_FIX,
        outlineWidth: labelStrokeWidth,
        showBackground: !!labelBackground,
        backgroundColor: (labelBackground && Color.fromCssColorString(labelBackground)) || undefined,
        backgroundPadding: (labelBackgroundPadding && new Cartesian2(labelBackgroundPadding, labelBackgroundPadding)) || undefined,
        style: LabelStyle.FILL_AND_OUTLINE,
        // scaleByDistance: new NearFarScalar(1.5e2, 1.0, 1.5e5, 0.1),
        // eyeOffset: labelStyles.eyeOffset, // https://github.com/CesiumGS/cesium/issues/4108
        // scale: 2,
      });
    }
    const graphics = this.getEntityGraphicsFromProperties(geometry.type, featureProperties);
    graphics.label = labelGraphics;

    return graphics;
  }

  // Todo: handle custom styles
  protected createEntity(feature: DrawingFeature, options?: KeyValue): Entity[] | undefined {
    const geometry = parseFromWKT(feature.geometry);
    if (!geometry) {
      return undefined;
    }
    // TODO: DRAG-3045
    feature.properties = removeEmpty(feature.properties);
    geometry.properties.feature = feature;
    const entityGraphics = this.getEntityGraphics(geometry);
    options = { ...options, properties: { ...options?.properties, feature }, entityGraphics };
    const featureEntity = createEntity(geometry, undefined, options)!;
    this.attachBillboard(featureEntity);
    return [
      featureEntity, // First entity is always original feature entity
      ...this.createChildrenEntities(featureEntity),
    ];
  }

  updateFeature = (feature: DrawingFeature) => {
    const geometry = parseFromWKT(feature.geometry);
    if (!geometry) {
      return undefined;
    }
    const entity = this.getEntityById(feature.id);
    // TODO: DRAG-3045
    feature.properties = removeEmpty(feature.properties);
    geometry.properties.feature = feature;
    if (entity && entity.properties) {
      entity.properties.geometry = geometry;
      entity.properties.feature = feature;
      const entityGraphics = this.getEntityGraphics(entity.properties.geometry);
      // Todo: do not update if icon was not changed
      this.attachBillboard(entity);
      setEntityStyles(entity, entityGraphics);
      setEntityPosition(entity, geometry);
      this.createChildrenEntities(entity)?.forEach((e) => this.entities.add(e));
    }
  };

  addFeature(feature: DrawingFeature) {
    if (this.features[feature.id]) {
      console.error('Drawing feature already exists', feature.id);
      return [];
    }
    this.features[feature.id] = feature;
    const entities = this.createEntity(feature, { id: feature.id });
    entities?.forEach((e) => this.entities.add(e));

    return entities;
  }

  /**
   * Create additional entities for the geometry styling, such as arrows for the line.
   */
  protected createChildrenEntities = (featureEntity: Entity): Entity[] => {
    this.removeFeatureChildrenEntities(featureEntity.id);
    const childrenEntities: Entity[] = [];
    const feature = featureEntity?.properties?.feature as DrawingFeature;
    const featureProperties = feature?.properties;
    if (featureProperties) {
      const positions = featureEntity?.polyline?.positions?.getValue(new JulianDate());
      const { startPointer, endPointer } = featureProperties;
      if (positions && (startPointer || endPointer)) {
        const line = new Line(positions.map((ele: Cartesian3) => cartesianToPoint(ele).transform(WGS_TO_MERCATOR)));
        const size = Math.max(line.maxX - line.minX, line.maxY - line.minY);
        const { strokeColor = DEFAULT_STROKE_COLOR, strokeWidth = DEFAULT_STROKE_WIDTH } = featureProperties;

        if (startPointer && startPointer !== DrawingFeatureLinePointer.NONE) {
          const entityData = {
            ...(this.getStylePolygon(
              startPointer,
              cartesianToPoint(positions[1]).transform(WGS_TO_MERCATOR),
              cartesianToPoint(positions[0]).transform(WGS_TO_MERCATOR),
              strokeColor,
              (size * strokeWidth) / LINE_STYLE_ARROW_SCALE,
            ) as any),
            parent: featureEntity,
          };
          childrenEntities.push(new Entity(entityData));
        }

        if (endPointer && endPointer !== DrawingFeatureLinePointer.NONE) {
          const entityData = {
            ...(this.getStylePolygon(
              endPointer,
              cartesianToPoint(positions[positions.length - 2]).transform(WGS_TO_MERCATOR),
              cartesianToPoint(positions[positions.length - 1]).transform(WGS_TO_MERCATOR),
              strokeColor,
              (size * strokeWidth) / LINE_STYLE_ARROW_SCALE,
            ) as any),
            parent: featureEntity,
          };
          childrenEntities.push(new Entity(entityData));
        }
      }
      childrenEntities.length && (this.featureChildrenEntities[featureEntity.id] = childrenEntities);
    }
    return childrenEntities;
  };

  protected attachBillboard(entity: Entity) {
    const featureProperties = entity.properties?.feature.properties as DrawingFeatureProperties;
    const { type, icon, pointSize = 1 } = featureProperties || {};
    if (type === DrawingFeatureStyleType.ICON && icon) {
      const size = Number(pointSize) * SYMBOL_SIZE_MULTIPLIER;
      getSymbol(icon).then((svg) => {
        if (svg) {
          svg.setAttribute('width', size.toString());
          svg.setAttribute('height', size.toString());
          const svgBlob = new Blob([svg.outerHTML], { type: 'image/svg+xml' });
          blobToCanvas(svgBlob, createCanvas(size, size)).then((canvas) => {
            entity.billboard = new BillboardGraphics({ image: canvas.canvas });
          });
        }
      });
    }
    entity.billboard = undefined;
    return entity;
  }

  removeFeature(idOrEntity: string | Entity) {
    const featureId = idOrEntity instanceof Entity ? idOrEntity.id : idOrEntity;
    if (this.features[featureId]) {
      delete this.features[featureId];
      this.removeFeatureChildrenEntities(featureId);
    }
    return super.removeFeature(idOrEntity);
  }

  removeFeatureChildrenEntities(featureId: FeatureId) {
    this.featureChildrenEntities[featureId]?.map((e: Entity) => this.entities.remove(e));
    this.featureChildrenEntities[featureId] = [];
  }

  protected getEntityGraphicsFromProperties = (geometryType: GeometryType, properties: DrawingFeatureProperties): EntityGraphics => {
    const fillColor = properties.fillColor && !properties.fillDisabled ? getColor(properties.fillColor) : TRANSPARENT_COLOR_FIX;
    const strokeColor = properties.strokeColor && !properties.strokeDisabled ? getColor(properties.strokeColor) : TRANSPARENT_COLOR_FIX;
    const outlineColor = strokeColor;
    const outlineWidth = properties.strokeWidth;

    switch (geometryType) {
      case GeometryType.POLYGON: {
        let polyline;
        const outline = strokeColor !== Color.TRANSPARENT;
        const polygon = new PolygonGraphics({ outline, outlineColor, material: fillColor, outlineWidth });
        if (outlineWidth && !properties.strokeDisabled) {
          polyline = new PolylineGraphics({ width: outlineWidth, material: outlineColor, clampToGround: true });
        }
        return { polyline, polygon };
      }

      case GeometryType.LINESTRING: {
        const material =
          properties.strokeStyle === DrawingFeatureStrokeStyle.DASHED
            ? new PolylineDashMaterialProperty({ color: strokeColor })
            : strokeColor;
        const polyline = new PolylineGraphics({ width: outlineWidth, material, clampToGround: true });
        return { polyline };
      }

      case GeometryType.POINT: {
        const point = new PointGraphics({ color: fillColor, pixelSize: properties.pointSize, outlineColor, outlineWidth });

        return { point };
      }

      default:
        throw Error('Not supported');
    }
  };

  protected getStylePolygon = (type: DrawingFeatureLinePointer, point1: Point, point2: Point, color: string, sizeInMeters: number) => {
    switch (type) {
      case DrawingFeatureLinePointer.ARROW:
        const basePoint = findPointInSameLine(point1.x, point1.y, point2.x, point2.y, (-1 * sizeInMeters) / 2)
          .clone()
          .transform(WGS_TO_MERCATOR);
        const [shapePoint3, shapePoint4] = findPerpendicularPointsByDistance(
          point1.x,
          point1.y,
          basePoint.x,
          basePoint.y,
          sizeInMeters / 2,
        );
        const headPoint = findPointInSameLine(point1.x, point1.y, point2.x, point2.y, sizeInMeters / 2);
        return {
          polygon: {
            hierarchy: [
              pointToCartesian3(headPoint),
              pointToCartesian3(shapePoint3),
              pointToCartesian3(shapePoint4),
              pointToCartesian3(headPoint),
            ],
            material: Color.fromCssColorString(color),
          },
        };
      case DrawingFeatureLinePointer.DIAMOND:
        const diamondpoint4 = findPointInSameLine(point1.x, point1.y, point2.x, point2.y, (-1 * Math.sqrt(2) * sizeInMeters) / 2);
        const [diamondPoint1, diamondPoint2] = findPerpendicularPointsByDistance(
          point1.x,
          point1.y,
          point2.x,
          point2.y,
          (Math.sqrt(2) * sizeInMeters) / 2,
        );
        const diamondPoint3 = findPointInSameLine(point1.x, point1.y, point2.x, point2.y, (Math.sqrt(2) * sizeInMeters) / 2);
        return {
          polygon: {
            hierarchy: [
              pointToCartesian3(diamondpoint4),
              pointToCartesian3(diamondPoint1),
              pointToCartesian3(diamondPoint3),
              pointToCartesian3(diamondPoint2),
              pointToCartesian3(diamondpoint4),
            ],
            material: Color.fromCssColorString(color),
          },
        };
      case DrawingFeatureLinePointer.CIRCLE:
        return {
          position: pointToCartesian3(point2.clone().transform(MERCATOR_TO_WGS)),
          ellipse: {
            semiMinorAxis: sizeInMeters / 2,
            semiMajorAxis: sizeInMeters / 2,
            material: Color.fromCssColorString(color),
          },
        };
      case DrawingFeatureLinePointer.SQUARE:
        const squareLineCenter1 = findPointInSameLine(point1.x, point1.y, point2.x, point2.y, -sizeInMeters / 2)
          .clone()
          .transform(WGS_TO_MERCATOR);
        const squareLineCenter2 = findPointInSameLine(point1.x, point1.y, point2.x, point2.y, sizeInMeters / 2)
          .clone()
          .transform(WGS_TO_MERCATOR);
        const [squarePoint1, squarePoint2] = findPerpendicularPointsByDistance(
          point1.x,
          point1.y,
          squareLineCenter1.x,
          squareLineCenter1.y,
          sizeInMeters / 2,
        );
        const [squarePoint3, squarePoint4] = findPerpendicularPointsByDistance(
          point1.x,
          point1.y,
          squareLineCenter2.x,
          squareLineCenter2.y,
          sizeInMeters / 2,
        );
        return {
          polygon: {
            hierarchy: [
              pointToCartesian3(squarePoint1),
              pointToCartesian3(squarePoint2),
              pointToCartesian3(squarePoint4),
              pointToCartesian3(squarePoint3),
            ],
            material: Color.fromCssColorString(color),
          },
        };
      case DrawingFeatureLinePointer.ARROW_NOTCH:
        const arrowNotchTailCenter = findPointInSameLine(point1.x, point1.y, point2.x, point2.y, (-1.5 * sizeInMeters) / 2);
        const [arrowNotchPoint1, arrowNotchPoint2] = findPerpendicularPointsByDistance(
          point1.x,
          point1.y,
          arrowNotchTailCenter.clone().transform(WGS_TO_MERCATOR).x,
          arrowNotchTailCenter.clone().transform(WGS_TO_MERCATOR).y,
          sizeInMeters / 2,
        );
        const arrowNotchhead = findPointInSameLine(point1.x, point1.y, point2.x, point2.y, sizeInMeters / 2);
        const arrowNotchBase = findPointInSameLine(point1.x, point1.y, point2.x, point2.y, -sizeInMeters / 4);

        return {
          polygon: {
            hierarchy: [
              pointToCartesian3(arrowNotchBase),
              pointToCartesian3(arrowNotchPoint1),
              pointToCartesian3(arrowNotchhead),
              pointToCartesian3(arrowNotchPoint2),
              pointToCartesian3(arrowNotchBase),
            ],
            material: Color.fromCssColorString(color),
          },
        };
      default:
        break;
    }
  };
}
