interface CurrentRequests {
  resolve: any;
  reject: any;
  fnToCall: any;
  args: any;
}
export default class Semaphore {
  currentRequests: CurrentRequests[];
  runningRequests: number;
  maxConcurrentRequests: number;

  constructor(maxConcurrentRequests: number = 3) {
    this.currentRequests = [];
    this.runningRequests = 0;
    this.maxConcurrentRequests = maxConcurrentRequests;
  }

  callFunction(fnToCall: any, ...args: any) {
    return new Promise((resolve, reject) => {
      this.currentRequests.push({
        resolve,
        reject,
        fnToCall,
        args,
      });
      this.tryNext();
    });
  }

  tryNext() {
    if (!this.currentRequests.length) {
      return;
    } else if (this.runningRequests < this.maxConcurrentRequests) {
      const { resolve, reject, fnToCall, args } = this.currentRequests.shift() as CurrentRequests;
      this.runningRequests++;
      const req = fnToCall(...args);
      req
        .then((res: any) => resolve(res))
        .catch((err: any) => reject(err))
        .finally(() => {
          this.runningRequests--;
          this.tryNext();
        });
    }
  }
}
