import { FC, PropsWithChildren } from 'react';
import { EASING } from '@/theme';
import { styled } from '@mui/material';
import { AnimatePresence, m } from 'framer-motion';
import { Navigation } from '@/models';
import UploadDialog from '@/components/FileUpload/Upload/UploadDialog';
import DataMappingDialog from '@/components/FileUpload/DataMapping/DataMappingDialog';
import TopNavigation from '@/components/Navigation/TopNavigation';

export const AppLayout = styled(m.div)`
  height: 100vh;
  overflow: hidden;
`;

const Content = styled('div')`
  height: inherit;
  width: 100%;
  display: grid;
  grid-template-columns: 73px minmax(0, 1fr); // (72px + 1px border)
  grid-template-rows: 1fr;
  container-type: size;
`;
const Placeholder = styled(m.div)`
  width: 0;
  height: 100vh;
`;
const variants = {
  hidden: { opacity: 0 },
  enter: { opacity: 1, transition: { ease: EASING.EMPHASIZED, duration: 0.2 } },
  exit: { opacity: 0, transition: { ease: EASING.EMPHASIZED, duration: 0.2 } },
};
interface Props {
  navigation?: Navigation[];
}
const DefaultLayout: FC<PropsWithChildren> = ({ children }) => {
  const handleExitComplete = () => {
    window?.scrollTo(0, 0);
  };

  return (
    <AppLayout>
      <Content>
        <TopNavigation />
        <AnimatePresence mode="wait" onExitComplete={handleExitComplete}>
          {children}
        </AnimatePresence>
      </Content>
      <UploadDialog />
      <DataMappingDialog />
    </AppLayout>
  );
};

export default DefaultLayout;
