import axios from 'axios';
import { getConfig } from '@/config';
import Semaphore from '@/utils/semaphore';
import { Api, METHOD, prepareParams, RequestPromise } from './Api';
import { singleton } from '@/utils/decorators/singleton';
import { DataAcquisitionProductId, ErrorResponse, FileUpload, Model, UploadStatus } from '@/models';
import { ModelFileTypes } from '@/consts';
import { extractFileExtension } from '@/utils/formatters';
import { FileUploaderStore, fileUploadStore } from '@/stores/file-uploader.store';
import { ModelUploaderStore, modelUploadStore } from '@/stores/model-uploader.store';
import { OrderStore } from '@/stores/acquisition-uploader.store';
import { ImportAnnotationStore, ImportAnnotationUploaderStore } from '@/stores/import-annotation-uploader.store';

type UpdateProgressFunction = (index: number, progress: number) => void;
type UpdateStatusFunction = (index: number, status: UploadStatus, details?: string | undefined) => void;
type OnCompleteFunction = () => void;

const getFile = (store: OrderStore, index: number) => {
  return store.itemsUpload[index] as FileUpload;
};

@singleton
class UploaderApi extends Api {
  clearCache!: (key?: string) => void;
  static getInstance: () => UploaderApi;

  initMultipartUpload = async (
    chunks: number,
    filename: string,
    fileSize: number,
    type: string,
    initURI: string,
    OtherFields: {} | undefined,
  ) => this.request(METHOD.POST, initURI, { chunks, filename, fileSize, type, ...OtherFields });

  upload = (
    file: FormData,
    uri: string,
    config: { abortController?: AbortController; onUploadProgress: (progressEvent: any) => void },
  ): RequestPromise<any | ErrorResponse> => {
    const promise = this.request(METHOD.POST, uri, file, config);
    promise.then(this.responseClearCachePromise);
    return promise;
  };

  uploadFile = async (file: FileUpload, store: FileUploaderStore | ImportAnnotationStore = fileUploadStore) => {
    const { sourceType, geometry } = file;
    const initURI = '/api/ingestion/initiate-multipart-upload';
    const completeURI = '/api/ingestion/complete-multipart-upload';
    const params = prepareParams({ sourceType, geometry: geometry?.toWKT() });

    return this.minioUpload(initURI, `${completeURI}${params}`, file, store);
  };

  uploadFileAcquisition = async (file: FileUpload, store: OrderStore) => {
    const { sourceType, geometry } = file;
    const initURI = '/api/ingestion/initiate-multipart-upload';
    const completeURI = '/api/ingestion/complete-multipart-upload';
    const params = prepareParams({ sourceType, geometry: geometry?.toWKT() });

    return this.minioUpload(initURI, `${completeURI}${params}`, file, store);
  };

  uploadMeta = (
    file: FormData,
    config: { abortController?: AbortController; onUploadProgress: (progressEvent: any) => void },
  ): RequestPromise<Model> => {
    const promise = this.request(METHOD.POST, '/api/model/upload/meta', file, config);
    promise.then(this.responseClearCachePromise);
    return promise;
  };

  uploadModel = async (file: FileUpload, OtherFields: Record<string, any>, onComplete: OnCompleteFunction) => {
    const initURI = '/api/model/initiate-model-upload';
    const completeURI = '/api/model/complete-model-upload';

    return this.minioUpload(initURI, completeURI, file, modelUploadStore, OtherFields, onComplete);
  };

  minioUpload = async (
    initURI: string,
    completeURI: string,
    fileUpload: FileUpload,
    store: FileUploaderStore | ModelUploaderStore | OrderStore | ImportAnnotationStore,
    OtherFields?: Record<string, any>,
    onComplete?: OnCompleteFunction,
  ) => {
    const { updateProgress, updateStatus, checkFileStatus } = store;
    const { file, dataMappingInfo, layerProperties } = fileUpload;

    const chunkSize: number = parseInt(getConfig().chunkSize ?? '20') * 1024 * 1024;
    const concurrency = new Semaphore(parseInt(getConfig().concurrentConnection ?? '1'));

    const client = axios.create();
    delete client.defaults.headers.put['Content-Type'];
    const resParts = [];
    const failedChunks: number[] = [];
    const fileSize = file.size - 1;
    const totalChunks = Math.ceil(fileSize / chunkSize);
    let currentChunk = 1;
    let part: number = 1;

    const renameIfModel = (fileName: string) => {
      const ext = extractFileExtension(fileName);
      return ModelFileTypes.includes(ext) ? `model.${ext}` : fileName;
    };

    const mc = await this.initMultipartUpload(totalChunks, renameIfModel(file.name), file.size, file.type, initURI, OtherFields);

    const uploadChunk = async (url: string, currentFilePart: Blob) => {
      if (checkFileStatus(fileUpload) === UploadStatus.CANCELLED) return; //abort upload
      return await client.put(url, currentFilePart);
    };

    const retryUploadChunk = async (url: string, idx: number) => {
      if (checkFileStatus(fileUpload) === UploadStatus.CANCELLED) return; //abort upload
      const retries = failedChunks.filter((x) => x == idx).length;
      failedChunks.push(idx);
      if (retries < 3) {
        const offset = idx * chunkSize;
        const retryCurrentFilePart: Blob = file.slice(offset, offset + chunkSize);
        const result = await client.put(url, retryCurrentFilePart);
        if (result.status !== 200) {
          retryUploadChunk(url, idx);
        } else {
          const progress = (part++ / totalChunks) * 100;
          updateProgress && updateProgress(fileUpload, progress);
        }
      } else {
        updateStatus && updateStatus(fileUpload, UploadStatus.FAILED, 'Retry exceeded');
      }
    };

    if (mc.uploadId !== null && checkFileStatus(fileUpload) === UploadStatus.UPLOADING) {
      while (currentChunk <= totalChunks) {
        const offset = (currentChunk - 1) * chunkSize;
        const currentFilePart: Blob = file.slice(offset, offset + chunkSize);
        const result = concurrency.callFunction(uploadChunk, mc.urls[currentChunk - 1], currentFilePart);
        resParts.push(result);
        currentChunk++;
      }
      resParts.forEach((p, idx) =>
        p.then((d: any) => {
          if (checkFileStatus(fileUpload) === UploadStatus.CANCELLED) return; //abort upload dont upload progress
          if (d.status == 200) {
            const progress = (part++ / totalChunks) * 100;
            updateProgress && updateProgress(fileUpload, checkFileStatus(fileUpload) === UploadStatus.UPLOADING ? progress : 0);
          } else {
            //reupload
            retryUploadChunk(mc.urls[idx], idx);
          }
        }),
      );

      const fileParts = await Promise.all(resParts); //wait for all concurrent upload to finish

      if (checkFileStatus(fileUpload) === UploadStatus.UPLOADING) {
        //aborted don't continue beyond this point
        const partsMap = fileParts.map((part, index) => ({
          etag: (part as any).headers.etag,
          partNumber: index + 1,
        }));

        const payload = {
          uploadId: mc.uploadId,
          parts: partsMap,
          ...(!!dataMappingInfo?.dataMapping && { vectorMappingProperties: dataMappingInfo.dataMapping }),
          ...(!!layerProperties && { layerProperties }),
        };
        await this.request(METHOD.POST, completeURI, payload);
        onComplete?.();
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  private responseClearCachePromise = (res: any) => {
    this.clearCache?.();
    return res;
  };
}

export const uploaderApi = () => UploaderApi.getInstance();
