import { Entity } from 'cesium';
import { Geometry, GeometryType, Line, Polygon } from '@/utils/geometry';
import { createEntity, createPropertyBag, EntityGraphics } from '@/components/Cesium/utils';
import { arrayChunk } from '@/utils';
import { FeaturesLayer } from './FeaturesLayer';
import { DEFAULT_STYLES, getEntityGraphicsFromStyle, setEntityStyles } from '@/components/Cesium/utils/styles';
import type { FeatureStyle, KeyValue } from '@/models';
import { VectorStyles } from '@/models';
import { CustomPromise } from '@/utils/CustomPromise';

export enum RectangleType {
  RECTANGLE = 'RECTANGLE',
}

export type ExtendedGeometryType = GeometryType | RectangleType;

export class BaseVectorLayer extends FeaturesLayer {
  protected _styles: any = DEFAULT_STYLES.rules;
  protected threads = 0;
  protected chunkSize = 500;
  protected chunkDelay = 50;

  addFeatures(features: any[], suspendEvents = true) {
    this.isLoading = true;
    const promise = CustomPromise();
    if (features && features.length) {
      suspendEvents && this.entities.suspendEvents();
      arrayChunk(features, this.chunkSize, (chunk, idx) => {
        this.threads++;
        setTimeout(() => {
          chunk.forEach((p) => {
            const entity = this.createEntity(p);
            if (entity) {
              Array.isArray(entity) ? entity.forEach((e) => this.entities.add(e)) : this.entities.add(entity);
            }
          });
          this.threads--;
          if (this.threads === 0) {
            suspendEvents && this.entities.resumeEvents();
            void promise.resolve();
          }
        }, idx * this.chunkDelay);
      });
    } else {
      void promise.resolve();
    }
    promise.finally(() => (this.isLoading = false));
    return promise;
  }

  removeFeatures() {
    this.entities.removeAll();
    return this;
  }

  getFeatureGeometry(feature: any): Geometry | undefined {
    return feature;
  }

  protected createEntity(feature: any, options?: KeyValue): Entity | Entity[] | undefined {
    const geometry = this.getFeatureGeometry(feature);
    if (!geometry) {
      return undefined;
    }
    switch (geometry.type) {
      case GeometryType.MULTIPOLYGON:
        if (geometry.polygons.length) {
          const firstPolygon = { ...geometry.polygons[0], properties: geometry.properties } as Polygon;
          const entityGraphics = this.getGeometryGraphics(firstPolygon, this._styles, options);
          const properties = createPropertyBag({ ...options?.properties, feature });
          const baseEntity = new Entity({ properties });
          const entities: Entity[] = [];
          geometry.polygons.forEach((polygon, idx) => {
            polygon.properties = geometry.properties;
            const entity = createEntity(polygon, undefined, {
              ...options,
              entityGraphics,
              parent: baseEntity,
              id: options!.id + '-' + idx,
            });
            entity && entities.push(entity);
          });

          return [baseEntity, ...entities];
        }
        break;

      case GeometryType.MULTILINESTRING:
        if (geometry.lines.length) {
          geometry.lines.forEach((l) => (l.properties = geometry.properties));
          const firstLine = { ...geometry.lines[0], properties: geometry.properties } as Line;
          const entityGraphics = this.getGeometryGraphics(firstLine, this._styles, options);
          const properties = createPropertyBag({ ...options?.properties, feature });
          const baseEntity = new Entity({ properties });
          const entities: Entity[] = [];
          geometry.lines.forEach((line, idx) => {
            line.properties = geometry.properties;
            const entity = createEntity(line, undefined, {
              ...options,
              entityGraphics,
              parent: baseEntity,
              id: options!.id + '-' + idx,
            });
            entity && entities.push(entity);
          });

          return [baseEntity, ...entities];
        }
        break;

      default:
        const entityGraphics = this.getGeometryGraphics(geometry, this._styles, options);
        options = { ...options, properties: { ...options?.properties, feature }, entityGraphics };
        return createEntity(geometry, undefined, options);
    }
  }

  getEntityGraphicsByGeometryType(geometryType: ExtendedGeometryType, style: FeatureStyle, options?: KeyValue): EntityGraphics {
    return getEntityGraphicsFromStyle(geometryType, style, options);
  }

  getGeometryGraphics(geometry: Geometry, style?: any, options?: KeyValue): EntityGraphics {
    return this.getEntityGraphicsByGeometryType(geometry.type, (style || this._styles)[geometry.type], options);
  }

  getEntityGraphics(entity: Entity, style?: any, options?: KeyValue): EntityGraphics {
    const geometry = entity.properties?.geometry;
    return this.getEntityGraphicsByGeometryType(geometry.type, (style || this._styles)[geometry.type]!);
  }

  applyStyles(styles: any = this._styles, entities?: Entity[]) {
    (entities || this.entities.values).forEach((entity: Entity) => setEntityStyles(entity, this.getEntityGraphics(entity, styles)));
  }

  protected setStyles = (styles: VectorStyles) => void (this._styles = styles);

  get styles() {
    return this._styles;
  }

  set styles(value) {
    this.applyStyles(value);
    this._styles = value;
  }
}
