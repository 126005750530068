import { ImageryLayer as CesiumImageryLayer, ImageryProvider } from 'cesium';

interface Options extends CesiumImageryLayer.ConstructorOptions {
  copyright?: string;
}

export class ImageryLayer extends CesiumImageryLayer {
  readonly copyright?: string;

  constructor(imageryProvider?: ImageryProvider, options?: Options) {
    const { copyright, ...restOptions } = options || {};
    super(imageryProvider, restOptions);
    this.copyright = copyright;
  }
}
