import { forwardRef } from 'react';
import { List, ListProps } from '@mui/material';
import { HTMLMotionProps, m } from 'framer-motion';

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
};
export const MotionList = forwardRef<HTMLUListElement, ListProps & HTMLMotionProps<'ul'>>(function MotionList(props, ref) {
  return <List ref={ref} {...props} component={m.ul} variants={container} initial="hidden" animate="visible" />;
});
