import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { MarketplaceItem, MarketplaceItemId, MySubscriptionsModel, Sort, SubscribersModel } from '@/models';
import { Filter, marketplaceApi, PinMarketplaceItemRequestDto } from '@/cmd/MarketplaceApi';
import useVirtuosoInfiniteQuery from '@/hooks/useVirtuosoInfiniteQuery';
import { getInfinitePlaceholder, getNextPageParam, initialPageParam } from '@/hooks/queries/utils';
import { minutesToMilliseconds } from 'date-fns';
import { PageableRequest } from '@/cmd/Api';
import { useSession } from 'next-auth/react';
import { marketplacePublicApi } from '@/cmd/MarketplacePublicApi';

export const marketplaceKeys = {
  all: ['marketplace'] as const,
  lists: () => [...marketplaceKeys.all, 'list'] as const,
  list: (filter?: Filter, sort?: Sort) => [...marketplaceKeys.lists(), filter, sort] as const,
  countAll: () => [...marketplaceKeys.all, 'count'] as const,
  single: (id: MarketplaceItemId) => [...marketplaceKeys.all, id] as const,
  filters: () => [...marketplaceKeys.all, 'marketplace_filters'] as const,
  subscriptionsAll: () => [...marketplaceKeys.all, 'subscriptions'] as const,
  subscriptions: (id: MarketplaceItemId) => [...marketplaceKeys.subscriptionsAll(), id] as const,
  mySubscriptions: (pagination?: PageableRequest) => [...marketplaceKeys.subscriptionsAll(), 'my-subscriptions', pagination] as const,
  subscribers: (pagination?: PageableRequest) => [...marketplaceKeys.subscriptionsAll(), 'subscribers', pagination] as const,
  statisticsAll: () => [...marketplaceKeys.all, 'marketplace_statistics'] as const,
  statistics: (id: string, query?: string) => [...marketplaceKeys.statisticsAll(), id, query] as const,
  navigationAll: () => [...marketplaceKeys.all, 'manage_navigation'] as const,
};

export const useMarketplaceItem = (id: MarketplaceItemId | undefined) => {
  const { status } = useSession();
  return useQuery({
    queryKey: [...marketplaceKeys.single(id!), status],
    queryFn: () => (status === 'authenticated' ? marketplaceApi().getById(id!) : marketplacePublicApi().getById(id!)),
    enabled: !!id,
  });
};

export const useMarketplaceFilters = () => {
  const { status } = useSession();
  return useQuery({
    queryKey: [...marketplaceKeys.filters(), status],
    queryFn: () => (status === 'authenticated' ? marketplaceApi().getFilterList() : marketplacePublicApi().getFilterList()),
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(15),
  });
};

export const useInfiniteMarketplaces = (filter?: Filter, sort?: Sort, enabled?: boolean) => {
  const { status } = useSession();
  return useVirtuosoInfiniteQuery({
    queryKey: [...marketplaceKeys.list(filter, sort), status],
    queryFn: ({ pageParam }) =>
      status === 'authenticated'
        ? marketplaceApi().getList(filter, { ...sort, pageSize: 50, ...pageParam })
        : marketplacePublicApi().getList(filter, { ...sort, pageSize: 50, ...pageParam }),
    getNextPageParam,
    initialPageParam,
    staleTime: minutesToMilliseconds(15),
    placeholderData: getInfinitePlaceholder<MarketplaceItem>(20, true),
    enabled: enabled !== false,
  });
};

export const useSubscribe = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: { id: MarketplaceItemId }) => marketplaceApi().subscribe(request.id),
    onSuccess: (_data, variables) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.single(variables.id) }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.subscriptionsAll() }),
      ]);
    },
  });
};

export const useUnsubscribe = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: { id: MarketplaceItemId }) => marketplaceApi().unsubscribe(request.id),
    onSuccess: (_data, variables) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.single(variables.id) }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.subscriptionsAll() }),
      ]);
    },
  });
};

export const useDeleteMarketplace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => marketplaceApi().delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.lists() });
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.countAll() });
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.filters() });
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.statisticsAll() });
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.navigationAll() });
    },
  });
};

export const useCreateMarketplace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (marketplace: Partial<MarketplaceItem>) => marketplaceApi().create(marketplace),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.countAll() }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.filters() }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.statisticsAll() }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.navigationAll() }),
      ]),
  });
};

export const useUpdateMarketplace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (marketplace: Partial<MarketplaceItem>) => marketplaceApi().update(marketplace),
    onSuccess: (marketplace) =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.countAll() }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.single(marketplace.id) }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.filters() }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.statisticsAll() }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.navigationAll() }),
      ]),
  });
};

export const useUploadFeaturedImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: { id: string; form: FormData }) => marketplaceApi().uploadFeaturedImage(request.form, request.id),
    onSuccess: (_data, request) => {
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.lists() });
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.single(request.id) });
    },
  });
};

export const useDeleteFeaturedImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: { id: string }) => marketplaceApi().deleteFeaturedImage(request.id),
    onSuccess: (_data, request) => {
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.lists() });
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.single(request.id) });
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.statisticsAll() });
    },
  });
};

export const useManageNavigation = () =>
  useQuery({
    queryKey: marketplaceKeys.navigationAll(),
    queryFn: () => marketplaceApi().getNavigationListNumbers(),
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(15),
  });

export const useGetMarketplaceItemStatistics = (id: string, query?: string) =>
  useQuery({
    queryKey: marketplaceKeys.statistics(id, query),
    queryFn: () => marketplaceApi().getMarketplaceItemStatistics(id, query),
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(15),
    enabled: !!query,
  });

export const usePinMarketplaceItem = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError<{ message: string }>, PinMarketplaceItemRequestDto>({
    mutationFn: (request: PinMarketplaceItemRequestDto) => marketplaceApi().pinMarketplaceItem(request),
    onSuccess: (_data, request) => {
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.lists() });
      queryClient.invalidateQueries({ queryKey: marketplaceKeys.single(request.id) });
    },
  });
};

export const useInfiniteSubscriptions = (id: string) => {
  return useVirtuosoInfiniteQuery({
    queryKey: marketplaceKeys.subscriptions(id),
    queryFn: ({ pageParam }) => marketplaceApi().getSubscriptions(id, pageParam),
    getNextPageParam,
    initialPageParam,
    placeholderData: keepPreviousData,
  });
};

type MySubscriptionsApiSuccessResponse = {
  totalElements: number;
  content: MySubscriptionsModel[];
  [key: string]: string | number | MySubscriptionsModel[];
};

export const useMySubscriptionsApi = (pagination?: PageableRequest) => {
  const { data, ...rest } = useQuery<MySubscriptionsApiSuccessResponse>({
    queryKey: marketplaceKeys.mySubscriptions(pagination),
    queryFn: () => marketplaceApi().getMySubscriptionsList(pagination),
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(15),
  });

  return {
    ...rest,
    totalElements: data?.totalElements ?? 0,
    subscriptions: data?.content ?? [],
  };
};

type SubscribersApiSuccessResponse = {
  totalElements: number;
  content: SubscribersModel[];
  [key: string]: string | number | SubscribersModel[];
};

export const useSubscribersApi = (pagination?: PageableRequest) => {
  const { data, ...rest } = useQuery<SubscribersApiSuccessResponse>({
    queryKey: marketplaceKeys.subscribers(pagination),
    queryFn: () => marketplaceApi().getSubscribers(pagination),
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(15),
  });

  return {
    totalElements: data?.totalElements ?? 0,
    subscribers: data?.content ?? [],
    ...rest,
  };
};
