import { useCallback, useEffect, useRef } from 'react';
import { Callback, TimeoutId } from '@/models';

export const useThrottle = (callback: Callback, delay = 300) => {
  const lastCallTimeRef = useRef(0);
  const timerRef = useRef<TimeoutId>();

  useEffect(() => {
    return () => timerRef.current && clearTimeout(timerRef.current);
  }, []);

  return useCallback((...args: any[]) => {
    const now = Date.now();
    const timerDelay = delay - (now - lastCallTimeRef.current);

    if (timerDelay <= 0) {
      callback.apply(this, args);
      lastCallTimeRef.current = now;
    } else {
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        callback.apply(this, args);
        lastCallTimeRef.current = Date.now();
      }, timerDelay);
    }
  }, []);
};

export const throttle = (callback: Function, timeout = 300) => {
  let inProgress = false;

  return (...args: any[]) => {
    if (inProgress) {
      return;
    }
    inProgress = true;
    clearTimeout(timeout);
    setTimeout(() => {
      callback.apply(this, args);
      inProgress = false;
    }, timeout);
  };
};
