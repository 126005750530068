// noinspection JSUnusedGlobalSymbols

import turfUnion from '@turf/union';
import turfIntersect from '@turf/intersect';
import turfDifference from '@turf/difference';
import { Coordinate, GeometryType, IGeometry, Multipolygon, ParseProps, Point, Projection, rotate, scale, SimpleLine } from '.';
import { Numbers } from './Numbers';
import { PSEUDO_MERCATOR, WORLD_GEODETIC_SYSTEM } from '@/consts';
import { CanvasDrawOptions, CanvasStrokeStyle } from '@/utils/canvas';
import {
  Feature as TFeature,
  MultiPolygon as TMultiPolygon,
  Polygon as TPolygon,
  polygon as TurfPolygon,
  Position as TPosition,
} from '@turf/helpers';
import { WKTType } from '@/utils/geometry/Geometry';

const COORDINATE_PRECISION = 7;

export class Polygon implements IGeometry {
  properties: { [key: string]: any } = {};
  holes: Polygon[] = [];
  readonly type = GeometryType.POLYGON;
  private _points: Point[] = [];
  private _projection?: string;

  /**
   * @constructor
   * @param {Point[]} points
   * @param {Point[]} holes
   */
  constructor(points: Point[] = [], holes: Polygon[] = []) {
    this._points = points;
    this.holes = holes;
  }

  static minAreaRectangleSize(poly: Polygon): Point {
    const { first, second, last } = poly.clone().open();
    return new Point(first.distance(second), first.distance(last));
  }

  static minAreaRectangleDirection(poly: Polygon): number {
    const { first, second, last } = poly.clone().open();
    if (!first || !second || !last) {
      return 0;
    }
    if (first.distance(second) > first.distance(last)) {
      return first.findLine(second).getFi();
    } else {
      return first.findLine(last).getFi();
    }
  }

  static ParseFromCoords(coords: Coordinate[] = []): Polygon {
    return new Polygon(coords.map((r: Coordinate) => Point.Parse(r)));
  }

  static ParseJSTSFeature(feature: TFeature<TPolygon | TMultiPolygon>): Polygon | Polygon[] {
    if (feature.geometry.type === 'Polygon') {
      return Polygon.ParseJSTSCoordinates(feature.geometry.coordinates);
    } else if (feature.geometry.type === 'MultiPolygon') {
      return feature.geometry.coordinates.map((coords: TPosition[][]) => Polygon.ParseJSTSCoordinates(coords));
    }
    throw new Error('Geometry is not supported');
  }

  static ParseJSTSCoordinates(coordinates: TPosition[][]) {
    const [polygonCoordinates, ...holesCoordinates] = coordinates;
    const points = polygonCoordinates.map((c) => new Point(c[0], c[1]));
    const holes = holesCoordinates.map((hole) => new Polygon(hole.map((c) => new Point(c[0], c[1]))));
    return new Polygon(points, holes);
  }

  static ParseFromWKT(wkt: string, optProps?: ParseProps | Projection): Polygon {
    const data = (wkt || '').trim().toUpperCase();
    let res: Polygon | undefined;
    if (data.indexOf(WKTType.POLYGON) === 0) {
      const regexp = new RegExp('POLYGON\\s?[Z|M]{0,2}\\s?\\(\\(((?:(?!\\)\\)$).)*?)\\)\\)$', 'mi');
      const reg = regexp.exec(data) as RegExpExecArray;
      // Todo: handle POLYGON M (...) WKTs
      const [path, ...holes] = (reg[1] as string)
        .replace(/,\s/g, ',')
        .split('),(')
        .map((p: string) => new Polygon(p.split(',').map((pares) => Point.Parse(pares.trim().split(' ').map(Number) as any))));
      if (holes && holes.length) {
        path.holes = holes;
      }
      res = path;
    }
    if (data.indexOf(WKTType.LINESTRING) === 0) {
      const regexp = new RegExp('LINESTRING\\s?[Z|M]{0,2}\\s?\\(((?:(?!\\)$).)*?)\\)$', 'mi');
      const reg = regexp.exec(data) as RegExpExecArray;
      res = new Polygon(reg[1].split(', ').map((t: string) => Point.Parse(t.split(' ').map(Number) as Coordinate)));
    }
    if (data.indexOf(WKTType.POINT) === 0) {
      res = new Polygon([Point.ParseFromWKT(data)]);
    }
    if (data.indexOf(WKTType.MULTIPOLYGON) === 0) {
      const m = Multipolygon.ParseFromWKT(data);
      if (m) res = new Polygon(m.points);
    }

    if (!res) {
      throw Error('Cannot parse WKT');
    }

    if (optProps) {
      res = typeof optProps === 'string' ? res.setProjection(optProps) : res.transform(optProps);
    }

    return res;
  }

  static CreateBySize(size: Point): Polygon {
    return new Polygon([Point.Zero(), size.clone().setX(0), size.clone(), size.clone().setY(0)]).close();
  }

  static CreateBySide(sideWidth: number, center: Point) {
    const shift = sideWidth / 2;
    return new Polygon([
      center.clone().move(-shift, -shift),
      center.clone().move(-shift, shift),
      center.clone().move(shift, shift),
      center.clone().move(shift, -shift),
    ]).setProjection(center.projection);
  }

  set points(p: Point[]) {
    this._points = p;
  }

  get points(): Point[] {
    return this._points;
  }

  get maxX(): number {
    return this._points.reduce((a: number, r: Point) => Math.max(a, r.x), -Infinity);
  }

  get minX(): number {
    return this._points.reduce((a: number, r: Point) => Math.min(a, r.x), Infinity);
  }

  get maxY(): number {
    return this._points.reduce((a: number, r: Point) => Math.max(a, r.y), -Infinity);
  }

  get minY(): number {
    return this._points.reduce((a: number, r: Point) => Math.min(a, r.y), Infinity);
  }

  get center(): Point {
    return this.leftTop.move(this.size.divide(2)).setProjection(this._projection);
  }

  get h(): number {
    return this.maxY - this.minY;
  }

  get w(): number {
    return this.maxX - this.minX;
  }

  get dY(): number {
    return this.h;
  }

  get dX(): number {
    return this.w;
  }

  get extent(): Polygon {
    const { minX, minY, maxX, maxY } = this;
    const points = [new Point(minX, minY), new Point(maxX, minY), new Point(maxX, maxY), new Point(minX, maxY), new Point(minX, minY)];
    return new Polygon(points).setProjection(this.projection);
  }

  get bbox(): [number, number, number, number] {
    return [this.minX, this.minY, this.maxX, this.maxY];
  }

  get size(): Point {
    const { w, h } = this;
    return new Point(w, h);
  }

  get leftTop(): Point {
    const { minX, minY } = this;
    return new Point(minX, minY);
  }

  get rightBottom(): Point {
    const { maxX, maxY } = this;
    return new Point(maxX, maxY);
  }

  get length(): number {
    return this._points.length;
  }

  get perimeter(): number {
    let p = 0;
    for (let i = 1; i < this._points.length; i++) {
      p += this._points[i - 1].distance(this._points[i]);
    }
    return p;
  }

  get area(): number {
    const closed = this.deintersection;
    let sum = 0;
    for (let i = 1; i < closed.length; i++) {
      const cur = closed.p(i);
      const prev = closed.p(i - 1);
      sum += prev.x * cur.y - prev.y * cur.x;
    }
    return Math.abs(sum / 2) - this.holes.reduce((a: number, hole: Polygon) => a + hole.area, 0);
  }

  get deintersection(): Polygon {
    const p = this.clone().close();
    for (let i = 0; i < p.length - 1; i++) {
      for (let j = i + 2; j < p.length - 1; j++) {
        const firstLine = p.p(i).findLine(p.p(i + 1));
        const secondLine = p.p(j).findLine(p.p(j + 1));
        const intersectionPoint = firstLine.intersection(secondLine);
        if (intersectionPoint && ![...firstLine.points, ...secondLine.points].some((t: Point) => t.like(intersectionPoint))) {
          const part = p.removePart(i, j - i).reverse();
          p.insertAfter(i, ...part);
          p.insertAfter(j, intersectionPoint);
          p.insertAfter(i, intersectionPoint);
        }
      }
    }
    return p;
  }

  get valid(): boolean {
    return this.length > 3;
  }

  get first(): Point {
    return this.p(0);
  }

  get second(): Point {
    return this.p(1);
  }

  get last(): Point {
    return this.p(this.length - 1);
  }

  get minAreaRectangle(): Polygon {
    const p = this.convex;
    let resultPolygon = new Polygon();
    let resultArea = Infinity;
    for (let k = 0; k < p.length - 1; k++) {
      const l = p.p(k).findLine(p.p(k + 1));
      let maxWidth = 0;
      let maxWidthPoint1: Point | null = null;
      let maxWidthPoint2: Point | null = null;
      let maxHeight = 0;
      let maxHeightPoint: Point | null = null;
      for (let i = 0; i < p.length - 1; i++) {
        const p1: Point = l.findPoint(l.findPerpendicular(p.p(i)))!;
        const h = p1.distance(p.p(i));
        if (h >= maxHeight) {
          maxHeight = h;
          maxHeightPoint = p.p(i);
        }
        for (let j = i; j < p.length - 1; j++) {
          const p2: Point = l.findPoint(l.findPerpendicular(p.p(j)))!;
          const w = p1.distance(p2);
          if (w >= maxWidth) {
            maxWidth = w;
            maxWidthPoint1 = p1;
            maxWidthPoint2 = p2;
          }
        }
      }
      if (!maxWidthPoint1 || !maxWidthPoint2 || !maxHeightPoint) {
        continue;
      }
      const widthLine = maxWidthPoint1.findLine(maxWidthPoint2);
      const perpendicular1 = widthLine.findPerpendicular(maxWidthPoint1);
      const perpendicular2 = widthLine.findPerpendicular(maxWidthPoint2);
      const tempPolygon = new Polygon([
        maxWidthPoint1,
        maxWidthPoint2,
        perpendicular2.findPoint(perpendicular2.findPerpendicular(maxHeightPoint))!,
        perpendicular1.findPoint(perpendicular1.findPerpendicular(maxHeightPoint))!,
      ]).close();
      if (tempPolygon.area < resultArea) {
        resultPolygon = tempPolygon;
        resultArea = tempPolygon.area;
      }
    }
    return resultPolygon;
  }

  get convex(): Polygon {
    let p = this.clone().open();
    const isClockwise = p.isClockwise;
    if (!isClockwise) {
      p.reverse();
    }
    let length;
    do {
      const p1 = p.last;
      const p2 = p.first;
      const p3 = p.second;
      const d = p2.findInnerAngle(p1, p3);
      if (d > Math.PI || Numbers.likeZero(Numbers.rad2Deg(d)) || Numbers.likePI(d) || Numbers.like2PI(d)) {
        p.removePart(-1, 1);
      } else {
        break;
      }
    } while (p.length);
    p.close();
    let iteration = 0;
    do {
      p = p.deintersection;
      length = p.length;
      for (let i = 1; i < p.length - 1; i++) {
        const p1 = p.p(i - 1);
        const p2 = p.p(i);
        const p3 = p.p(i + 1);
        const d = p2.findInnerAngle(p1, p3);
        if (d > Math.PI || Numbers.likeZero(Numbers.rad2Deg(d)) || Numbers.likePI(d) || Numbers.like2PI(d)) {
          p.removePart(--i, 1);
        }
      }
      iteration++;
    } while (p.length !== length && iteration < 100);
    if (!isClockwise) {
      p.reverse();
    }
    return p;
  }

  get isClockwise(): boolean {
    let sum = 0;
    const p = this.clone().close();
    for (let i = 1; i < p.length; i++) {
      const p1 = p.p(i - 1);
      const p2 = p.p(i);
      sum += (p2.x - p1.x) * (p2.y + p1.y);
    }
    return sum < 0;
  }

  get clockWise(): Polygon {
    if (this.isClockwise) {
      return this.clone();
    } else {
      return this.clone().reverse();
    }
  }

  get noHoles(): Polygon {
    const res = this.clone();
    res.holes = [];
    return res;
  }

  get projection(): Projection | undefined {
    return this._projection;
  }

  setProjection(value: Projection | undefined) {
    this._projection = value;
    this.points.forEach((p) => p.setProjection(value));
    return this;
  }

  setCenter(newCenter: Point): Polygon {
    return this.clone().move(newCenter.move(this.center.minus()));
  }

  toWKT(): string {
    const zm = this.getModifiers();
    return `POLYGON ${zm}(${this.toString()})`;
  }

  rotate(angle: number, anchor: Point = this.center): Polygon {
    this._points = rotate(this.points, angle, anchor);
    this.holes = this.holes.map((poly) => poly.rotate(angle, anchor));
    return this;
  }

  move(x: number | Point = 0, y?: number): Polygon {
    this._points = this._points.map((p: Point) => p.move(x, y));
    this.holes = this.holes.map((h: Polygon) => h.move(x, y));
    return this;
  }

  multiply(x: number | Point = 0, y?: number): Polygon {
    this._points = this._points.map((p: Point) => p.multiply(x, y));
    this.holes = this.holes.map((h: Polygon) => h.multiply(x, y));
    return this;
  }

  divide(x: number | Point = 0, y?: number): Polygon {
    this._points = this._points.map((p: Point) => p.divide(x, y));
    this.holes = this.holes.map((h: Polygon) => h.divide(x, y));
    return this;
  }

  scale(factor: number | Point, anchor: Point = this.center) {
    this.points = scale(this.points, factor, anchor);
    this.holes = this.holes.map((poly) => poly.scale(factor, anchor));
    return this;
  }

  mirrorY() {
    return this.multiply(1, -1);
  }

  round(precision: number = 0): Polygon {
    this._points = this._points.map((p: Point) => p.round(precision));
    this.holes = this.holes.map((h: Polygon) => h.round(precision));
    return this;
  }

  map(f: (r: Point, index?: number) => Point): Polygon {
    this._points = this._points.map(f);
    this.holes = this.holes.map((h: Polygon) => h.map(f));
    return this;
  }

  forEach(f: (r: Point, index: number) => any): Polygon {
    this._points.forEach(f);
    return this;
  }

  p(index: number, divide: boolean = false): Point {
    if (divide) {
      let t = index;
      while (t < 0) {
        t += this.length;
      }
      return this._points[t % this.length];
    }
    return this._points[index];
  }

  pop(): Point | undefined {
    return this._points.pop();
  }

  push(p: Point) {
    return this._points.push(p);
  }

  shift(): Point {
    return this._points.shift()!;
  }

  unshift(p: Point) {
    return this._points.unshift(p);
  }

  reverse() {
    this._points = this._points.reverse();
    this.holes = this.holes.map((h: Polygon) => h.reverse());
    return this;
  }

  getValue(): string {
    return this._points.map((r: Point) => r.getValue()) + this.holes.reduce((a: string, h: Polygon) => a + h.getValue(), '');
  }

  getCoordinates(): Coordinate[] {
    return this._points.map((p: Point) => p.getCoordinates());
  }

  transformTo(to: Projection) {
    if (!this._projection) {
      throw Error('Source projection is not specified');
    }
    return this.transform(this._projection, to);
  }

  toJSTS(): TFeature<TPolygon> {
    return TurfPolygon([this.getCoordinates() as any]);
  }

  transform(from: Projection | ParseProps = PSEUDO_MERCATOR, to: Projection = WORLD_GEODETIC_SYSTEM) {
    if (typeof from === 'object') {
      to = from.featureProjection;
      from = from.dataProjection;
    }
    if (this._projection) {
      if (from !== this._projection) {
        throw Error('Source projection mismatch');
      }
      if (to === this._projection) {
        return this;
      }
    }
    this._projection = to;
    this._points = this._points.map((r: Point) => r.transform(from, to));
    this.holes = this.holes.map((h: Polygon) => h.transform(from, to));
    return this;
  }

  transformBy(transformer: (p: Point) => Point) {
    this._points = this._points.map((r: Point) => transformer(r));
    this.holes = this.holes.map((h: Polygon) => h.transformBy(transformer));
    return this;
  }

  pointsToString(points: Point[]): string {
    const isZ = this.isZPolygon();
    return `(${points.map((r: Point) => r.toString(isZ)).join(', ')})`;
  }

  toString() {
    // const poly = this.pointsToString(this.deintersection.points);
    const poly = this.pointsToString(this._points);
    const holes = this.holes.map((p) => this.pointsToString(p.points));
    return [poly, ...holes].join(', ');
  }

  getLastCoordinate(): Point {
    return this.p(this.length - 1);
  }

  close() {
    const p0 = this.first;
    if (p0 && !p0.equal(this.last)) {
      this.push(p0.clone());
    }
    return this;
  }

  open() {
    const p = this.first;
    if (this.length > 2 && p && p.equal(this.last)) {
      this.pop();
    }
    return this;
  }

  depth(z: number) {
    this.map((p: Point) => p.depth(z));
    this.holes = this.holes.map((h: Polygon) => h.depth(z));
    return this;
  }

  add(poly: Polygon): Polygon {
    const res = new Polygon([...this.points, ...poly.points]).close();
    res.holes = [...this.holes, ...poly.holes].map((h: Polygon) => h.clone());
    return res;
  }

  has(p: Point): boolean {
    return this._points.some((q: Point) => q.equal(p));
  }

  clone(): Polygon {
    const res = new Polygon([...this.points.map((r: Point) => r.clone())]);
    res.holes = this.holes.map((h: Polygon) => h.clone());
    res.properties = { ...this.properties };
    res.setProjection(this.projection);
    return res;
  }

  equal(p: Polygon | null): boolean {
    if (!(p instanceof Polygon)) {
      return false;
    }
    if (this.clone().open().length !== p.clone().open().length || this.holes.length !== p.holes.length) {
      return false;
    }
    return (
      this.same(p) && this.holes.reduce((a: boolean, hole: Polygon) => a && p.holes.some((pHoles: Polygon) => pHoles.same(hole)), true)
    );
  }

  same(p: Polygon): boolean {
    const pClone = p.clone().open();
    const thisClone = this.clone().open();
    const thisAsString = thisClone.noHoles.toString();
    return thisClone.points.reduce((a: boolean) => {
      const f = pClone.shift();
      pClone.push(f);
      return a || thisAsString === pClone.noHoles.toString() || thisAsString === pClone.noHoles.reverse().toString();
    }, false);
  }

  simpleUnion(p: Polygon): Polygon | null {
    try {
      const res = this.simpleLogicFunction(p, true, true);
      if (res === null) {
        return null;
      }
      if (res instanceof Polygon) {
        return res;
      }
    } catch (ex) {
      console.error(`simpleUnion FAILED. Polygon1[${this}] Polygon2[${p}]`);
      throw ex;
    }
    return null;
  }

  simpleIntersection(p: Polygon): Polygon | Polygon[] | undefined {
    return this.simpleLogicFunction(p, false, false);
  }

  simpleDifference(p: Polygon): Polygon | Polygon[] | undefined {
    return this.simpleLogicFunction(p, true, false);
  }

  findIndex(p: Point): number {
    return this.points.findIndex((t: Point) => t.equal(p));
  }

  approximation(e: number = Math.sqrt(this.perimeter) * 0.1): Polygon {
    return new Polygon(this.clone().douglasPeucker(this._points, e));
  }

  insertAfter(index: number, ...points: Point[]): void {
    this._points.splice(index + 1, 0, ...points);
  }

  removePart(index: number, count: number): Point[] {
    return this._points.splice(index + 1, count);
  }

  hasSimpleIntersection(p: Polygon): boolean {
    const extend1 = this.extent;
    const extend2 = p.extent;
    const extend1points = extend1.points;
    const extend2points = extend2.points;
    const in1 = extend1points.some((t: Point) => extend2.simpleInclude(t));
    const in2 = extend2points.some((t: Point) => extend1.simpleInclude(t));
    return in1 || in2;
  }

  simpleInclude(p: Point): boolean {
    return this.simpleIncludeX(p) && this.simpleIncludeY(p);
  }

  inside(p: Polygon): boolean {
    let idx;
    const move = new Point(0.002, 0);
    for (idx in this.points) {
      if (!p.contain(this.points[idx], true, move)) {
        return false;
      }
    }
    let pidx;
    let line;
    let pline;
    const len = this.points.length;
    const plen = p.points.length;
    for (idx = 0; idx < len - 1; idx++) {
      line = this.points[idx].findLine(this.points[idx + 1]);
      for (pidx = 0; pidx < plen - 1; pidx++) {
        pline = p.points[pidx].findLine(p.points[pidx + 1]);
        if (pline.intersection(line)) {
          return false;
        }
      }
    }
    return true;
  }

  drawPolygonOnCanvas(
    canvas: HTMLCanvasElement,
    fillColorOrOptions?: string | CanvasDrawOptions,
    strokeColor?: string,
    shadowColor?: string,
    lineWidth?: number,
  ) {
    let fillColor;
    let strokeStyle;
    if (fillColorOrOptions !== null) {
      if (typeof fillColorOrOptions === 'object') {
        fillColor = fillColorOrOptions.fillColor;
        strokeColor = fillColorOrOptions.strokeColor;
        shadowColor = fillColorOrOptions.shadowColor;
        lineWidth = fillColorOrOptions.lineWidth;
        strokeStyle = fillColorOrOptions.strokeStyle;
      } else {
        fillColor = fillColorOrOptions;
      }
    }
    const ctx = canvas.getContext('2d')!;
    if (fillColor) {
      ctx.fillStyle = fillColor;
    }
    if (strokeColor) {
      ctx.strokeStyle = strokeColor;
    }
    if (lineWidth) {
      ctx.lineWidth = lineWidth;
    }
    if (strokeStyle) {
      switch (strokeStyle) {
        case CanvasStrokeStyle.DASHED:
          ctx.setLineDash([20, 10]);
          break;
      }
    }

    this.goByPath(ctx);
    if (shadowColor) {
      ctx.shadowColor = shadowColor;
      ctx.shadowBlur = 0;
      ctx.shadowOffsetX = 1;
      ctx.shadowOffsetY = 1;
    }
    if (fillColor) {
      ctx.fill();
    } else if (strokeColor && lineWidth) {
      ctx.stroke();
    }
    ctx.setLineDash([]);
  }

  clearPolygonOnCanvas(canvas: HTMLCanvasElement) {
    const ctx = canvas.getContext('2d')!;
    const old = ctx.globalCompositeOperation;
    ctx.globalCompositeOperation = 'destination-out';
    this.goByPath(ctx);
    ctx.fill();
    ctx.globalCompositeOperation = old;
  }

  contain(p: Point, isBorderInside: boolean = false, move: Point = Point.Zero()): boolean {
    const simpleInclude = this.simpleInclude(p);
    if (!simpleInclude) {
      return false;
    } else {
      const onBorder = this.onBorder(p);
      if (onBorder) {
        return isBorderInside;
      }
      const line = p.findLine(this.leftTop.move(move));
      const poly = this.deintersection;
      const intersectionPoints: Point[] = [];
      for (let i = 0; i < poly.length - 1; i++) {
        const polygonLine = poly.p(i).findLine(poly.p(i + 1));
        const intersection = line.intersection(polygonLine, 0.001);
        if (intersection) {
          intersection.setX(parseFloat(intersection.x.toFixed(COORDINATE_PRECISION)));
          intersection.setY(parseFloat(intersection.y.toFixed(COORDINATE_PRECISION)));
          intersectionPoints.push(intersection as Point);
        }
      }
      const hasCorners = intersectionPoints.some((z: Point) => poly.has(z));
      if (hasCorners) {
        return this.contain2(p, isBorderInside);
      }
      return intersectionPoints.length % 2 === 1;
    }
  }

  onBorder(p: Point): boolean {
    const simpleInclude = this.simpleInclude(p);
    if (simpleInclude) {
      const poly = this.deintersection;
      const hasSamePoint = this.points.some((point: Point) => point.equal(p));
      if (hasSamePoint) {
        return true;
      }
      for (let i = 0; i < poly.length - 1; i++) {
        const p0 = poly.p(i);
        const p1 = poly.p(i + 1);
        const polygonLine = p0.findLine(p1);
        const onBorder = polygonLine.x(p).equal(p) && polygonLine.inRange(p);
        if (onBorder) {
          return true;
        }
      }
    }
    return false;
  }

  nextStart() {
    this.open();
    this.push(this.shift());
    this.close();
    return this;
  }

  removeDuplicates() {
    for (let i = 0; i < this.length - 1; i++) {
      const p1 = this.p(i);
      const p2 = this.p(i + 1);
      if (p1.equal(p2)) {
        this.removePart(i, 1);
        i--;
      }
    }
    return this;
  }

  smartUnion(p: Polygon) {
    const res = this.clone().simpleUnion(p);
    if (res) {
      let allHoles = [...this.holes, ...p.holes, ...(res.holes ?? [])].map((h: Polygon) => h.clone());
      for (const a of allHoles) {
        for (const b of allHoles) {
          if (a.equal(b)) {
            continue;
          }
          const r = a.simpleUnion(b);
          if (r) {
            allHoles = allHoles.filter((v: Polygon) => !v.equal(a) && !v.equal(b));
            if (Array.isArray(r)) {
              allHoles = [...allHoles, ...r];
            } else {
              allHoles.push(r);
            }
          }
        }
      }
      res.holes = allHoles;
    }
    return res;
  }

  createSquare(): Polygon {
    const { w, h, center } = this;
    const wh = w > h ? w : h;
    const size = new Point(wh, wh);
    return Polygon.CreateBySize(size)
      .move(center.move(size.divide(2).minus()))
      .setProjection(this._projection);
  }

  isZPolygon(): boolean {
    return this._points.some((p) => p.z !== undefined);
  }

  removeDepth() {
    this._points.forEach((p) => p.removeDepth());
    return this;
  }

  // Todo: handle modifiers M and MZ
  getModifiers(): string {
    const z = this.isZPolygon() ? 'Z' : '';
    const m = '';
    return `${z}${m}`;
  }

  simplify(tolerance: number = 2.5, highestQuality = true): Polygon {
    function getSqDist(p1: Point, p2: Point) {
      const dx = p1.x - p2.x;
      const dy = p1.y - p2.y;
      return dx * dx + dy * dy;
    }

    function getSqSegDist(p: Point, p1: Point, p2: Point) {
      let x = p1.x,
        y = p1.y,
        dx = p2.x - x,
        dy = p2.y - y;

      if (dx !== 0 || dy !== 0) {
        const t = ((p.x - x) * dx + (p.y - y) * dy) / (dx * dx + dy * dy);

        if (t > 1) {
          x = p2.x;
          y = p2.y;
        } else if (t > 0) {
          x += dx * t;
          y += dy * t;
        }
      }

      dx = p.x - x;
      dy = p.y - y;

      return dx * dx + dy * dy;
    }

    function simplifyRadialDist(points: Point[], sqTolerance: number) {
      let point;
      let prevPoint = points[0];
      const newPoints = [prevPoint];

      for (let i = 1, len = points.length; i < len; i++) {
        point = points[i];

        if (getSqDist(point, prevPoint) > sqTolerance) {
          newPoints.push(point);
          prevPoint = point;
        }
      }

      if (point && prevPoint !== point) {
        newPoints.push(point);
      }

      return newPoints;
    }

    function simplifyDPStep(points: Point[], first: number, last: number, sqTolerance: number, simplified: Point[]) {
      let maxSqDist = sqTolerance,
        index = 0;

      for (let i = first + 1; i < last; i++) {
        const sqDist = getSqSegDist(points[i], points[first], points[last]);

        if (sqDist > maxSqDist) {
          index = i;
          maxSqDist = sqDist;
        }
      }

      if (maxSqDist > sqTolerance) {
        if (index - first > 1) simplifyDPStep(points, first, index, sqTolerance, simplified);
        simplified.push(points[index]);
        if (last - index > 1) simplifyDPStep(points, index, last, sqTolerance, simplified);
      }
    }

    function simplifyDouglasPeucker(points: Point[], sqTolerance: number) {
      const last = points.length - 1;

      const simplified = [points[0]];
      simplifyDPStep(points, 0, last, sqTolerance, simplified);
      simplified.push(points[last]);

      return simplified;
    }

    if (this.points.length <= 2) {
      return this;
    }

    const sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;

    this._points = highestQuality ? this._points : simplifyRadialDist(this._points, sqTolerance);
    this._points = simplifyDouglasPeucker(this._points, sqTolerance);
    this.holes.forEach((p) => p.simplify(tolerance, highestQuality));

    return this.close();
  }

  private simpleIncludeX(p: Point) {
    const { x } = p;
    return this.minX <= x && this.maxX >= x;
  }

  private simpleIncludeY(p: Point) {
    const { y } = p;
    return this.minY <= y && this.maxY >= y;
  }

  private douglasPeucker(points: Point[], e: number): Point[] {
    let dMax = 0;
    let index = 0;
    const end = points.length - 1;
    const line = points[0].findLine(points[end]);
    for (let i = 1; i < end; i++) {
      const d = line.perpendicularDistance(points[i]);
      if (d > dMax) {
        index = i;
        dMax = d;
      }
    }
    if (dMax >= e) {
      const recResult1 = this.douglasPeucker(points.slice(0, index + 1), e);
      const recResult2 = this.douglasPeucker(points.slice(index), e);
      recResult1.pop();
      return [...recResult1, ...recResult2];
    } else {
      return [points[0], points[end]];
    }
  }

  private goByPath(ctx: CanvasRenderingContext2D) {
    ctx.beginPath();
    const start = this.first;
    ctx.moveTo(start.x, start.y);
    for (let i = 1; i < this.length; i++) {
      const { x, y } = this.p(i);
      ctx.lineTo(x, y);
    }
    ctx.closePath();
  }

  private simpleLogicFunction(p: Polygon, unionThis: boolean, unionThat: boolean): Polygon | Polygon[] | undefined {
    const unionOrIntersection = unionThat === unionThis;
    const a = this.noHoles.toJSTS();
    const b = p.noHoles.toJSTS();
    let c: TFeature<TPolygon | TMultiPolygon> | null;
    if (!unionOrIntersection) {
      c = turfDifference(a, b);
    } else {
      if (unionThis) {
        c = turfUnion(a, b);
      } else {
        c = turfIntersect(a, b);
      }
    }
    if (c) {
      return Polygon.ParseJSTSFeature(c);
    }
    return undefined;
  }

  private contain2(p: Point, isBorderInside: boolean = false): boolean {
    const simpleInclude = this.simpleInclude(p);
    if (!simpleInclude) {
      return false;
    } else {
      const onBorder = this.onBorder(p);
      if (onBorder) {
        return isBorderInside;
      }
      const poly = this.deintersection;
      let totalFi = 0;
      for (let i = 0; i < poly.length - 1; i++) {
        const p1 = poly.p(i);
        const p2 = poly.p(i + 1);
        const line1 = new SimpleLine(p1.x - p.x, p1.y - p.y, 0);
        const line2 = new SimpleLine(p2.x - p.x, p2.y - p.y, 0);
        const fiDif = line1.findFi(line2);

        if (line1.vectorProduct(line2).c > 0) {
          totalFi += fiDif;
        } else {
          totalFi -= fiDif;
        }
      }

      const eps = Math.PI / 10000;
      let result = false;

      const absTotalFi = Math.abs(totalFi);

      if (absTotalFi < eps) {
        result = false;
      } else if (Math.abs(2 * Math.PI - absTotalFi) < eps) {
        result = true;
      } else {
        console.error(`contains2 FAILED! Point[ ${p} ] Poly[ ${poly} ]`);
        throw new Error('contains2 failed');
      }

      return result;
    }
  }
}
