import { FileUpload, IndicatorStatus, ModelId, RasterSourceType, UploadStatus } from '@/models';
import { extractFileExtension } from '@/utils/formatters';
import { Geometry } from '@/utils/geometry';
import { uploaderApi } from '@/cmd/UploaderApi';
import { QueryClient } from '@tanstack/react-query';
import { ModelFileTypes, YAMLFileTypes } from '@/consts';

let filesUpload: FileUpload[] = [];
let modelId: ModelId | undefined = undefined;
let state: {
  filesUpload: FileUpload[];
  modelId: ModelId | undefined;
  yamlStatus: IndicatorStatus;
  modelStatus: IndicatorStatus;
  isValidModelUpload: boolean;
  availableToUpdate: boolean;
  uploadInProgress: boolean;
  pendingFiles: boolean;
  isAllSuccess: boolean;
  isUploadComplete: boolean;
} = {
  filesUpload,
  modelId,
  yamlStatus: IndicatorStatus.DISABLED,
  modelStatus: IndicatorStatus.DISABLED,
  isValidModelUpload: false,
  availableToUpdate: false,
  uploadInProgress: false,
  pendingFiles: false,
  isAllSuccess: false,
  isUploadComplete: false,
};
const listeners: Set<() => void> = new Set();

function emitChange() {
  generateSnapshot();
  for (const listener of listeners) {
    listener();
  }
}

export const modelUploadStore = {
  setModelId(newModelId: ModelId) {
    modelId = newModelId;
    emitChange();
  },
  getFileIndex(file: FileUpload) {
    return filesUpload.findIndex((f) => f.name === file.name);
  },
  addFilesToUploader(files: File[]) {
    const newFiles = files
      .filter((f) => !filesUpload.find((ff) => ff.name === f.name))
      .map((f) => ({
        name: f.name,
        size: f.size,
        status: UploadStatus.NOT_STARTED,
        progress: 0,
        ext: extractFileExtension(f.name),
        file: f,
        sourceType: undefined,
      }));
    filesUpload = [...filesUpload, ...newFiles];
    emitChange();
  },
  updateStatus(file: FileUpload, status: UploadStatus, details?: string) {
    const index = modelUploadStore.getFileIndex(file);
    if (filesUpload[index]) {
      const copy = [...filesUpload];
      copy[index].status = status;
      if (status === UploadStatus.QUEUED) {
        copy[index].progress = 0;
        copy[index].statusDetails = '';
      }
      if (details) copy[index].statusDetails = details;
      filesUpload = copy;
      emitChange();
    }
  },
  updateProgress(file: FileUpload, progress: number) {
    const index = modelUploadStore.getFileIndex(file);
    if (filesUpload[index]) {
      const copy = [...filesUpload];
      copy[index].progress = Math.round(progress);
      filesUpload = copy;
      emitChange();
    }
  },
  setSourceType(file: FileUpload, sourceType: RasterSourceType) {
    const index = modelUploadStore.getFileIndex(file);
    if (index > -1) {
      const copy = [...filesUpload];
      copy[index].sourceType = sourceType;
      filesUpload = copy;
      emitChange();
    }
  },
  setGeometry(file: FileUpload, geometry: Geometry | undefined) {
    const index = modelUploadStore.getFileIndex(file);
    if (index > -1) {
      const copy = [...filesUpload];
      copy[index].geometry = geometry;
      filesUpload = copy;
      emitChange();
    }
  },
  removeFileUpload(file: FileUpload) {
    const index = modelUploadStore.getFileIndex(file);
    if (index > -1) {
      const copy = [...filesUpload];
      copy.splice(index, 1);
      filesUpload = copy;
      emitChange();
    }
  },
  prepareUpload() {
    filesUpload = filesUpload.map((file) => ({
      ...file,
      status: file.status === UploadStatus.NOT_STARTED ? UploadStatus.QUEUED : file.status,
    }));
    emitChange();
  },
  reset() {
    filesUpload = [];
    modelId = undefined;
    emitChange();
  },
  checkFileStatus(file: FileUpload) {
    const index = modelUploadStore.getFileIndex(file);
    return filesUpload[index].status;
  },
  async uploadModel(queryClient: QueryClient) {
    if (filesUpload.length > 0) {
      const ymlFile = filesUpload.find((f) => YAMLFileTypes.includes(f.ext.toLowerCase()));
      const modelFile = filesUpload.find((f) => ModelFileTypes.includes(f.ext.toLowerCase()));
      //upload yaml first
      if (!!ymlFile && ymlFile.status === UploadStatus.QUEUED) {
        const form = new FormData();
        form.append('file', ymlFile.file);

        modelUploadStore.updateStatus(ymlFile, UploadStatus.UPLOADING);
        if (modelFile) modelUploadStore.updateStatus(modelFile, UploadStatus.QUEUED);
        //get modelid
        try {
          const modelDetails = await uploaderApi().uploadMeta(form, {
            onUploadProgress: (d) => {
              const progress = (d.loaded / d.bytes) * 100;
              modelUploadStore.updateProgress(ymlFile, progress);
            },
          });
          if (modelDetails) {
            modelUploadStore.updateStatus(ymlFile, UploadStatus.UPLOADED);
          }
          modelId = modelDetails.id;
        } catch (e) {
          modelUploadStore.updateStatus(ymlFile, UploadStatus.FAILED);
          if (modelFile) modelUploadStore.updateStatus(modelFile, UploadStatus.SKIPPED);
        }
      }

      if (modelId && !!modelFile && modelFile.status === UploadStatus.QUEUED) {
        const onComplete = () => {
          modelUploadStore.updateStatus(modelFile, UploadStatus.UPLOADED);
          queryClient.invalidateQueries({ queryKey: ['model_group_list'] });
          queryClient.invalidateQueries({ queryKey: ['models'] });
        };

        //upload model
        modelUploadStore.updateStatus(modelFile, UploadStatus.UPLOADING);
        const res = await uploaderApi().uploadModel(
          modelFile,
          {
            modelId,
          },
          onComplete,
        );
      }
    }
    // this.setUploadState(UploadState.STOP);
  },
  subscribe(listener: () => void) {
    listeners.add(listener);
    return () => {
      listeners.delete(listener);
    };
  },
  getSnapshot() {
    return state;
  },
  getServerSnapshot() {
    return state;
  },
};

const generateSnapshot = () => {
  state = {
    filesUpload,
    modelId,
    ...getUploadStatuses(filesUpload),
  };
};

const getIsValidModelUpload = (modelCount: number, yamlCount: number) => {
  if (modelId) {
    return filesUpload.length > 0 && modelCount == 1;
  }
  const maxFiles = 2;
  //check for duplicate yaml
  return filesUpload.length > 0 && filesUpload.length <= maxFiles && modelCount == 1 && yamlCount == 1;
};

const getYamlStatus = (yamlCount: number): IndicatorStatus => {
  const ymlFile = filesUpload.find((f) => YAMLFileTypes.includes(f.ext.toLowerCase()));
  if (ymlFile?.status === UploadStatus.UPLOADED || (modelId && yamlCount === 0)) return IndicatorStatus.SUCCESS;
  return yamlCount === 0 ? IndicatorStatus.DISABLED : yamlCount > 1 ? IndicatorStatus.ERROR : IndicatorStatus.READY;
};

const getModelStatus = (modelCount: number): IndicatorStatus => {
  const modelFile = filesUpload.find((f) => ModelFileTypes.includes(f.ext.toLowerCase()));
  if (modelFile?.status === UploadStatus.UPLOADED) return IndicatorStatus.SUCCESS;
  return modelCount === 0 ? IndicatorStatus.DISABLED : modelCount > 1 ? IndicatorStatus.ERROR : IndicatorStatus.READY;
};

const getUploadStatuses = (filesUpload: FileUpload[]) => {
  const yamlCount = filesUpload.filter((f) => YAMLFileTypes.includes(f.ext.toLowerCase())).length;
  const modelCount = filesUpload.filter((f) => ModelFileTypes.includes(f.ext.toLowerCase())).length;
  const yamlStatus = getYamlStatus(yamlCount);
  const modelStatus = getModelStatus(modelCount);
  const availableToUpdate = filesUpload.some((file) => file.status === UploadStatus.NOT_STARTED);
  const uploadInProgress = filesUpload.some((file) => file.status === UploadStatus.UPLOADING);
  const pendingFiles = filesUpload.some((file) => file.status === UploadStatus.QUEUED);
  const isAllSuccess = yamlStatus === IndicatorStatus.SUCCESS && modelStatus === IndicatorStatus.SUCCESS;
  const isUploadComplete =
    modelCount > 0 &&
    filesUpload.every((f) => [UploadStatus.UPLOADED, UploadStatus.FAILED, UploadStatus.CANCELLED, UploadStatus.SKIPPED].includes(f.status));

  return {
    yamlStatus,
    modelStatus,
    isValidModelUpload: getIsValidModelUpload(modelCount, yamlCount),
    availableToUpdate,
    uploadInProgress,
    pendingFiles,
    isAllSuccess,
    isUploadComplete,
  };
};

export type ModelUploaderStore = typeof modelUploadStore;
