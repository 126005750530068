import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { PopperDialog, popperDialogInnerContentClass, PopperDialogProps } from '@/components/PopperDialog';
import { Box, cardContentClasses, styled, Typography } from '@mui/material';
import Button from '@/components/Button';
import NotificationList, { MAX_HEIGHT } from '@/components/Notifications/NotificationList';
import { notificationKeys, useNotificationCount, useNotificationList } from '@/components/Notifications/queries';
import { useQueryClient } from '@tanstack/react-query';
import { notificationsApi } from '@/cmd/NotificationsApi';
import { numRange } from '@/utils';
import { Notification } from '@/models';

const Layout = styled(PopperDialog)`
  width: 360px;

  .${cardContentClasses.root} {
    padding: 0;
    margin: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .${popperDialogInnerContentClass} {
    max-height: ${MAX_HEIGHT}px;
    scrollbar-gutter: initial;
    overflow: hidden;
  }
`;

const PAGE_SIZE = 6;

interface Props extends PopperDialogProps {
  anchorEl: HTMLElement | null;
}

const popperMod = [
  {
    name: 'offset',
    options: {
      offset: [0, 21],
    },
  },
];

export const NotificationDialog: FC<Props> = (props) => {
  const queryClient = useQueryClient();
  const { open, onClose, anchorEl, ...restProps } = props;
  const { t } = useTranslation(['common']);
  const { data: count, refetch: updateCount } = useNotificationCount();
  const { data, isLoading, loadMore, refetch: updateList } = useNotificationList(open && (count || 0) > 0, undefined, PAGE_SIZE);
  const notificationList: Notification[] = useMemo(
    () =>
      isLoading ? [...new Array(numRange(count || 0, 0, PAGE_SIZE))].fill({ id: 'dummy' }) : data?.pages.flatMap((p) => p.content) ?? [],
    [data, count],
  );
  const updateNotifications = () => {
    void updateList();
    void updateCount();
  };

  useEffect(() => {
    !open && queryClient.invalidateQueries({ queryKey: notificationKeys.all });
  }, [open]);

  const handleReadAll = () => notificationsApi().markReadAll().then(updateNotifications);

  const header = (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="body2">{t('common:notifications')}</Typography>
      <Button onClick={handleReadAll}>{t('common:mark_all_read')}</Button>
    </Box>
  );

  return (
    <Layout
      open={open}
      header={header}
      actions={false}
      PopperProps={{ ...restProps.PopperProps, sx: { zIndex: 3 }, placement: 'right-end', modifiers: popperMod, anchorEl }}
    >
      {open && <NotificationList items={notificationList} loadMore={loadMore} updateList={updateNotifications} updateCount={updateCount} />}
    </Layout>
  );
};
