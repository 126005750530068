import { Point } from './Point';

const delta = 0.001;

export class Numbers {
  static like(v: number, s: number, d: number = delta): boolean {
    return v >= s - d && v <= s + d;
  }

  static likeZero(v: number): boolean {
    return Numbers.like(v, 0);
  }

  static like2PI(v: number): boolean {
    return Numbers.like(Numbers.rad2Deg(v), 360);
  }

  static likePI(v: number): boolean {
    return Numbers.like(Numbers.rad2Deg(v), 180);
  }

  static rad2Deg(v: number): number {
    return (v / Math.PI) * 180;
  }

  static deg2Rad(v: number): number {
    return (v / 180) * Math.PI;
  }

  static minInPoint(p: Point): number {
    return Math.min(p.x, p.y);
  }

  static maxInPoint(p: Point): number {
    return Math.max(p.x, p.y);
  }
}
