import axios, { AxiosError } from 'axios';
import { getSession } from 'next-auth/react';
import { getConfig } from '@/config';
import { logout } from '@/auth-provider/helper';
import { HTTP_CODE } from '@/cmd/Api';

// A list of URLs that, when obtaining 401, won't cause the user to log out
const LOGOUT_EXCLUSION_LIST = ['/websocket/api/auth/validate-token'];

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (config) => {
  if (config.headers['Skip-Authorization'] !== 'true' && !config.headers.Authorization) {
    const session = await getSession();
    if (session) config.headers.Authorization = session.user.token;
  }
  delete config.headers['Skip-Authorization'];

  return config;
});

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: AxiosError) => {
    if (error && !error.config?.signal?.aborted) {
      if (error.response) {
        if (error.response.status === HTTP_CODE.UNAUTHORIZED && typeof window !== 'undefined') {
          const appEndpoint = getConfig().appEndpoint ?? window.location.origin;
          if (error.response.config.url?.includes(appEndpoint)) {
            const requestUrl = new URL(error.request.responseURL);
            if (!LOGOUT_EXCLUSION_LIST.includes(requestUrl.pathname)) {
              void logout();
            }
          }
        }
      } else {
        console.error(error.toString());
      }
    }
    return Promise.reject(error);
  },
);
