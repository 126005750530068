import { Cartesian3, Color, ConstantProperty, Entity, LabelGraphics, LabelStyle, Math as CMath } from 'cesium';
import { Geometry, GeometryType, isRectangle, parseFromWKT } from '@/utils/geometry';
import { dataSourceApi } from '@/cmd/DataSourceApi';
import vectorStylesStore from '@/stores/vector-styles.store';
import { RectangleType, BaseVectorLayer } from './BaseVectorLayer';
import type { DrawingFeature, EntityFeature, FeatureStyle, SourceData, SourceDataId } from '@/models';
import { AnnotationFeature, EntityId, GeometryStyles, KeyValue, PropertyStyle, StylesMode, VectorStyles } from '@/models';
import { cartesianToPoint, cloneEntity, createEntity, EntityGraphics, pointToCartesian3 } from '@/components/Cesium/utils';
import { DEFAULT_FEATURE_STYLE, DEFAULT_STYLES } from '@/components/Cesium/utils/styles';
import { PageableResponse } from '@/cmd/Api';
import { invertColor, isEmptyObject, removeEmpty } from '@/utils';
import { WGS, WGS_TO_MERCATOR } from '@/consts';
import { Controller } from '@/components/Cesium/Map/Controller';
import { getDefaultStrokeWidth, getFeatureStyleByRange } from '@/components/Map/utils';

const eyeOffset = new Cartesian3(0, 10, 0);
const BATCH_SIZE = 5000;
const GRAPHIC_OPTIONS = { clampToGround: false, extrudedHeight: 0, perPositionHeight: true };
const ACTIVE_FEATURE_ID = 'active-vector-feature';

export interface SourceDataLayerOptions {
  mapCtl: Controller;
  styles?: VectorStyles;
}

export class SourceDataLayer extends BaseVectorLayer {
  readonly sourceData: SourceData;
  protected _styles!: VectorStyles;
  protected propertyValueStyle: Record<string, FeatureStyle> = {};
  protected stylesDisposer: () => void;
  protected loadingCallback: undefined | ((id: string, isLoading: boolean) => void);
  protected mapCtl: Controller;
  protected _isEditModeEnabled: boolean = false;
  constructor(source: SourceData, { mapCtl, styles }: SourceDataLayerOptions) {
    super(source.id, source.name);
    this.sourceData = source;
    this.mapCtl = mapCtl;

    // Debug
    // this.changedEvent.addEventListener((e) => console.log('VectorDataSource Change', e));
    // this.loadingEvent.addEventListener((e) => console.log('Loading', e));
    // this.entities.collectionChanged.addEventListener((e) => console.log('collections Change', e));
    this.entities.collectionChanged.addEventListener(() => this.mapCtl.renderScene());
    this.stylesDisposer = vectorStylesStore.subscribe(this.onStyleChanged);
    if (styles) {
      this.setStyles(styles);
      this.loadColorPresetStyles()?.then(this.loadAllFeatures);
    } else {
      vectorStylesStore.load(source.id).then(this.setStyles).then(this.loadColorPresetStyles).then(this.loadAllFeatures);
    }
  }

  getGeometryGraphics(geometry: Geometry, styles: VectorStyles = this._styles, options?: KeyValue): EntityGraphics {
    options = { ...options, ...GRAPHIC_OPTIONS };
    const type = geometry.properties.area > 1000 && isRectangle(geometry) ? RectangleType.RECTANGLE : geometry.type;
    const graphics = this.getEntityGraphicsByGeometryType(type, this.getGeometryStyles(geometry, styles), options);
    const label = this.getLabelGraphics(geometry, styles);
    return { ...graphics, label };
  }

  getEntityGraphics(entity: Entity, styles: VectorStyles = this._styles, options?: KeyValue): EntityGraphics {
    options = { ...options, ...GRAPHIC_OPTIONS };
    const geometry = entity.properties?.geometry || entity.parent?.properties?.geometry;
    if (!geometry) {
      return {};
    }
    const type = geometry.properties?.area > 1000 && isRectangle(geometry) ? RectangleType.RECTANGLE : geometry.type;
    const graphics = this.getEntityGraphicsByGeometryType(type, this.getGeometryStyles(geometry, styles), options);
    const label = this.getLabelGraphics(geometry, styles);
    return { ...graphics, label };
  }

  protected getGeometryStyles(geometry: Geometry, style: VectorStyles = this._styles, options?: any): FeatureStyle {
    const type: Exclude<GeometryType, 'MULTIPOLYGON'> = geometry.type as any;
    if (style.mode === StylesMode.Property) {
      const value = geometry.properties[style.property];
      // ---- TODO: DRAG-1897
      // const key = getType(value) === Type.INTEGER ? value.toFixed(1) : value + '';
      // ----
      return this.propertyValueStyle[value] || DEFAULT_STYLES.rules[type]!;
    }

    return style.rules[type]!;
  }

  getLabelGraphics(geometry: Geometry, styles: VectorStyles = this._styles): LabelGraphics | undefined {
    const labelStyles = (styles as GeometryStyles | PropertyStyle).labelStyle;
    if (labelStyles && labelStyles.property && (geometry.properties[labelStyles.property] ?? null) !== null) {
      const text = (geometry.properties[labelStyles.property] + '').substring(0, 255);
      return new LabelGraphics({
        text,
        eyeOffset: labelStyles.eyeOffset, // https://github.com/CesiumGS/cesium/issues/4108
        show: labelStyles.show,
        fillColor: Color.fromCssColorString(labelStyles.fill),
        outlineColor: Color.fromCssColorString(labelStyles.outline),
        outlineWidth: 10,
        style: !labelStyles.outlineDisable ? LabelStyle.FILL_AND_OUTLINE : LabelStyle.FILL,
        font: '16px "IBM Plex Sans" sans-serif',
        scale: labelStyles.size / 16,
      });
    }
  }

  protected onStyleChanged = (sourceId: SourceDataId) => {
    if (this.id === sourceId) {
      this.styles = vectorStylesStore.get(sourceId);
    }
  };

  protected createEntity(feature: AnnotationFeature, options?: any): Entity | Entity[] | undefined {
    const properties = { sourceDataId: this.id };
    options = { ...options, ...GRAPHIC_OPTIONS };
    return super.createEntity(feature, { ...options, properties, id: feature.id });
  }

  getFeatureGeometry(feature: AnnotationFeature): Geometry | undefined {
    const geometry = parseFromWKT(feature.geometry, WGS)!;
    if (!geometry) {
      return undefined;
    }
    geometry.properties = feature.properties || {};
    switch (geometry.type) {
      case GeometryType.POLYGON:
        geometry.properties.area = geometry.clone().transform(WGS_TO_MERCATOR).area;
        break;
      case GeometryType.LINESTRING:
        geometry.properties.length = geometry.clone().transform(WGS_TO_MERCATOR).distance;
        break;
    }
    return geometry;
  }

  protected loadAllFeatures = async (): Promise<any> => {
    let page = 0;
    let res = { last: false } as PageableResponse<AnnotationFeature>;
    while (!this.destroyed && res && !res.last) {
      res = await dataSourceApi().getFeaturesList(this.id!, undefined, { page: page++, pageSize: BATCH_SIZE });
      if (this.destroyed) {
        break;
      }
      await this.addFeatures(res.content);
    }
  };
  updateFeature = (feature: EntityFeature) => {
    const geometry = parseFromWKT(feature.geometry);
    if (!geometry) {
      return undefined;
    }
    const entity = this.getEntityById(feature.id);
    // TODO: DRAG-3045
    feature.properties = removeEmpty(feature.properties);
    geometry.properties.feature = feature;
    if (entity && entity.properties) {
      entity.properties.geometry = geometry;
      entity.properties.feature = feature;
    }
  };
  addFeature = (feature: EntityFeature) => {
    return this.addFeatures([feature]);
  };
  protected loadColorPresetStyles = (styles: VectorStyles = this._styles) => {
    if (styles.mode === StylesMode.Property && styles.colorPreset) {
      const { property } = styles;
      const load = (page: number) => dataSourceApi().getPropertyValues(this.id!, property, {}, { pageSize: 500, page });
      this.propertyValueStyle = {};
      return dataSourceApi().getAll(load).then(this.updatePropertyStyles);
    }
  };

  protected updatePropertyStyles = (values: string[], styles = this._styles) => {
    this.propertyValueStyle = {};
    if (styles.mode === StylesMode.Property) {
      if (styles.colorPreset) {
        const strokeWidth = styles.strokeWidth || getDefaultStrokeWidth(this.sourceData);
        const total = values.length;
        values.forEach((value, idx) => {
          this.propertyValueStyle[value + ''] = getFeatureStyleByRange({ strokeWidth, ...styles }, total, idx) || DEFAULT_FEATURE_STYLE;
        });
      }
      styles.rules.forEach(({ value, style }) => style && (this.propertyValueStyle[value + ''] = style));
    }
  };
  set isEditModeEnabled(enabled: boolean) {
    this._isEditModeEnabled = enabled;
    if (enabled) {
      const { viewer } = this.mapCtl;
      viewer && viewer.entities.removeById(ACTIVE_FEATURE_ID);
    }
  }
  activeEntity(): Entity | undefined {
    const { viewer } = this.mapCtl;
    if (viewer && viewer.dataSourceDisplay) {
      const entity = this.mapCtl.viewer.entities.getById(ACTIVE_FEATURE_ID);
      return (entity && entity.parent) || undefined;
    }
  }

  set active(entityId: EntityId | undefined) {
    const { viewer } = this.mapCtl;
    viewer && viewer.entities.removeById(ACTIVE_FEATURE_ID);
    if (entityId && viewer && !this._isEditModeEnabled) {
      const entity = this.entities.getById(entityId);
      if (entity && entity.position) {
        const activeEntity = cloneEntity(entity, { id: ACTIVE_FEATURE_ID });
        activeEntity.parent = entity;
        activeEntity.label = undefined;
        activeEntity.position = entity.position!.getValue(viewer.clock.currentTime)!.clone() as any;
        if (entity.point?.color) {
          const color = (entity.point.color as ConstantProperty).getValue().toCssHexString();
          const newColor = Color.fromCssColorString(invertColor(color)) as any;
          activeEntity.point = {
            color: newColor,
            outlineColor: newColor,
            pixelSize: (activeEntity.point!.pixelSize as any)!.getValue() + 4,
          } as any;
        }
        if ((entity.polyline?.material as any)?.color) {
          const origMaterial = entity.polyline?.material as any;
          const color = origMaterial?.color?.getValue().toCssHexString();
          const material = (color && Color.fromCssColorString(invertColor(color))) || undefined;
          const positions = entity
            .polyline!.positions!.getValue(viewer.clock.currentTime)
            .map((p: any) => pointToCartesian3(cartesianToPoint(p).move(0, 0, CMath.EPSILON2)));
          activeEntity.polyline = { positions, material, zIndex: 100, width: (entity.polyline!.width as any)!.getValue() + 2 } as any;
        }

        viewer.entities.add(activeEntity);
        this.mapCtl.renderScene();
      }
    }
  }

  protected turnOffActive = () => {
    const entity = this.activeEntity();
    if (entity && entity.entityCollection.owner === this) {
      this.active = undefined;
    }
  };

  applyStyles(styles: VectorStyles = this._styles, entities?: Entity[]) {
    if (styles.mode === StylesMode.Property) {
      if (styles.colorPreset) {
        if (isEmptyObject(this.propertyValueStyle) || !('property' in this._styles) || styles.property !== this._styles.property) {
          return this.loadColorPresetStyles(styles)?.then(() => super.applyStyles(styles));
        }
      }
      this.updatePropertyStyles(Object.keys(this.propertyValueStyle), styles);
    }
    super.applyStyles(styles);
  }

  destroy() {
    this.turnOffActive();
    this.stylesDisposer();
    if (!!this.loadingCallback) {
      this.loadingEvent.removeEventListener(this.loadingCallback);
    }
    super.destroy();
  }
}
