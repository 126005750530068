import { SourceDataId } from '@/models';
import { NetCDFWindOptions } from '@/components/Cesium/utils/wind/utils';
import { defaultSettings } from '@/components/Cesium/utils/wind/options';

type OptionsStore = Record<SourceDataId, NetCDFWindOptions>;

const listeners: Set<(sourceId: SourceDataId) => void> = new Set();
const store: OptionsStore = {};

function emitChange(sourceId: SourceDataId) {
  for (const listener of listeners) {
    listener(sourceId);
  }
}

const WindOptionsStore = {
  subscribe(listener: (sourceId: SourceDataId) => void) {
    listeners.add(listener);

    return () => {
      listeners.delete(listener);
    };
  },

  getSnapshot() {
    return store;
  },

  patch(sourceId: SourceDataId, options: Partial<NetCDFWindOptions>) {
    store[sourceId] = { ...store[sourceId], ...options };
    emitChange(sourceId);
  },

  set(sourceId: SourceDataId, options: NetCDFWindOptions) {
    store[sourceId] = options;
    emitChange(sourceId);
  },

  get(sourceId: SourceDataId, withDefaults = true): NetCDFWindOptions {
    return withDefaults ? { ...defaultSettings, ...store[sourceId] } : store[sourceId];
  },
};

export default WindOptionsStore;
