import { DropDownMenu } from '@/components/DropDownMenu';
import { Icon, IconButton, ListItemText, MenuItem, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { Localisations, UserSettings } from '@/models';
import { useCreateUpdateUserSettings, useUserSettings } from '@/hooks/useSettings';
import { useRouter } from 'next/router';
import { CornerIcon } from '@/components/Navigation/CornerIcon';

const LanguageMenu = () => {
  const router = useRouter();
  const { t, i18n } = useTranslation('common');
  const theme = useTheme();

  const updateUserSettings = useCreateUpdateUserSettings();
  const { data: userSettings } = useUserSettings();
  const language = userSettings && 'language' in userSettings ? userSettings.language : (i18n.language as UserSettings['language']);

  const handleLanguageChange = (nextLocalisation: UserSettings['language']) => {
    const settings = { language: nextLocalisation };
    updateUserSettings.mutate(
      { settings },
      {
        onSuccess: (_, variables) => {
          if (variables.settings.language && variables.settings.language !== userSettings?.language) {
            router.push(router.asPath, router.asPath, { locale: variables.settings.language });
          }
        },
      },
    );
  };

  return (
    <>
      <DropDownMenu
        opener={
          <Stack>
            <Tooltip title={t(`common:language_name_${language}`)} placement="right">
              <IconButton sx={{ color: 'text.primary' }}>
                <Icon>translate</Icon>
                <CornerIcon sx={{ position: 'absolute' }} />
              </IconButton>
            </Tooltip>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 50 }}
            >
              {t(`common:language_name_${language}`)}
            </Typography>
          </Stack>
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: theme.direction === 'rtl' ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: theme.direction === 'rtl' ? 'right' : 'left',
        }}
        sx={{
          ...(theme.direction === 'rtl' ? { marginInlineEnd: -5 } : { marginInlineStart: 5 }),
        }}
      >
        <MenuItem onClick={() => handleLanguageChange(Localisations.ar)} selected={language === Localisations.ar}>
          <ListItemText>{t('common:language_name_ar')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange(Localisations.en)} selected={language === Localisations.en}>
          <ListItemText>{t('common:language_name_en')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange(Localisations.id)} selected={language === Localisations.id}>
          <ListItemText>{t('common:language_name_id')}</ListItemText>
        </MenuItem>
      </DropDownMenu>
    </>
  );
};

export default LanguageMenu;
