import { FC, ReactNode } from 'react';
import AnimatedIcon from '@/components/AnimatedIcon';
import { Box, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { FADE_TRANSITIONS } from '@/theme';

interface Props {
  icon: string;
  primary: string;
  secondary: ReactNode;
}
const EmptyMessage: FC<Props> = ({ icon, primary, secondary }) => {
  return (
    <Box
      component={m.div}
      {...FADE_TRANSITIONS}
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={1}
    >
      <AnimatedIcon fontSize="large" color="action">
        {icon}
      </AnimatedIcon>
      <Typography variant="body2">{primary}</Typography>
      <Typography variant="body2" color="text.secondary">
        {secondary}
      </Typography>
    </Box>
  );
};

export default EmptyMessage;
