import { VertexArray, ClearCommand, Pass, BufferUsage, ShaderProgram, RenderState, DrawCommand, ComputeCommand } from 'cesium';
import { Texture, Framebuffer, Color, defaultValue, Matrix4, destroyObject, defined, PrimitiveType } from 'cesium';

export default class CustomPrimitive {
  commandType: string;
  geometry: any;
  attributeLocations: any;
  primitiveType?: PrimitiveType;
  uniformMap: any;
  vertexShaderSource?: string;
  fragmentShaderSource: string;
  rawRenderState: RenderState;
  framebuffer: Framebuffer;
  outputTexture: Texture;
  autoClear: boolean;
  preExecute?: () => void;
  show: boolean;
  commandToExecute?: DrawCommand | ComputeCommand;
  clearCommand?: ClearCommand | undefined;

  constructor(options: any) {
    this.commandType = options.commandType;

    this.geometry = options.geometry;
    this.attributeLocations = options.attributeLocations;
    this.primitiveType = options.primitiveType;

    this.uniformMap = options.uniformMap;

    this.vertexShaderSource = options.vertexShaderSource;
    this.fragmentShaderSource = options.fragmentShaderSource;

    this.rawRenderState = options.rawRenderState;
    this.framebuffer = options.framebuffer;

    this.outputTexture = options.outputTexture;

    this.autoClear = defaultValue(options.autoClear, false);
    this.preExecute = options.preExecute;

    this.show = true;
    this.commandToExecute = undefined;
    this.clearCommand = undefined;
    if (this.autoClear) {
      this.clearCommand = new ClearCommand({
        color: new Color(0.0, 0.0, 0.0, 0.0),
        depth: 1.0,
        framebuffer: this.framebuffer,
        pass: Pass.OPAQUE,
      });
    }
  }

  createCommand(context: any) {
    switch (this.commandType) {
      case 'Draw': {
        const vertexArray = VertexArray.fromGeometry({
          context,
          geometry: this.geometry,
          attributeLocations: this.attributeLocations,
          bufferUsage: BufferUsage.STATIC_DRAW,
        });

        const shaderProgram = ShaderProgram.fromCache({
          context,
          attributeLocations: this.attributeLocations,
          vertexShaderSource: this.vertexShaderSource,
          fragmentShaderSource: this.fragmentShaderSource,
        });

        const renderState = RenderState.fromCache(this.rawRenderState);
        return new DrawCommand({
          owner: this,
          vertexArray,
          primitiveType: this.primitiveType,
          uniformMap: this.uniformMap,
          modelMatrix: Matrix4.IDENTITY,
          shaderProgram,
          framebuffer: this.framebuffer,
          renderState,
          pass: Pass.OPAQUE,
        });
      }
      case 'Compute': {
        return new ComputeCommand({
          owner: this,
          fragmentShaderSource: this.fragmentShaderSource,
          uniformMap: this.uniformMap,
          outputTexture: this.outputTexture,
          persists: true,
        });
      }
    }
  }

  setGeometry(context: any, geometry: any) {
    this.geometry = geometry;
    const vertexArray = VertexArray.fromGeometry({
      context,
      geometry: this.geometry,
      attributeLocations: this.attributeLocations,
      bufferUsage: BufferUsage.STATIC_DRAW,
    });
    if (this.commandToExecute) {
      this.commandToExecute.vertexArray = vertexArray;
    }
  }

  update(frameState: any) {
    if (!this.show) {
      return;
    }

    if (!defined(this.commandToExecute)) {
      this.commandToExecute = this.createCommand(frameState.context);
    }

    if (defined(this.preExecute)) {
      this.preExecute();
    }

    if (defined(this.clearCommand)) {
      frameState.commandList.push(this.clearCommand);
    }
    frameState.commandList.push(this.commandToExecute);
  }

  isDestroyed() {
    return false;
  }

  destroy() {
    if (defined(this.commandToExecute)) {
      this.commandToExecute.shaderProgram = this.commandToExecute.shaderProgram && this.commandToExecute.shaderProgram.destroy();
    }
    return destroyObject(this);
  }
}
