import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, RequestPromise, SORT_DIRECTION } from './Api';
import { singleton } from '@/utils/decorators/singleton';
import {
  AttachmentType,
  Category,
  CategoryAttachmentItem,
  CategoryCaption,
  CategoryCaptionResponse,
  CategoryId,
  ErrorResponse,
} from '@/models';

@singleton
class CategoryApi extends Api {
  endpoint: string = '/api/categories';
  static getInstance: () => CategoryApi;

  getCategory(categoryId: CategoryId): RequestPromise<Category> {
    return this.request(METHOD.GET, '/' + categoryId);
  }

  getList(pagination?: PageableRequest): RequestPromise<PageableResponse<Category>> {
    return this.request(METHOD.GET, '', {
      ...DEFAULT_PAGINATION,
      pageSize: 100,
      sort: 'name',
      direction: SORT_DIRECTION.ASC,
      ...pagination,
    });
  }

  getListById(categoryIds: CategoryId[]): RequestPromise<Category[]> {
    return this.request(METHOD.GET, '/list', { ids: categoryIds });
  }

  add(category: Category): RequestPromise<Category> {
    return this.request(METHOD.POST, '/', category);
  }

  update(categoryId: CategoryId, payload: Category): RequestPromise<Category> {
    return this.request(METHOD.PATCH, '/' + categoryId, payload);
  }

  delete(categoryId: CategoryId): RequestPromise<Category> {
    return this.request(METHOD.DELETE, '/' + categoryId);
  }

  deleteAttachment(attachment: CategoryAttachmentItem): RequestPromise<CategoryAttachmentItem> {
    return this.request(METHOD.DELETE, '/attachment/' + attachment.id);
  }

  patchAttachment(attachment: CategoryAttachmentItem): RequestPromise<CategoryAttachmentItem> {
    return this.request(METHOD.PATCH, '/attachment/' + attachment.id, attachment);
  }

  postAttachmentCaptions(categoryCaptions: CategoryCaption[]): RequestPromise<CategoryCaptionResponse[]> {
    return this.request(METHOD.POST, '/attachment-captions', categoryCaptions);
  }

  upload = (
    file: FormData,
    categoryId: CategoryId,
    config: { abortController?: AbortController; onUploadProgress: (progressEvent: any) => void },
  ): RequestPromise<any | ErrorResponse> => {
    return this.request(METHOD.POST, `/${categoryId}/attachment`, file, config);
  };

  getCategoryAttachments(
    categoryId: CategoryId,
    type: AttachmentType,
    pagination?: PageableRequest,
  ): RequestPromise<PageableResponse<CategoryAttachmentItem>> {
    return this.request(METHOD.GET, `/${categoryId}/attachments?type=${type}`, {
      sort: DEFAULT_PAGINATION.sort,
      direction: SORT_DIRECTION.DESC,
      ...pagination,
    });
  }
}

export const categoryApi = () => CategoryApi.getInstance();
