import * as React from 'react';
import clsx from 'clsx';
import usePathname from '@/hooks/usePathname';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import MuiLink, { LinkProps as MuiLinkProps, LinkTypeMap } from '@mui/material/Link';
import { buttonClasses, styled } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({});

const StyledMuiLink = styled(MuiLink)`
  &:has(.${buttonClasses.root}) {
    text-decoration: none;
  }

  .${buttonClasses.root} {
    height: 100%;
  }
` as OverridableComponent<LinkTypeMap<{}, 'a'>>;

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<NextLinkProps, 'href' | 'as' | 'onClick' | 'onMouseEnter' | 'onTouchStart'> {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
}

export const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(function NextLinkComposed(props, ref) {
  const { to, linkAs, ...other } = props;

  return <NextLink href={to} as={linkAs} passHref ref={ref} {...other} />;
});

export type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps['as'];
  href: NextLinkProps['href'];
  linkAs?: NextLinkProps['as']; // Useful when the as prop is shallow by styled().
  noLinkStyle?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  const {
    activeClassName = 'active',
    as,
    className: classNameProps,
    href,
    linkAs: linkAsProp,
    locale,
    noLinkStyle,
    prefetch,
    replace,
    role, // Link don't have roles.
    scroll = false,
    shallow,
    ...other
  } = props;

  const routerPathname = usePathname();
  const pathname = typeof href === 'string' ? href : href.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: routerPathname === pathname && activeClassName,
  });

  const isExternal = typeof href === 'string' && (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);

  if (isExternal) {
    if (noLinkStyle) {
      return <Anchor className={className} href={href} ref={ref} rel="noopener noreferrer" target="_blank" {...other} />;
    }

    return <StyledMuiLink className={className} href={href} ref={ref} rel="noopener noreferrer" target="_blank" {...other} />;
  }

  const linkAs = linkAsProp || as;
  const nextjsProps = { to: href, linkAs, replace, scroll, shallow, prefetch, locale };

  if (noLinkStyle) {
    return <NextLinkComposed className={className} ref={ref} {...nextjsProps} {...other} />;
  }

  return <StyledMuiLink component={NextLinkComposed} className={className} ref={ref} {...nextjsProps} {...other} />;
});

export default Link;
