import { CustomPromise, CustomPromiseProps, CustomPromiseState } from '@/utils/CustomPromise';
import { fixMeridianCrossViewPort, Geometry, GeometryType, hasIntersection, Point, Polygon } from '@/utils/geometry';
import { getArrayDifference, numRange } from '@/utils';
import { cartesianToPoint, getZoomDistance, polygonToRectangle, rectangleToPolygon } from '@/components/Cesium/utils';
import { Numbers } from '@/utils/geometry/Numbers';
import { MAX_MERCATOR_LATITUDE, WGS } from '@/consts';
import { isGeometry } from '@/utils/geometry/Geometry';
import { RasterLayer } from '@/components/Cesium/Layers/RasterLayer';
import { FeaturesLayer } from '@/components/Cesium/DataSource/FeaturesLayer';
import { SourceData3DTilesLayer } from '@/components/Cesium/Layers/SourceData3DTilesLayer';
import { CzmlVectorLayer } from '@/components/Cesium/DataSource/CzmlVectorLayer';
import { WindLayer } from '@/components/Cesium/Layers/WindLayer';
import { Cesium3DFeature, Layer, LayerInteractionId, SourceDataId } from '@/models';
import { LayerInteraction } from '@/components/Cesium/Interactions/LayerInteraction';
import { ScreenEvent } from '@/components/Cesium/ScreenEvent';
import { CesiumPrimitivesLayer } from '@/components/Cesium/Layers/models';
import { ImageryLayer } from '@/components/Cesium/Layers/ImageryLayer';
import {
  Cartesian2,
  Cartesian3,
  Cesium3DTileFeature,
  DataSourceCollection,
  Entity,
  Event,
  HeadingPitchRoll,
  ImageryLayer as CesiumImageryLayer,
  ImageryLayerCollection,
  Math,
  Rectangle,
  SceneMode,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  SplitDirection,
  Viewer,
} from 'cesium';

export const EntireWorldPolygon = Polygon.ParseFromWKT('POLYGON ((-180 90, 180 90, 180 -90, -180 -90, -180 90))', WGS);

export interface Location {
  destination: number[];
  orientation?: number[];
}

export class Controller {
  readonly screenEvent: ScreenEvent;
  protected readonly _viewer: Viewer;
  protected _viewPort?: Polygon;
  protected _baseLayer?: ImageryLayer;
  protected flyPromise?: CustomPromiseProps;
  protected rasterLayers: ImageryLayer[] = [];
  protected vectorLayers: FeaturesLayer[] = [];
  protected primitivesLayers: CesiumPrimitivesLayer[] = [];
  protected interactions: LayerInteraction[] = [];
  protected scratchRectangle: Rectangle = new Rectangle();
  protected screenSpaceEventHandler?: ScreenSpaceEventHandler;
  protected splitScreenSlider?: HTMLElement;
  protected animationDelay = 2;
  protected datasourceLoadingCount = 0;
  protected tileLoading = false;
  protected vectorLoading = false;
  protected loadingState = false;
  protected drillPickCache: { [key: string]: Entity[] } = {};
  readonly loading = new Event();

  constructor(viewer: Viewer) {
    this._viewer = viewer;
    this._baseLayer = this._viewer.imageryLayers.get(0); // Bing Aerial by Default
    this.screenEvent = new ScreenEvent(viewer);
    // Viewer debugger
    // this.viewer.extend(viewerCesiumInspectorMixin);

    // viewer.scene.morphStart.addEventListener(() => {
    // });
    viewer.scene.globe.tileLoadProgressEvent.addEventListener(this.tileLoadingHandler);
    viewer.dataSources.dataSourceAdded.addEventListener(this.renderScene);
    viewer.dataSources.dataSourceRemoved.addEventListener(this.renderScene);
    viewer.scene.morphComplete.addEventListener(() => {
      this.viewPort = this.getViewPort();
    });
  }

  renderScene = () => {
    if (this._viewer?.cesiumWidget) {
      this._viewer.scene.requestRender();
    }
  };

  getCoordinateByPosition = (cursorPos: Cartesian2): Cartesian3 | undefined => {
    if (!cursorPos) {
      return undefined;
    }
    const ellipsoid = this._viewer.scene?.globe.ellipsoid;
    return (ellipsoid && this.getCamera()?.pickEllipsoid(cursorPos, ellipsoid)) || undefined;
  };
  enableRotate(enabled: boolean) {
    this.viewer.scene.screenSpaceCameraController.enableRotate = enabled;
  }
  enableTilt(enabled: boolean) {
    this.viewer.scene.screenSpaceCameraController.enableTilt = enabled;
  }
  enableTranslate(enabled: boolean) {
    this.viewer.scene.screenSpaceCameraController.enableTranslate = enabled;
  }
  enableScreenSpaceCameraController(enabled: boolean) {
    this.enableRotate(enabled);
    this.enableTilt(enabled);
    this.enableTranslate(enabled);
  }
  getPositionByCoordinate = (position: Cartesian3): Cartesian2 | undefined => {
    const canvasPosition = new Cartesian2();
    const success = this._viewer.scene?.cartesianToCanvasCoordinates(position, canvasPosition);
    return (success && canvasPosition) || undefined;
  };

  pickRasterLayer = (coordinate: Cartesian2 | Point): RasterLayer | undefined => {
    const point: Point | undefined = isGeometry(coordinate) ? coordinate : cartesianToPoint(this.getCoordinateByPosition(coordinate)!);
    return (
      point && (this.rasterLayers.findLast((l) => l instanceof RasterLayer && hasIntersection(l.geometry as any, point)) as RasterLayer)
    );
  };

  pick = (cursorPos: Cartesian2): Entity | Cesium3DTileFeature | Cesium3DFeature | undefined => {
    let entity: Entity | Cesium3DTileFeature | Cesium3DFeature | undefined;
    const list = this.drillPick(cursorPos);

    if (list.length === 1) {
      entity = list[0];
    } else {
      entity =
        list.find((item) => {
          if (item instanceof Entity) {
            const geometry = item.properties?.geometry;
            const coordinate = cartesianToPoint(this.getCoordinateByPosition(cursorPos)!);
            return geometry && coordinate && hasIntersection(geometry, coordinate);
          } else {
            return item;
          }
        }) || list[0];
    }

    return entity;
  };

  drillPick = (cursorPos: Cartesian2): (Entity | Cesium3DTileFeature | Cesium3DFeature)[] => {
    const key = cursorPos.toString();
    if (!this.drillPickCache[key]) {
      this.drillPickCache[key] = this.viewer.scene.drillPick(cursorPos).map((item) => {
        return 'id' in item && item.id ? item.id : item;
      });
      setTimeout(() => delete this.drillPickCache[key], 500);
    }

    return this.drillPickCache[key];
  };

  setPosition(location: Location) {
    this.getCamera()?.setView(this.transformLocation(location));
    this.viewPort = this.getViewPort();
  }

  flyTo(locationOrPolygon: Location | Geometry, duration?: number): CustomPromiseProps {
    let destination, orientation;
    this.cancelFlight();

    if (isGeometry(locationOrPolygon)) {
      let polygon: Polygon;

      if (locationOrPolygon.type === GeometryType.POINT) {
        polygon = Polygon.CreateBySide(1, locationOrPolygon.center);
      } else if (locationOrPolygon.type === GeometryType.POLYGON && locationOrPolygon.w < 0.01) {
        polygon = Polygon.CreateBySide(locationOrPolygon.w < 0.001 ? 0.001 : 0.01, locationOrPolygon.center);
      } else {
        polygon = locationOrPolygon as Polygon;
        // https://github.com/CesiumGS/cesium/issues/9155
        // Web Mercator entire world polygon workaround (DRAG-2048)
        polygon.points.forEach((p) => (p.y = numRange(p.y, -MAX_MERCATOR_LATITUDE, MAX_MERCATOR_LATITUDE)));
        if (polygon.w < 100) {
          polygon.scale(2);
        }
      }
      polygon.extent.close().setProjection(WGS);

      destination = polygonToRectangle(polygon);
    } else {
      ({ destination, orientation } = this.transformLocation(locationOrPolygon));
    }
    this.flyPromise = CustomPromise();
    this.flyPromise.catch((e) => (this.flyPromise?.status === CustomPromiseState.CANCELLED ? false : console.error(e)));
    // promise.then(() => (this.viewPort = this.getViewPort()));
    this.getCamera()?.flyTo({ destination, orientation, duration, complete: this.flyPromise.resolve });

    return this.flyPromise;
  }

  isAvailable(): boolean {
    return !!this._viewer?.cesiumWidget;
  }

  getCamera() {
    return this.isAvailable() ? this._viewer.camera : undefined;
  }

  cancelFlight = () => {
    if (this.isFlyAnimation) {
      this.getCamera()?.cancelFlight();
      this.flyPromise?.cancel();
    }
  };

  getLayerById(layerId: string): Layer | undefined {
    return (
      this.rasterLayers.find((l) => 'id' in l && l.id === layerId) ||
      this.vectorLayers.find((l) => l.id === layerId) ||
      this.primitivesLayers.find((l) => l.id === layerId)
    );
  }

  isLayerVisible(layerId: string): boolean | undefined {
    return this.getLayerById(layerId)?.show;
  }

  setLayers(layers: Layer[]) {
    const { imageryLayers, dataSources } = this._viewer;
    const rasterLayers: ImageryLayer[] = [];
    const vectorLayers: FeaturesLayer[] = [];
    const primitivesLayers: CesiumPrimitivesLayer[] = [];
    let hasTemporalLayers = false;

    layers.forEach((l) => {
      hasTemporalLayers = hasTemporalLayers || ('isTemporal' in l && l.isTemporal);
      switch (true) {
        case l instanceof CesiumImageryLayer:
          rasterLayers.push(l as ImageryLayer);
          break;
        case l instanceof CzmlVectorLayer:
        case l instanceof FeaturesLayer:
          vectorLayers.push(l as FeaturesLayer);
          break;
        case l instanceof WindLayer:
        case l instanceof SourceData3DTilesLayer:
          primitivesLayers.push(l as SourceData3DTilesLayer);
          break;
        default:
          console.error('Layer type is not supported', l);
          break;
      }
    });

    getArrayDifference(rasterLayers, this.rasterLayers).forEach((l) => imageryLayers.add(l));
    getArrayDifference(this.rasterLayers, rasterLayers).forEach((l) => imageryLayers.remove(l, true));

    getArrayDifference(this.vectorLayers, vectorLayers).forEach(this.removeVectorLayer);
    const addLayersPromises = getArrayDifference(vectorLayers, this.vectorLayers).map(this.addVectorLayer);

    getArrayDifference(primitivesLayers, this.primitivesLayers).forEach(this.addPrimitiveLayer);
    getArrayDifference(this.primitivesLayers, primitivesLayers).forEach(this.removePrimitivesLayer);

    Promise.all(addLayersPromises).then(() => {
      this.sortLayers(imageryLayers, rasterLayers);
      this.sortLayers(dataSources, vectorLayers);
    });

    this.rasterLayers = rasterLayers;
    this.vectorLayers = vectorLayers;
    this.primitivesLayers = primitivesLayers;

    this.viewer.clock.shouldAnimate = hasTemporalLayers;
    this.viewer.scene.maximumRenderTimeChange = hasTemporalLayers ? this.animationDelay : Infinity;
    this.loadingHandler();
  }

  getLayers = (): Layer[] => [...this.rasterLayers, ...this.vectorLayers, ...this.primitivesLayers];

  addLayer = (layer: Layer) => {
    switch (true) {
      case layer instanceof CesiumImageryLayer: {
        return this.addRasterLayer(layer as ImageryLayer);
      }
      case layer instanceof FeaturesLayer: {
        return this.addVectorLayer(layer as FeaturesLayer);
      }
      case layer instanceof SourceData3DTilesLayer: {
        return this.addPrimitiveLayer(layer as SourceData3DTilesLayer);
      }
    }
  };

  removeLayer = (layer: Layer, destroy?: boolean) => {
    switch (true) {
      case layer instanceof CesiumImageryLayer: {
        const idx = this.rasterLayers.findIndex((l) => l === layer);
        idx >= 0 && this.rasterLayers.splice(idx, 1);
        return this._viewer.imageryLayers.remove(layer, destroy);
      }

      case layer instanceof SourceData3DTilesLayer: {
        const idx = this.primitivesLayers.findIndex((l) => l === layer);
        idx >= 0 && this.primitivesLayers.splice(idx, 1);
        return this.removePrimitivesLayer(layer);
      }

      case layer instanceof CzmlVectorLayer:
      case layer instanceof FeaturesLayer: {
        const idx = this.vectorLayers.findIndex((l) => l === layer);
        idx >= 0 && this.vectorLayers.splice(idx, 1);
        return this.removeVectorLayer(layer);
      }
    }
  };

  reloadLayer(layerOrId: Layer | SourceDataId) {
    let layer;
    if (typeof layerOrId === 'string') {
      layer = [...this.rasterLayers, ...this.vectorLayers, ...this.primitivesLayers].find((l) => 'id' in l && l.id === layerOrId);
    } else {
      layer = layerOrId;
    }

    if (layer) {
      this.removeLayer(layer, false);
      this.addLayer(layer);
    }
  }

  protected sortLayers(collection: ImageryLayerCollection | DataSourceCollection, newOrder: ImageryLayer[] | FeaturesLayer[]) {
    const l = (collection as any)[collection instanceof ImageryLayerCollection ? '_layers' : '_dataSources'];
    // const l = (collection as any)['_layers' in collection ? '_layers' : '_dataSources'];
    const og = [...l];
    // Sort layers
    l.sort((a: any, b: any) => newOrder.indexOf(a) - newOrder.indexOf(b));
    (collection as any)._update?.();
    l.forEach((layer: any, newIndex: number) => {
      if (og.indexOf(layer) !== newIndex) {
        if (collection instanceof ImageryLayerCollection) {
          collection.layerMoved.raiseEvent(layer, og.indexOf(layer) as any, newIndex as any);
        } else {
          collection.dataSourceMoved.raiseEvent(layer, og.indexOf(layer) as any, newIndex as any);
        }
      }
    });
  }

  transformLocation(location: Location) {
    const destination = new Cartesian3(...location.destination);
    let orientation;
    if (location.orientation) {
      orientation = new HeadingPitchRoll(...location.orientation);
    }
    return { destination, orientation };
  }

  getViewPort = () => {
    let viewPort;
    switch (this._viewer.scene.mode) {
      case SceneMode.SCENE3D:
        const ellipsoid = this.viewer!.scene.globe.ellipsoid;
        const rect = this.getCamera()?.computeViewRectangle(ellipsoid, this.scratchRectangle);
        const isWholeWorld = rect && Numbers.like(rect.east, Math.PI) && Numbers.like(rect.north, Math.PI / 2);
        viewPort = !rect || isWholeWorld ? EntireWorldPolygon : rectangleToPolygon(rect);
        break;

      case SceneMode.COLUMBUS_VIEW:
      case SceneMode.SCENE2D:
        viewPort = this.getCustomViewPort();
        break;

      default:
        return undefined;
    }
    return fixMeridianCrossViewPort(viewPort);
  };

  getCustomViewPort() {
    const pixWidth = this.viewer!.scene.canvas.clientWidth;
    const pixHeight = this.viewer!.scene.canvas.clientHeight;
    const topRight = this.viewer!.scene.camera.pickEllipsoid(new Cartesian2(pixWidth - 1, 1));
    const topLeft = this.viewer!.scene.camera.pickEllipsoid(new Cartesian2(1, 1));
    const bottomLeft = this.viewer!.scene.camera.pickEllipsoid(new Cartesian2(1, pixHeight - 1));
    const bottomRight = this.viewer!.scene.camera.pickEllipsoid(new Cartesian2(pixWidth - 1, pixHeight - 1));
    if (topLeft && bottomLeft && bottomRight && topRight && topLeft) {
      const points = [topLeft, topRight, bottomRight, bottomLeft].map(cartesianToPoint) as Point[];
      return new Polygon(points).setProjection(WGS).close();
    }
    return EntireWorldPolygon;
  }

  removeBaseLayer() {
    if (this._baseLayer) {
      this._viewer.imageryLayers.remove(this._baseLayer, true);
    }
  }

  addLayerInteraction(interaction: LayerInteraction) {
    this.interactions.push(interaction);
  }

  getLayerInteraction(id: LayerInteractionId): LayerInteraction | undefined {
    return this.interactions.find((i) => i.id === id);
  }

  removeLayerInteraction(interactionOrId: LayerInteraction | LayerInteractionId): boolean {
    const id = typeof interactionOrId === 'string' ? interactionOrId : interactionOrId.id;
    const idx = this.interactions.findIndex((i) => i.id === id);
    if (idx < 0) {
      return false;
    }
    const interactions = this.interactions.splice(idx, 1);
    interactions.forEach((i) => i.destroy());
    return !!interactions.length;
  }

  enableInteractions(enable = true) {
    this.interactions.forEach((i) => (i.enabled = enable));
    return this;
  }

  splitScreen(leftLayer: RasterLayer, rightLayer: RasterLayer, sliderEl: HTMLElement) {
    try {
      leftLayer.splitDirection = SplitDirection.LEFT;
      rightLayer.splitDirection = SplitDirection.RIGHT;
    } catch (error) {
      console.error(`Error loading tileset: ${error}`);
      return;
    }
    this.screenSpaceEventHandler = new ScreenSpaceEventHandler(sliderEl as any);
    // Sync the position of the slider with the split position
    let moveActive = false;

    this.viewer.scene.splitPosition = sliderEl.offsetLeft / sliderEl.parentElement!.offsetWidth;

    const move = (movement: any) => {
      if (!moveActive) {
        return;
      }

      const relativeOffset = movement.endPosition.x;

      const splitPosition = (sliderEl.offsetLeft + relativeOffset) / sliderEl.parentElement!.offsetWidth;
      if (splitPosition > 0.05 && splitPosition < 0.99) {
        sliderEl.style.left = `${100.0 * splitPosition}%`;
        this.viewer.scene.splitPosition = splitPosition;
        this.renderScene();
      }
    };

    this.screenSpaceEventHandler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
    this.screenSpaceEventHandler.setInputAction(() => (moveActive = true), ScreenSpaceEventType.LEFT_DOWN);
    this.screenSpaceEventHandler.setInputAction(() => (moveActive = false), ScreenSpaceEventType.LEFT_UP);
  }

  disableSplitScreen = () => this.screenSpaceEventHandler && this.screenSpaceEventHandler.destroy();

  protected addRasterLayer = (layer: ImageryLayer) => {
    if (this.rasterLayers.includes(layer)) {
      console.error('Layer already exists', layer);
      return;
    }
    this.rasterLayers.push(layer);
    return this._viewer && this._viewer.cesiumWidget && this._viewer.imageryLayers.add(layer);
  };

  protected addVectorLayer = (layer: FeaturesLayer) => {
    if (this.vectorLayers.includes(layer)) {
      console.error('Layer already exists', layer);
      return;
    }
    this.vectorLayers.push(layer);
    const { dataSources } = this._viewer;
    layer.viewer = this._viewer;
    layer.changedEvent.addEventListener(this.renderScene);
    layer.loadingEvent.addEventListener(this.dataSourceLoadingHandler);
    return dataSources.add(layer);
  };

  protected removeVectorLayer = (vectorLayer: FeaturesLayer | CzmlVectorLayer) => {
    vectorLayer.changedEvent.removeEventListener(this.renderScene);
    vectorLayer.loadingEvent.removeEventListener(this.dataSourceLoadingHandler);
    if (!(vectorLayer instanceof CzmlVectorLayer)) {
      vectorLayer.isLoading = false;
    }
    this.dataSourceLoadingHandler(vectorLayer);
    this._viewer && this._viewer.cesiumWidget && this._viewer.dataSources.remove(vectorLayer, true);
  };

  addPrimitiveLayer = (layer: CesiumPrimitivesLayer) => {
    layer.loadingEvent.addEventListener(this.loadingHandler);
    if (this.primitivesLayers.includes(layer)) {
      return;
    }
    this.primitivesLayers.push(layer);
    return layer.ready.then((primitives) => {
      primitives.forEach((primitive) => this.viewer.scene.primitives.add(primitive));
    });
  };

  removePrimitivesLayer = (layer: CesiumPrimitivesLayer) => {
    layer.loadingEvent.removeEventListener(this.loadingHandler);
    layer.primitives.forEach((primitive) => this.viewer.scene.primitives.remove(primitive));
    layer.destroy();
  };

  protected dataSourceLoadingHandler = (dataSource: FeaturesLayer | CzmlVectorLayer) => {
    this.datasourceLoadingCount = numRange(this.datasourceLoadingCount + (dataSource.isLoading ? 1 : -1), 0);
    this.vectorLoading = this.datasourceLoadingCount > 0;
    this.loadingHandler();
  };

  protected tileLoadingHandler = (tilesLeft: number) => {
    this.tileLoading = tilesLeft > 0;
    this.loadingHandler();
  };

  protected loadingHandler = () => {
    const newState = this.vectorLoading || this.tileLoading || !!this.primitivesLayers.find((l) => l.isLoading);
    if (this.loadingState !== newState) {
      this.loading.raiseEvent(newState as any);
    }
    // Todo: debug loading gaps
    // !newState && console.time(newState.toString());
    // newState && console.timeEnd((!newState).toString());
    this.loadingState = newState;
  };

  get viewer(): Viewer {
    return this._viewer;
  }

  get baseLayer(): ImageryLayer | undefined {
    return this._baseLayer;
  }

  set baseLayer(baseLayer: ImageryLayer | undefined) {
    if (this._baseLayer === baseLayer) {
      return;
    }
    const { imageryLayers } = this._viewer;

    this.removeBaseLayer();
    if (baseLayer) {
      imageryLayers.add(baseLayer);
      imageryLayers.raiseToTop(baseLayer);
    }
    const layers = this.getLayers();
    if (layers.length > 0) {
      this.setLayers(layers);
    }
    this._baseLayer = baseLayer;
  }

  get viewPort(): Polygon | undefined {
    return this._viewPort;
  }

  get zoomDistance(): number | undefined {
    return getZoomDistance(this._viewer);
  }

  set viewPort(value: Polygon | undefined) {
    // if (value && !this.viewPort?.equal(value)) {
    //   console.log(value.toWKT());
    // }
    this._viewPort = value;
  }

  get isFlyAnimation(): boolean {
    return !!this.flyPromise && this.flyPromise.status === CustomPromiseState.WAITING;
  }

  destroy() {
    this.screenEvent.destroy();
  }
}
