import { Permission, PermissionItem } from '@/models';

export const getModule = (permission: PermissionItem) => permission.name.split('_')[0];

export const hasPermission = (permission: PermissionItem, permissions: PermissionItem[]) =>
  permissions.findIndex((p) => p.id === permission.id) > -1;

export const MODELS_PERMISSION = [Permission.MODELS_UPLOAD, Permission.MODELS_ADMIN];
export const ACQUISITION_PERMISSION = [
  Permission.ACQUISITIONS_APPROVER,
  Permission.ACQUISITIONS_CREATOR,
  Permission.ACQUISITIONS_ADMIN,
  Permission.ACQUISITIONS_VIEW_ALL,
];
export const FILES_PERMISSION = [Permission.FILES_ADMIN, Permission.FILES_UPLOAD];
export const WORKSPACE_PERMISSION = [Permission.WORKSPACES_ADMIN, Permission.WORKSPACES_CREATOR];
export const AMD_PERMISSION = [Permission.AMD_ADMIN, Permission.AMD_CREATOR];
export const MARKET_PERMISSION = [Permission.MARKET_ADMIN, Permission.MARKET_CREATOR];
export const CATEGORIES_PERMISSION = [Permission.CATEGORIES_ADMIN, Permission.CATEGORIES_CREATOR];
