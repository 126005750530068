import { FC, PropsWithChildren, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Button from './Button';

export interface ConfirmationDialogRawProps {
  id?: string;
  title: string;
  content?: string;
  value: any;
  open: boolean;
  onClose: (value?: any) => Promise<void> | unknown;
  size?: DialogProps['maxWidth'];
  confirmationButton?: string;
  disableConfirm?: boolean;
}

const ConfirmationDialog: FC<PropsWithChildren<ConfirmationDialogRawProps>> = ({
  title,
  content,
  onClose,
  value,
  open,
  size = 'sm',
  confirmationButton,
  disableConfirm,
  children,
  ...other
}) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const handleCancel = () => {
    onClose();
  };

  const handleOk = async () => {
    const res = onClose(value);
    res instanceof Promise && (await res);
  };

  return (
    <Dialog fullWidth maxWidth={size} open={open} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 4, whiteSpace: 'pre-line' }}>
        {content}
        {children}
      </DialogContent>
      <DialogActions>
        <Button autoFocus={!children} onClick={handleCancel} disabled={disabled}>
          {t('common:cancel')}
        </Button>
        <Button onClick={handleOk} onLoading={setDisabled} disabled={disableConfirm}>
          {t(confirmationButton ?? 'common:ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
