import { BufferUsage, ClearCommand, Color, Pass, VertexArray } from 'cesium';
import ParticlesRendering from './ParticlesRendering';
import ParticlesComputing from './ParticlesComputing';
import { ColorBy, ViewerParameters } from './Particle3D';
import { ParticlesOptions } from './utils';

export default class ParticleSystem {
  context: any;
  data: any;
  userInput: ParticlesOptions;
  viewerParameters: ViewerParameters;
  colour: ColorBy;
  particlesComputing: ParticlesComputing;
  particlesRendering: ParticlesRendering;

  constructor(context: any, data: any, userInput: any, viewerParameters: any, colour: any) {
    this.context = context;
    this.data = data;
    this.userInput = userInput;
    this.viewerParameters = viewerParameters;
    this.colour = colour;

    this.particlesComputing = new ParticlesComputing(this.context, this.data, this.userInput, this.viewerParameters);
    this.particlesRendering = new ParticlesRendering(
      this.context,
      this.data,
      this.userInput,
      this.viewerParameters,
      this.particlesComputing,
      this.colour,
    );
  }

  canvasResize(context: any) {
    this.particlesComputing.destroyParticlesTextures();
    Object.keys(this.particlesComputing.windTextures).forEach((key) => {
      this.particlesComputing.windTextures[key].destroy();
    });
    this.particlesRendering.textures.colorTable.destroy();
    Object.keys(this.particlesRendering.frameBuffers).forEach((key) => {
      this.particlesRendering.frameBuffers[key].destroy();
    });

    this.context = context;
    this.particlesComputing = new ParticlesComputing(this.context, this.data, this.userInput, this.viewerParameters);
    this.particlesRendering = new ParticlesRendering(
      this.context,
      this.data,
      this.userInput,
      this.viewerParameters,
      this.particlesComputing,
    );
  }

  clearFrameBuffers() {
    const clearCommand = new ClearCommand({
      color: new Color(0.0, 0.0, 0.0, 0.0),
      depth: 1.0,
      framebuffer: undefined,
      pass: Pass.OPAQUE,
    });

    Object.keys(this.particlesRendering.frameBuffers).forEach((key) => {
      clearCommand.framebuffer = this.particlesRendering.frameBuffers[key];
      clearCommand.execute(this.context);
    });
  }

  refreshParticles(maxParticlesChanged: any, dynamic = true) {
    if (dynamic) {
      this.clearFrameBuffers();

      this.particlesComputing.destroyParticlesTextures();
      this.particlesComputing.createParticlesTextures(this.context, this.userInput, this.viewerParameters);
    }

    if (maxParticlesChanged) {
      const geometry = this.particlesRendering.createSegmentsGeometry(this.userInput);
      this.particlesRendering.primitives!.segments!.geometry = geometry;
      this.particlesRendering.primitives!.segments!.commandToExecute!.vertexArray = VertexArray.fromGeometry({
        context: this.context,
        geometry,
        attributeLocations: this.particlesRendering.primitives!.segments!.attributeLocations,
        bufferUsage: BufferUsage.STATIC_DRAW,
      });
    }
  }

  applyUserInput(userInput: ParticlesOptions) {
    let maxParticlesChanged = false;
    if (this.userInput.maxParticles != userInput.maxParticles) {
      maxParticlesChanged = true;
    }
    this.userInput = { ...this.userInput, ...userInput };

    this.refreshParticles(maxParticlesChanged, userInput.dynamic);
  }

  applyViewerParameters(viewerParameters: ViewerParameters) {
    this.viewerParameters = { ...this.viewerParameters, ...viewerParameters };

    this.refreshParticles(false);
    if (!this.userInput.dynamic) {
      this.userInput.dynamic = true;
      this.applyUserInput(this.userInput);
      setTimeout(() => {
        this.userInput.dynamic = false;
        this.applyUserInput(this.userInput);
      }, 500);
    }
  }
}
