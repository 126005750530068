type DataProcessor = (...args: any[]) => any;
type Callback<T> = (value?: T) => Promise<T | undefined | null | void>;

export interface CustomPromiseProps<T = any> extends Promise<T> {
  resolve: Callback<T>;
  reject: Callback<any>;
  dataProcessor?: DataProcessor;
  status: CustomPromiseState;
  cancel: () => void;
}

export enum CustomPromiseState {
  WAITING = 'WAITING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
}

export const cancelCatchHandler = (e: any) => {
  if (e === CustomPromiseState.CANCELLED) {
    return false;
  }
  console.error(e);
  return true;
};

export const addSuccessHandler = <T = any>(promise: CustomPromiseProps<T>, handler: (res: T) => void): CustomPromiseProps<T> => {
  promise.then(handler);
  return promise;
};

export const setDataProcessor = <T = any, R = any>(
  promise: CustomPromiseProps<T>,
  dataProcessor: (data: T) => R,
): CustomPromiseProps<R> => {
  const origProcessor = promise.dataProcessor;
  if (origProcessor) {
    promise.dataProcessor = (res: T) => dataProcessor(origProcessor(res));
  } else {
    promise.dataProcessor = dataProcessor;
  }
  return promise as any;
};

export const CustomPromise = <T = any>(): CustomPromiseProps<T> => {
  let callback: Callback<T> | undefined;
  let errorBack: Callback<any> | undefined;
  const promise = new Promise((cb, eb) => {
    callback = (value?: T) => {
      if (promise.status === CustomPromiseState.WAITING) {
        if (promise.dataProcessor) {
          cb(promise.dataProcessor(value));
        } else {
          cb(value as any);
        }
        promise.status = CustomPromiseState.SUCCESS;
      }
      return promise;
    };
    errorBack = (error: any) => {
      if ([CustomPromiseState.WAITING, CustomPromiseState.CANCELLED].includes(promise.status)) {
        if (promise.status === CustomPromiseState.WAITING) {
          promise.status = CustomPromiseState.ERROR;
        }
        eb(error);
      }
      return promise;
    };
  }) as CustomPromiseProps<T>;
  promise.status = CustomPromiseState.WAITING;
  promise.cancel = () =>
    promise.status === CustomPromiseState.WAITING
      ? (promise.status = CustomPromiseState.CANCELLED) && promise.reject(CustomPromiseState.CANCELLED)
      : false;
  promise.resolve = callback!;
  promise.reject = errorBack!;
  return promise;
};
