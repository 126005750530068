import { ClickAwayListener, Menu, MenuProps, styled } from '@mui/material';
import * as React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useRouter } from 'next/router';
import useToggle from '@/hooks/useToggle';

interface Props extends Partial<MenuProps> {
  opener: React.ReactElement;
  onOpen?: () => void;
  onClose?: () => void;
  stopPropagation?: boolean;
}

const Opener = styled('div')`
  &:hover {
    cursor: pointer;
  }
`;

export interface DropDownMenuRef {
  toggle: () => void;
}

export const DropDownMenu = forwardRef<DropDownMenuRef, Props>(function MyInput(
  { open: propsOpen, opener, onOpen, onClose, stopPropagation, children, ...rest },
  ref,
) {
  const router = useRouter();
  const anchorEl = useRef<HTMLDivElement>(null);
  const [isOpenState, toggleOpen] = useToggle(propsOpen);
  const open = propsOpen ?? isOpenState;

  useEffect(() => {
    if (open) {
      toggleOpen();
    }
  }, [router.asPath]);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggle: toggleOpen,
      };
    },
    [toggleOpen],
  );

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (open) {
      stopPropagation && event.stopPropagation();
      handleClose(event);
    }
  };

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    stopPropagation && event.stopPropagation();
    toggleOpen();
    onOpen?.();
  };
  const handleClose = (event: MouseEvent | TouchEvent) => {
    stopPropagation && event.stopPropagation();
    toggleOpen();
    onClose?.();
  };

  return (
    <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
      <div>
        <Opener ref={anchorEl} onClick={handleOpen}>
          {opener}
        </Opener>
        <Menu anchorEl={anchorEl.current} open={open} onClose={handleClose} sx={{ color: 'text.primary' }} {...rest}>
          {children}
        </Menu>
      </div>
    </ClickAwayListener>
  );
});
