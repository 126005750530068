import CustomPrimitive from './CustomPrimitive';
import Util from './utils';
import { shaders } from './shader';
import { ColorBy } from './Particle3D';
import {
  Sampler,
  ShaderSource,
  Cartesian2,
  ComponentDatatype,
  DepthFunction,
  Geometry,
  GeometryAttribute,
  GeometryAttributes,
  PixelDatatype,
  PixelFormat,
  PrimitiveType,
  TextureMagnificationFilter,
  TextureMinificationFilter,
  Framebuffer,
} from 'cesium';

export type PrimitiveName = 'calculateSpeed' | 'updatePosition' | 'postProcessingPosition' | 'segments' | 'trails' | 'screen';

export default class ParticlesRendering {
  colour: boolean;
  textures: any;
  frameBuffers: Record<string, Framebuffer> = {};
  primitives?: Partial<Record<PrimitiveName, CustomPrimitive>>;

  constructor(context: any, data: any, userInput: any, viewerParameters: any, particlesComputing: any, colour?: ColorBy) {
    this.colour = colour === ColorBy.HEIGHT;
    this.createRenderingTextures(context, data);
    this.createRenderingFramebuffers(context);
    this.createRenderingPrimitives(context, data, userInput, viewerParameters, particlesComputing);
  }

  createRenderingTextures(context: any, data: any) {
    const colorTextureOptions = {
      context,
      width: context.drawingBufferWidth,
      height: context.drawingBufferHeight,
      pixelFormat: PixelFormat.RGBA,
      pixelDatatype: PixelDatatype.UNSIGNED_BYTE,
    };
    const depthTextureOptions = {
      context,
      width: context.drawingBufferWidth,
      height: context.drawingBufferHeight,
      pixelFormat: PixelFormat.DEPTH_COMPONENT,
      pixelDatatype: PixelDatatype.UNSIGNED_INT,
    };

    const colorTableTextureOptions = {
      context,
      width: data.colorTable.colorNum,
      height: 1,
      pixelFormat: PixelFormat.RGB,
      pixelDatatype: PixelDatatype.FLOAT,
      sampler: new Sampler({
        minificationFilter: TextureMinificationFilter.LINEAR,
        magnificationFilter: TextureMagnificationFilter.LINEAR,
      }),
    };

    this.textures = {
      segmentsColor: Util.createTexture(colorTextureOptions),
      segmentsDepth: Util.createTexture(depthTextureOptions),

      currentTrailsColor: Util.createTexture(colorTextureOptions),
      currentTrailsDepth: Util.createTexture(depthTextureOptions),

      nextTrailsColor: Util.createTexture(colorTextureOptions),
      nextTrailsDepth: Util.createTexture(depthTextureOptions),
      colorTable: Util.createTexture(colorTableTextureOptions, data.colorTable.array),
    };
  }

  createRenderingFramebuffers(context: any) {
    this.frameBuffers = {
      segments: Util.createFramebuffer(context, this.textures.segmentsColor, this.textures.segmentsDepth),
      currentTrails: Util.createFramebuffer(context, this.textures.currentTrailsColor, this.textures.currentTrailsDepth),
      nextTrails: Util.createFramebuffer(context, this.textures.nextTrailsColor, this.textures.nextTrailsDepth),
    };
  }

  createSegmentsGeometry(userInput: any) {
    let i;
    const repeatVertex = 4;
    // Coordinate System
    //  z
    //  | /y
    //  |/
    //  o------x
    // Texture array st coordinate system, the lower left corner is defined as (0,0)
    // The upper right corner is (1,1), which is used to pass into the vertex shader to refer to the position of the particles.
    const st = [];
    for (let s = 0; s < userInput.particlesTextureSize; s++) {
      for (let t = 0; t < userInput.particlesTextureSize; t++) {
        for (i = 0; i < repeatVertex; i++) {
          st.push(s / userInput.particlesTextureSize);
          st.push(t / userInput.particlesTextureSize);
        }
      }
    }
    const stBuffer = new Float32Array(st);

    const normal = [];
    // it is not normal itself, but used to control lines drawing
    const pointToUse = [-1, 1];
    const offsetSign = [-1, 1];
    for (i = 0; i < userInput.maxParticles; i++) {
      // (point to use, offset sign, not used component)
      normal.push(-1, -1, 0, -1, 1, 0, 1, -1, 0, 1, 1, 0);
    }
    const normalBuffer = new Float32Array(normal);

    const vertexIndexes = []; // Index, a particle rectangle consists of two triangles
    i = 0;
    let vertex = 0;
    for (; i < userInput.maxParticles; i++) {
      vertexIndexes.push(
        // The vertices used for the first triangle
        vertex + 0,
        vertex + 1,
        vertex + 2,
        // The vertices used for the second triangle
        vertex + 2,
        vertex + 1,
        vertex + 3,
      );

      vertex += repeatVertex;
    }

    const geometryAttributesOptions = {
      st: new GeometryAttribute({
        componentDatatype: ComponentDatatype.FLOAT,
        componentsPerAttribute: 2,
        values: stBuffer,
      }),
      normal: new GeometryAttribute({
        componentDatatype: ComponentDatatype.FLOAT,
        componentsPerAttribute: 3,
        values: normalBuffer,
      }),
    };

    return new Geometry({
      // @ts-ignore
      attributes: new GeometryAttributes(geometryAttributesOptions),
      indices: new Uint32Array(vertexIndexes),
    });
  }

  createRenderingPrimitives(context: any, data: any, userInput: any, viewerParameters: any, particlesComputing: any) {
    const that = this;
    const { segmentDrawVert, segmentDrawFrag, fullscreenVert, trailDrawFrag, screenDrawFrag } = shaders;
    this.primitives = {
      segments: new CustomPrimitive({
        commandType: 'Draw',
        attributeLocations: {
          st: 0, // When true, the vertex has a 2D texture coordinate attribute.
          // 32-bit floating-point. 2 components per attribute
          normal: 1, // When true, the vertex has a normal attribute (normalized), which is commonly used for lighting.
          // 32-bit floating-point. 3 components per attribute.
        },
        geometry: this.createSegmentsGeometry(userInput),
        primitiveType: PrimitiveType.TRIANGLES,
        uniformMap: {
          previousParticlesPosition() {
            return particlesComputing.particlesTextures.previousParticlesPosition;
          },
          currentParticlesPosition() {
            return particlesComputing.particlesTextures.currentParticlesPosition;
          },
          postProcessingPosition() {
            return particlesComputing.particlesTextures.postProcessingPosition;
          },
          particlesSpeed() {
            return particlesComputing.particlesTextures.particlesSpeed;
          },
          colorTable() {
            return that.textures.colorTable;
          },
          aspect() {
            return context.drawingBufferWidth / context.drawingBufferHeight;
          },
          H() {
            return data.H.array;
          },
          hRange() {
            return new Cartesian2(data.H.min, data.H.max);
          },
          uSpeedRange() {
            return new Cartesian2(data.U.min, data.U.max);
          },
          vSpeedRange() {
            return new Cartesian2(data.V.min, data.V.max);
          },
          wSpeedRange() {
            return new Cartesian2(data.W.min, data.W.max);
          },
          pixelSize() {
            return viewerParameters.pixelSize;
          },
          lineWidth() {
            return userInput.lineWidth;
          },
          particleHeight() {
            return userInput.particleHeight;
          },
          colour() {
            return that.colour;
          },
        },
        vertexShaderSource: new ShaderSource({
          sources: [segmentDrawVert],
        }),
        fragmentShaderSource: new ShaderSource({
          sources: [segmentDrawFrag],
        }),
        rawRenderState: Util.createRawRenderState({
          // undefined value means let Cesium deal with it
          viewport: undefined,
          depthTest: {
            enabled: true,
          },
          depthMask: true,
        }),
        framebuffer: this.frameBuffers!.segments,
        autoClear: true,
      }),

      trails: new CustomPrimitive({
        commandType: 'Draw',
        attributeLocations: {
          position: 0, // When true, the vertex has a 3D position attribute.
          // 64-bit floating-point (for precision). 3 components per attribute.
          st: 1,
        },
        geometry: Util.getFullscreenQuad(),
        primitiveType: PrimitiveType.TRIANGLES,
        uniformMap: {
          segmentsColorTexture() {
            return that.textures.segmentsColor;
          },
          segmentsDepthTexture() {
            return that.textures.segmentsDepth;
          },
          currentTrailsColor() {
            return that.frameBuffers!.currentTrails.getColorTexture(0);
          },
          trailsDepthTexture() {
            return that.frameBuffers!.currentTrails.depthTexture;
          },
          fadeOpacity() {
            return userInput.fadeOpacity;
          },
        },
        // prevent Cesium from writing depth because the depth here should be written manually
        vertexShaderSource: new ShaderSource({
          defines: ['DISABLE_GL_POSITION_LOG_DEPTH'],
          sources: [fullscreenVert],
        }),
        fragmentShaderSource: new ShaderSource({
          defines: ['DISABLE_LOG_DEPTH_FRAGMENT_WRITE'],
          sources: [trailDrawFrag],
        }),
        rawRenderState: Util.createRawRenderState({
          viewport: undefined,
          depthTest: {
            enabled: true,
            func: DepthFunction.ALWAYS, // always pass depth test for full control of depth information
          },
          depthMask: true,
        }),
        framebuffer: this.frameBuffers.nextTrails,
        autoClear: true,
        preExecute() {
          // swap framebuffers before binding
          if (userInput.dynamic) {
            const temp = that.frameBuffers.currentTrails;
            that.frameBuffers.currentTrails = that.frameBuffers.nextTrails;
            that.frameBuffers.nextTrails = temp;
          }

          // keep the framebuffers up to date
          that.primitives!.trails!.commandToExecute!.framebuffer = that.frameBuffers.nextTrails;
          that.primitives!.trails!.clearCommand!.framebuffer = that.frameBuffers.nextTrails;
        },
      }),

      screen: new CustomPrimitive({
        commandType: 'Draw',
        attributeLocations: {
          position: 0,
          st: 1,
        },
        geometry: Util.getFullscreenQuad(),
        primitiveType: PrimitiveType.TRIANGLES,
        uniformMap: {
          trailsColorTexture() {
            return that.frameBuffers.nextTrails.getColorTexture(0);
          },
          trailsDepthTexture() {
            return that.frameBuffers.nextTrails.depthTexture;
          },
        },
        // prevent Cesium from writing depth because the depth here should be written manually
        vertexShaderSource: new ShaderSource({
          defines: ['DISABLE_GL_POSITION_LOG_DEPTH'],
          sources: [fullscreenVert],
        }),
        fragmentShaderSource: new ShaderSource({
          defines: ['DISABLE_LOG_DEPTH_FRAGMENT_WRITE'],
          sources: [screenDrawFrag],
        }),
        rawRenderState: Util.createRawRenderState({
          viewport: undefined,
          depthTest: {
            enabled: false,
          },
          depthMask: true,
          blending: {
            enabled: true,
          },
        }),
        framebuffer: undefined, // undefined value means let Cesium deal with it
      }),
    };
  }
}
