import { MultiNavigation, Navigation, Permission } from '@/models';
import { getConfig } from '@/config';
import {
  ACQUISITION_PERMISSION,
  AMD_PERMISSION,
  CATEGORIES_PERMISSION,
  FILES_PERMISSION,
  MODELS_PERMISSION,
  WORKSPACE_PERMISSION,
} from '@/utils/permissions';

const config = getConfig();

export const AcquisitionNavigation: MultiNavigation = {
  id: 'acquisition',
  label: 'navigation:data_acquisition',
  shortLabel: 'navigation:data_acquisition_short',
  icon: 'satellite',
  permission: ACQUISITION_PERMISSION,
  route: '/acquisition',
  subNavigation: [
    {
      id: 'buy',
      label: 'navigation:buy',
      icon: 'shopping_cart',
      path: '/acquisition/buy',
      permission: Permission.ACQUISITIONS_CREATOR,
    },
    {
      id: 'orders',
      label: 'navigation:orders',
      icon: 'list',
      path: '/acquisition/orders',
      permission: ACQUISITION_PERMISSION,
    },
  ],
};

export const ModelsNavigation: Navigation = {
  id: 'models',
  label: 'navigation:models',
  icon: 'psychology',
  permission: MODELS_PERMISSION,
  path: '/models/info',
  route: '/models/info/[[...options]]',
};

export const MarketplaceNavigation: MultiNavigation = {
  id: 'marketplace',
  route: '/marketplace',
  label: 'navigation:marketplace',
  icon: 'apps',
  subNavigation: [
    {
      id: 'marketplace_sub',
      label: 'navigation:market_title',
      icon: 'apps',
      path: '/marketplace',
      route: '/marketplace',
    },
    {
      id: 'marketplace_managements',
      label: 'navigation:manage_title',
      icon: 'store',
      path: '/marketplace/manage/published',
      route: '/marketplace/manage/[manageId]',
      permission: [Permission.MARKET_ADMIN, Permission.MARKET_CREATOR],
    },
  ],
};

export const LabsNavigation: MultiNavigation = {
  id: 'labs',
  route: '/demo',
  label: 'navigation:labs',
  icon: 'labs',
  permission: Permission.LABS_VIEW,
  subNavigation: [
    ...(config.isWindDemoEnabled
      ? [
          {
            id: 'wind',
            path: '/demo/map/wind',
            label: 'navigation:wind',
            icon: 'airwave',
          },
        ]
      : []),
    {
      id: 'mapbox',
      path: '/demo/map/mapbox',
      label: 'navigation:mapbox_demo',
      shortLabel: 'navigation:mapbox_demo_short',
      icon: 'map',
    },
    {
      id: 'earthquake',
      path: '/demo/map/earthquake',
      label: 'navigation:earthquake',
      icon: 'earthquake',
    },
  ],
};

export const NavigationMap: (Navigation | MultiNavigation)[] = [
  {
    id: 'exploration',
    path: '/map',
    label: 'navigation:exploration',
    icon: 'explore',
  },
  {
    id: 'workspaces',
    path: '/workspace',
    label: 'navigation:workspaces',
    icon: 'workspaces',
    permission: WORKSPACE_PERMISSION,
  },
  {
    id: 'files',
    path: '/files',
    label: 'navigation:file_manager',
    shortLabel: 'navigation:file_manager_short',
    icon: 'folder',
    permission: FILES_PERMISSION,
  },
  AcquisitionNavigation,
  ModelsNavigation,
  // TODO for future annotations
  // {
  //   path: '/annotation',
  //   label: 'annotations',
  //   icon: 'shapes',
  //   permission: ANNOTATIONS_PERMISSION
  // },
  {
    id: 'amd',
    path: '/annotation',
    label: 'navigation:amd',
    shortLabel: 'navigation:amd_short',
    icon: 'model_training',
    permission: AMD_PERMISSION,
  },
  /*{
    id: 'refdata',
    path: '/refdata/category',
    label: 'navigation:ref_data',
    shortLabel: 'navigation:ref_data_short',
    icon: 'data_table',
    permission: CATEGORIES_PERMISSION,
  },*/
  MarketplaceNavigation,
  LabsNavigation,
];
export const AdminNavigation: MultiNavigation = {
  id: 'admin',
  route: '/admin',
  label: 'navigation:admin_panel',
  shortLabel: 'navigation:admin_panel_short',
  icon: 'admin_panel_settings',
  subNavigation: [
    {
      id: 'users',
      label: 'navigation:users',
      icon: 'group',
      path: '/admin/users',
      permission: Permission.USERS_ADMIN,
    },
    {
      id: 'roles',
      label: 'navigation:roles',
      icon: 'lock_person',
      path: '/admin/roles',
      permission: Permission.USERS_ADMIN,
    },
    {
      id: 'settings',
      label: 'navigation:settings',
      icon: 'settings',
      path: '/admin/settings',
      permission: Permission.USERS_ADMIN,
    },
    {
      id: 'sensitivity',
      label: 'navigation:sensitivity',
      icon: 'checklist',
      path: '/admin/sensitivity',
      permission: Permission.SENSITIVITY_ADMIN,
    },
    {
      id: 'data_managements',
      label: 'navigation:data_management',
      icon: 'database',
      path: '/admin/data',
      // TODO this is for now, we will add a proper permission later
      permission: Permission.USERS_ADMIN,
    },
  ],
};
export const SystemNavigation: MultiNavigation = {
  id: 'system',
  route: '/system',
  label: 'navigation:system_panel',
  shortLabel: 'navigation:system_panel_short',
  icon: 'settings_applications',
  subNavigation: [
    {
      id: 'organizations',
      label: 'navigation:organizations',
      icon: 'domain',
      path: '/system/organizations',
      permission: Permission.PLATFORM_ADMIN,
    },
    {
      id: 'providers',
      label: 'navigation:providers',
      icon: 'satellite_alt',
      path: '/system/providers',
      permission: Permission.PLATFORM_ADMIN,
    },
    {
      id: 'announcements',
      label: 'navigation:announcements',
      icon: 'announcement',
      path: '/system/announcements',
      permission: Permission.PLATFORM_ADMIN,
    },
    /*{
      id: 'basemaps',
      label: 'Basemaps',
      icon: 'public',
      path: '/system/basemaps',
      permission: Permission.PLATFORM_ADMIN,
    },*/
  ],
};
