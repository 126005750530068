import { useCallback, useMemo, useState } from 'react';
import { GridSortItem, GridSortModel, GridFeatureMode, GridSortDirection } from '@mui/x-data-grid';
import { DEFAULT_PAGINATION, SORT_DIRECTION } from '@/cmd/Api';

const sortingMachine = {
  [SORT_DIRECTION.ASC]: SORT_DIRECTION.DESC,
  [SORT_DIRECTION.DESC]: SORT_DIRECTION.ASC,
};

type SortDetails = {
  field: string;
  direction: GridSortDirection;
};

const getSortingInfo = (prevSorting: SortDetails, sorting: GridSortItem | undefined) => {
  const field = sorting?.field ?? prevSorting.field ?? DEFAULT_PAGINATION.sort;

  const key = prevSorting.direction || DEFAULT_PAGINATION.direction;
  const direction = !sorting && key && key in sortingMachine ? sortingMachine[key] : sorting?.sort || DEFAULT_PAGINATION.direction;

  return { field, direction };
};

export type UseDataGridSortOptions = {
  sortingMode?: GridFeatureMode;
  field?: string;
  direction?: GridSortDirection;
};

export const useDataGridSort = ({
  sortingMode = 'server',
  field = DEFAULT_PAGINATION.sort,
  direction = DEFAULT_PAGINATION.direction,
}: UseDataGridSortOptions = {}) => {
  const [sortDetails, setSortDetails] = useState<SortDetails>({ field, direction });

  const onSortChange = useCallback(
    ([sorting]: GridSortModel) => {
      setSortDetails((prevSorting) => getSortingInfo(prevSorting, sorting));
    },
    [setSortDetails],
  );

  const sortModel: GridSortModel = useMemo(
    () => [
      {
        field: sortDetails.field,
        sort: sortDetails.direction,
      },
    ],
    [sortDetails],
  );

  return {
    sortModel,
    sortDetails,
    sortingMode,
    onSortChange,
  };
};
