import { ReactNode } from 'react';
import { SelectProps, TextField, TextFieldProps } from '@mui/material';

type CustomSelectProps<T> = Omit<TextFieldProps, 'value' | 'onChange' | 'select' | 'SelectProps' | 'minRows' | 'maxRows' | 'multiline'> &
  SelectProps<T>;

export const Select: <T>(props: CustomSelectProps<T>) => ReactNode = ({ children, ...props }) => {
  const {
    autoWidth,
    defaultOpen,
    displayEmpty,
    IconComponent,
    labelId,
    MenuProps,
    multiple,
    native,
    onClose,
    onOpen,
    open,
    renderValue,
    SelectDisplayProps,
    ...rest
  } = props;
  return (
    <TextField
      fullWidth
      select
      SelectProps={{
        autoWidth,
        defaultOpen,
        displayEmpty,
        IconComponent,
        labelId,
        MenuProps,
        multiple,
        native,
        onClose,
        onOpen,
        open,
        // @ts-ignore
        renderValue,
        SelectDisplayProps,
      }}
      {...rest}
    >
      {children}
    </TextField>
  );
};
