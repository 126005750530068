import {
  DrawingFeature,
  DrawingFeatureLinePointer,
  DrawingFeatureProperties,
  DrawingFeatureStrokeStyle,
  DrawingFeatureStyleType,
  KeyValue,
  LayerAttribute,
  MapTool,
  SourceData,
  SvgImportProps,
} from '@/models';
import { Cartesian3 } from 'cesium';
import { cartesianToPoint, getGeometryByTool } from '@/components/Cesium/utils';
import { addOpacity } from '@/utils';
import { Api, METHOD } from '@/cmd/Api';

export const DEFAULT_LABEL_FONT = 'Helvetica';
export const DEFAULT_LABEL_FONT_SIZE = 30;
export const DEFAULT_LABEL_PADDING = 10;
export const DEFAULT_LABEL_FILL = '#ffffff';
export const DEFAULT_LABEL_STROKE_COLOR = '#000000';
export const DEFAULT_LABEL_STROKE_WIDTH = 1;
export const DEFAULT_POINT_SIZE = 5;
export const DEFAULT_LINE_STYLE = DrawingFeatureStrokeStyle.SOLID;
export const DEFAULT_LINE_POINTER = DrawingFeatureLinePointer.NONE;

export const DEFAULT_STROKE_WIDTH = 1;
export const DEFAULT_LINE_STROKE_WIDTH = 3;
export const DEFAULT_STROKE_COLOR = '#00d1fa';
export const DEFAULT_FILL_COLOR = addOpacity(DEFAULT_STROKE_COLOR, 0.5);

export const DEFAULT_ICON_COLOR = '#ffffff';

export const getLinePointerIconKey = (pointer: DrawingFeatureLinePointer, direction: 'start' | 'end') =>
  !pointer || pointer === DrawingFeatureLinePointer.NONE ? undefined : ['line', direction, pointer.toLowerCase()].join('_');

export interface ChangeFeaturePropertiesEvent {
  type: EventType.CHANGE_FEATURE_PROPERTIES;
  data: DrawingFeature;
}

export interface ChangeFeatureGeometryEvent {
  type: EventType.CHANGE_FEATURE_GEOMETRY;
  data: DrawingFeature;
}

export enum EventType {
  CHANGE_FEATURE_PROPERTIES = 'CHANGE_FEATURE_PROPERTIES',
  CHANGE_FEATURE_GEOMETRY = 'CHANGE_FEATURE_GEOMETRY',
}

export const DEFAULT_DRAWING_LABEL_PROPERTIES: Partial<DrawingFeatureProperties> = {
  label: 'Text',
  labelFont: DEFAULT_LABEL_FONT,
  labelFontSize: DEFAULT_LABEL_FONT_SIZE,
  labelStrokeColor: DEFAULT_LABEL_STROKE_COLOR,
  labelStrokeWidth: DEFAULT_LABEL_STROKE_WIDTH,
  labelFillColor: DEFAULT_LABEL_FILL,
  labelBackgroundPadding: DEFAULT_LABEL_PADDING,
};

export const DEFAULT_DRAWING_SHAPE_PROPERTIES: Partial<DrawingFeatureProperties> = {
  strokeWidth: DEFAULT_STROKE_WIDTH,
  strokeColor: DEFAULT_STROKE_COLOR,
  fillColor: DEFAULT_FILL_COLOR,
};

export const DEFAULT_DRAWING_LINE_PROPERTIES: Partial<DrawingFeatureProperties> = {
  strokeWidth: DEFAULT_LINE_STROKE_WIDTH,
  strokeColor: DEFAULT_STROKE_COLOR,
  strokeStyle: DEFAULT_LINE_STYLE,
  startPointer: DEFAULT_LINE_POINTER,
  endPointer: DEFAULT_LINE_POINTER,
};

export const DEFAULT_DRAWING_POINT_PROPERTIES: Partial<DrawingFeatureProperties> = {
  strokeWidth: DEFAULT_STROKE_WIDTH,
  strokeColor: DEFAULT_STROKE_COLOR,
  fillColor: DEFAULT_FILL_COLOR,
  pointSize: DEFAULT_POINT_SIZE,
  // icon: undefined,
  iconColor: DEFAULT_ICON_COLOR,
  // rotation: 0,
};

export const createFeatureFromDrawing = (tool: MapTool, points: Cartesian3[]): Partial<DrawingFeature> => {
  let defaultProperties;
  switch (tool) {
    case MapTool.TEXT: {
      const geometry = cartesianToPoint(points[0]).removeDepth();
      return {
        geometry: geometry.toWKT(),
        type: geometry.type,
        properties: { ...DEFAULT_DRAWING_LABEL_PROPERTIES, type: DrawingFeatureStyleType.TEXT },
      } as any;
    }

    case MapTool.LINESTRING: {
      const geometry = getGeometryByTool(tool, points)!;
      return {
        geometry: geometry.toWKT(),
        type: geometry.type,
        properties: { ...DEFAULT_DRAWING_LINE_PROPERTIES, type: DrawingFeatureStyleType.LINE },
      } as any;
    }

    case MapTool.POINT: {
      const geometry = cartesianToPoint(points[0]).removeDepth();

      return {
        geometry: geometry.toWKT(),
        type: geometry.type,
        properties: { ...DEFAULT_DRAWING_POINT_PROPERTIES, type: DrawingFeatureStyleType.POINT },
      } as any;
    }

    case MapTool.POLYGON: {
      const geometry = getGeometryByTool(tool, points)!;
      return {
        type: geometry.type,
        geometry: geometry.toWKT(),
        properties: { ...DEFAULT_DRAWING_SHAPE_PROPERTIES, tool },
      } as any;
    }

    default:
      throw Error('Does not support yet');
  }
};
export const createFeatureFromVectorDrawing = (tool: MapTool, points: Cartesian3[], properties: any = {}): Partial<DrawingFeature> => {
  let defaultProperties;
  switch (tool) {
    case MapTool.LINESTRING: {
      const geometry = getGeometryByTool(tool, points)!;
      return {
        geometry: geometry.toWKT(),
        properties: { ...properties },
      } as any;
    }

    case MapTool.POINT: {
      const geometry = cartesianToPoint(points[0]).removeDepth();

      return {
        geometry: geometry.toWKT(),
      } as any;
    }

    case MapTool.POLYGON: {
      const geometry = getGeometryByTool(tool, points)!;
      return {
        geometry: geometry.toWKT(),
        properties: { ...properties },
      } as any;
    }

    default:
      throw Error('Does not support yet');
  }
};

export const getDefaultDataFromVectorSource = (mapping?: KeyValue<LayerAttribute>) => {
  const data: KeyValue = {};
  mapping &&
    Object.getOwnPropertyNames(mapping).forEach((ele) => {
      if (mapping[ele]?.name) {
        data[mapping[ele].name!] = mapping[ele].defaultValue;
      }
    });

  return data;
};
export const getSymbolInternalSrc = (icon: string): Promise<string> => {
  if (defaultSymbols[icon]) {
    return import('@/assets/symbols/' + defaultSymbols[icon].file + '?url').then((res: { default: SvgImportProps }) => res.default.src);
  }

  return Promise.resolve(icon);
};

export const getSymbol = (icon: string) => {
  return getSymbolInternalSrc(icon).then((src) =>
    new Api().request(METHOD.GET, '/' + src, undefined, { /*responseType: 'blob',*/ forceUrlLocation: true }).then((res) => {
      if (res.data) {
        let icon;
        try {
          const mimeType = res.headers['content-type']; // image/svg+xml
          icon = new DOMParser().parseFromString(res.data, mimeType);
        } catch (e) {
          console.error(e);
          return undefined;
        }
        return icon.querySelector('svg') || undefined;
      }
    }),
  );
};

export interface DrawingSymbol {
  key: string;
  file: string;
  title: string;
  tags: string[];
}

const defaultList = [
  { key: 'default/aerialway', file: 'default/aerialway.svg', title: 'aerialway', tags: ['aerialway'] },
  { key: 'default/airfield', file: 'default/airfield.svg', title: 'airfield', tags: ['airfield'] },
  { key: 'default/airport', file: 'default/airport.svg', title: 'airport', tags: ['airport'] },
  { key: 'default/alcoholShop', file: 'default/alcohol-shop.svg', title: 'alcohol shop', tags: ['alcohol', 'shop'] },
  { key: 'default/americanFootball', file: 'default/american-football.svg', title: 'american football', tags: ['american', 'football'] },
  { key: 'default/amusementPark', file: 'default/amusement-park.svg', title: 'amusement park', tags: ['amusement', 'park'] },
  { key: 'default/aquarium', file: 'default/aquarium.svg', title: 'aquarium', tags: ['aquarium'] },
  { key: 'default/arrow', file: 'default/arrow.svg', title: 'arrow', tags: ['arrow'] },
  { key: 'default/artGallery', file: 'default/art-gallery.svg', title: 'art gallery', tags: ['art', 'gallery'] },
  { key: 'default/attraction', file: 'default/attraction.svg', title: 'attraction', tags: ['attraction'] },
  { key: 'default/bakery', file: 'default/bakery.svg', title: 'bakery', tags: ['bakery'] },
  { key: 'default/bankJP', file: 'default/bank-JP.svg', title: 'bank JP', tags: ['bank', 'jp'] },
  { key: 'default/bank', file: 'default/bank.svg', title: 'bank', tags: ['bank'] },
  { key: 'default/bar', file: 'default/bar.svg', title: 'bar', tags: ['bar'] },
  { key: 'default/barrier', file: 'default/barrier.svg', title: 'barrier', tags: ['barrier'] },
  { key: 'default/baseball', file: 'default/baseball.svg', title: 'baseball', tags: ['baseball'] },
  { key: 'default/basketball', file: 'default/basketball.svg', title: 'basketball', tags: ['basketball'] },
  { key: 'default/bbq', file: 'default/bbq.svg', title: 'bbq', tags: ['bbq'] },
  { key: 'default/beach', file: 'default/beach.svg', title: 'beach', tags: ['beach'] },
  { key: 'default/beer', file: 'default/beer.svg', title: 'beer', tags: ['beer'] },
  { key: 'default/bicycleShare', file: 'default/bicycle-share.svg', title: 'bicycle share', tags: ['bicycle', 'share'] },
  { key: 'default/bicycle', file: 'default/bicycle.svg', title: 'bicycle', tags: ['bicycle'] },
  { key: 'default/bloodBank', file: 'default/blood-bank.svg', title: 'blood bank', tags: ['blood', 'bank'] },
  { key: 'default/bowlingAlley', file: 'default/bowling-alley.svg', title: 'bowling alley', tags: ['bowling', 'alley'] },
  { key: 'default/bridge', file: 'default/bridge.svg', title: 'bridge', tags: ['bridge'] },
  { key: 'default/buildingAlt1', file: 'default/building-alt1.svg', title: 'building alt1', tags: ['building', 'alt1'] },
  { key: 'default/building', file: 'default/building.svg', title: 'building', tags: ['building'] },
  { key: 'default/bus', file: 'default/bus.svg', title: 'bus', tags: ['bus'] },
  { key: 'default/cafe', file: 'default/cafe.svg', title: 'cafe', tags: ['cafe'] },
  { key: 'default/campsite', file: 'default/campsite.svg', title: 'campsite', tags: ['campsite'] },
  { key: 'default/carRental', file: 'default/car-rental.svg', title: 'car rental', tags: ['car', 'rental'] },
  { key: 'default/carRepair', file: 'default/car-repair.svg', title: 'car repair', tags: ['car', 'repair'] },
  { key: 'default/car', file: 'default/car.svg', title: 'car', tags: ['car'] },
  { key: 'default/casino', file: 'default/casino.svg', title: 'casino', tags: ['casino'] },
  { key: 'default/castleJP', file: 'default/castle-JP.svg', title: 'castle JP', tags: ['castle', 'jp'] },
  { key: 'default/castle', file: 'default/castle.svg', title: 'castle', tags: ['castle'] },
  { key: 'default/caution', file: 'default/caution.svg', title: 'caution', tags: ['caution'] },
  { key: 'default/cemeteryJP', file: 'default/cemetery-JP.svg', title: 'cemetery JP', tags: ['cemetery', 'jp'] },
  { key: 'default/cemetery', file: 'default/cemetery.svg', title: 'cemetery', tags: ['cemetery'] },
  { key: 'default/chargingStation', file: 'default/charging-station.svg', title: 'charging station', tags: ['charging', 'station'] },
  { key: 'default/cinema', file: 'default/cinema.svg', title: 'cinema', tags: ['cinema'] },
  { key: 'default/circleStroked', file: 'default/circle-stroked.svg', title: 'circle stroked', tags: ['circle', 'stroked'] },
  { key: 'default/circle', file: 'default/circle.svg', title: 'circle', tags: ['circle'] },
  { key: 'default/city', file: 'default/city.svg', title: 'city', tags: ['city'] },
  { key: 'default/clothingStore', file: 'default/clothing-store.svg', title: 'clothing store', tags: ['clothing', 'store'] },
  { key: 'default/collegeJP', file: 'default/college-JP.svg', title: 'college JP', tags: ['college', 'jp'] },
  { key: 'default/college', file: 'default/college.svg', title: 'college', tags: ['college'] },
  { key: 'default/commercial', file: 'default/commercial.svg', title: 'commercial', tags: ['commercial'] },
  {
    key: 'default/communicationsTower',
    file: 'default/communications-tower.svg',
    title: 'communications tower',
    tags: ['communications', 'tower'],
  },
  { key: 'default/confectionery', file: 'default/confectionery.svg', title: 'confectionery', tags: ['confectionery'] },
  { key: 'default/construction', file: 'default/construction.svg', title: 'construction', tags: ['construction'] },
  { key: 'default/convenience', file: 'default/convenience.svg', title: 'convenience', tags: ['convenience'] },
  { key: 'default/cricket', file: 'default/cricket.svg', title: 'cricket', tags: ['cricket'] },
  { key: 'default/cross', file: 'default/cross.svg', title: 'cross', tags: ['cross'] },
  { key: 'default/dam', file: 'default/dam.svg', title: 'dam', tags: ['dam'] },
  { key: 'default/danger', file: 'default/danger.svg', title: 'danger', tags: ['danger'] },
  { key: 'default/defibrillator', file: 'default/defibrillator.svg', title: 'defibrillator', tags: ['defibrillator'] },
  { key: 'default/dentist', file: 'default/dentist.svg', title: 'dentist', tags: ['dentist'] },
  { key: 'default/diamond', file: 'default/diamond.svg', title: 'diamond', tags: ['diamond'] },
  { key: 'default/doctor', file: 'default/doctor.svg', title: 'doctor', tags: ['doctor'] },
  { key: 'default/dogPark', file: 'default/dog-park.svg', title: 'dog park', tags: ['dog', 'park'] },
  { key: 'default/drinkingWater', file: 'default/drinking-water.svg', title: 'drinking water', tags: ['drinking', 'water'] },
  { key: 'default/elevator', file: 'default/elevator.svg', title: 'elevator', tags: ['elevator'] },
  { key: 'default/embassy', file: 'default/embassy.svg', title: 'embassy', tags: ['embassy'] },
  { key: 'default/emergencyPhone', file: 'default/emergency-phone.svg', title: 'emergency phone', tags: ['emergency', 'phone'] },
  { key: 'default/entranceAlt1', file: 'default/entrance-alt1.svg', title: 'entrance alt1', tags: ['entrance', 'alt1'] },
  { key: 'default/entrance', file: 'default/entrance.svg', title: 'entrance', tags: ['entrance'] },
  { key: 'default/farm', file: 'default/farm.svg', title: 'farm', tags: ['farm'] },
  { key: 'default/fastFood', file: 'default/fast-food.svg', title: 'fast food', tags: ['fast', 'food'] },
  { key: 'default/fence', file: 'default/fence.svg', title: 'fence', tags: ['fence'] },
  { key: 'default/ferryJP', file: 'default/ferry-JP.svg', title: 'ferry JP', tags: ['ferry', 'jp'] },
  { key: 'default/ferry', file: 'default/ferry.svg', title: 'ferry', tags: ['ferry'] },
  { key: 'default/fireStationJP', file: 'default/fire-station-JP.svg', title: 'fire station JP', tags: ['fire', 'station', 'jp'] },
  { key: 'default/fireStation', file: 'default/fire-station.svg', title: 'fire station', tags: ['fire', 'station'] },
  { key: 'default/fitnessCentre', file: 'default/fitness-centre.svg', title: 'fitness centre', tags: ['fitness', 'centre'] },
  { key: 'default/florist', file: 'default/florist.svg', title: 'florist', tags: ['florist'] },
  { key: 'default/fuel', file: 'default/fuel.svg', title: 'fuel', tags: ['fuel'] },
  { key: 'default/furniture', file: 'default/furniture.svg', title: 'furniture', tags: ['furniture'] },
  { key: 'default/gaming', file: 'default/gaming.svg', title: 'gaming', tags: ['gaming'] },
  { key: 'default/gardenCentre', file: 'default/garden-centre.svg', title: 'garden centre', tags: ['garden', 'centre'] },
  { key: 'default/garden', file: 'default/garden.svg', title: 'garden', tags: ['garden'] },
  { key: 'default/gift', file: 'default/gift.svg', title: 'gift', tags: ['gift'] },
  { key: 'default/globe', file: 'default/globe.svg', title: 'globe', tags: ['globe'] },
  { key: 'default/golf', file: 'default/golf.svg', title: 'golf', tags: ['golf'] },
  { key: 'default/grocery', file: 'default/grocery.svg', title: 'grocery', tags: ['grocery'] },
  { key: 'default/hairdresser', file: 'default/hairdresser.svg', title: 'hairdresser', tags: ['hairdresser'] },
  { key: 'default/harbor', file: 'default/harbor.svg', title: 'harbor', tags: ['harbor'] },
  { key: 'default/hardware', file: 'default/hardware.svg', title: 'hardware', tags: ['hardware'] },
  { key: 'default/heart', file: 'default/heart.svg', title: 'heart', tags: ['heart'] },
  { key: 'default/heliport', file: 'default/heliport.svg', title: 'heliport', tags: ['heliport'] },
  { key: 'default/highwayRestArea', file: 'default/highway-rest-area.svg', title: 'highway rest area', tags: ['highway', 'rest', 'area'] },
  { key: 'default/home', file: 'default/home.svg', title: 'home', tags: ['home'] },
  { key: 'default/horseRiding', file: 'default/horse-riding.svg', title: 'horse riding', tags: ['horse', 'riding'] },
  { key: 'default/hospitalJP', file: 'default/hospital-JP.svg', title: 'hospital JP', tags: ['hospital', 'jp'] },
  { key: 'default/hospital', file: 'default/hospital.svg', title: 'hospital', tags: ['hospital'] },
  { key: 'default/hotSpring', file: 'default/hot-spring.svg', title: 'hot spring', tags: ['hot', 'spring'] },
  { key: 'default/iceCream', file: 'default/ice-cream.svg', title: 'ice cream', tags: ['ice', 'cream'] },
  { key: 'default/industry', file: 'default/industry.svg', title: 'industry', tags: ['industry'] },
  { key: 'default/information', file: 'default/information.svg', title: 'information', tags: ['information'] },
  { key: 'default/jewelryStore', file: 'default/jewelry-store.svg', title: 'jewelry store', tags: ['jewelry', 'store'] },
  { key: 'default/karaoke', file: 'default/karaoke.svg', title: 'karaoke', tags: ['karaoke'] },
  { key: 'default/landmarkJP', file: 'default/landmark-JP.svg', title: 'landmark JP', tags: ['landmark', 'jp'] },
  { key: 'default/landmark', file: 'default/landmark.svg', title: 'landmark', tags: ['landmark'] },
  { key: 'default/landuse', file: 'default/landuse.svg', title: 'landuse', tags: ['landuse'] },
  { key: 'default/laundry', file: 'default/laundry.svg', title: 'laundry', tags: ['laundry'] },
  { key: 'default/library', file: 'default/library.svg', title: 'library', tags: ['library'] },
  { key: 'default/lighthouseJP', file: 'default/lighthouse-JP.svg', title: 'lighthouse JP', tags: ['lighthouse', 'jp'] },
  { key: 'default/lighthouse', file: 'default/lighthouse.svg', title: 'lighthouse', tags: ['lighthouse'] },
  { key: 'default/lodging', file: 'default/lodging.svg', title: 'lodging', tags: ['lodging'] },
  { key: 'default/logging', file: 'default/logging.svg', title: 'logging', tags: ['logging'] },
  { key: 'default/markerStroked', file: 'default/marker-stroked.svg', title: 'marker stroked', tags: ['marker', 'stroked'] },
  { key: 'default/marker', file: 'default/marker.svg', title: 'marker', tags: ['marker'] },
  { key: 'default/mobilePhone', file: 'default/mobile-phone.svg', title: 'mobile phone', tags: ['mobile', 'phone'] },
  { key: 'default/monumentJP', file: 'default/monument-JP.svg', title: 'monument JP', tags: ['monument', 'jp'] },
  { key: 'default/monument', file: 'default/monument.svg', title: 'monument', tags: ['monument'] },
  { key: 'default/mountain', file: 'default/mountain.svg', title: 'mountain', tags: ['mountain'] },
  { key: 'default/museum', file: 'default/museum.svg', title: 'museum', tags: ['museum'] },
  { key: 'default/music', file: 'default/music.svg', title: 'music', tags: ['music'] },
  { key: 'default/natural', file: 'default/natural.svg', title: 'natural', tags: ['natural'] },
  { key: 'default/observationTower', file: 'default/observation-tower.svg', title: 'observation tower', tags: ['observation', 'tower'] },
  { key: 'default/optician', file: 'default/optician.svg', title: 'optician', tags: ['optician'] },
  { key: 'default/paint', file: 'default/paint.svg', title: 'paint', tags: ['paint'] },
  { key: 'default/parkAlt1', file: 'default/park-alt1.svg', title: 'park alt1', tags: ['park', 'alt1'] },
  { key: 'default/park', file: 'default/park.svg', title: 'park', tags: ['park'] },
  { key: 'default/parkingGarage', file: 'default/parking-garage.svg', title: 'parking garage', tags: ['parking', 'garage'] },
  { key: 'default/parking', file: 'default/parking.svg', title: 'parking', tags: ['parking'] },
  { key: 'default/pharmacy', file: 'default/pharmacy.svg', title: 'pharmacy', tags: ['pharmacy'] },
  { key: 'default/picnicSite', file: 'default/picnic-site.svg', title: 'picnic site', tags: ['picnic', 'site'] },
  { key: 'default/pitch', file: 'default/pitch.svg', title: 'pitch', tags: ['pitch'] },
  { key: 'default/placeOfWorship', file: 'default/place-of-worship.svg', title: 'place of worship', tags: ['place', 'of', 'worship'] },
  { key: 'default/playground', file: 'default/playground.svg', title: 'playground', tags: ['playground'] },
  { key: 'default/policeJP', file: 'default/police-JP.svg', title: 'police JP', tags: ['police', 'jp'] },
  { key: 'default/police', file: 'default/police.svg', title: 'police', tags: ['police'] },
  { key: 'default/postJP', file: 'default/post-JP.svg', title: 'post JP', tags: ['post', 'jp'] },
  { key: 'default/post', file: 'default/post.svg', title: 'post', tags: ['post'] },
  { key: 'default/prison', file: 'default/prison.svg', title: 'prison', tags: ['prison'] },
  { key: 'default/racetrackBoat', file: 'default/racetrack-boat.svg', title: 'racetrack boat', tags: ['racetrack', 'boat'] },
  { key: 'default/racetrackCycling', file: 'default/racetrack-cycling.svg', title: 'racetrack cycling', tags: ['racetrack', 'cycling'] },
  { key: 'default/racetrackHorse', file: 'default/racetrack-horse.svg', title: 'racetrack horse', tags: ['racetrack', 'horse'] },
  { key: 'default/racetrack', file: 'default/racetrack.svg', title: 'racetrack', tags: ['racetrack'] },
  { key: 'default/railLight', file: 'default/rail-light.svg', title: 'rail light', tags: ['rail', 'light'] },
  { key: 'default/railMetro', file: 'default/rail-metro.svg', title: 'rail metro', tags: ['rail', 'metro'] },
  { key: 'default/rail', file: 'default/rail.svg', title: 'rail', tags: ['rail'] },
  { key: 'default/rangerStation', file: 'default/ranger-station.svg', title: 'ranger station', tags: ['ranger', 'station'] },
  { key: 'default/recycling', file: 'default/recycling.svg', title: 'recycling', tags: ['recycling'] },
  {
    key: 'default/religiousBuddhist',
    file: 'default/religious-buddhist.svg',
    title: 'religious buddhist',
    tags: ['religious', 'buddhist'],
  },
  {
    key: 'default/religiousChristian',
    file: 'default/religious-christian.svg',
    title: 'religious christian',
    tags: ['religious', 'christian'],
  },
  { key: 'default/religiousJewish', file: 'default/religious-jewish.svg', title: 'religious jewish', tags: ['religious', 'jewish'] },
  { key: 'default/religiousMuslim', file: 'default/religious-muslim.svg', title: 'religious muslim', tags: ['religious', 'muslim'] },
  { key: 'default/religiousShinto', file: 'default/religious-shinto.svg', title: 'religious shinto', tags: ['religious', 'shinto'] },
  {
    key: 'default/residentialCommunity',
    file: 'default/residential-community.svg',
    title: 'residential community',
    tags: ['residential', 'community'],
  },
  { key: 'default/restaurantBbq', file: 'default/restaurant-bbq.svg', title: 'restaurant bbq', tags: ['restaurant', 'bbq'] },
  { key: 'default/restaurantNoodle', file: 'default/restaurant-noodle.svg', title: 'restaurant noodle', tags: ['restaurant', 'noodle'] },
  { key: 'default/restaurantPizza', file: 'default/restaurant-pizza.svg', title: 'restaurant pizza', tags: ['restaurant', 'pizza'] },
  {
    key: 'default/restaurantSeafood',
    file: 'default/restaurant-seafood.svg',
    title: 'restaurant seafood',
    tags: ['restaurant', 'seafood'],
  },
  { key: 'default/restaurantSushi', file: 'default/restaurant-sushi.svg', title: 'restaurant sushi', tags: ['restaurant', 'sushi'] },
  { key: 'default/restaurant', file: 'default/restaurant.svg', title: 'restaurant', tags: ['restaurant'] },
  { key: 'default/roadAccident', file: 'default/road-accident.svg', title: 'road accident', tags: ['road', 'accident'] },
  { key: 'default/roadblock', file: 'default/roadblock.svg', title: 'roadblock', tags: ['roadblock'] },
  { key: 'default/rocket', file: 'default/rocket.svg', title: 'rocket', tags: ['rocket'] },
  { key: 'default/schoolJP', file: 'default/school-JP.svg', title: 'school JP', tags: ['school', 'jp'] },
  { key: 'default/school', file: 'default/school.svg', title: 'school', tags: ['school'] },
  { key: 'default/scooter', file: 'default/scooter.svg', title: 'scooter', tags: ['scooter'] },
  { key: 'default/shelter', file: 'default/shelter.svg', title: 'shelter', tags: ['shelter'] },
  { key: 'default/shoe', file: 'default/shoe.svg', title: 'shoe', tags: ['shoe'] },
  { key: 'default/shop', file: 'default/shop.svg', title: 'shop', tags: ['shop'] },
  { key: 'default/skateboard', file: 'default/skateboard.svg', title: 'skateboard', tags: ['skateboard'] },
  { key: 'default/skiing', file: 'default/skiing.svg', title: 'skiing', tags: ['skiing'] },
  { key: 'default/slaughterhouse', file: 'default/slaughterhouse.svg', title: 'slaughterhouse', tags: ['slaughterhouse'] },
  { key: 'default/slipway', file: 'default/slipway.svg', title: 'slipway', tags: ['slipway'] },
  { key: 'default/snowmobile', file: 'default/snowmobile.svg', title: 'snowmobile', tags: ['snowmobile'] },
  { key: 'default/soccer', file: 'default/soccer.svg', title: 'soccer', tags: ['soccer'] },
  { key: 'default/squareStroked', file: 'default/square-stroked.svg', title: 'square stroked', tags: ['square', 'stroked'] },
  { key: 'default/square', file: 'default/square.svg', title: 'square', tags: ['square'] },
  { key: 'default/stadium', file: 'default/stadium.svg', title: 'stadium', tags: ['stadium'] },
  { key: 'default/starStroked', file: 'default/star-stroked.svg', title: 'star stroked', tags: ['star', 'stroked'] },
  { key: 'default/star', file: 'default/star.svg', title: 'star', tags: ['star'] },
  { key: 'default/suitcase', file: 'default/suitcase.svg', title: 'suitcase', tags: ['suitcase'] },
  { key: 'default/swimming', file: 'default/swimming.svg', title: 'swimming', tags: ['swimming'] },
  { key: 'default/tableTennis', file: 'default/table-tennis.svg', title: 'table tennis', tags: ['table', 'tennis'] },
  { key: 'default/teahouse', file: 'default/teahouse.svg', title: 'teahouse', tags: ['teahouse'] },
  { key: 'default/telephone', file: 'default/telephone.svg', title: 'telephone', tags: ['telephone'] },
  { key: 'default/tennis', file: 'default/tennis.svg', title: 'tennis', tags: ['tennis'] },
  { key: 'default/theatre', file: 'default/theatre.svg', title: 'theatre', tags: ['theatre'] },
  { key: 'default/toilet', file: 'default/toilet.svg', title: 'toilet', tags: ['toilet'] },
  { key: 'default/townHall', file: 'default/town-hall.svg', title: 'town hall', tags: ['town', 'hall'] },
  { key: 'default/town', file: 'default/town.svg', title: 'town', tags: ['town'] },
  { key: 'default/triangleStroked', file: 'default/triangle-stroked.svg', title: 'triangle stroked', tags: ['triangle', 'stroked'] },
  { key: 'default/triangle', file: 'default/triangle.svg', title: 'triangle', tags: ['triangle'] },
  { key: 'default/veterinary', file: 'default/veterinary.svg', title: 'veterinary', tags: ['veterinary'] },
  { key: 'default/viewpoint', file: 'default/viewpoint.svg', title: 'viewpoint', tags: ['viewpoint'] },
  { key: 'default/village', file: 'default/village.svg', title: 'village', tags: ['village'] },
  { key: 'default/volcano', file: 'default/volcano.svg', title: 'volcano', tags: ['volcano'] },
  { key: 'default/volleyball', file: 'default/volleyball.svg', title: 'volleyball', tags: ['volleyball'] },
  { key: 'default/warehouse', file: 'default/warehouse.svg', title: 'warehouse', tags: ['warehouse'] },
  { key: 'default/wasteBasket', file: 'default/waste-basket.svg', title: 'waste basket', tags: ['waste', 'basket'] },
  { key: 'default/watch', file: 'default/watch.svg', title: 'watch', tags: ['watch'] },
  { key: 'default/water', file: 'default/water.svg', title: 'water', tags: ['water'] },
  { key: 'default/waterfall', file: 'default/waterfall.svg', title: 'waterfall', tags: ['waterfall'] },
  { key: 'default/watermill', file: 'default/watermill.svg', title: 'watermill', tags: ['watermill'] },
  { key: 'default/wetland', file: 'default/wetland.svg', title: 'wetland', tags: ['wetland'] },
  { key: 'default/wheelchair', file: 'default/wheelchair.svg', title: 'wheelchair', tags: ['wheelchair'] },
  { key: 'default/windmill', file: 'default/windmill.svg', title: 'windmill', tags: ['windmill'] },
  { key: 'default/zoo', file: 'default/zoo.svg', title: 'zoo', tags: ['zoo'] },
];

export const defaultSymbols: Record<string, DrawingSymbol> = {};
defaultList.forEach((item) => {
  defaultSymbols[item.key] = item;
});
