import { useMemo } from 'react';
import { hasPermission, useSessionPermissions } from '@/utils/session';
import { MultiNavigation, Navigation, PermissionItem } from '@/models';

const checkPermission = (n: Omit<Navigation, 'id'> | MultiNavigation, permissions: PermissionItem[]) =>
  !n.permission || hasPermission(permissions, n.permission!);

const checkMultiPermission = (n: MultiNavigation, permissions: PermissionItem[]) => {
  const updated: MultiNavigation = {
    ...n,
    subNavigation: n.subNavigation.filter((nn) => checkPermission(nn, permissions)),
  };
  if (!updated.subNavigation.length) return null;
  return updated;
};

export const useAccessNavigationMap = (navigationMap: (Navigation | MultiNavigation)[]) => {
  const userPermissions = useSessionPermissions();
  return useMemo(() => {
    return navigationMap
      .filter((n) => checkPermission(n, userPermissions))
      .map((n) => {
        if ('subNavigation' in n) {
          return checkMultiPermission(n, userPermissions);
        }
        return n;
      })
      .filter((n) => n !== null) as (Navigation | MultiNavigation)[];
  }, [navigationMap, userPermissions]);
};

export const useAccessMultiNavigation = (navigation: MultiNavigation) => {
  const userPermissions = useSessionPermissions();
  return useMemo(() => checkMultiPermission(navigation, userPermissions), [navigation, userPermissions]);
};
