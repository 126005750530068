import { getConfig } from '@/config';
import { Cesium3DFeature, Feature, SourceData } from '@/models';
import { CustomPromise } from '@/utils/CustomPromise';
import { Cesium3DTileset, Color, Resource } from 'cesium';
import { AbstractSourceDataLayer } from '@/components/Cesium/Layers/AbstractSourceDataLayer';
import { CesiumPrimitivesLayer } from '@/components/Cesium/Layers/models';
import { getAccessToken } from '@/cmd/Api';

export class SourceData3DTilesLayer extends AbstractSourceDataLayer implements CesiumPrimitivesLayer {
  readonly url: Resource;
  readonly ready = CustomPromise<Cesium3DTileset[]>();
  public primitives: Cesium3DTileset[] = [];
  protected path;
  protected _show = true;
  protected _active?: Cesium3DFeature;

  constructor(sourceData: SourceData) {
    super(sourceData);

    this.path = sourceData.spatialProperties?.rootS3Path;
    this.url = this.getUrlResource();

    this.isLoading = true;
    Cesium3DTileset.fromUrl(this.url)
      .then((tileSet) => {
        tileSet.show = this.show;
        tileSet.allTilesLoaded.addEventListener(() => (this.isLoading = false));
        this.primitives.push(tileSet);
        if (tileSet.properties) {
          tileSet.properties.layer = this;
        } else {
          (tileSet as any)._properties = { layer: { ...this, sourceData } };
        }
        return this.ready.resolve(this.primitives);
      })
      .catch(() => (this.isLoading = false));
  }

  set active(id: Feature | undefined) {
    //Todo
    let feature;
    if (this._active) (this._active as any).color = Color.WHITE;
    if (id) {
      const selectedTiles = (this.primitives[0] as any)._selectedTiles;
      if (selectedTiles.length) {
        const tileSet = selectedTiles[0];
        if (!tileSet.isDestroyed()) {
          feature = selectedTiles[0].content.getFeature(id);
          if ('color' in feature) {
            feature.color = Color.RED;
          }
        }
      }
    }
    this._active = feature;
  }

  set show(value: boolean) {
    this.primitives.forEach((primitive) => (primitive.show = value));
    this._show = value;
  }

  get show(): boolean {
    return this._show;
  }

  getUrl() {
    const config = getConfig();

    return (config.fileServerUrl || '') + '/' + this.path;
  }

  getUrlResource() {
    return new Resource({ url: this.getUrl(), headers: { Authorization: getAccessToken() } });
  }

  destroy() {
    super.destroy();
    this.primitives.forEach((p) => !p.isDestroyed() && p.destroy());
  }
}
