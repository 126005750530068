import { singleton } from '@/utils/decorators/singleton';
import { Api, METHOD } from './Api';

export interface Options {
  url?: string;
  authUrl?: string;
  reconnectTimeOut: number;
}

interface TokenResponse {
  token: string;
}

@singleton
class MessagesApi extends Api {
  endpoint: string = '/api';
  protected wsToken?: string;
  static getInstance: () => MessagesApi;

  protected getMessageServiceToken(url: string): Promise<string> {
    return this.request(METHOD.POST, url).then((res: TokenResponse) => res.token);
  }

  protected parseAuthUrl(wsUrl: string) {
    const url = new URL(wsUrl);
    url.protocol = 'https:';
    url.pathname = '/websocket/api/auth/validate-token';
    return url.toString();
  }

  async createWebsocketConnection(options: Options) {
    if (options.url) {
      this.wsToken = await this.getMessageServiceToken(options.authUrl || this.parseAuthUrl(options.url));
      if (this.wsToken) {
        const wsUrl = options.url + '/websocket/connect?shortLiveToken=' + this.wsToken;
        return new WebSocket(wsUrl);
      }
    }

    throw Error('Cannot create WS connection');
  }
}

export const messagesApi = () => MessagesApi.getInstance();
