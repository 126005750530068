import { useRef, useState } from 'react';
import { isSameAs } from '@/utils';

export const debounce = (func: Function, timeout = 300) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeout);
  };
};

export const useDebounce = <T extends (...args: any) => any>(func: T, timeout = 300) => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  return (...args: Parameters<T>) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => func.apply(this, args), timeout);
  };
};

export const useDebounceData = <T = any>(data: T, timeout = 300): T => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const [dataState, setDataState] = useState(data);

  if (!isSameAs(data, dataState)) {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setDataState(data);
      timerRef.current = undefined;
    }, timeout);
  }

  return dataState;
};

export const useDebounceLeading = (func: Function, timeout = 300) => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  return (...args: any[]) => {
    if (!timerRef.current) {
      func.apply(this, args);
    }
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => (timerRef.current = undefined), timeout);
  };
};

export const useLoadingDebounce = (func: (state: boolean) => void, timeout = 300) => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const prevStateRef = useRef<boolean>();

  return (state: boolean) => {
    if (prevStateRef.current === state) {
      return;
    }
    prevStateRef.current = state;
    if (state) {
      clearTimeout(timerRef.current);
      func.apply(this, [state]);
      return;
    }

    timerRef.current = setTimeout(() => func.apply(this, [false]), timeout);
  };
};
