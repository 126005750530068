import { Point } from './geometry';
import { CustomPromise, CustomPromiseProps } from './CustomPromise';

export const getImageDimension = (img: HTMLImageElement): Point => {
  const width = ((img.getAttribute('width') ?? img.width) as any) * 1;
  const height = ((img.getAttribute('height') ?? img.height) as any) * 1;
  return new Point(width, height);
};

export interface CanvasWithCtx {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
}

export enum CanvasStrokeStyle {
  SOLID,
  DASHED,
}

export interface CanvasDrawOptions {
  fillColor?: string;
  strokeColor?: string;
  strokeStyle?: CanvasStrokeStyle;
  shadowColor?: string;
  lineWidth?: number;
}

export const createCanvas = (w: number | Point = 0, h: number | Point = w): CanvasWithCtx => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  if (w instanceof Point) {
    canvas.width = w.w;
    canvas.height = w.h;
  } else if (typeof h === 'number') {
    canvas.width = w;
    canvas.height = h;
  }

  return { canvas, ctx };
};

export const cloneCanvas = (canvas: HTMLCanvasElement | CanvasWithCtx): CanvasWithCtx => {
  canvas = canvas instanceof HTMLCanvasElement ? canvas : canvas.canvas;
  const newCanvas = createCanvas(canvas.width, canvas.height);
  newCanvas.ctx.drawImage(canvas, 0, 0);
  return newCanvas;
};

export const getCanvasCtx = (canvas: HTMLCanvasElement | CanvasWithCtx) =>
  canvas instanceof HTMLCanvasElement ? canvas.getContext('2d') : canvas.ctx;

export const canvasToBlob = (canvas: CanvasWithCtx | HTMLCanvasElement): Promise<Blob> => {
  const promise: CustomPromiseProps<Blob> = CustomPromise();
  const hCanvas = canvas instanceof HTMLCanvasElement ? canvas : canvas.canvas;
  hCanvas.toBlob(promise.resolve as any);
  return promise;
};

export const blobToCanvas = (blob: Blob, canvas?: HTMLCanvasElement | CanvasWithCtx): Promise<CanvasWithCtx> => {
  const img = new Image();
  const url = window.URL || window.webkitURL;
  const src = url.createObjectURL(blob);
  const promise = CustomPromise<CanvasWithCtx>();
  img.onerror = promise.reject;
  img.onabort = promise.reject;
  img.onload = () => {
    img.onload = null;
    const outCanvas = (canvas && 'canvas' in canvas ? canvas.canvas : canvas) || createCanvas(getImageDimension(img)).canvas;
    const ctx = getCanvasCtx(outCanvas)!;
    ctx.drawImage(img, 0, 0, outCanvas.width, outCanvas.height);
    url.revokeObjectURL(src);
    void promise.resolve({ canvas: outCanvas, ctx });
  };
  img.src = src;
  return promise;
};
