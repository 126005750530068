import { getConfig } from '@/config';
import { ImageryLayer } from './Layers/ImageryLayer';
import {
  ArcGisMapServerImageryProvider,
  OpenStreetMapImageryProvider,
  UrlTemplateImageryProvider,
  WebMapServiceImageryProvider,
} from 'cesium';

export enum Provider {
  WMS = 'WMS',
  URL_TEMPLATE = 'URL_TEMPLATE',
  ARCGIS = 'ARCGIS',
  OPEN_STREET_MAP = 'OPEN_STREET_MAP',
}

export enum BASE_LAYER {
  DEFAULT = 'default',
  ALTERNATE = 'alternate',
  VECTOR_DARK = 'vectorDark',
}

const ProviderMap = {
  [Provider.WMS]: WebMapServiceImageryProvider,
  [Provider.URL_TEMPLATE]: UrlTemplateImageryProvider,
  [Provider.ARCGIS]: ArcGisMapServerImageryProvider,
  [Provider.OPEN_STREET_MAP]: OpenStreetMapImageryProvider,
};

const config = getConfig();

export const getThumbnailUrl = (baseLayer: BASE_LAYER = BASE_LAYER.DEFAULT) => config.tileServer[baseLayer].thumbnailUrl;

export const getBaseLayer = (baseLayer: BASE_LAYER = BASE_LAYER.DEFAULT): Promise<ImageryLayer> | ImageryLayer | undefined => {
  const tileServerCfg = config.tileServer[baseLayer];

  const url = tileServerCfg.url || undefined;
  const providerKey = tileServerCfg.provider || Provider.URL_TEMPLATE;
  const layers = tileServerCfg.layers;
  const copyright = tileServerCfg.copyright;
  const options = { url, layers, copyright } as any;
  const provider = resolveProvider(providerKey);
  if (url && 'fromUrl' in provider && typeof provider.fromUrl === 'function') {
    return provider.fromUrl(url).then((resource: ArcGisMapServerImageryProvider) => new ImageryLayer(resource, options));
  }

  return new ImageryLayer(new provider(options), options);
};

const resolveProvider = (providerKey: Provider) => {
  if (ProviderMap[providerKey]) {
    return ProviderMap[providerKey];
  }

  throw Error('Cannot resolve imagery provider ' + providerKey);
};
