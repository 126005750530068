import { useEffect, useState } from 'react';
import { userApi } from '@/cmd/UserApi';
import { useUser } from '@/hooks/useUser';
import { UserId } from '@/models';
import { useSession } from 'next-auth/react';

const useAvatar = (userId?: UserId) => {
  const { status } = useSession();
  const user = useUser(userId);
  const [avatarSrc, setAvatarSrc] = useState<string>();

  useEffect(() => {
    let file: string;
    if (user?.hasAvatar && status === 'authenticated') {
      userApi()
        .getAvatar(user.id)
        .then((response) => {
          file = URL.createObjectURL(response.data);
          setAvatarSrc(file);
        })
        .catch((e) => console.error(e));
    }

    return () => {
      if (file) {
        URL.revokeObjectURL(file);
      }
    };
  }, [status, user?.hasAvatar, user?.id]);

  return avatarSrc;
};

export default useAvatar;
