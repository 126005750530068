import { ColorPreset } from '@/components/ColorPresetSelector';
import { getColorFromRanges, numRange, rgbToHex } from '@/utils';
import { DEFAULT_FEATURE_STYLE, DEFAULT_STYLES } from '@/components/Cesium/utils/styles';
import { BandHistogramData } from '@/cmd/MapApi';
import { State as GlobeMapState } from '@/components/Map/GlobeMap';
import {
  Band,
  BandColorRamp,
  BandGrayColorRamp,
  BandLevels,
  BandMapping,
  BandPseudoColorRamp,
  BandRanges,
  BandRenderType,
  BandsSettings,
  ColorRamp,
  HexColor,
  PropertyColorPreset,
  PropertyStyle,
  RasterSourceType,
  SingleBandsSettings,
  SourceData,
  SourceDataId,
  SourceType,
  VectorSourceType,
} from '@/models';

export enum LayerAction {
  UNION = 'UNION',
  DIFF = 'DIFF',
  INTERSECT = 'INTERSECT',
  HISTORICAL_IMAGERY = 'HISTORICAL IMAGERY',
  IMAGE_COMPARISON_SLIDER = 'IMAGE COMPARISON SLIDER',
  IMAGE_COREGISTRATION = 'IMAGE_COREGISTRATION',
  OPTICAL_CHANGE_DETECTION = 'OPTICAL_CHANGE_DETECTION',
  SAR_CHANGE_DETECTION = 'SAR_CHANGE_DETECTION',
  BANDS_MATH = 'BANDS_MATH',
  MOSAIC = 'MOSAIC',
}

export const DEFAULT_BANDS_RANGE: BandRanges = [0, 255, 0, 255, 0, 255];
export const DEFAULT_BANDS_LEVELS: BandLevels = [0, 255, 0, 255, 0, 255];

export type Range = { min: number; max: number };
export type RangeList = Range[];

export type PositionMapping = { red: number; green: number; blue: number; alpha: number };
export const POSITION_MAPPING: PositionMapping = { red: 0, green: 1, blue: 2, alpha: 3 };
export const DEFAULT_OPTION: Band = { index: -1, name: 'None' };

export const getBands = (sourceData?: SourceData): Band[] => sourceData?.rasterProperties?.bands || [];
export const getBandName = (band: Band) => (band.name !== 'Undefined' && band.name) || 'Band ' + (band.index + 1);

type ColorRangeMap = { [key in ColorRamp]: HexColor[] };

export const ColorRanges: ColorRangeMap = {
  [PropertyColorPreset.DIV_SUNSET]: ['#aa0b26', '#fa9958', '#eaebca', '#78aed1', '#08306b'],
  [PropertyColorPreset.DIV_RAINBOW]: ['#0021f5', '#73fcfe', '#a6fb4e', '#ec5328', '#931b11'],
  [PropertyColorPreset.SEQ_YELLOWS]: ['#ffffff', '#f4ad3c', '#931b11'],
  [PropertyColorPreset.SEQ_REDS]: ['#ffffff', '#ef3b2c', '#450009'],
  [PropertyColorPreset.SEQ_BLUES]: ['#e6eaf0', '#08306b'],
  [PropertyColorPreset.SEQ_GREENS]: ['#e9eee6', '#205000'],
  [BandGrayColorRamp.BLACK_WHITE]: ['#000000', '#ffffff'],
  [BandGrayColorRamp.WHITE_BLACK]: ['#ffffff', '#000000'],
  [BandPseudoColorRamp.BLUES]: ['#f7fbff', '#08306b'],
  [BandPseudoColorRamp.CIVIDIS]: ['#00204d', '#ffea46'],
  [BandPseudoColorRamp.GREENS]: ['#f7fcf5', '#00441b'],
  [BandPseudoColorRamp.GREYS]: ['#fafafa', '#050505'],
  [BandPseudoColorRamp.MAGMA]: ['#000004', '#882881', '#ed5960', '#fcfdbf'],
  [BandPseudoColorRamp.MAKO]: ['#0b0405', '#3a5b9b', '#56cbad', '#def5e5'],
  [BandPseudoColorRamp.RD_GY]: ['#ca0020', '#f9f9f9', '#404040'],
  [BandPseudoColorRamp.REDS]: ['#fff5f0', '#a50f15', '#67000d'],
  [BandPseudoColorRamp.ROCKET]: ['#03051a', '#851e5a', '#ef5740', '#faebdd'],
  [BandPseudoColorRamp.SPECTRAL]: ['#aa0b26', '#fa9958', '#eaebca', '#78aed1', '#08306b'],
  [BandPseudoColorRamp.TURBO]: ['#0021f5', '#73fcfe', '#a6fb4e', '#ec5328', '#931b11'],
  [BandPseudoColorRamp.VIRIDIS]: ['#440154', '#2a768e', '#46c06f', '#fde725'],
};

export const getColorPresetsByRamp = <T = ColorRamp>(colorRampEnum: any, colorRampInverted = false): ColorPreset<T>[] =>
  Object.values(colorRampEnum).map((rampKey) => getColorPresetByRange(rampKey as any, colorRampInverted));

export const getColorPresetByRange = <T extends keyof ColorRangeMap>(colorRangeName: T, colorRampInverted = false): ColorPreset<T> => ({
  name: colorRangeName,
  range: colorRampInverted ? [...ColorRanges[colorRangeName]].reverse() : ColorRanges[colorRangeName],
});

export const getColorRamp = (bandsSettings: BandsSettings): BandColorRamp | undefined => {
  const colorRamp: any = 'colorRamp' in bandsSettings && bandsSettings.colorRamp;
  switch (bandsSettings.renderType) {
    case BandRenderType.PSEUDOCOLOR:
      return colorRamp && Object.values(BandPseudoColorRamp).includes(colorRamp) ? colorRamp : BandPseudoColorRamp.BLUES;

    case BandRenderType.GRAY:
      return colorRamp && Object.values(BandGrayColorRamp).includes(colorRamp) ? colorRamp : BandGrayColorRamp.WHITE_BLACK;
  }
};

export const rangeListToBandRanges = (renderType: BandRenderType, rangeList?: RangeList, histogramMaxValue = 255): BandRanges => {
  const ranges = [...Array(3)]
    .map((_, idx) => {
      const position = renderType === BandRenderType.MULTIBAND ? idx : 0;
      const { min = 0, max = histogramMaxValue } = (rangeList || [])[position];
      return [min, max];
    })
    .flat();

  return ranges as any;
};

export const bandRangesToRangeList = (bandRanges?: BandRanges): RangeList | undefined => {
  if (bandRanges) {
    return [...Array(3)].map((range, bandIndex) => {
      const position = bandIndex * 2;
      return { min: bandRanges[position], max: bandRanges[position + 1] };
    });
  }
};

export const getFeatureStyleByRange = (style: PropertyStyle, valuesCount: number, offset: number) => {
  const { colorPreset: colorPresetName, colorPresetInverted } = style;
  if (colorPresetName) {
    const colorPreset = getColorPresetByRange(colorPresetName);
    if (colorPreset) {
      const range = colorPresetInverted ? [...colorPreset.range!].reverse() : colorPreset.range!;
      const color = colorPreset && colorPreset.range && valuesCount && rgbToHex(getColorFromRanges(range, valuesCount, offset));
      if (color) {
        const strokeWidth = style.strokeWidth || DEFAULT_FEATURE_STYLE.strokeWidth;
        return { fill: color + 'a0', stroke: color, strokeWidth };
      }
    }
  }
};

export const getDefaultStrokeWidth = (sourceData?: SourceData): number =>
  (sourceData && sourceData.sourceType === VectorSourceType.POINT
    ? DEFAULT_STYLES.rules.POINT!.strokeWidth
    : DEFAULT_STYLES.rules.POLYGON!.strokeWidth) ||
  DEFAULT_STYLES.rules.POLYGON!.strokeWidth ||
  2;

export const getDefaultBandsMapping = (bands: Band[] | undefined = []): BandMapping =>
  [...Array(4)].map((band, idx) =>
    idx < 3 ? (bands.length - 1 > idx ? idx : bands?.filter((b) => !b.isAlpha).length - 1) : bands.findIndex((b) => b.isAlpha),
  ) as BandMapping;

export const getSinglebandDefaults = (bandsSettings: SingleBandsSettings): SingleBandsSettings => {
  const mapping: BandMapping = [0, 0, 0, -1];
  const colorRamp = getColorRamp(bandsSettings)!;
  return { ...bandsSettings, colorRamp, mapping, ranges: undefined };
};

export const getDefaultBandsSettings = (
  bands: Band[] | undefined,
  sourceType?: SourceType,
  histogram?: BandHistogramData[],
): BandsSettings => {
  if (sourceType === RasterSourceType.SAR) {
    const maxRange = histogram?.[0].normalized.length || 255;
    const range95 = getRange95(BandRenderType.GRAY, getDefaultBandsMapping(bands), histogram);
    return {
      renderType: BandRenderType.GRAY,
      colorRamp: BandGrayColorRamp.BLACK_WHITE,
      mapping: getDefaultBandsMapping(bands),
      ranges: rangeListToBandRanges(BandRenderType.GRAY, range95, maxRange),
    };
  }

  return {
    renderType: BandRenderType.MULTIBAND,
    mapping: getDefaultBandsMapping(bands),
    ranges: DEFAULT_BANDS_RANGE,
    // Todo: applySharpen(false);
  };
};

export const getBandsLevels = (bandsSettings: BandsSettings | undefined, histogram: BandHistogramData[] | undefined): BandLevels => {
  if (!bandsSettings?.ranges || !histogram) {
    return DEFAULT_BANDS_LEVELS;
  }

  return bandsSettings.ranges.map((range, idx) => {
    const position = Math.floor(idx / 2);
    const bandIndex = bandsSettings.mapping[position];
    const bandHistogram = histogram!.find((data) => data.index === bandIndex);
    if (bandHistogram) {
      return bandHistogram.min + (bandHistogram.max - bandHistogram.min) * (range / bandHistogram.normalized.length);
    }
    return 0;
  }) as any;
};

const sourceStateModificationCallback =
  (sourceId: SourceDataId, property: 'incompleteJobsCount' | 'jobsCount' | 'childrenCount', count = 1) =>
  (prev: GlobeMapState) => {
    const updatedSources = [...prev.dataSources.list];
    const index = updatedSources.findIndex((source) => source.id === sourceId);
    if (index >= 0) {
      updatedSources[index][property] += count;
    }

    return {
      dataSources: { ...prev.dataSources, list: updatedSources },
    };
  };

export const addJobsCountStateCallback = (sourceId: SourceDataId, count = 1) =>
  sourceStateModificationCallback(sourceId, 'incompleteJobsCount', count);

export const addChildrenCountStateCallback = (sourceId: SourceDataId, count = 1) =>
  sourceStateModificationCallback(sourceId, 'childrenCount', count);
export const rangeFinder = (targetPercent = 95) => {
  let currentPercent = 0;
  const targetPercentNormalized = (100 - targetPercent) / 100 / 2;
  return (val: number) => {
    currentPercent += val;
    return currentPercent >= targetPercentNormalized;
  };
};

export const getRange95 = (renderType: BandRenderType, mapping: BandMapping, histogram: BandHistogramData[] | undefined) => {
  const maxRange = histogram?.[0].normalized.length || 255;
  const rangesNum = renderType === BandRenderType.MULTIBAND ? 3 : 1;
  return mapping.slice(0, rangesNum).map((bandIndex, bandPosition) => {
    const histogramData = histogram?.find((histogramData) => histogramData.index === bandIndex);
    if (histogramData) {
      const min = numRange(histogramData.normalized.findIndex(rangeFinder(95)) || 0, 0);
      const max = numRange(histogramData.normalized.findLastIndex(rangeFinder(95)) || maxRange, 0) + 1;
      return { min, max };
    }
    return { min: 0, max: maxRange };
  });
};
