import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Settings, TenantId, UserSettings } from '@/models';
import { userApi } from '@/cmd/UserApi';
import { tenantApi } from '@/cmd/TenantApi';
import { useSession } from 'next-auth/react';

export const settingsKeys = {
  user: ['user_settings'] as const,
  tenant: (id: TenantId | undefined) => ['tenant_settings', id] as const,
};

export const useUserSettings = (enabled?: boolean) =>
  useQuery({
    queryKey: settingsKeys.user,
    queryFn: () => userApi().getSettings(),
    enabled,
    staleTime: Infinity,
  });

export const useTenantSettings = (tenantId?: TenantId) =>
  useQuery({
    queryKey: settingsKeys.tenant(tenantId),
    queryFn: () => tenantApi().getSettings(tenantId!),
    enabled: !!tenantId,
    staleTime: Infinity,
  });

export const useCreateUpdateUserSettings = () => {
  const queryClient = useQueryClient();
  const { update } = useSession();
  return useMutation({
    mutationFn: (request: { settings: Partial<UserSettings> }) => userApi().createSettings(request.settings),
    onMutate: async (request) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: settingsKeys.user });

      // Snapshot the previous value
      const previousSettings = queryClient.getQueryData(settingsKeys.user);

      // Optimistically update to the new value
      queryClient.setQueryData(settingsKeys.user, request.settings);

      // Return a context with the previous and new
      return {
        newSettings: request.settings,
        previousSettings,
      };
    },
    onError: (error, variables, context) => {
      if (context) {
        queryClient.setQueryData(settingsKeys.user, context.previousSettings);
      }
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries({ queryKey: settingsKeys.user });
      update();
    },
  });
};

export const useCreateUpdateTenantSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: { id: TenantId; settings: Partial<Settings> }) => tenantApi().createSettings(request.id, request.settings),
    onMutate: async (request) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: settingsKeys.tenant(request.id) });

      // Snapshot the previous value
      const previousSettings = queryClient.getQueryData(settingsKeys.tenant(request.id));

      // Optimistically update to the new value
      queryClient.setQueryData(settingsKeys.tenant(request.id), request.settings);

      // Return a context with the previous and new
      return {
        newSettings: request.settings,
        previousSettings,
      };
    },
    onError: (error, variables, context) => {
      if (context) {
        queryClient.setQueryData(settingsKeys.tenant(variables.id), context.previousSettings);
      }
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries({ queryKey: settingsKeys.tenant(variables.id) });
    },
  });
};
