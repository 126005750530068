import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Typography, styled, Skeleton, alpha, Theme } from '@mui/material';
import { formatDistance } from 'date-fns';
import { ModelSubscriptionStatus, Notification, NotificationEntityType, UnixTime } from '@/models';
import AnimatedIcon from '@/components/AnimatedIcon';
import Button from '@/components/Button';
import { getObjectKeys } from '@/utils';
import { useForceUpdate } from '@/hooks/useForceUpdate';
import { useApproveModelSubscription, useRejectModelSubscription } from '@/components/Notifications/queries';

const READ_TIMEOUT_IN_SEC = 3;

const unreadSx = { background: (theme: Theme) => alpha(theme.palette.primary.main, 0.16) };

export interface LoadingNotification {
  id: 'dummy';
}

const LoadingItem = {
  title: <Skeleton variant="rounded" width="100%" height={20} sx={{ m: 1 }} />,
  time: <Skeleton variant="rounded" width={40} height={20} sx={{ m: 1 }} />,
  content: <Skeleton variant="rounded" width="100%" height={40} sx={{ m: 1 }} />,
};

interface Props {
  idx: number;
  item: Notification | LoadingNotification;
  onRead: (item: Notification) => void;
}

interface NotificationItemProps {
  id: string | number;
  icon?: string;
  title?: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
  time?: ReactNode | UnixTime;
  read?: boolean;
}

const isActionRequired = (item: Notification) => {
  if (
    item.entityType === NotificationEntityType.MODEL_SUBSCRIPTION_REQUESTED &&
    item.entity?.status === ModelSubscriptionStatus.PENDING_APPROVAL
  ) {
    return true;
  }

  return false;
};

export const NotificationListItem: FC<Props> = ({ idx, item, onRead }) => {
  const { t } = useTranslation(['common', 'notifications']);
  const forceUpdate = useForceUpdate();
  const markAsRead = () => {
    if ('entityType' in item) {
      item.read = true;
      onRead(item);
      forceUpdate();
    }
  };

  const { mutate: approveModelSubscription } = useApproveModelSubscription();
  const { mutate: rejectModelSubscription } = useRejectModelSubscription();

  const getNotificationContent = (item: Notification) => {
    switch (item.entityType) {
      case NotificationEntityType.MODEL_SUBSCRIPTION_REQUESTED: {
        const user = item.entity?.user?.fullname || '-';
        const tenant = item.entity?.tenant?.name || '-';
        const model = item.entity?.market.model?.name || '-';
        return t('common:' + item.entityType + '_TEXT', { user, tenant, model });
      }

      case NotificationEntityType.MODEL_SUBSCRIPTION_APPROVED:
      case NotificationEntityType.MODEL_SUBSCRIPTION_REJECTED: {
        const model = item.entity?.market.model?.name || '-';
        return t('common:' + item.entityType + '_TEXT', { model });
      }
    }
  };

  const getNotificationActions = (item: Notification) => {
    switch (item.entityType) {
      case NotificationEntityType.MODEL_SUBSCRIPTION_REQUESTED:
        if (!item.read && item.entity?.status === ModelSubscriptionStatus.PENDING_APPROVAL) {
          const modelInfo = { id: item.entity.id, modelId: item.entity.market.id };

          return (
            <>
              <Button onClick={() => approveModelSubscription(modelInfo, { onSuccess: markAsRead })}>{t('common:approve')}</Button>
              <Button onClick={() => rejectModelSubscription(modelInfo, { onSuccess: markAsRead })}>{t('common:reject')}</Button>
            </>
          );
        }
    }
  };

  const getNotificationItem = (idx: number, item: Notification | LoadingNotification) => {
    if (item.id === 'dummy') {
      return renderNotificationItem({ ...LoadingItem, id: idx });
    }

    if ('entityType' in item && getObjectKeys(NotificationEntityType).includes(item.entityType)) {
      return renderNotificationItem({
        id: item.id,
        title: t('common:' + item.entityType),
        content: getNotificationContent(item),
        actions: getNotificationActions(item),
        time: item.createdAt,
        read: item.read,
      });
    }

    return renderNotificationItem({
      id: item.id,
      title: 'entityType' in item ? item.entityType : 'Unsupported type of a notifications',
      content: 'Sorry. This notification can not be displayed',
      time: 'createdAt' in item && item.createdAt,
      read: !!(item as any).read,
    });
  };

  useEffect(() => {
    if ('entityType' in item && !isActionRequired(item)) {
      const timer = setTimeout(() => {
        markAsRead();
        forceUpdate();
      }, READ_TIMEOUT_IN_SEC * 1000);

      return () => clearTimeout(timer);
    }
  }, [item.id]);

  return getNotificationItem(idx, item);
};

const renderNotificationItem = (props: NotificationItemProps) => {
  const { icon = 'notifications', title, content, actions, time, read } = props;

  return (
    <Box display="flex" justifyContent="space-between" flex={1} gap={2} px={4} py={3} sx={(!read && unreadSx) || undefined}>
      <AnimatedIcon color="action" fontSize="medium">
        {icon}
      </AnimatedIcon>
      <Box flex={1}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between" flex={1} gap={1}>
          {typeof title === 'string' ? (
            <Typography variant="body2" color="text.primary" sx={{ wordBreak: 'break-word' }}>
              {title}
            </Typography>
          ) : (
            title
          )}
          {typeof time === 'number' ? (
            <Typography variant="caption" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
              {formatDistance(time, new Date().getTime(), { addSuffix: true })}
            </Typography>
          ) : (
            time
          )}
        </Box>
        <Box display="flex">
          {typeof content === 'string' ? (
            <Typography variant="body2" color="text.secondary">
              {content}
            </Typography>
          ) : (
            content
          )}
        </Box>
        {!!actions && <Box>{actions}</Box>}
      </Box>
    </Box>
  );
};
