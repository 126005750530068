import { NetCDFReader } from 'netcdfjs';
import { Math as CMath } from 'cesium';
import { NetCDFFieldsMapping } from './utils';
import { getObjectKeys } from '@/utils';

interface LoadDataOptions {
  fields: NetCDFFieldsMapping;
  valueRange: { min: number; max: number };
  offset: Record<string, number>;
  colorTable?: any; // Replace 'any' with the appropriate type for colorTable
}

export default (function () {
  let data: any;

  const loadColorTable = function (colorTable: any) {
    data.colorTable = { colorNum: colorTable.length, array: new Float32Array(colorTable.flat()) };
  };

  // Get the maximum value of the array
  const getMaxMin = (arr: any) => {
    let min = Infinity;
    let max = -Infinity;
    let hasNumber = false; // Add a variable to record whether the array contains numbers

    for (const num of arr) {
      if (!isNaN(num)) {
        hasNumber = true; // If there is a number, update the status

        if (num < min) {
          min = num;
        }
        if (num > max) {
          max = num;
        }
      }
    }

    if (!hasNumber) {
      // Return 0 if the array has no numbers
      return { min: 0, max: 0 };
    }

    return { min, max };
  };

  const loadNetCDF = function (file: any, { fields, valueRange, offset }: LoadDataOptions) {
    return new Promise(function (resolve, reject) {
      // const { U, V, W, H, lon, lat, lev } = fields;
      const reader = new FileReader();
      // Read file content using readAsText
      reader.readAsArrayBuffer(file);

      reader.onload = function () {
        const arrayToMap = function (array: any[]) {
          return array.reduce(function (map, object) {
            map[object.name] = object;
            return map;
          }, {});
        };
        const NetCDF = new NetCDFReader(reader.result as ArrayBuffer);
        data = {};

        const variables = NetCDF.header.variables.map((item) => item.name);
        getObjectKeys(fields).forEach((key) => {
          const arr = [];
          if (fields[key] && variables.indexOf(fields[key]) === -1) {
            arr.push(fields[key]);
          }
          if (arr.length) {
            reject('NetCDF file no such attribute: ' + arr + '\n all variables are: ' + variables);
            return;
          }
        });

        const dimensions = arrayToMap(NetCDF.dimensions);
        data.dimensions = {
          lon: 1,
          lat: 1,
          lev: 1,
        };
        (['lon', 'lat', 'lev'] as (keyof NetCDFFieldsMapping)[]).map((key) => {
          try {
            if (fields[key]) {
              data.dimensions[key] = dimensions[fields[key]].size;
              const array = NetCDF.getDataVariable(fields[key])
                .flat()
                .map((item) => {
                  if (offset[key]) return Number(item) + offset[key];
                  return item;
                }) as number[];
              const minMax = getMaxMin(array);
              data[key] = {};
              data[key].array = new Float32Array(array);
              data[key].min = minMax.min;
              data[key].max = minMax.max;
            }
          } catch (e) {
            reject(e);
            return;
          }
        });

        (['U', 'V', 'W', 'H'] as (keyof NetCDFFieldsMapping)[]).map((key) => {
          try {
            if (fields[key]) {
              const array = NetCDF.getDataVariable(fields[key])
                .flat()
                .map((item) => {
                  item = Number(item);
                  if (item < valueRange.min || item > valueRange.max) return 0;
                  return item;
                });
              const minMax = getMaxMin(array);
              data[key] = {};
              data[key].array = new Float32Array(array);
              data[key].min = minMax.min;
              data[key].max = minMax.max;
            }
          } catch (e) {
            reject(e);
            return;
          }
        });

        if (!data.lev) {
          data.lev = {
            array: [0].flat(),
            min: 0,
            max: 0,
          };
        }

        if (!fields['W']) {
          data.W = {
            array: new Float32Array(data.U.array.length),
            min: 0,
            max: 0,
          };
        }

        if (!fields['H']) {
          data.H = {
            array: new Float32Array(data.U.array.length),
            min: 0,
            max: 0,
          };
          if (fields['lev']) {
            const { lon, lat, lev } = data.dimensions;
            const arr = [];
            for (let i = 0; i < lev; i++) {
              for (let j = 0; j < lat; j++) {
                for (let k = 0; k < lon; k++) {
                  const index = i * (lon * lat) + j * lon + k;
                  data.H.array[index] = data.lev.array[i];
                }
              }
            }
            data.H.min = Math.min(...data.lev.array);
            data.H.max = Math.max(...data.lev.array);
          }
        }

        resolve(data);
      };
    });
  };

  const loadData = async function (input: any, type: any, { fields, valueRange, offset, colorTable }: LoadDataOptions) {
    if (type === 'json') {
      data = input;
    } else {
      try {
        await loadNetCDF(input, { fields, valueRange, offset });
      } catch (e) {
        throw e;
      }
    }

    loadColorTable(colorTable);

    return data;
  };

  // First find a random pixel and generate a random location based on the longitude and latitude range of the pixel.
  const getValidRange = function () {
    const dimensions = [data.dimensions.lon, data.dimensions.lat, data.dimensions.lev];
    const minimum = [data.lon.min, data.lat.min, data.lev.min];
    const maximum = [data.lon.max, data.lat.max, data.lev.max];
    const interval = [
      (maximum[0] - minimum[0]) / (dimensions[0] - 1),
      (maximum[1] - minimum[1]) / (dimensions[1] - 1),
      dimensions[2] > 1 ? (maximum[2] - minimum[2]) / (dimensions[2] - 1) : 1.0,
    ];
    const id = Math.floor(Math.random() * data.U.array.length);

    const z = Math.floor(id / (dimensions[0] * dimensions[1]));
    const left = id % (dimensions[0] * dimensions[1]);
    const y = Math.floor(left / dimensions[0]);
    const x = left % dimensions[0];

    const lon = CMath.randomBetween(minimum[0] + x * interval[0], minimum[0] + (x + 1) * interval[0]);
    const lat = CMath.randomBetween(minimum[1] + (y - 1) * interval[1], minimum[1] + y * interval[1]);
    // let lev = Cesium.Math.randomBetween(minimum[2] + (z - 1) * interval[2], minimum[2] + z * interval[2])
    const lev = data.H.array[id] || 0;
    return [lon, lat, lev];
  };

  const randomizeParticles = function (maxParticles: number, viewerParameters?: any) {
    const array = new Float32Array(4 * maxParticles);
    for (let i = 0; i < maxParticles; i++) {
      const pos = getValidRange();
      array[4 * i] = pos[0];
      array[4 * i + 1] = pos[1];
      array[4 * i + 2] = pos[2];
      array[4 * i + 3] = 0.0;
    }
    return array;
  };

  return {
    loadData,
    randomizeParticles,
  };
})();
