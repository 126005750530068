import { ParticlesOptions, NetCDFWindOptions, convertColorTable } from './utils';
import { Optional } from '@/utils/types';

const defaultFields = {
  U: 'U',
  V: 'V',
  W: '',
  H: '',
  lon: 'lon',
  lat: 'lat',
  lev: '',
};

const defaultColorTable = [[1.0, 1.0, 1.0]];

const defaultParticleOptions: ParticlesOptions = {
  maxParticles: 64 * 64,
  particleHeight: 1000.0,
  fadeOpacity: 0.996,
  dropRate: 0.003,
  dropRateBump: 0.01,
  speedFactor: 1.0,
  lineWidth: 2,
  dynamic: true,
};

export const defaultSettings: Optional<NetCDFWindOptions, 'input'> = {
  input: undefined,
  userInput: defaultParticleOptions,
  fields: undefined,
  valueRange: {
    max: 100,
    min: -100,
  },
  offset: {
    lon: 0,
    lat: 0,
    lev: 0,
  },
  colorTable: convertColorTable([
    '#040ed8',
    '#2050ff',
    '#4196ff',
    '#6dc1ff',
    '#86d9ff',
    '#9ceeff',
    '#aff5ff',
    '#ceffff',
    '#fffe47',
    '#ffeb00',
    '#ffc400',
    '#ff9000',
    '#ff4800',
    '#ff0000',
    '#d50000',
    '#9e0000',
  ]),
};

export { defaultFields, defaultColorTable, defaultParticleOptions };
