import {
  Api,
  DEFAULT_PAGINATION,
  METHOD,
  PageableRequest,
  PageableResponse,
  prepareParams,
  RequestParams,
  RequestPromise,
  SearchFilter,
  SORT_DIRECTION,
} from './Api';
import { singleton } from '@/utils/decorators/singleton';
import { cache, cacheKeyFn } from '@/utils/decorators/cache';
import { addSuccessHandler, CustomPromiseState, setDataProcessor } from '@/utils/CustomPromise';
import { downloadFromBackend } from '@/cmd/download';
import { removeEmpty } from '@/utils';
import { jobApi } from '@/cmd/JobApi';
import { OnlyRequired } from '@/utils/types';
import { PAGE_SIZE_BIG } from '@/consts';
import { GeometryType } from '@/utils/geometry';
import {
  AnnotationFeature,
  BandMap,
  BandMapping,
  BandsMathJob,
  BandsSettings,
  Category,
  CategoryAggregation,
  CategoryId,
  CoRegistrationJob,
  Feature,
  FeatureId,
  FormattedDateTime,
  GrdWorkflow,
  JobPropertyType,
  JobType,
  KeyValue,
  type LayerAttribute,
  ModelId,
  MosaickingJob,
  OpticalChangeDetectionJob,
  ResamplingJob,
  ResamplingMethod,
  SarChangeDetectionJob,
  SarChangeDetectionWorkflow,
  SarJob,
  SarWorkflow,
  SourceData,
  SourceDataId,
  SourceDataRelation,
  SourceDataSearch,
  SourceDataTimestamp,
  SourceDataType,
  SourceGeometryType,
  TemporalSettings,
  UnixTime,
  VectorPropertiesMappingResponseDTO,
  VectorStyles,
  WithReferenceLayer,
  WKT,
  WorkspaceId,
} from '@/models';

// TODO: handle cache condition for promise
const cacheCondition = (res: RequestPromise<any>) => ![CustomPromiseState.ERROR, CustomPromiseState.CANCELLED].includes(res.status);

const geometryMap: Partial<Record<GeometryType, GeometryType>> = {
  [GeometryType.MULTIPOLYGON]: GeometryType.POLYGON,
  [GeometryType.MULTILINESTRING]: GeometryType.LINESTRING,
};

export interface Filter extends SourceDataSearch {
  categories?: (CategoryId | Category)[] | null;
}

export interface AnnotationFilter extends SearchFilter {
  categoryIds?: CategoryId[];
}
export interface CreateSourceDataDto {
  name: string;
  description?: string;
  parentId?: SourceDataId;
  type: SourceDataType.ANNOTATION | SourceDataType.VECTOR | SourceDataType.DRAWING;
}

export interface CountFilter {
  intersects?: WKT;
  minAcquisitionDate?: UnixTime | FormattedDateTime;
  maxAcquisitionDate?: UnixTime | FormattedDateTime;
  minCreationDate?: UnixTime | FormattedDateTime;
  maxCreationDate?: UnixTime | FormattedDateTime;
  sourceTypes?: SourceDataType[];
  categoryIds?: CategoryId[];
  propertyIsNotNullList?: string[];
  propertyMatchMap?: KeyValue<string>;
}

export interface AggregationFilter extends SearchFilter {
  categoryIds?: CategoryId[];
  allowGeometryEmpty?: boolean;
  intersects?: WKT;
  properties?: string[];
}

interface ActionBaseOptions {
  workspaceId?: WorkspaceId;
  originalLayerId: SourceDataId;
  outputLayerName: string;
}

export interface SarProcessingOptions extends ActionBaseOptions {
  workflow: SarWorkflow | GrdWorkflow;
  correction: TerrainCorrection;
  dem?: Dem;
}

export interface ResamplingOptions extends ActionBaseOptions {
  size: [number, number];
  method: ResamplingMethod;
}

export interface OpticalChangeDetectionOptions extends ActionBaseOptions, WithReferenceLayer {
  modelVersionId: ModelId;
}

export interface SarChangeDetectionOptions extends ActionBaseOptions, WithReferenceLayer {
  workflow: SarChangeDetectionWorkflow;
  dem: Dem;
}

export interface BandsMathOptions extends ActionBaseOptions, WithReferenceLayer {
  layerBands: BandMap[];
  formula: string;
}

interface MosaickingOptions extends ActionBaseOptions {
  layerIds: SourceDataId[];
}

export enum TerrainCorrection {
  TERRAIN = 'TERRAIN',
  ELLIPSOID = 'ELLIPSOID',
}

export enum Dem {
  SRTM30M = 'SRTM30M',
  SRTM90M = 'SRTM90M',
  COPERNICUS30M = 'COPERNICUS30M',
  COPERNICUS90M = 'COPERNICUS90M',
}

export enum FeaturesExportFormat {
  GEO_JSON = 'geojson',
  KML = 'kml',
  KMZ = 'kmz',
}

export interface FeaturesExportCsvOptions extends RequestParams {
  fileName: string;
  includeGeometry: boolean;
  includeTimestamp: boolean;
  propertyKeys: string[];
}

export interface TimeSeriesFilter {
  minTimeSeriesDate: FormattedDateTime | undefined;
  maxTimeSeriesDate: FormattedDateTime | undefined;
  all: boolean;
}

export type FilterResultsCount = {
  [key in SourceDataType]: number;
};

export const DEFAULT_DEM = Dem.COPERNICUS30M;

@singleton
class DataSourceApi extends Api {
  endpoint: string = '/api/sources';
  clearCache!: (key?: string) => void;
  clearGetCache!: (key?: string) => void;
  clearFindCache!: (key?: string) => void;
  clearStyleCache!: (key?: string) => void;
  clearCountCache!: (key?: string) => void;
  static getInstance: () => DataSourceApi;

  @cache(cacheKeyFn, 'clearGetCache', 900)
  get(sourceDataId: SourceDataId): Promise<SourceData> {
    return this.find([sourceDataId]).then((res) => res[0]);
  }

  getList(filter?: Filter, pagination?: PageableRequest): RequestPromise<PageableResponse<SourceData>> {
    const { categories, ...rest } = removeEmpty(filter ?? {})!;
    const categoryIds = categories && categories.map((c: any) => (typeof c === 'object' ? c.id : c));
    return this.search([{ ...rest, ...((categoryIds?.length ?? 0) > 0 && { categoryIds }) }], pagination);
  }

  @cache(cacheKeyFn, 'clearCache', 900, cacheCondition)
  search(searches?: SourceDataSearch[], pagination?: PageableRequest): RequestPromise<PageableResponse<SourceData>> {
    const params = prepareParams({ ...DEFAULT_PAGINATION, ...pagination });
    return this.request(METHOD.POST, '/search' + params, searches ?? []);
  }

  @cache(cacheKeyFn, 'clearCountCache', 900, cacheCondition)
  getCount(searches?: SourceDataSearch[]): RequestPromise<number> {
    return setDataProcessor(this.search(searches, { pageSize: 1 }), (res) => res.totalElements);
  }

  getTypesCount(filter: CountFilter): RequestPromise<FilterResultsCount> {
    return this.request(METHOD.GET, '/statistic', filter);
  }

  @cache(cacheKeyFn, 'clearFindCache', 900)
  find(ids: SourceDataId[]): RequestPromise<SourceData[]> {
    return setDataProcessor(this.request(METHOD.GET, '', { ids, pageSize: ids.length }), (res) => res.content);
  }

  create = (sourceData: CreateSourceDataDto): RequestPromise<SourceData> => {
    let url;
    switch (sourceData.type) {
      case SourceDataType.DRAWING:
      case SourceDataType.VECTOR:
        url = '/vector';
        break;
      case SourceDataType.ANNOTATION:
        url = '/annotation';
        break;
    }
    return addSuccessHandler(this.request(METHOD.POST, url, sourceData), this.responseClearCachePromise);
  };

  update = (dataSource: Partial<SourceData>): RequestPromise<SourceData> =>
    addSuccessHandler(this.request(METHOD.PATCH, '/' + dataSource.id, dataSource), this.responseClearCachePromise);

  delete = (sourceDataId: SourceDataId, force = false): RequestPromise<any> =>
    addSuccessHandler(this.request(METHOD.DELETE, '/' + sourceDataId, { force }), this.responseClearCachePromise);

  makePermanent = (sourceDataId: SourceDataId): RequestPromise<SourceData> =>
    addSuccessHandler(
      this.request(METHOD.PATCH, '/' + sourceDataId, { id: sourceDataId, temporary: false }),
      this.responseClearCachePromise,
    );

  getFeature = (featureId: FeatureId): RequestPromise<Feature> => this.request(METHOD.GET, '/features/' + featureId);

  createFeature = <T extends Feature>(feature: Partial<T>): RequestPromise<T> =>
    this.request(METHOD.POST, '/' + feature.sourceId + '/features', feature);

  copyDetections = (destinationSourceId: SourceDataId, modelIds: ModelId[]): RequestPromise<string> =>
    this.request(METHOD.POST, '/annotation/' + destinationSourceId + '/copy/inference', modelIds);

  editFeature = <T extends Feature>(feature: Partial<T>): RequestPromise<T> =>
    this.request(METHOD.PATCH, '/features/' + feature.id, { ...feature, layerId: feature.sourceId });

  deleteFeature = (featureId: FeatureId): RequestPromise<any> => this.request(METHOD.DELETE, '/features/' + featureId);

  exportFeatures = (sourceDataId: SourceDataId, format: FeaturesExportFormat, fileName: string = sourceDataId) =>
    downloadFromBackend(this.endpoint + '/' + sourceDataId + '/features/download' + prepareParams({ fileName, format }), {
      name: fileName + '.' + format,
    });

  exportFeaturesCSV = (sourceDataId: SourceDataId, options: FeaturesExportCsvOptions) =>
    downloadFromBackend(this.endpoint + '/' + sourceDataId + '/features/download/csv' + prepareParams(options), {
      name: options.fileName + '.csv',
    });

  downloadFeature(sourceDataId: SourceDataId, fileName: string) {
    return downloadFromBackend('/api/sources/annotation/' + sourceDataId + '/download' + prepareParams({ fileName }), {
      name: `${fileName}`,
    });
  }

  getFeaturesList = (
    sourceDataId: SourceDataId,
    filter?: AnnotationFilter,
    pagination?: PageableRequest,
  ): RequestPromise<PageableResponse<AnnotationFeature>> => {
    return this.request(METHOD.GET, '/' + sourceDataId + '/features', {
      ...removeEmpty(filter),
      ...DEFAULT_PAGINATION,
      sort: 'id',
      ...pagination,
    });
  };

  getPropertiesKeys = (
    sourceDataId: SourceDataId,
    filter?: SearchFilter,
    pagination?: PageableRequest,
  ): RequestPromise<PageableResponse<string>> => {
    return this.request(METHOD.GET, '/' + sourceDataId + '/features/properties/keys', { ...filter, ...DEFAULT_PAGINATION, ...pagination });
  };

  getPropertyValues = (
    sourceDataId: SourceDataId,
    propertyKey: string,
    filter?: SearchFilter,
    pagination?: PageableRequest,
  ): RequestPromise<PageableResponse<string>> => {
    return this.request(METHOD.GET, '/' + sourceDataId + '/features/properties/values', {
      propertyKey,
      ...filter,
      ...DEFAULT_PAGINATION,
      ...pagination,
    });
  };

  getTimeSeriesFeatures = (
    sourceDataId: SourceDataId,
    filter: TimeSeriesFilter,
    pagination?: PageableRequest,
  ): RequestPromise<PageableResponse<Feature>> => {
    return this.request(METHOD.GET, '/' + sourceDataId + '/features/timeseries', {
      ...filter,
      ...pagination,
      sort: 'timeStamp',
      direction: SORT_DIRECTION.ASC,
      pageSize: PAGE_SIZE_BIG,
    });
  };

  getTimeSeriesStartEnd = (
    sourceDataId: SourceDataId,
  ): RequestPromise<[OnlyRequired<Feature, 'timeStamp'>, OnlyRequired<Feature, 'timeStamp'>]> => {
    return this.request(METHOD.GET, '/' + sourceDataId + '/features/minmaxtimeseries');
  };

  @cache(cacheKeyFn, 'clearStyleCache', 900)
  getVectorStyles(sourceDataId: SourceDataId): RequestPromise<VectorStyles> {
    return this.request(METHOD.GET, '/' + sourceDataId + '/setting/vector-styles');
  }

  saveVectorStyles = (sourceDataId: SourceDataId, styles: VectorStyles): RequestPromise<VectorStyles> => {
    return addSuccessHandler(
      this.request(METHOD.POST, '/' + sourceDataId + '/setting/vector-styles', styles),
      this.responseClearStyleCachePromise,
    );
  };

  // Todo: Remove
  getBandMapping = (sourceDataId: SourceDataId): RequestPromise<BandMapping | undefined> =>
    this.request(METHOD.GET, '/' + sourceDataId + '/setting/bandMapping');

  // Todo: Remove
  saveBandMapping = (sourceDataId: SourceDataId, mapping: BandMapping): RequestPromise<BandMapping> =>
    this.request(METHOD.POST, '/' + sourceDataId + '/setting/bandMapping', mapping);

  getBandsSettings = (sourceDataId: SourceDataId): RequestPromise<BandsSettings | undefined> =>
    this.request(METHOD.GET, '/' + sourceDataId + '/setting/bandsSettings');

  saveBandsSettings = (sourceDataId: SourceDataId, settings: BandsSettings): RequestPromise<BandsSettings> =>
    this.request(METHOD.POST, '/' + sourceDataId + '/setting/bandsSettings', settings);

  getTemporalSettings = (sourceDataId: SourceDataId): RequestPromise<TemporalSettings | undefined> =>
    this.request(METHOD.GET, '/' + sourceDataId + '/setting/temporalSettings');

  saveTemporalSettings = (sourceDataId: SourceDataId, settings: TemporalSettings): RequestPromise<TemporalSettings> =>
    this.request(METHOD.POST, '/' + sourceDataId + '/setting/temporalSettings', settings);

  @cache(cacheKeyFn, 'clearGeometriesCache', 900)
  getSourceGeometries(sourceDataId: SourceDataId): RequestPromise<SourceGeometryType[]> {
    return setDataProcessor<SourceGeometryType[]>(this.request(METHOD.GET, '/' + sourceDataId + '/features/geometries'), (list) =>
      list.map((geom) => (geom in geometryMap && geometryMap[geom]) || geom),
    );
  }

  getCategoriesAggregation(sourceDataId: SourceDataId, filter?: AggregationFilter): RequestPromise<CategoryAggregation[]> {
    return this.request(METHOD.GET, '/' + sourceDataId + '/categories/aggregation', { ...filter });
  }

  getTimeline(searches?: SourceDataSearch[]): RequestPromise<SourceDataTimestamp[]> {
    return this.request(METHOD.POST, '/timeline/search', searches ?? []);
  }

  getRelations(sourceDataId: SourceDataId): RequestPromise<SourceDataRelation[]> {
    return this.request(METHOD.GET, '/' + sourceDataId + '/relations');
  }

  getVideoPreviewUrl = (sourceDataId: SourceDataId): Promise<string> =>
    this.request(METHOD.GET, '/' + sourceDataId + '/video/playback/uri');

  mapProperty(sourceDataId: SourceDataId, layerAttributes: LayerAttribute) {
    return this.request(METHOD.POST, '/' + sourceDataId + '/vector/properties/map-property', layerAttributes);
  }

  deleteProperty(sourceDataId: SourceDataId, name: string) {
    return this.request(METHOD.DELETE, '/' + sourceDataId + '/vector/properties/mappings/' + name);
  }

  getMapping(sourceDataId: SourceDataId): Promise<VectorPropertiesMappingResponseDTO> {
    return setDataProcessor(this.request(METHOD.GET, '/' + sourceDataId + '/vector/properties/mappings'), (res) => res || {});
  }

  resampling(options: ResamplingOptions): RequestPromise<ResamplingJob> {
    const { outputLayerName, originalLayerId, workspaceId, method, size } = options;
    const job: Partial<ResamplingJob> = {
      jobType: JobType.RESAMPLING,
      jobProperties: {
        jobPropertyType: JobPropertyType.RESAMPLING,
        width: size[0],
        height: size[1],
        outputLayerName,
        method,
        workspaceId,
      },
      originalLayerId,
    };
    return jobApi().create(job);
  }

  sarProcessing(options: SarProcessingOptions) {
    const { outputLayerName, originalLayerId, workspaceId, correction, dem, workflow } = options;
    const job: Partial<SarJob> = {
      jobType: JobType.SAR_BASIC,
      jobProperties: {
        jobPropertyType: JobPropertyType.SAR,
        workflow,
        terrain_correction: correction,
        outputLayerName,
        dem,
        workspaceId,
      },
      originalLayerId,
    };
    return jobApi().create(job);
  }

  coRegistration(options: ActionBaseOptions & WithReferenceLayer) {
    const { outputLayerName, referenceLayerId, originalLayerId, workspaceId } = options;
    const job: Partial<CoRegistrationJob> = {
      jobType: JobType.CO_REGISTRATION,
      jobProperties: {
        jobPropertyType: JobPropertyType.CO_REGISTRATION,
        outputLayerName,
        referenceLayerId,
        workspaceId,
      },
      originalLayerId,
    };
    return jobApi().create(job);
  }

  runChangeDetection(options: OpticalChangeDetectionOptions) {
    const { outputLayerName, referenceLayerId, originalLayerId, workspaceId, modelVersionId: modelId } = options;
    const job: Partial<OpticalChangeDetectionJob> = {
      jobType: JobType.OPTICAL_CHANGE_DETECTION,
      jobProperties: {
        jobPropertyType: JobPropertyType.OPTICAL_CHANGE_DETECTION,
        modelId,
        outputLayerName,
        referenceLayerId,
        workspaceId,
      },
      originalLayerId,
    };
    return jobApi().create(job);
  }

  runSarChangeDetection(options: SarChangeDetectionOptions) {
    const { outputLayerName, originalLayerId, referenceLayerId, workspaceId, dem, workflow } = options;
    const job: Partial<SarChangeDetectionJob> = {
      jobType: JobType.SAR_CHANGE_DETECTION,
      jobProperties: {
        jobPropertyType: JobPropertyType.SAR_CHANGE_DETECTION,
        referenceLayerId,
        workspaceId,
        workflow,
        outputLayerName,
        dem,
      },
      originalLayerId,
    };
    return jobApi().create(job);
  }

  runBandsMath(options: BandsMathOptions) {
    const { outputLayerName, originalLayerId, referenceLayerId, workspaceId, layerBands, formula } = options;
    const job: Partial<BandsMathJob> = {
      jobType: JobType.BANDS_MATH,
      jobProperties: {
        jobPropertyType: JobPropertyType.BANDS_MATH,
        referenceLayerId,
        workspaceId,
        outputLayerName,
        layerBands,
        formula,
      },
      originalLayerId,
    };
    return jobApi().create(job);
  }

  runMosaicking(options: MosaickingOptions) {
    const { outputLayerName, originalLayerId, workspaceId, layerIds } = options;
    const job: Partial<MosaickingJob> = {
      jobType: JobType.MOSAIC,
      jobProperties: {
        jobPropertyType: JobPropertyType.MOSAIC,
        workspaceId,
        outputLayerName,
        layerIds,
      },
      originalLayerId,
    };
    return jobApi().create(job);
  }

  private responseClearCachePromise = (res: any) => {
    this.clearCache();
    this.clearGetCache();
    this.clearFindCache();
    this.clearCountCache();
    return res;
  };

  private responseClearStyleCachePromise = (res: any) => {
    this.clearStyleCache();
    return res;
  };
}

export const dataSourceApi = () => DataSourceApi.getInstance();
