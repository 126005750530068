import { useMemo } from 'react';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CountFilter, dataSourceApi, Filter } from '@/cmd/DataSourceApi';
import { filterBy } from '@/utils/array';
import {
  ConvertRequestDTO,
  Job,
  JobId,
  JobStatus,
  SavedSourceDataSearch,
  SourceData,
  SourceDataId,
  SourceDataSearch,
  UploadedFileId,
} from '@/models';
import { DetectionsFilter, inferenceApi } from '@/cmd/InferenceApi';
import { videoInferenceApi } from '@/cmd/VideoInferenceApi';
import { PageableRequest, SORT_DIRECTION } from '@/cmd/Api';
import { annotationApi } from '@/cmd/AnnotationApi';
import { jobApi } from '@/cmd/JobApi';
import { minutesToMilliseconds, secondsToMilliseconds } from 'date-fns';
import { getInfinitePlaceholder, getNextPageParam, getPlaceholder, initialPageParam } from '@/hooks/queries/utils';
import useVirtuosoInfiniteQuery from '@/hooks/useVirtuosoInfiniteQuery';
import { AnnotationFilter } from '@/cmd/AnnotationProjectApi';
import { resourceApi } from '@/cmd/ResourceApi';

export const keys = {
  relations: (sourceId: SourceDataId) => ['source_data_relations', sourceId] as const,
  resource: (id: UploadedFileId) => ['resource', id] as const,
};

export const useInfiniteAnnotations = (sourceDataId?: SourceDataId, filter?: AnnotationFilter) => {
  return useVirtuosoInfiniteQuery({
    queryKey: ['annotations', sourceDataId, filter],
    queryFn: ({ pageParam }) =>
      annotationApi().getAnnotationsList(sourceDataId!, filter, {
        ...pageParam,
        pageSize: 5000,
        sort: 'categoryId,id',
        direction: SORT_DIRECTION.ASC,
      }),
    getNextPageParam,
    initialPageParam,
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(15),
    enabled: !!sourceDataId,
  });
};

export const useAnnotationCategoryAggregations = (sourceDataId: SourceDataId, search: string = '') => {
  const { data, ...rest } = useQuery({
    queryKey: ['annotation_category_aggregations', sourceDataId],
    queryFn: () => annotationApi().getCategoriesAggregation(sourceDataId),
  });
  const searchedData = useMemo(() => filterBy(search, 'name', data ?? []), [search, data]);

  return { data: searchedData, ...rest };
};

export const useInferenceCategoryAggregations = (sourceDataId: SourceDataId, isVideo: boolean = false, filter?: DetectionsFilter) =>
  useQuery({
    queryKey: ['inference_category_aggregations', sourceDataId, isVideo, filter],
    queryFn: () =>
      isVideo
        ? videoInferenceApi().getCategoriesAggregation(sourceDataId, filter)
        : inferenceApi().getCategoriesAggregation(sourceDataId, filter),
    staleTime: Infinity,
  });
export const useInfiniteSourceData = (filter?: Filter) => {
  return useVirtuosoInfiniteQuery({
    queryKey: ['source_data_list', filter],
    queryFn: ({ pageParam }) => dataSourceApi().getList(filter, pageParam),
    getNextPageParam,
    initialPageParam,
    staleTime: minutesToMilliseconds(15),
    placeholderData: getInfinitePlaceholder<SourceData>(20, true),
  });
};

export const useSourceData = (sourceId?: SourceDataId) => {
  return useQuery({
    queryKey: ['source_data', sourceId],
    queryFn: async () => {
      const result = await dataSourceApi().getList({ ids: [sourceId!] });
      return result.content[0];
    },
    placeholderData: keepPreviousData,
    staleTime: Infinity,
    enabled: !!sourceId,
  });
};

export const useSourceDataList = (filter?: Filter, pagination?: PageableRequest, enabled = true) => {
  return useQuery({
    queryKey: ['source_data_list', filter, pagination],
    queryFn: () => dataSourceApi().getList(filter, pagination),
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(15),
    enabled,
  });
};

export const useSourceDataListAll = (filter?: Filter) => {
  return useQuery({
    queryKey: ['source_data_list_all', filter],
    queryFn: () => dataSourceApi().getAll((page) => dataSourceApi().getList(filter, { page, pageSize: 50 })),
    placeholderData: getPlaceholder(20, false),
    staleTime: minutesToMilliseconds(15),
    enabled: !!filter,
  });
};

export const useDeleteSourceData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (sourceData: SourceData) => dataSourceApi().delete(sourceData.id),
    onSuccess: (data, variables) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['source_data_list'] }),
        queryClient.invalidateQueries({ queryKey: ['source_data_list_all'] }),
        queryClient.invalidateQueries({ queryKey: ['source_data', variables.id] }),
      ]);
    },
  });
};

export const useSourceDataRelations = (sourceId: SourceDataId, enabled = true) => {
  return useQuery({
    queryKey: keys.relations(sourceId),
    queryFn: () => dataSourceApi().getRelations(sourceId),
    // staleTime: minutesToMilliseconds(5),
    enabled,
  });
};

export const useSourceTypeCount = (filter: CountFilter) => {
  return useQuery({
    queryKey: ['source_type_count', filter],
    queryFn: () => dataSourceApi().getTypesCount(filter),
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(15),
  });
};

export const useSourceDataCount = (searches: SavedSourceDataSearch[]) => {
  return useQuery({
    queryKey: ['workspace_file_count', searches],
    queryFn: () => dataSourceApi().getCount(searches),
    placeholderData: keepPreviousData,
    staleTime: minutesToMilliseconds(15),
  });
};

export const jobKeys = {
  all: ['job'] as const,
  sources: () => [...jobKeys.all, 'source'] as const,
  source: (id: SourceDataId) => [...jobKeys.sources(), id] as const,
  single: (id?: JobId) => [...jobKeys.all, id] as const,
};

export const useAnalyticJob = (analyticJobId?: JobId) =>
  useQuery({
    queryKey: jobKeys.single(analyticJobId),
    queryFn: () => jobApi().getJob(analyticJobId!),
    refetchInterval: (query) =>
      !query.state.data?.status || [JobStatus.IN_PROGRESS, JobStatus.QUEUED].includes(query.state.data?.status)
        ? secondsToMilliseconds(30)
        : false,
    enabled: !!analyticJobId,
  });

export const useAnalyticJobsFromSource = (sourceData: SourceData) =>
  useQuery({
    queryKey: jobKeys.source(sourceData.id),
    queryFn: () => jobApi().getAll((page) => jobApi().getList({ originalLayerId: sourceData.id }, { page, pageSize: 100 })),
    // TODO: remove polling DRAG-3246
    refetchInterval: (query) =>
      query.state.data?.some(
        (job) =>
          !job?.status ||
          [JobStatus.IN_PROGRESS, JobStatus.QUEUED].includes(job?.status) ||
          (JobStatus.COMPLETED === job?.status && !job?.outputLayerId),
      )
        ? secondsToMilliseconds(30)
        : false,
    placeholderData: getPlaceholder<Job>(sourceData.incompleteJobsCount),
  });

export const useCreateAnalyticJob = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (analyticJob: Partial<Job>) => jobApi().create(analyticJob),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: jobKeys.all }),
  });
};

export const useChildrenSourceData = (sourceData: SourceData) => {
  return useQuery({
    queryKey: ['source_data_children', sourceData.id],
    queryFn: () => dataSourceApi().getAll((page) => dataSourceApi().search([{ parentId: sourceData.id }], { page, pageSize: 100 })),
    // staleTime: minutesToMilliseconds(15),
    enabled: sourceData.childrenCount > 0,
    placeholderData: getPlaceholder<SourceData>(sourceData.childrenCount, true),
  });
};

export const useSourceDataTimeline = (searches?: SourceDataSearch[]) => {
  return useQuery({
    queryKey: ['source_data_timeline', searches],
    queryFn: () => dataSourceApi().getTimeline(searches!),
    placeholderData: keepPreviousData,
    enabled: !!searches,
    staleTime: minutesToMilliseconds(15),
  });
};

export const useConvertAnnotation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: ConvertRequestDTO) => annotationApi().convert(request),
    onSuccess: (data, variables) => queryClient.invalidateQueries({ queryKey: ['source_data_children', variables.rasterSourceId] }),
  });
};

export const useGetResourceUrl = (id?: UploadedFileId) => {
  return useQuery({
    queryKey: keys.resource(id!),
    queryFn: () => resourceApi().getUrl(id!),
    enabled: !!id,
    staleTime: minutesToMilliseconds(15),
  });
};
