import { useContext } from 'react';
import { FileUploaderContext } from '@/hooks/provider/fileUploaderProvider';

const useFileUploader = () => {
  const ctx = useContext(FileUploaderContext);

  if (ctx === undefined) throw new Error('useFileUploader must be used within FileUploaderProvider');

  return ctx;
};

export default useFileUploader;
