import { ChangeEvent, DragEvent, FC, MutableRefObject, ReactNode, useRef, useState } from 'react';
import { Button, Card, Icon, styled, SxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { stopEvent } from '@/utils/dom';
import clsx from 'clsx';
import { extractFileExtension } from '@/utils/formatters';

const Layout = styled(Card, { shouldForwardProp: (prop) => prop !== 'hideBorder' })<{ disabled?: boolean; hideBorder?: boolean }>`
  grid-area: uploader;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  justify-content: center;
  align-content: center;
  border: ${({ hideBorder }) => (hideBorder ? 'none' : ' 1px dashed #17a7ff')};
  border-radius: 5px;
  min-height: 60px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &.draggingOver {
    background: linear-gradient(in oklab 135deg, #07f49e 0%, #42047e 100%);
    animation: onDragOver 5s ease infinite;
    background-size: 400% 400%;
  }}

  @keyframes onDragOver {
    0%{background-position:100% 50%}
    50%{background-position:70% 50%}
    100%{background-position:100% 50%}
  }
`;

interface CustomProps {
  onClick: (e: Event) => void;
}

interface Props {
  fileTypes?: string[];
  onChange: (files: File[]) => void;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  label?: string;
  disabled?: boolean;
  hideIcon?: boolean;
  hideButton?: boolean;
  hideBorder?: boolean;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  limit?: boolean;
}

const DropArea: FC<Props> = (props) => {
  const { fileTypes, onChange, disabled, label, hideBorder, hideButton, hideIcon, sx, children, limit } = props;
  const localInputRef = useRef<HTMLInputElement>(null);
  const inputRef = props.inputRef || localInputRef;
  const { t } = useTranslation(['upload', 'common']);
  const [draggingOver, setDraggingOver] = useState(false);
  const accept = fileTypes?.map((ext) => '.' + ext).join(',');

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    stopEvent(e);
    !disabled && setDraggingOver(true);
  };

  const handleDragEnd = (e: DragEvent<HTMLDivElement>) => {
    setDraggingOver(false);
  };

  const handleFiles = (files: FileList) => {
    const fileList = Array.from(!limit ? files : [files[0]]).filter((f) => {
      if (!fileTypes) {
        return true;
      }
      const ext = extractFileExtension(f.name);
      return !!ext && fileTypes.includes(ext);
    });
    if (fileList.length > 0) {
      onChange(fileList);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    stopEvent(e);
    e.persist();
    if (e.dataTransfer.files) {
      handleFiles(e.dataTransfer.files);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFiles(e.target.files);
    }
  };

  const onClick = () => {
    !disabled && inputRef.current && inputRef.current.click();
  };

  return (
    <Layout
      id="drop-area"
      className={clsx('uploader', { draggingOver })}
      elevation={0}
      onDragOver={handleDragOver}
      onDragLeave={handleDragEnd}
      onDropCapture={handleDragEnd}
      {...(!disabled && { onDrop: handleDrop })}
      hideBorder={hideBorder}
      onClick={onClick}
      disabled={disabled}
      sx={sx}
    >
      <>
        <input hidden accept={accept} multiple={!limit} type="file" onChange={handleChange} ref={inputRef} />
        {children ? (
          children
        ) : (
          <>
            {!hideIcon && (
              <Icon color={disabled ? 'disabled' : 'secondary'} sx={{ fontSize: 52 }}>
                cloud_upload
              </Icon>
            )}
            <Typography sx={{ whiteSpace: 'pre-line', textAlign: 'center' }} color={disabled ? 'text.disabled' : 'text.primary'}>
              {label ? label : t('upload:drag_drop')}{' '}
            </Typography>
            {!hideButton && (
              <Button variant="contained" component="label" size="small" disabled={disabled}>
                {t('browse')}
              </Button>
            )}
          </>
        )}
      </>
    </Layout>
  );
};

export default DropArea;
