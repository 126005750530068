import { FC, useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Icon, IconButton, MenuItem, SelectChangeEvent, styled, Tooltip, Typography } from '@mui/material';
import { DataMappingPresetId, FileUpload, MapTool, RasterSourceType, UploadStatus, WorkspaceId } from '@/models';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { formatByteSize } from '@/utils';
import LinearProgressWithLabel from '@/components/LinearProgressWithLabel';
import {
  COMPRESSED_FILE_FORMATS,
  DATA_MAPPING_FORMATS,
  DEFAULT_TRANSLATIONS,
  DOCUMENT_AND_VIDEO_FORMATS,
  MANUAL_TYPE_SELECTION_FORMATS,
  WGS,
} from '@/consts';
import { Select } from '@/components/Select';
import Selector from '@/components/Map/Selector';
import { Geometry } from '@/utils/geometry';
import { mapApi } from '@/cmd/MapApi';
import { getWKTType, parseFromWKT } from '@/utils/geometry/Geometry';
import { copyToClipboard, readClipboard } from '@/utils/clipboard';
import { coordinatesToPoint } from '@/utils/coordinates';
import { getStatusColor, getStatusIcon } from '@/utils/upload';
import useFileUploader from '@/hooks/useFileUploader';
import { FileItemProps } from '@/components/Uploader';
import Button from '@/components/Button';
import { useDataMappingPresets } from '@/components/FileUpload/DataMapping/queries';

const FileIcon = styled('div')(({ theme }) => ({
  backgroundColor: theme.vars.palette.secondary.main,
  borderRadius: 4,
  width: 60,
  height: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
}));

const Layout = styled('div')`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-block: ${({ theme }) => theme.spacing(1.5)};

  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    width: 326px;
    gap: 0;

    &:has(.MuiFormControl-root) {
      gap: ${({ theme }) => theme.spacing(1)};
    }

    .MuiTypography-root {
      flex: 1;
      white-space: nowrap;
      display: flex;
      gap: ${({ theme }) => theme.spacing(1)};

      span:first-of-type {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &:has(img) .container {
    width: 258px;
  }

  .geo-actions {
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing(2)};
    padding-inline: ${({ theme }) => theme.spacing(4)};
  }
`;

interface AddWorkspaceProps {
  file: FileUpload;
  workspaceId?: WorkspaceId;
  onChange: (file: FileUpload, state: boolean) => void;
}

const AddToWorkspace: FC<AddWorkspaceProps> = ({ file, workspaceId, onChange }) => {
  const { t } = useTranslation(DEFAULT_TRANSLATIONS);

  return (
    <FormControlLabel
      checked={!!file.layerProperties?.workspaceId}
      onChange={(_, checked) => onChange(file, checked)}
      control={<Checkbox />}
      label={t('common:add_to_workspace')}
      disabled={file.status !== UploadStatus.NOT_STARTED}
    />
  );
};

export const FileItem: FC<FileItemProps> = ({ file, workspaceId }) => {
  const { t } = useTranslation(DEFAULT_TRANSLATIONS);
  const {
    setSourceType,
    setGeometry,
    updateStatus,
    removeFileUpload,
    toggleOpenDataMappingDialog,
    setFileToMapName,
    setDataMapping,
    addToWorkspace,
  } = useFileUploader();
  const { data: presets } = useDataMappingPresets();
  const [showBoundary, setShowBoundary] = useState(false);
  const [geometryFromClipboard, setGeometryFromClipboard] = useState<Geometry>();
  const toggleSetShowBoundary = () => setShowBoundary(!showBoundary);

  const checkClipboard = () =>
    readClipboard().then((text) => {
      const geometry = getWKTType(text) && parseFromWKT(text, WGS);
      setGeometryFromClipboard(geometry || coordinatesToPoint(text));
    });

  useEffect(() => {
    void checkClipboard();
    const copyEvtFn = () => checkClipboard();

    document.addEventListener('copy', copyEvtFn);
    return () => document.removeEventListener('copy', copyEvtFn);
  }, []);

  const handleChange = (e: any) => {
    const { value } = e.target;
    setSourceType(file, value as RasterSourceType);
  };

  const handleRemoveLocation = () => setGeometry(file, undefined);
  const handleRemove = () => {
    removeFileUpload(file);
  };

  const handleCancel = () => {
    updateStatus(file, UploadStatus.CANCELLED);
  };

  const handleRetry = () => {
    updateStatus(file, UploadStatus.CANCELLED);
    updateStatus(file, UploadStatus.QUEUED);
  };

  const showSourceTypeSelect = file.status === UploadStatus.NOT_STARTED && MANUAL_TYPE_SELECTION_FORMATS.includes(file.ext);
  const showDataMapping = file.status === UploadStatus.NOT_STARTED && DATA_MAPPING_FORMATS.includes(file.ext);

  const saveToClipboard = () => {
    if (file.geometry) {
      copyToClipboard(file.geometry.toWKT());
      setGeometryFromClipboard(file.geometry);
    }
  };

  const copyFromClipboard = () => setGeometry(file, geometryFromClipboard);

  const handleMapping = () => {
    setFileToMapName(file.name);
    toggleOpenDataMappingDialog();
  };

  const handlePresetChange = (e: SelectChangeEvent<DataMappingPresetId>) => {
    const preset = presets?.find((p) => p.id === e.target.value);
    if (preset) {
      setDataMapping(file, preset);
    }
  };

  const addToWorkspaceHandler = (file: FileUpload, state: boolean) => addToWorkspace(file, state ? workspaceId : undefined);

  const hasDataMapping = !!file.dataMappingInfo?.dataMapping;

  return (
    <>
      <Layout>
        {file.preview ? (
          <Image src={file.preview} width={60} height={60} style={{ borderRadius: 4 }} alt={file.name} />
        ) : (
          <FileIcon>
            <Icon sx={{ color: 'secondary.contrastText' }}>
              {COMPRESSED_FILE_FORMATS.includes(file.ext) ? 'folder_zip' : 'file_present'}
            </Icon>
          </FileIcon>
        )}

        <div className="container">
          <Typography variant="body2" color="text.primary">
            <span title={file.name}>{file.name}</span>
            <span>•</span>
            <span>{formatByteSize(file.size, 2)}</span>
          </Typography>
          <Box display="flex" gap={2} height="inherit" alignItems="center">
            {showSourceTypeSelect && (
              <Select
                required
                label={t('common:source_type')}
                value={file.sourceType ?? ''}
                onChange={handleChange}
                sx={{ width: '220px' }}
              >
                {Object.values(RasterSourceType).map((v) => (
                  <MenuItem key={v} value={v}>
                    {t('common:source_type_' + v)}
                  </MenuItem>
                ))}
              </Select>
            )}
            {showDataMapping && (
              <>
                <Select
                  label={t('upload:data_mapping_presets')}
                  value={file.dataMappingInfo?.id ?? ''}
                  onChange={handlePresetChange}
                  sx={{ width: '220px' }}
                >
                  {(presets?.length ?? 0) > 0 ? (
                    presets?.map((preset) => (
                      <MenuItem key={preset.id} value={preset.id} disabled={!preset.dataMapping}>
                        {preset.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">None</MenuItem>
                  )}
                </Select>
                <Tooltip title={t(hasDataMapping ? 'upload:edit_data_mapping' : 'upload:new_data_mapping')}>
                  <Button variant="icon" color="action" onClick={handleMapping}>
                    <Icon>{hasDataMapping ? 'edit_square' : 'add'}</Icon>
                  </Button>
                </Tooltip>
                {hasDataMapping && (
                  <Typography variant="body2" color="success.main">
                    {t('upload:mapping_applied')}
                  </Typography>
                )}
              </>
            )}
          </Box>
          {!!workspaceId && <AddToWorkspace file={file} workspaceId={workspaceId} onChange={addToWorkspaceHandler} />}
          {file.status !== UploadStatus.NOT_STARTED && (
            <>
              <LinearProgressWithLabel
                color="primary"
                variant={file.progress === 100 && file.status === UploadStatus.UPLOADING ? 'indeterminate' : 'determinate'}
                value={file.progress || 0}
                sx={{ width: '100%' }}
              />
              <Box display="flex" alignItems="center" gap={1}>
                <Icon fontSize="small" color={getStatusColor(file.status)}>
                  {getStatusIcon(file.status)}
                </Icon>
                <Typography variant="body2" sx={{ color: `${getStatusColor(file.status)}.main` }}>
                  {t('upload:' + (file.progress === 100 && file.status === UploadStatus.UPLOADING ? 'processing' : file.status))}
                  {file.statusDetails && `: ${file.statusDetails}`}
                </Typography>
              </Box>
            </>
          )}
        </div>

        {file.status === UploadStatus.NOT_STARTED && (
          <>
            {DOCUMENT_AND_VIDEO_FORMATS.includes(file.ext) && (
              <div className="geo-actions">
                {file.geometry && (
                  <Tooltip title={t('upload:edit_geolocation')}>
                    <img
                      style={{ borderRadius: 4, cursor: 'pointer' }}
                      src={file.geometry && mapApi().getBaseMapThumbnailUrl(60, file.geometry)}
                      onClick={toggleSetShowBoundary}
                      alt={t('upload:edit_geolocation')}
                    />
                  </Tooltip>
                )}

                {file.geometry ? (
                  <Tooltip title={t('common:copy_to_clipboard')}>
                    <IconButton onClick={saveToClipboard}>
                      <Icon>content_copy</Icon>
                    </IconButton>
                  </Tooltip>
                ) : (
                  geometryFromClipboard && (
                    <Tooltip title={t('common:paste_from_clipboard')}>
                      <IconButton onClick={copyFromClipboard}>
                        <Icon>content_paste</Icon>
                      </IconButton>
                    </Tooltip>
                  )
                )}

                {file.geometry ? (
                  <Tooltip title={t('upload:clear_geometry')}>
                    <IconButton onClick={handleRemoveLocation} color="error">
                      <Icon>wrong_location</Icon>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={t('upload:set_geolocation')}>
                    <IconButton onClick={toggleSetShowBoundary}>
                      <Icon>pin_drop</Icon>
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}

            <Tooltip title={t('common:remove')}>
              <IconButton size="small" onClick={handleRemove}>
                <Icon>do_not_disturb_on</Icon>
              </IconButton>
            </Tooltip>
          </>
        )}
        {[UploadStatus.QUEUED, UploadStatus.UPLOADING].includes(file.status) && file.progress !== 100 && (
          <Tooltip title={t('common:cancel')}>
            <IconButton onClick={handleCancel}>
              <Icon>cancel</Icon>
            </IconButton>
          </Tooltip>
        )}
        {[UploadStatus.FAILED, UploadStatus.CANCELLED].includes(file.status) && (
          <>
            <Tooltip title={t('upload:retry')}>
              <IconButton onClick={handleRetry}>
                <Icon>refresh</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title={t('common:remove')}>
              <IconButton size="small" onClick={handleRemove}>
                <Icon>do_not_disturb_on</Icon>
              </IconButton>
            </Tooltip>
          </>
        )}
        {[UploadStatus.SKIPPED].includes(file.status) && (
          <>
            <Tooltip title={t('common:remove')}>
              <IconButton size="small" onClick={handleRemove}>
                <Icon>do_not_disturb_on</Icon>
              </IconButton>
            </Tooltip>
          </>
        )}
      </Layout>
      {showBoundary && (
        <Selector
          geometry={file.geometry}
          onSelect={(geo) => geo && setGeometry(file, geo)}
          onClose={toggleSetShowBoundary}
          tool={MapTool.POINT}
        />
      )}
    </>
  );
};
