import { Color, Framebuffer, Texture } from 'cesium';
import { Appearance, ComponentDatatype, defined, Geometry, GeometryAttribute, GeometryAttributes, Math } from 'cesium';
import type { SourceData } from '@/models';
import { getObjectEntries } from '@/utils';
import { ColorBy } from './Particle3D';

export type DimensionData = {
  array: Float32Array;
  min: number;
  max: number;
};

type DimensionValue = {
  array: Float32Array;
  max: number;
  min: number;
};

export interface NetCDFFieldsMapping {
  U: string; // Lateral speed
  V: string; // Longitudinal velocity
  W: string; // Vertical velocity
  H: string; // Height property
  lon: string;
  lat: string;
  lev: string;
}

export interface ParticlesOptions {
  maxParticles: number;
  particleHeight: number;
  fadeOpacity: number;
  dropRate: number;
  dropRateBump: number;
  speedFactor: number;
  lineWidth: number;
  dynamic: boolean;
}

export interface OperatingParams extends Required<ParticlesOptions> {
  particlesTextureSize: number;
}

export type NetCDFWindOptions = {
  input: JsonData | Blob;
  type?: 'json' | 'file';
  fields?: NetCDFFieldsMapping;
  valueRange?: { min: number; max: number };
  offset?: {
    lon?: number;
    lat?: number;
    lev?: number;
  };
  userInput?: ParticlesOptions;
  colorTable?: number[][];
  colour?: ColorBy;
};

export type JsonData = {
  /** The number of intervals between values in three dimensions */
  dimensions: {
    lon: number;
    lat: number;
    lev?: number;
  };
  /** All longitude values */
  lon: DimensionData;
  /** All latitude values */
  lat: DimensionData;
  /** All height stratification */
  lev?: DimensionData;
  /** Lateral speed */
  U: DimensionValue;
  /** Longitudinal speed */
  V: DimensionValue;
  /** Vertical speed */
  W?: DimensionValue;
  /** Height value */
  H?: DimensionValue;
};

const fieldsAutoDetectionMap: Record<keyof NetCDFFieldsMapping, string[]> = {
  U: ['u', 'U', 'water_u'],
  V: ['v', 'V', 'water_v'],
  W: ['w', 'W'],
  H: ['h', 'H'],
  lon: ['lon', 'Lon', 'LON', 'longitude', 'Longitude'],
  lat: ['lat', 'Lat', 'LAT', 'latitude', 'Latitude'],
  lev: ['lev', 'Lev', 'LEV', 'level', 'Level'],
};

export const getNetCDFVariables = (sourceData?: SourceData): string[] => Object.keys(sourceData?.netCDFProperties?.variables || {});

export const getNetCDFFields = (sourceData?: SourceData, emptyValue = ''): NetCDFFieldsMapping => {
  const fieldsMap = {} as NetCDFFieldsMapping;
  const variablesKeys = getNetCDFVariables(sourceData);
  getObjectEntries(fieldsAutoDetectionMap).forEach(([key, possibleKeys]) => {
    fieldsMap[key] = possibleKeys.find((possibleKey) => variablesKeys.find((k) => possibleKey === k)) || emptyValue;
  });
  return fieldsMap;
};

export const convertColorTable = (colorTable: string[]): number[][] =>
  colorTable.map((c) => Color.fromCssColorString(c).toBytes().slice(0, -1));

export default (function () {
  const getFullscreenQuad = function () {
    const options = {
      position: new GeometryAttribute({
        componentDatatype: ComponentDatatype.FLOAT,
        componentsPerAttribute: 3,
        //  v3----v2
        //  |     |
        //  |     |
        //  v0----v1
        values: new Float32Array([
          -1,
          -1,
          0, // v0
          1,
          -1,
          0, // v1
          1,
          1,
          0, // v2
          -1,
          1,
          0, // v3
        ]),
      }),
      st: new GeometryAttribute({
        componentDatatype: ComponentDatatype.FLOAT,
        componentsPerAttribute: 2,
        values: new Float32Array([0, 0, 1, 0, 1, 1, 0, 1]),
      }),
    };

    return new Geometry({
      // @ts-ignore
      attributes: new GeometryAttributes(options),
      indices: new Uint32Array([3, 2, 0, 0, 2, 1]),
    });
  };

  const createTexture = function (options: any, typedArray?: any) {
    if (defined(typedArray)) {
      // typed array needs to be passed as source option, this is required by Texture
      const source: any = {};
      source.arrayBufferView = typedArray;
      options.source = source;
    }
    return new Texture(options);
  };

  const createFramebuffer = function (context: any, colorTexture: any, depthTexture: any) {
    return new Framebuffer({
      context,
      colorTextures: [colorTexture],
      depthTexture,
    });
  };

  const createRawRenderState = function (options: any) {
    const translucent = true;
    const closed = false;
    const existing = {
      viewport: options.viewport,
      depthTest: options.depthTest,
      depthMask: options.depthMask,
      blending: options.blending,
    };

    return (Appearance as any).getDefaultRenderState(translucent, closed, existing);
  };

  const viewRectangleToLonLatRange = function (viewRectangle: any) {
    const range: any = {};

    const postiveWest = Math.mod(viewRectangle.west, Math.TWO_PI);
    const postiveEast = Math.mod(viewRectangle.east, Math.TWO_PI);
    const width = viewRectangle.width;

    let longitudeMin;
    let longitudeMax;
    if (width > Math.THREE_PI_OVER_TWO) {
      longitudeMin = 0.0;
      longitudeMax = Math.TWO_PI;
    } else {
      if (postiveEast - postiveWest < width) {
        longitudeMin = postiveWest;
        longitudeMax = postiveWest + width;
      } else {
        longitudeMin = postiveWest;
        longitudeMax = postiveEast;
      }
    }

    range.lon = {
      min: Math.toDegrees(longitudeMin),
      max: Math.toDegrees(longitudeMax),
    };

    const south = viewRectangle.south;
    const north = viewRectangle.north;
    const height = viewRectangle.height;

    const extendHeight = height > Math.PI / 12 ? height / 2 : 0;
    let extendedSouth = Math.clampToLatitudeRange(south - extendHeight);
    let extendedNorth = Math.clampToLatitudeRange(north + extendHeight);

    // extend the bound in high latitude area to make sure it can cover all the visible area
    if (extendedSouth < -Math.PI_OVER_THREE) {
      extendedSouth = -Math.PI_OVER_TWO;
    }
    if (extendedNorth > Math.PI_OVER_THREE) {
      extendedNorth = Math.PI_OVER_TWO;
    }

    range.lat = {
      min: Math.toDegrees(extendedSouth),
      max: Math.toDegrees(extendedNorth),
    };

    return range;
  };

  return {
    getFullscreenQuad,
    createTexture,
    createFramebuffer,
    createRawRenderState,
    viewRectangleToLonLatRange,
  };
})();
