import Link from '@/components/Link';
import { DropDownMenu } from '@/components/DropDownMenu';
import { Icon, IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip, useTheme } from '@mui/material';
import { getConfig } from '@/config';
import { useTranslation } from 'next-i18next';
import ContactForm from '@/components/ContactForm';
import useToggle from '@/hooks/useToggle';

const config = getConfig();

const HelpMenu = () => {
  const { t } = useTranslation('common');
  const [open, toggleOpen] = useToggle();
  const theme = useTheme();

  const handleContactUs = () => {
    toggleOpen();
  };

  return (
    <>
      <DropDownMenu
        opener={
          <Tooltip title={t('common:help')} placement="right">
            <IconButton sx={{ color: 'text.primary' }}>
              <Icon>help</Icon>
            </IconButton>
          </Tooltip>
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: theme.direction === 'rtl' ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: theme.direction === 'rtl' ? 'right' : 'left',
        }}
        sx={{
          ...(theme.direction === 'rtl' ? { marginInlineEnd: -5 } : { marginInlineStart: 5 }),
        }}
      >
        {!!config.documentationUrl && (
          <Link href={config.documentationUrl} color="text.primary" underline="none">
            <MenuItem>
              <ListItemIcon>
                <Icon>open_in_new</Icon>
              </ListItemIcon>
              <ListItemText>{t('common:documentation')}</ListItemText>
            </MenuItem>
          </Link>
        )}
        <Link href="/help" color="text.primary" underline="none">
          <MenuItem>
            <ListItemIcon>
              <Icon>info</Icon>
            </ListItemIcon>
            <ListItemText>{t('common:faq')}</ListItemText>
          </MenuItem>
        </Link>
        <MenuItem onClick={handleContactUs}>
          <ListItemIcon>
            <Icon>mail</Icon>
          </ListItemIcon>
          <ListItemText>{t('common:contact_support')}</ListItemText>
        </MenuItem>
      </DropDownMenu>
      <ContactForm open={open} onClose={toggleOpen} />
    </>
  );
};

export default HelpMenu;
