import { forwardRef } from 'react';
import { Divider, ListItem, ListItemProps } from '@mui/material';
import { HTMLMotionProps, m } from 'framer-motion';
import { EASING } from '@/theme';

const item = {
  hidden: { y: 0, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.4, easing: EASING.EMPHASIZED_DECELERATE },
  },
};

export const MotionListItemWithPadding = forwardRef<HTMLLIElement, ListItemProps & HTMLMotionProps<'li'>>(
  function MotionListItem(props, ref) {
    return <ListItem ref={ref} {...props} component={m.li} variants={item} />;
  },
);

export const MotionListItemWithPaddingY = forwardRef<HTMLLIElement, ListItemProps & HTMLMotionProps<'li'>>(
  function MotionListItem(props, ref) {
    return (
      <ListItem
        ref={ref}
        {...props}
        component={m.li}
        variants={item}
        sx={{
          py: 2,
          '&:nth-of-type(odd)': {
            backgroundColor: 'action.hover',
          },
        }}
      />
    );
  },
);

export const MotionListItem = forwardRef<HTMLLIElement, ListItemProps & HTMLMotionProps<'li'>>(function MotionListItem(props, ref) {
  return <ListItem disablePadding ref={ref} {...props} component={m.li} variants={item} />;
});

export const MotionDenseListItem = forwardRef<HTMLLIElement, ListItemProps & HTMLMotionProps<'li'>>(function MotionListItem(props, ref) {
  return <ListItem disablePadding dense ref={ref} {...props} component={m.li} variants={item} />;
});

export const MotionListItemWithDivider = forwardRef<HTMLLIElement, ListItemProps & HTMLMotionProps<'li'>>(
  function MotionListItem(props, ref) {
    return (
      <>
        <ListItem disablePadding ref={ref} {...props} component={m.li} variants={item} />
        <Divider component={m.li} sx={{ marginInlineStart: 2 }} variants={item} />
      </>
    );
  },
);

export const MotionListItemWithDividerPadding = forwardRef<HTMLLIElement, ListItemProps & HTMLMotionProps<'li'>>(
  function MotionListItem(props, ref) {
    return (
      <>
        <ListItem ref={ref} {...props} component={m.li} variants={item} />
        <Divider component={m.li} sx={{ marginInlineStart: 2 }} variants={item} />
      </>
    );
  },
);
