import { singleton } from '@/utils/decorators/singleton';
import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, RequestPromise } from '@/cmd/Api';

@singleton
class CountryApi extends Api {
  endpoint: string = '/api/country';
  static getInstance: () => CountryApi;

  getCountryCodes(): RequestPromise<PageableResponse<string>> {
    return this.request(METHOD.GET, '/codes', {});
  }
}

export const countryApi = () => CountryApi.getInstance();
