// noinspection JSUnusedGlobalSymbols

import { Point, Circle } from '.';

export class SimpleLine {
  a: number = 0;
  b: number = 0;
  c: number = 0;
  p1: Point = Point.Zero();
  p2: Point = Point.Zero();

  constructor(a: number = 0, b: number = 0, c: number = 0, p1: Point = Point.Zero(), p2: Point = Point.Zero()) {
    this.a = a;
    this.b = b;
    this.c = c;
    this.p1 = p1;
    this.p2 = p2;
  }

  clone(): SimpleLine {
    return new SimpleLine(this.a, this.b, this.c, this.p1.clone(), this.p2.clone());
  }

  findPerpendicular(p: Point) {
    return new SimpleLine(-this.b, this.a, this.b * p.x - this.a * p.y);
  }

  perpendicularDistance(p: Point) {
    const perpendicularLine = this.findPerpendicular(p);
    const targetPoint = perpendicularLine.findPoint(this);
    return targetPoint!.distance(p);
  }

  intersection(l: SimpleLine, d: number = 0): Point | null {
    const p = this.findPoint(l);
    if (p) {
      return this.inRange(p, d) && l.inRange(p, d) ? p : null;
    } else {
      return null;
    }
  }

  intersectionWithCircle(circle: Circle): Point | [Point, Point] | null {
    const { center, radius } = circle;
    const per = this.findPerpendicular(center);
    const t = this.intersection(per, Infinity)!;
    const distance = t.distance(center);
    if (distance < radius) {
      const { a, b, c } = this;
      const s = a * a + b * b;
      const d = Math.sqrt(radius * radius - (c * c) / s);
      const mult = Math.sqrt((d * d) / s);
      const r1 = t.move(b * mult, -a * mult);
      const r2 = t.move(-b * mult, a * mult);
      return [r1, r2];
    }
    if (distance === radius) {
      return t;
    }
    return null;
  }

  inRange(p: Point, d: number = 0): boolean {
    const { minX, minY, maxX, maxY } = this;
    const { x, y } = p;
    const isInX = (x >= minX && x <= maxX) || Math.abs(minX - x) < d || Math.abs(maxX - x) < d;
    const isInY = (y >= minY && y <= maxY) || Math.abs(minY - y) < d || Math.abs(maxY - y) < d;
    return isInX && isInY;
  }

  get minX(): number {
    return Math.min(this.p1.x, this.p2.x);
  }

  get minY(): number {
    return Math.min(this.p1.y, this.p2.y);
  }

  get maxX(): number {
    return Math.max(this.p1.x, this.p2.x);
  }

  get maxY(): number {
    return Math.max(this.p1.y, this.p2.y);
  }

  toString() {
    return `(${this.a}, ${this.b}, ${this.c})`;
  }

  getValue() {
    return [this.a, this.b, this.c];
  }

  x(p: Point): Point {
    if (this.isParallelY) {
      return new Point(-this.c / this.a, p.y);
    }
    if (this.isParallelX) {
      return new Point(p.x, -this.c / this.b);
    }
    return new Point((-this.b / this.a) * p.y - this.c / this.a, p.y);
  }

  y(p: Point): Point {
    if (this.isParallelY) {
      return new Point(-this.c / this.a, p.y);
    }
    if (this.isParallelX) {
      return new Point(p.x, -this.c / this.b);
    }
    return new Point(p.x, (-this.a / this.b) * p.x - this.c / this.b);
  }

  findPoint(l: SimpleLine): Point | null {
    if (this.isParallelY && l.isParallelY) {
      return null;
    }
    if (this.isParallelX && l.isParallelX) {
      return null;
    }
    if (this.isParallelX && l.isParallelY) {
      return new Point(-l.c / l.a, -this.c / this.b);
    }
    if (this.isParallelY && l.isParallelX) {
      return new Point(-this.c / this.a, -l.c / l.b);
    }
    if (this.isParallelY) {
      const x = -this.c / this.a;
      return l.y(new Point(x));
    }
    if (this.isParallelX) {
      const y = -this.c / this.b;
      return l.x(new Point(0, y));
    }
    if (l.isParallelY) {
      const x = -l.c / l.a;
      return this.y(new Point(x));
    }
    if (l.isParallelX) {
      const y = -l.c / l.b;
      return this.x(new Point(0, y));
    }
    return this.y(new Point((l.c / l.b - this.c / this.b) / (this.a / this.b - l.a / l.b)));
  }

  get isParallel(): boolean {
    return this.isParallelX || this.isParallelY;
  }

  get isParallelY(): boolean {
    return this.b === 0;
  }

  get isParallelX(): boolean {
    return this.a === 0;
  }

  get points(): [Point, Point] {
    return [this.p1, this.p2];
  }

  getFi(): number {
    const l: SimpleLine = new SimpleLine(0, 1, 0);
    const result = this.findFi(l);
    if (!this.p1 || !this.p2) {
      return result;
    }
    if (this.p1.x === this.p2.x) {
      if (this.p1.y === this.p2.y) {
        return 0;
      }
      if (this.p1.y < this.p2.y) {
        return (Math.PI * 3) / 2;
      }
      if (this.p1.y > this.p2.y) {
        return Math.PI / 2;
      }
    }
    if (this.p1.x < this.p2.x) {
      if (this.p1.y === this.p2.y) {
        return 0;
      }
      if (this.p1.y < this.p2.y) {
        return Math.PI - result + Math.PI;
      }
      if (this.p1.y > this.p2.y) {
        return result;
      }
    }
    if (this.p1.x > this.p2.x) {
      if (this.p1.y === this.p2.y) {
        return Math.PI;
      }
      if (this.p1.y < this.p2.y) {
        return Math.PI - result + Math.PI;
      }
      if (this.p1.y > this.p2.y) {
        return result;
      }
    }
    return 0;
  }

  toWKT(): string {
    const {
      p1: { x: x1, y: y1 },
      p2: { x: x2, y: y2 },
    } = this;
    return `LINESTRING (${x1} ${y1}, ${x2} ${y2})`;
  }

  movePoint(p: Point, d: number) {
    const fi = this.findFi(new SimpleLine(1, 0, 0));
    const sign = d < 0 ? -1 : 1;
    return new Point(p.x + sign * Math.abs(d * Math.sin(fi)), p.y + sign * Math.abs(d * Math.cos(fi)));
  }

  findFi(l: SimpleLine): number {
    let val = (this.a * l.a + this.b * l.b) / (Math.sqrt(this.a * this.a + this.b * this.b) * Math.sqrt(l.a * l.a + l.b * l.b));
    if (val > 1 && val < 1.0001) {
      val = 1;
    } else if (val < -1 && val > -1.0001) {
      val = -1;
    }
    return Math.acos(val);
  }

  vectorProduct(l: SimpleLine): SimpleLine {
    const i = this.b * l.c - this.c * l.b;
    const j = this.c * l.a - this.a * l.c;
    const k = this.a * l.b - this.b * l.a;
    return new SimpleLine(i, j, k);
  }
}
