import { useTenantSettings, useUserSettings } from '@/hooks/useSettings';
import { SupportedColorScheme, useColorScheme as useMuiColorScheme } from '@mui/material/styles';
import { ColorSchemeContextValue } from '@mui/system';
import { Settings, UserSettings } from '@/models';
import { useSessionInfo } from '@/utils/session';

export const useColorScheme = (): ColorSchemeContextValue<SupportedColorScheme> & {
  userSessionColorScheme: UserSettings['colorScheme'] | undefined;
  userSettingsColorScheme: UserSettings['colorScheme'] | undefined;
  tenantSettingsColorScheme: Settings['colorScheme'] | undefined;
  isLoading: boolean;
} => {
  const sessionInfo = useSessionInfo();
  const { data: userSettings, isLoading: userLoading } = useUserSettings(!!sessionInfo?.id);
  const { data: tenantSettings, isLoading: tenantLoading } = useTenantSettings(sessionInfo?.tenantId);
  const muiColorScheme = useMuiColorScheme();

  return {
    ...muiColorScheme,
    userSessionColorScheme: sessionInfo?.settings?.colorScheme,
    userSettingsColorScheme: userSettings?.colorScheme,
    tenantSettingsColorScheme: tenantSettings?.colorScheme,
    isLoading: userLoading || tenantLoading,
  };
};
