import { singleton } from '@/utils/decorators/singleton';
import { Job, JobId, SourceDataId } from '@/models';
import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, RequestPromise } from './Api';

export interface Filter {
  originalLayerId: SourceDataId;
}

@singleton
class JobApi extends Api {
  endpoint: string = '/api/analyticsjob';
  static getInstance: () => JobApi;

  getList(filter?: Filter, pagination?: PageableRequest): RequestPromise<PageableResponse<Job>> {
    return this.request(METHOD.GET, '', { ...filter, ...DEFAULT_PAGINATION, pageSize: 100 });
  }

  getJob = (jobId: JobId): RequestPromise<Job> => this.request(METHOD.GET, '/' + jobId);

  create<T = Job>(job: Partial<T>): RequestPromise<T> {
    return this.request(METHOD.POST, '', job);
  }

  delete(jobId: JobId) {
    return this.request(METHOD.DELETE, '/' + jobId);
  }
}

export const jobApi = () => JobApi.getInstance();
