import { CesiumEvent } from '@/components/Cesium/Event';
import { SourceData, SourceDataId } from '@/models';

export class AbstractSourceDataLayer {
  readonly id: SourceDataId;
  readonly sourceData: SourceData;
  readonly loadingEvent = new CesiumEvent();
  protected _isLoading: boolean = false;

  constructor(sourceData: SourceData) {
    this.id = sourceData.id;
    this.sourceData = sourceData;
  }

  set isLoading(value: boolean) {
    if (value !== this._isLoading) {
      this._isLoading = value;
      this.loadingEvent.raiseEvent(value as any);
    }
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  destroy() {}
}
