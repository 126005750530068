import { singleton } from '@/utils/decorators/singleton';
import { Api, METHOD, prepareParams, RequestPromise } from './Api';
import type { SourceData, SourceDataId, SourceDataPixelInfo, UrlString, WKT } from '@/models';
import { setDataProcessor } from '@/utils/CustomPromise';
import { Geometry, GeometryType, getPolygon, parseFromWKT, Point, Polygon, Size } from '@/utils/geometry';
import { BASE_LAYER, getThumbnailUrl } from '@/components/Cesium/tileServer';
import { cache, cacheKeyFn } from '@/utils/decorators/cache';
import { getDefaultBandsMapping } from '@/components/Map/utils';

const POINT_THUMBNAIL_SIZE = 1; // Degrees

export type BandHistogramData = {
  counts: number[];
  normalized: number[];
  minNormalized: number;
  maxNormalized: number;
  index: number;
  min: number;
  max: number;
};
type BandsHistogramResponseObjectData = { [key: string]: BandHistogramData };
type BandsHistogramResponseArrayData = BandHistogramData[];
type BandsHistogramRawData = BandsHistogramResponseObjectData | BandsHistogramResponseArrayData;
type BandsHistogramResponse = { bandHistogramMap: BandsHistogramRawData; min: number; max: number };

@singleton
class MapApi extends Api {
  endpoint: string = '/api/map';
  static getInstance: () => MapApi;

  getThumbnail(sourceDataId: SourceDataId, size: Size, bbox: Polygon | WKT): RequestPromise<Blob> {
    return setDataProcessor(
      this.rawRequest<Blob>(METHOD.GET, this.getThumbnailUrl(sourceDataId, size, bbox), undefined, { responseType: 'blob' }),
      (res) => res.data,
    );
  }

  getThumbnailUrl = (source: SourceDataId | SourceData, size: Size, bbox: Polygon | WKT): UrlString => {
    const { minX, minY, maxX, maxY } = getPolygon(bbox);
    const { width, height } = Point.CreateFromSize(size);
    let bandMapping;
    const sourceId = typeof source === 'string' ? source : source.id;
    if (typeof source === 'object') {
      bandMapping = getDefaultBandsMapping(source.rasterProperties?.bands || undefined);
    }
    const params = prepareParams({ bbox: [minX, minY, maxX, maxY], width, height, sourceId, bandMapping });
    return this.getFullUrl('/preview' + params);
  };

  getBaseMapThumbnailUrl = (size: Size, bbox: Geometry | WKT, baseLayer: BASE_LAYER = BASE_LAYER.DEFAULT): UrlString | undefined => {
    bbox = typeof bbox === 'string' ? parseFromWKT(bbox)! : bbox;
    if (bbox) {
      const polygon = bbox.type === GeometryType.POINT ? Polygon.CreateBySide(POINT_THUMBNAIL_SIZE, bbox) : new Polygon(bbox.points);
      const { minX, minY, maxX, maxY } = polygon;
      const { width, height } = Point.CreateFromSize(size);
      const map = { width, height, minX, minY, maxX, maxY } as any;
      const regex = new RegExp('{' + Object.keys(map).join('}|{') + '}', 'g');
      const thumbnailUrl = getThumbnailUrl(baseLayer);
      if (thumbnailUrl) {
        return this.getFullUrl(thumbnailUrl.replaceAll(regex, (key) => map[key.replaceAll(/[{}]/g, '')]));
      }
    }
  };

  getTile(sourceDataId: SourceDataId, x: number, y: number, level: number): RequestPromise<Blob> {
    return setDataProcessor(
      this.rawRequest<Blob>(METHOD.GET, this.getTileUrl(sourceDataId, x, y, level), undefined, { responseType: 'blob' }),
      (res) => res.data,
    );
  }

  getTileUrl = (sourceDataId: SourceDataId, x?: number, y?: number, level?: number): string => {
    return this.getFullUrl(`/xyz/${level || '{z}'}/${x || '{x}'}/${y || '{y}'}.png?sourceId=` + sourceDataId);
  };

  getTileColorRampUrl = (sourceDataId: SourceDataId, x?: number, y?: number, level?: number): string => {
    return this.getFullUrl(`/colormap/${level || '{z}'}/${x || '{x}'}/${y || '{y}'}.png?sourceId=` + sourceDataId);
  };

  getHeightMap(sourceDataId: SourceDataId, x: number, y: number, level: number): RequestPromise<ArrayBuffer> {
    return setDataProcessor(
      this.rawRequest<ArrayBuffer>(METHOD.GET, `/heightxyz/${level}/${x}/${y}.hgt?sourceId=` + sourceDataId, undefined, {
        responseType: 'arraybuffer',
      }),
      (res) => res.data,
    );
  }

  getPixelInfo(sourceId: SourceDataId, point: Point): RequestPromise<SourceDataPixelInfo[]> {
    return this.request(METHOD.GET, '/pixel', { lon: point.x, lat: point.y, sourceId });
  }

  @cache(cacheKeyFn, 'clearCache', 900)
  getHistogram(sourceId: SourceDataId, viewPort?: WKT | Polygon): RequestPromise<BandHistogramData[] | undefined> {
    const bbox = viewPort && getPolygon(viewPort).bbox;
    return setDataProcessor(this.request(METHOD.GET, '/histogram', { sourceId, bbox }), (res: BandsHistogramResponse | undefined) => {
      if (res && res.bandHistogramMap) {
        const histogramData = Array.isArray(res.bandHistogramMap)
          ? res.bandHistogramMap
          : Object.values(res.bandHistogramMap).map((data, index) => ({ ...data, index }));

        return histogramData.map((bandData) => {
          bandData.normalized[0] = 0; // TODO: Same value issue should be fixed on BE side
          bandData.maxNormalized = Math.max(...bandData.normalized);
          bandData.minNormalized = Math.min(...bandData.normalized);
          return bandData;
        });
      }
    });
  }
}

export const mapApi = () => MapApi.getInstance();
