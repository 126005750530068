import { Components } from 'react-virtuoso';
import { MotionDenseListItem, MotionListItem, MotionListItemWithDivider } from '@/components/Virtuoso/MotionListItem';
import { MotionList } from '@/components/Virtuoso/MotionList';
import { MotionScroller } from '@/components/Virtuoso/MotionScroller';

export const MotionComponents: Components<any, unknown> = {
  Scroller: MotionScroller as any,
  List: MotionList as any,
  Item: MotionListItem as any,
};

export const MotionDenseComponents: Components<any, unknown> = {
  Scroller: MotionScroller as any,
  List: MotionList as any,
  Item: MotionDenseListItem as any,
};

export const MotionComponentsWithDivider: Components<any, unknown> = {
  Scroller: MotionScroller as any,
  List: MotionList as any,
  Item: MotionListItemWithDivider as any,
};

export {
  MotionListItem,
  MotionDenseListItem,
  MotionListItemWithDivider,
  MotionListItemWithDividerPadding,
  MotionListItemWithPadding,
} from '@/components/Virtuoso/MotionListItem';
export { MotionList } from '@/components/Virtuoso/MotionList';
