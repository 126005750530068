import { TenantId } from '@/models';
import { Filter, tenantApi } from '@/cmd/TenantApi';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';
import { tenantKeys } from '@/components/Organizations/queries';

export const useTenantsInfo = (tenantIds?: (TenantId | undefined)[]) => {
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: tenantKeys.byIds(tenantIds),
    queryFn: () => tenantApi().getListInfo(tenantIds),
    enabled: !tenantIds || tenantIds.some((uid) => !!uid),
    staleTime: minutesToMilliseconds(15),
    placeholderData: keepPreviousData,
  });

  if (isError) return [];

  return isLoading || isFetching || !data ? [] : data;
};

export const useTenants = (filter?: Filter) => {
  return useQuery({
    queryKey: tenantKeys.list(filter),
    queryFn: () => tenantApi().getAll((page) => tenantApi().getList(filter, { page })),
    staleTime: minutesToMilliseconds(15),
    placeholderData: keepPreviousData,
  });
};
