import { CssVarsThemeOptions, darken, experimental_extendTheme as extendTheme } from '@mui/material/styles';
import * as MaterialColors from '@mui/material/colors';
import { unstable_prepareCssVars as prepareCssVars } from '@mui/system';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useUserSettings } from '@/hooks/useSettings';
import { useSession } from 'next-auth/react';

const MUI_PREFIX = 'mui';

type DIRECTION = 'ltr' | 'rtl';

export const TOP_BAR_HEIGHT = 44;

export const EASING = {
  EMPHASIZED: [0.2, 0.0, 0, 1.0],
  EMPHASIZED_DECELERATE: [0.05, 0.7, 0.1, 1.0],
  EMPHASIZED_ACCELERATE: [0.3, 0.0, 0.8, 0.15],
  STANDARD: [0.2, 0.0, 0, 1.0],
  STANDARD_DECELERATE: [0, 0, 0, 1],
  STANDARD_ACCELERATE: [0.3, 0, 1, 1],
  LEGACY: [0.4, 0.0, 0.2, 1],
  LEGACY_DECELERATE: [0.0, 0.0, 0.2, 1],
  LEGACY_ACCELERATE: [0.4, 0.0, 1.0, 1],
};

const MaterialPalette = {
  amber: MaterialColors.amber,
  blue: MaterialColors.blue,
  blueGrey: MaterialColors.blueGrey,
  brown: MaterialColors.brown,
  common: MaterialColors.common,
  cyan: MaterialColors.cyan,
  deepOrange: MaterialColors.deepOrange,
  deepPurple: MaterialColors.deepPurple,
  green: MaterialColors.green,
  grey: MaterialColors.grey,
  indigo: MaterialColors.indigo,
  lightBlue: MaterialColors.lightBlue,
  lightGreen: MaterialColors.lightGreen,
  lime: MaterialColors.lime,
  orange: MaterialColors.orange,
  pink: MaterialColors.pink,
  purple: MaterialColors.purple,
  red: MaterialColors.red,
  teal: MaterialColors.teal,
  yellow: MaterialColors.yellow,
};

const themeSkeleton: Partial<CssVarsThemeOptions> = {
  cssVarPrefix: MUI_PREFIX,
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
  typography: {
    fontFamily: 'inherit',
    h7: {
      fontSize: '1.063rem', // 17px
      fontWeight: 500,
    },
    body1: {
      fontSize: '0.9375rem', // 15px
      lineHeight: 1.467, // 22px
    },
    body2: {
      fontSize: '0.8125rem', // 13px
      lineHeight: 1.385, // 18px
    },
    button: {
      textTransform: 'capitalize',
    },
    label: {
      fontSize: '0.75rem',
      fontWeight: 'normal',
      lineHeight: 1,
      letterSpacing: '0.15px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        '*::-webkit-scrollbar': {
          width: 15,
        },

        /* background of the scrollbar except button or resizer */
        '*::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
          border: '1px solid transparent',
          borderRadius: '8px',
        },
        '*::-webkit-scrollbar-corner': {
          backgroundColor: 'transparent',
          border: '1px solid transparent',
        },

        /* scrollbar itself */
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.vars.palette.scrollbar.thumb,
          border: '3px solid transparent',
          borderRadius: '8px',
          backgroundClip: 'content-box',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: theme.vars.palette.scrollbar.active,
          // border: '3px solid transparent',
          // borderRadius: '8px',
          // backgroundClip: 'content-box',
        },
        /* set button(top and bottom of the scrollbar) */
        '*::-webkit-scrollbar-button': {
          display: 'none',
        },
      }),
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          lineHeight: 1.5385,
        },
        outlined: {
          backgroundImage: 'none',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          elevation: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
      },
    },
    MuiToolbar: {
      defaultProps: {
        style: {
          minHeight: TOP_BAR_HEIGHT,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          whiteSpace: 'pre-line',
        },
      },
    },
    MuiIcon: {
      defaultProps: {
        // Replace the `material-icons` default value.
        baseClassName: 'material-symbols-rounded',
      },
      variants: [
        {
          props: { color: 'text.secondary' },
          style: ({ theme }) => ({
            color: theme.vars.palette.text.secondary,
          }),
        },
        {
          props: { fontSize: 'tiny' },
          style: ({ theme }) => ({
            fontSize: '1rem',
          }),
        },
      ],
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'tiny' },
          style: ({ theme }) => ({
            fontSize: '1rem',
          }),
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: ({ theme }) => ({
          borderColor: theme.vars.palette.outlinedBorder,
        }),
      },
    },
    MuiToggleButton: {
      variants: [
        {
          props: { color: 'primary' },
          style: ({ theme }) => ({
            [`${theme.getColorSchemeSelector('aid')}`]: {
              borderColor: theme.vars.palette.outlinedBorder,
            },
          }),
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      variants: [
        {
          props: { color: 'primary', variant: 'outlined' },
          style: ({ theme }) => ({
            [`${theme.getColorSchemeSelector('aid')}`]: {
              borderColor: theme.vars.palette.outlinedBorder,
            },
          }),
        },
        {
          props: { color: 'action', variant: 'text' },
          style: ({ theme }) => ({
            color: theme.vars.palette.action.active,
            '&:hover': {
              backgroundColor: `rgb(${theme.vars.palette.action.activeChannel} / ${theme.vars.palette.action.hoverOpacity})`,
            },
          }),
        },
        {
          props: { color: 'action', variant: 'outlined' },
          style: ({ theme }) => ({
            color: theme.vars.palette.action.active,
            border: `1px solid ${theme.vars.palette.outlinedBorder}`,
            '&:hover': {
              backgroundColor: `rgb(${theme.vars.palette.action.activeChannel} / ${theme.vars.palette.action.hoverOpacity})`,
            },
          }),
        },
        {
          props: { color: 'action', variant: 'contained' },
          style: ({ theme }) => ({
            color: theme.palette.getContrastText(theme.palette.action.active),
            backgroundColor: theme.vars.palette.action.active,
            '&:hover': {
              backgroundColor: darken(theme.palette.action.active, theme.palette.tonalOffset as number),
            },
          }),
        },
        {
          props: { variant: 'icon' },
          style: ({ theme }) => ({
            aspectRatio: 1,
            minWidth: '36px',
            padding: '5px 5px',
            border: '1px solid',
          }),
        },
        {
          props: { variant: 'icon', color: 'primary' },
          style: ({ theme }) => ({
            color: theme.vars.palette.primary.main,
            border: `1px solid rgb(${theme.vars.palette.primary.mainChannel} / 0.5)`,
            '&:hover': {
              backgroundColor: `rgb(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.hoverOpacity})`,
            },
          }),
        },
        {
          props: { variant: 'icon', color: 'secondary' },
          style: ({ theme }) => ({
            color: theme.vars.palette.secondary.main,
            border: `1px solid rgb(${theme.vars.palette.secondary.mainChannel} / 0.5)`,
            '&:hover': {
              backgroundColor: `rgb(${theme.vars.palette.secondary.mainChannel} / ${theme.vars.palette.action.hoverOpacity})`,
            },
          }),
        },
        {
          props: { variant: 'icon', color: 'error' },
          style: ({ theme }) => ({
            color: theme.vars.palette.error.main,
            border: `1px solid rgb(${theme.vars.palette.error.mainChannel} / 0.5)`,
            '&:hover': {
              backgroundColor: `rgb(${theme.vars.palette.error.mainChannel} / ${theme.vars.palette.action.hoverOpacity})`,
            },
          }),
        },
        {
          props: { variant: 'icon', color: 'warning' },
          style: ({ theme }) => ({
            color: theme.vars.palette.warning.main,
            border: `1px solid rgb(${theme.vars.palette.warning.mainChannel} / 0.5)`,
            '&:hover': {
              backgroundColor: `rgb(${theme.vars.palette.warning.mainChannel} / ${theme.vars.palette.action.hoverOpacity})`,
            },
          }),
        },
        {
          props: { variant: 'icon', color: 'success' },
          style: ({ theme }) => ({
            color: theme.vars.palette.success.main,
            border: `1px solid rgb(${theme.vars.palette.success.mainChannel} / 0.5)`,
            '&:hover': {
              backgroundColor: `rgb(${theme.vars.palette.success.mainChannel} / ${theme.vars.palette.action.hoverOpacity})`,
            },
          }),
        },
        {
          props: { variant: 'icon', color: 'info' },
          style: ({ theme }) => ({
            color: theme.vars.palette.info.main,
            border: `1px solid rgb(${theme.vars.palette.info.mainChannel} / 0.5)`,
            '&:hover': {
              backgroundColor: `rgb(${theme.vars.palette.info.mainChannel} / ${theme.vars.palette.action.hoverOpacity})`,
            },
          }),
        },
        {
          props: { variant: 'icon', color: 'action' },
          style: ({ theme }) => ({
            color: theme.vars.palette.action.active,
            border: `1px solid ${theme.vars.palette.outlinedBorder}`,
            '&:hover': {
              backgroundColor: `rgb(${theme.vars.palette.action.activeChannel} / ${theme.vars.palette.action.hoverOpacity})`,
            },
          }),
        },
        {
          props: { variant: 'icon-contained' },
          style: ({ theme }) => ({
            aspectRatio: 1,
            minWidth: '36px',
            padding: '5px 5px',
            border: 'none',
          }),
        },
        {
          props: { variant: 'icon-contained', color: 'primary' },
          style: ({ theme }) => ({
            color: theme.palette.getContrastText(theme.palette.primary.main),
            backgroundColor: theme.vars.palette.primary.main,
            '&:hover': {
              backgroundColor: darken(theme.palette.primary.main, theme.palette.tonalOffset as number),
            },
          }),
        },
        {
          props: { variant: 'icon-contained', color: 'secondary' },
          style: ({ theme }) => ({
            color: theme.palette.getContrastText(theme.palette.secondary.main),
            backgroundColor: theme.vars.palette.secondary.main,
            '&:hover': {
              backgroundColor: darken(theme.palette.secondary.main, theme.palette.tonalOffset as number),
            },
          }),
        },
        {
          props: { variant: 'icon-contained', color: 'error' },
          style: ({ theme }) => ({
            color: theme.palette.getContrastText(theme.palette.error.main),
            backgroundColor: theme.vars.palette.error.main,
            '&:hover': {
              backgroundColor: darken(theme.palette.error.main, theme.palette.tonalOffset as number),
            },
          }),
        },
        {
          props: { variant: 'icon-contained', color: 'warning' },
          style: ({ theme }) => ({
            color: theme.palette.getContrastText(theme.palette.warning.main),
            backgroundColor: theme.vars.palette.warning.main,
            '&:hover': {
              backgroundColor: darken(theme.palette.warning.main, theme.palette.tonalOffset as number),
            },
          }),
        },
        {
          props: { variant: 'icon-contained', color: 'success' },
          style: ({ theme }) => ({
            color: theme.palette.getContrastText(theme.palette.success.main),
            backgroundColor: theme.vars.palette.success.main,
            '&:hover': {
              backgroundColor: darken(theme.palette.success.main, theme.palette.tonalOffset as number),
            },
          }),
        },
        {
          props: { variant: 'icon-contained', color: 'info' },
          style: ({ theme }) => ({
            color: theme.palette.getContrastText(theme.palette.info.main),
            backgroundColor: theme.vars.palette.info.main,
            '&:hover': {
              backgroundColor: darken(theme.palette.info.main, theme.palette.tonalOffset as number),
            },
          }),
        },
        {
          props: { variant: 'icon-contained', color: 'action' },
          style: ({ theme }) => ({
            color: theme.palette.getContrastText(theme.palette.action.active),
            backgroundColor: theme.vars.palette.action.active,
            '&:hover': {
              backgroundColor: darken(theme.palette.action.active, theme.palette.tonalOffset as number),
            },
          }),
        },
      ],
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
      variants: [
        {
          props: { color: 'action' },
          style: ({ theme }) => ({
            color: theme.vars.palette.action.active,
          }),
        },
      ],
    },
    MuiSkeleton: {
      variants: [
        {
          props: { variant: 'invisible' },
          style: ({ theme }) => ({
            backgroundColor: 'transparent',
            animation: 'none',
          }),
        },
      ],
    },
    AnimatedIcon: {
      defaultProps: {
        // Replace the `material-icons` default value.
        baseClassName: 'material-symbols-rounded',
        variant: 'outlined',
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            fontVariationSettings: '"FILL" 0',
            animation: 'fill-out',
            animationDuration: '100ms',
          },
        },
        {
          props: { variant: 'filled' },
          style: {
            fontVariationSettings: '"FILL" 1',
            animation: 'fill-in',
            animationDuration: '100ms',
          },
        },
      ],
    },
    MuiTab: {
      defaultProps: {
        style: {
          minWidth: 'auto',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: ({ theme }) => ({
          borderColor: theme.vars.palette.divider,
        }),
        footerContainer: ({ theme }) => ({
          borderColor: theme.vars.palette.divider,
        }),
      },
    },
  },
  transitions: {
    easing: {
      emphasized: `cubic-bezier(${EASING.EMPHASIZED})`,
      emphasizedDecelerate: `cubic-bezier(${EASING.EMPHASIZED_DECELERATE})`,
      emphasizedAccelerate: `cubic-bezier(${EASING.EMPHASIZED_ACCELERATE})`,
      standard: `cubic-bezier(${EASING.STANDARD})`,
      standardDecelerate: `cubic-bezier(${EASING.STANDARD_DECELERATE})`,
      standardAccelerate: `cubic-bezier(${EASING.STANDARD_ACCELERATE})`,
      legacy: `cubic-bezier(${EASING.LEGACY})`,
      legacyDecelerate: `cubic-bezier(${EASING.LEGACY_DECELERATE})`,
      legacyAccelerate: `cubic-bezier(${EASING.LEGACY_ACCELERATE})`,
    },
  },
};

// DEFAULT
const createFirstTheme = (dir?: DIRECTION) =>
  extendTheme({
    direction: dir ?? 'ltr',
    ...themeSkeleton,
    colorSchemes: {
      light: {
        palette: {
          material: MaterialPalette,
          primary: {
            main: '#002CC4',
          },
          secondary: {
            main: '#383874',
          },
          text: {
            primary: '#383874',
            secondary: 'rgb(56, 56, 116, .6)', // #383874 60%
            disabled: 'rgb(56, 56, 116, .38)', // #383874 38%
          },
          action: {
            active: 'rgb(56, 56, 116, .54)', // #383874 54%
            activeChannel: '#383874', // #383874 54%
          },
          background: {
            default: '#f3f4f9',
            container: '#ECEFF1',
          },
          divider: 'rgb(56 56 116 / 0.12)', // 383874 12%
          scrollbar: {
            track: '#FAFAFA',
            thumb: '#C1C1C1',
            active: '#00000080',
            border: '#E8E8E8',
          },
          dataSource: {
            RASTER: MaterialColors.lightBlue['500'],
            VECTOR: MaterialColors.deepPurple['400'],
            ANNOTATION: MaterialColors.deepPurple['400'],
            DRAWING: MaterialColors.deepPurple['400'],
            VIDEO: MaterialColors.indigo['500'],
            DOCUMENT: MaterialColors.green['500'],
            UNKNOWN: MaterialColors.blueGrey['500'],
            SPATIAL: MaterialColors.blueGrey['500'],
            NETCDF: MaterialColors.blueGrey['500'],
          },
          dataSourceSimple: {
            RASTER: MaterialColors.lightBlue['500'],
            VECTOR: MaterialColors.deepPurple['400'],
            ANNOTATION: MaterialColors.deepPurple['400'],
            DRAWING: MaterialColors.deepPurple['400'],
            VIDEO: MaterialColors.indigo['500'],
            DOCUMENT: MaterialColors.green['500'],
            UNKNOWN: MaterialColors.blueGrey['500'],
            SPATIAL: MaterialColors.blueGrey['500'],
            NETCDF: MaterialColors.blueGrey['500'],
          },
          mapShadow: 'inset 0 0 200px 150px #FFFFFF',
        },
        shadows: [
          'none',
          '0px 2px 1px -1px rgb(46 97 125 / 0.2), 0px 1px 1px rgb(46 97 125 / 0.14), 0px 1px 3px rgb(46 97 125 / 0.12)',
          '0px 3px 1px -2px rgb(46 97 125 / 0.2), 0px 2px 2px rgb(46 97 125 / 0.14), 0px 1px 5px rgb(46 97 125 / 0.12)',
          '0px 3px 3px -2px rgb(46 97 125 / 0.2), 0px 3px 4px rgb(46 97 125 / 0.14), 0px 1px 8px rgb(46 97 125 / 0.12)',
          '0px 2px 4px -1px rgb(46 97 125 / 0.2), 0px 4px 5px rgb(46 97 125 / 0.14), 0px 1px 10px rgb(46 97 125 / 0.12)',
          '0px 3px 5px -1px rgb(46 97 125 / 0.2), 0px 5px 8px rgb(46 97 125 / 0.14), 0px 1px 14px rgb(46 97 125 / 0.12)',
          '0px 3px 5px -1px rgb(46 97 125 / 0.2), 0px 6px 10px rgb(46 97 125 / 0.14), 0px 1px 18px rgb(46 97 125 / 0.12)',
          '0px 4px 5px -2px rgb(46 97 125 / 0.2), 0px 7px 10px 1px rgb(46 97 125 / 0.14), 0px 2px 16px 1px rgb(46 97 125 / 0.12)',
          '0px 5px 5px -3px rgb(46 97 125 / 0.2), 0px 8px 10px 1px rgb(46 97 125 / 0.14), 0px 3px 14px 2px rgb(46 97 125 / 0.12)',
          '0px 5px 6px -3px rgb(46 97 125 / 0.2), 0px 9px 12px 1px rgb(46 97 125 / 0.14), 0px 3px 16px 2px rgb(46 97 125 / 0.12)',
          '0px 6px 6px -3px rgb(46 97 125 / 0.2), 0px 10px 14px 1px rgb(46 97 125 / 0.14), 0px 4px 18px 3px rgb(46 97 125 / 0.12)',
          '0px 6px 7px -4px rgb(46 97 125 / 0.2), 0px 11px 15px 1px rgb(46 97 125 / 0.14), 0px 4px 20px 3px rgb(46 97 125 / 0.12)',
          '0px 7px 8px -4px rgb(46 97 125 / 0.2), 0px 12px 17px 2px rgb(46 97 125 / 0.14), 0px 5px 22px 4px rgb(46 97 125 / 0.12)',
          '0px 7px 8px -4px rgb(46 97 125 / 0.2), 0px 13px 19px 2px rgb(46 97 125 / 0.14), 0px 5px 24px 4px rgb(46 97 125 / 0.12)',
          '0px 7px 9px -4px rgb(46 97 125 / 0.2), 0px 14px 21px 2px rgb(46 97 125 / 0.14), 0px 5px 26px 4px rgb(46 97 125 / 0.12)',
          '0px 8px 9px -5px rgb(46 97 125 / 0.2), 0px 15px 22px 2px rgb(46 97 125 / 0.14), 0px 6px 28px 5px rgb(46 97 125 / 0.12)',
          '0px 8px 10px -5px rgb(46 97 125 / 0.2), 0px 16px 24px 2px rgb(46 97 125 / 0.14), 0px 6px 30px 5px rgb(46 97 125 / 0.12)',
          '0px 8px 11px -5px rgb(46 97 125 / 0.2), 0px 17px 26px 2px rgb(46 97 125 / 0.14), 0px 6px 32px 5px rgb(46 97 125 / 0.12)',
          '0px 9px 11px -5px rgb(46 97 125 / 0.2), 0px 18px 28px 2px rgb(46 97 125 / 0.14), 0px 7px 34px 6px rgb(46 97 125 / 0.12)',
          '0px 9px 12px -6px rgb(46 97 125 / 0.2), 0px 19px 29px 2px rgb(46 97 125 / 0.14), 0px 7px 36px 6px rgb(46 97 125 / 0.12)',
          '0px 10px 13px -6px rgb(46 97 125 / 0.2), 0px 20px 31px 3px rgb(46 97 125 / 0.14), 0px 8px 38px 7px rgb(46 97 125 / 0.12)',
          '0px 10px 13px -6px rgb(46 97 125 / 0.2), 0px 21px 33px 3px rgb(46 97 125 / 0.14), 0px 8px 40px 7px rgb(46 97 125 / 0.12)',
          '0px 10px 14px -6px rgb(46 97 125 / 0.2), 0px 22px 35px 3px rgb(46 97 125 / 0.14), 0px 8px 42px 7px rgb(46 97 125 / 0.12)',
          '0px 11px 14px -7px rgb(46 97 125 / 0.2), 0px 23px 36px 3px rgb(46 97 125 / 0.14), 0px 9px 44px 8px rgb(46 97 125 / 0.12)',
          '0px 11px 15px -7px rgb(46 97 125 / 0.2), 0px 24px 38px 3px rgb(46 97 125 / 0.14), 0px 9px 46px 8px rgb(46 97 125 / 0.12)',
        ],
      },
      dark: {
        palette: {
          material: MaterialPalette,
          primary: {
            main: '#007FFF',
          },
          secondary: {
            main: '#17B596',
          },
          scrollbar: {
            track: '#2b2b2b',
            thumb: '#6b6b6b',
            active: '#959595',
            border: '#515151',
          },
          text: {
            primary: '#FFFFFF',
            secondary: '#A5AEB7',
            disabled: 'rgb(255 255 255 / .50)', // #FFFFFF 50%
          },
          background: {
            default: '#0A1929',
            paper: '#001E3C',
            // container: '#455A64',
            container: '#21344E',
          },
          divider: 'rgb(30 73 118 / 0.3)',
          outlinedBorder: '#1E4976',
          gradient: 'linear-gradient(in oklab 135deg, #07F49E 0%, #42047E 100%)',
          action: {
            active: '#66B2FF',
            activeChannel: '102 178 255',
            selectedOpacity: 0.25,
            hoverOpacity: 0.12,
          },
          dataSource: {
            RASTER: 'linear-gradient(in oklab 135deg, #28B8D5 0%, #020344 100%)',
            VECTOR: 'linear-gradient(in oklab 135deg, #00CCCC 0.01%, #00171E 100%)',
            ANNOTATION: 'linear-gradient(in oklab 135deg, #00CCCC 0.01%, #00171E 100%)',
            DRAWING: 'linear-gradient(in oklab 135deg, #00CCCC 0.01%, #00171E 100%)',
            VIDEO: 'linear-gradient(in oklab 135deg, #3F51B5 0.01%, #091243 100%)',
            DOCUMENT: 'linear-gradient(in oklab 135deg, #159957 0%, #0B2D50 100%)',
            UNKNOWN: 'linear-gradient(in oklab 135deg, #607D8B 0.01%, #142228 100%)',
            SPATIAL: 'linear-gradient(in oklab 135deg, #607D8B 0.01%, #142228 100%)',
            NETCDF: 'linear-gradient(in oklab 135deg, #607D8B 0.01%, #142228 100%)',
          },
          dataSourceSimple: {
            RASTER: '#28B8D5',
            VECTOR: '#00CCCC',
            ANNOTATION: '#00CCCC',
            DRAWING: '#00CCCC',
            VIDEO: '#3F51B5',
            DOCUMENT: '#159957',
            UNKNOWN: '#607D8B',
            SPATIAL: '#607D8B',
            NETCDF: '#607D8B',
          },
          mapShadow: 'inset 0 0 200px 150px #020e17',
        },
        overlays: [
          'linear-gradient(in oklab 154.92deg, #001E3C 0%, #002C57 100%)', // design elevation 0 = elevation 0
          'linear-gradient(in oklab 154.92deg, #001E3C 0%, #001E3C 100%)', // design elevation 1 = elevation 1 - 6
          'linear-gradient(in oklab 154.92deg, #001E3C 0%, #001E3C 100%)',
          'linear-gradient(in oklab 154.92deg, #001E3C 0%, #001E3C 100%)',
          'linear-gradient(in oklab 154.92deg, #001E3C 0%, #001E3C 100%)',
          'linear-gradient(in oklab 154.92deg, #001E3C 0%, #001E3C 100%)',
          'linear-gradient(in oklab 154.92deg, #001E3C 0%, #001E3C 100%)',
          'linear-gradient(in oklab 154.92deg, #081D3A 0%, #081D3A 100%)', // design elevation 2 = elevation 7 - 12
          'linear-gradient(in oklab 154.92deg, #081D3A 0%, #081D3A 100%)',
          'linear-gradient(in oklab 154.92deg, #081D3A 0%, #081D3A 100%)',
          'linear-gradient(in oklab 154.92deg, #081D3A 0%, #081D3A 100%)',
          'linear-gradient(in oklab 154.92deg, #081D3A 0%, #081D3A 100%)',
          'linear-gradient(in oklab 154.92deg, #081D3A 0%, #081D3A 100%)',
          'linear-gradient(in oklab 154.92deg, #21344E 0%, #21344E 100%)', // design elevation 3 = elevation 13 - 18
          'linear-gradient(in oklab 154.92deg, #21344E 0%, #21344E 100%)',
          'linear-gradient(in oklab 154.92deg, #21344E 0%, #21344E 100%)',
          'linear-gradient(in oklab 154.92deg, #21344E 0%, #21344E 100%)',
          'linear-gradient(in oklab 154.92deg, #21344E 0%, #21344E 100%)',
          'linear-gradient(in oklab 154.92deg, #21344E 0%, #21344E 100%)',
          'linear-gradient(in oklab 154.92deg, #394A61 0%, #394A61 100%)', // design elevation 4 = elevation 19 - 24
          'linear-gradient(in oklab 154.92deg, #394A61 0%, #394A61 100%)',
          'linear-gradient(in oklab 154.92deg, #394A61 0%, #394A61 100%)',
          'linear-gradient(in oklab 154.92deg, #394A61 0%, #394A61 100%)',
          'linear-gradient(in oklab 154.92deg, #394A61 0%, #394A61 100%)',
          'linear-gradient(in oklab 154.92deg, #394A61 0%, #394A61 100%)',
        ],
        shadows: [
          'none',
          '0px 2px 1px -1px rgb(0 0 0 / 0.2), 0px 1px 1px rgb(0 0 0 / 0.14), 0px 1px 3px rgb(0 0 0 / 0.12)',
          '0px 3px 1px -2px rgb(0 0 0 / 0.2), 0px 2px 2px rgb(0 0 0 / 0.14), 0px 1px 5px rgb(0 0 0 / 0.12)',
          '0px 3px 3px -2px rgb(0 0 0 / 0.2), 0px 3px 4px rgb(0 0 0 / 0.14), 0px 1px 8px rgb(0 0 0 / 0.12)',
          '0px 2px 4px -1px rgb(0 0 0 / 0.2), 0px 4px 5px rgb(0 0 0 / 0.14), 0px 1px 10px rgb(0 0 0 / 0.12)',
          '0px 3px 5px -1px rgb(0 0 0 / 0.2), 0px 5px 8px rgb(0 0 0 / 0.14), 0px 1px 14px rgb(0 0 0 / 0.12)',
          '0px 3px 5px -1px rgb(0 0 0 / 0.2), 0px 6px 10px rgb(0 0 0 / 0.14), 0px 1px 18px rgb(0 0 0 / 0.12)',
          '0px 4px 5px -2px rgb(0 0 0 / 0.2), 0px 7px 10px 1px rgb(0 0 0 / 0.14), 0px 2px 16px 1px rgb(0 0 0 / 0.12)',
          '0px 5px 5px -3px rgb(0 0 0 / 0.2), 0px 8px 10px 1px rgb(0 0 0 / 0.14), 0px 3px 14px 2px rgb(0 0 0 / 0.12)',
          '0px 5px 6px -3px rgb(0 0 0 / 0.2), 0px 9px 12px 1px rgb(0 0 0 / 0.14), 0px 3px 16px 2px rgb(0 0 0 / 0.12)',
          '0px 6px 6px -3px rgb(0 0 0 / 0.2), 0px 10px 14px 1px rgb(0 0 0 / 0.14), 0px 4px 18px 3px rgb(0 0 0 / 0.12)',
          '0px 6px 7px -4px rgb(0 0 0 / 0.2), 0px 11px 15px 1px rgb(0 0 0 / 0.14), 0px 4px 20px 3px rgb(0 0 0 / 0.12)',
          '0px 7px 8px -4px rgb(0 0 0 / 0.2), 0px 12px 17px 2px rgb(0 0 0 / 0.14), 0px 5px 22px 4px rgb(0 0 0 / 0.12)',
          '0px 7px 8px -4px rgb(0 0 0 / 0.2), 0px 13px 19px 2px rgb(0 0 0 / 0.14), 0px 5px 24px 4px rgb(0 0 0 / 0.12)',
          '0px 7px 9px -4px rgb(0 0 0 / 0.2), 0px 14px 21px 2px rgb(0 0 0 / 0.14), 0px 5px 26px 4px rgb(0 0 0 / 0.12)',
          '0px 8px 9px -5px rgb(0 0 0 / 0.2), 0px 15px 22px 2px rgb(0 0 0 / 0.14), 0px 6px 28px 5px rgb(0 0 0 / 0.12)',
          '0px 8px 10px -5px rgb(0 0 0 / 0.2), 0px 16px 24px 2px rgb(0 0 0 / 0.14), 0px 6px 30px 5px rgb(0 0 0 / 0.12)',
          '0px 8px 11px -5px rgb(0 0 0 / 0.2), 0px 17px 26px 2px rgb(0 0 0 / 0.14), 0px 6px 32px 5px rgb(0 0 0 / 0.12)',
          '0px 9px 11px -5px rgb(0 0 0 / 0.2), 0px 18px 28px 2px rgb(0 0 0 / 0.14), 0px 7px 34px 6px rgb(0 0 0 / 0.12)',
          '0px 9px 12px -6px rgb(0 0 0 / 0.2), 0px 19px 29px 2px rgb(0 0 0 / 0.14), 0px 7px 36px 6px rgb(0 0 0 / 0.12)',
          '0px 10px 13px -6px rgb(0 0 0 / 0.2), 0px 20px 31px 3px rgb(0 0 0 / 0.14), 0px 8px 38px 7px rgb(0 0 0 / 0.12)',
          '0px 10px 13px -6px rgb(0 0 0 / 0.2), 0px 21px 33px 3px rgb(0 0 0 / 0.14), 0px 8px 40px 7px rgb(0 0 0 / 0.12)',
          '0px 10px 14px -6px rgb(0 0 0 / 0.2), 0px 22px 35px 3px rgb(0 0 0 / 0.14), 0px 8px 42px 7px rgb(0 0 0 / 0.12)',
          '0px 11px 14px -7px rgb(0 0 0 / 0.2), 0px 23px 36px 3px rgb(0 0 0 / 0.14), 0px 9px 44px 8px rgb(0 0 0 / 0.12)',
          '0px 11px 15px -7px rgb(0 0 0 / 0.2), 0px 24px 38px 3px rgb(0 0 0 / 0.14), 0px 9px 46px 8px rgb(0 0 0 / 0.12)',
        ],
      },
    },
  });

// UAE Federal & AID
const createSecondTheme = (dir?: DIRECTION) =>
  extendTheme({
    direction: dir ?? 'ltr',
    ...themeSkeleton,
    colorSchemes: {
      // UAE Federal
      light: {
        palette: {
          material: MaterialPalette,
          primary: {
            main: '#b68a35',
          },
          secondary: {
            main: '#2f3165',
          },
          scrollbar: {
            track: '#2b2b2b',
            thumb: '#6b6b6b',
            active: '#959595',
            border: '#515151',
          },
          text: {
            primary: '#06122a',
            secondary: '#06122a99',
            disabled: '#06122a61',
          },
          background: {
            default: '#ffffff',
            paper: '#e6e8ea',
            container: '#DADBDD', //elevation 3
          },
          divider: '#2f316526',
          outlinedBorder: '#cacbdd',
          gradient: 'linear-gradient(in oklab 135deg, #07F49E 0%, #42047E 100%)',
          action: {
            active: '#b68a35',
            activeChannel: '#b68a35',
            selectedChannel: '#b68a35',
            selectedOpacity: 0.16,
            hoverOpacity: 0.08,
            disabled: '#b68a354d',
            disabledOpacity: 0.3,
            disabledBackground: '#b68a351f',
            focus: '#b68a351f',
            focusOpacity: 0.12,
          },
          success: {
            main: '#3f8e50',
          },
          error: {
            main: '#b52e29',
          },
          warning: {
            main: '#d0a122',
          },
          info: {
            main: '#29b6f6',
          },
          dataSource: {
            RASTER: 'linear-gradient(in oklab 135deg, #28B8D5 0%, #020344 100%)',
            VECTOR: 'linear-gradient(in oklab 135deg, #00CCCC 0.01%, #00171E 100%)',
            ANNOTATION: 'linear-gradient(in oklab 135deg, #00CCCC 0.01%, #00171E 100%)',
            DRAWING: 'linear-gradient(in oklab 135deg, #00CCCC 0.01%, #00171E 100%)',
            VIDEO: 'linear-gradient(in oklab 135deg, #3F51B5 0.01%, #091243 100%)',
            DOCUMENT: 'linear-gradient(in oklab 135deg, #159957 0%, #0B2D50 100%)',
            UNKNOWN: 'linear-gradient(in oklab 135deg, #607D8B 0.01%, #142228 100%)',
            SPATIAL: 'linear-gradient(in oklab 135deg, #607D8B 0.01%, #142228 100%)',
            NETCDF: 'linear-gradient(in oklab 135deg, #607D8B 0.01%, #142228 100%)',
          },
          dataSourceSimple: {
            RASTER: '#28B8D5',
            VECTOR: '#00CCCC',
            ANNOTATION: '#00CCCC',
            DRAWING: '#00CCCC',
            VIDEO: '#3F51B5',
            DOCUMENT: '#159957',
            UNKNOWN: '#607D8B',
            SPATIAL: '#607D8B',
            NETCDF: '#607D8B',
          },
          mapShadow: 'inset 0 0 200px 150px #020e17',
        },
        overlays: [
          'linear-gradient(in oklab 0deg, #e6e8ea 100%, #e6e8ea 100%)', // design elevation 0 = elevation 0
          'linear-gradient(in oklab 0deg, #e8e9eb 100%, #e8e9eb 100%)', // design elevation 1 = elevation 1 - 6
          'linear-gradient(in oklab 0deg, #e8e9eb 100%, #e8e9eb 100%)',
          'linear-gradient(in oklab 0deg, #e8e9eb 100%, #e8e9eb 100%)',
          'linear-gradient(in oklab 0deg, #e8e9eb 100%, #e8e9eb 100%)',
          'linear-gradient(in oklab 0deg, #e8e9eb 100%, #e8e9eb 100%)',
          'linear-gradient(in oklab 0deg, #e8e9eb 100%, #e8e9eb 100%)',
          'linear-gradient(in oklab 0deg, #dcdee0 100%, #dcdee0 100%)', // design elevation 2 = elevation 7 - 12
          'linear-gradient(in oklab 0deg, #dcdee0 100%, #dcdee0 100%)',
          'linear-gradient(in oklab 0deg, #dcdee0 100%, #dcdee0 100%)',
          'linear-gradient(in oklab 0deg, #dcdee0 100%, #dcdee0 100%)',
          'linear-gradient(in oklab 0deg, #dcdee0 100%, #dcdee0 100%)',
          'linear-gradient(in oklab 0deg, #dcdee0 100%, #dcdee0 100%)',
          'linear-gradient(in oklab 0deg, #dadbdd 100%, #dadbdd 100%)', // design elevation 3 = elevation 13 - 18
          'linear-gradient(in oklab 0deg, #dadbdd 100%, #dadbdd 100%)',
          'linear-gradient(in oklab 0deg, #dadbdd 100%, #dadbdd 100%)',
          'linear-gradient(in oklab 0deg, #dadbdd 100%, #dadbdd 100%)',
          'linear-gradient(in oklab 0deg, #dadbdd 100%, #dadbdd 100%)',
          'linear-gradient(in oklab 0deg, #dadbdd 100%, #dadbdd 100%)',
          'linear-gradient(in oklab 0deg, #eff1f3 100%, #eff1f3 100%)', // design elevation 4 = elevation 19 - 24
          'linear-gradient(in oklab 0deg, #eff1f3 100%, #eff1f3 100%)',
          'linear-gradient(in oklab 0deg, #eff1f3 100%, #eff1f3 100%)',
          'linear-gradient(in oklab 0deg, #eff1f3 100%, #eff1f3 100%)',
          'linear-gradient(in oklab 0deg, #eff1f3 100%, #eff1f3 100%)',
          'linear-gradient(in oklab 0deg, #eff1f3 100%, #eff1f3 100%)',
        ],
        shadows: [
          'none',
          '0px 2px 1px -1px rgb(46 97 125 / 0.2), 0px 1px 1px rgb(46 97 125 / 0.14), 0px 1px 3px rgb(46 97 125 / 0.12)',
          '0px 3px 1px -2px rgb(46 97 125 / 0.2), 0px 2px 2px rgb(46 97 125 / 0.14), 0px 1px 5px rgb(46 97 125 / 0.12)',
          '0px 3px 3px -2px rgb(46 97 125 / 0.2), 0px 3px 4px rgb(46 97 125 / 0.14), 0px 1px 8px rgb(46 97 125 / 0.12)',
          '0px 2px 4px -1px rgb(46 97 125 / 0.2), 0px 4px 5px rgb(46 97 125 / 0.14), 0px 1px 10px rgb(46 97 125 / 0.12)',
          '0px 3px 5px -1px rgb(46 97 125 / 0.2), 0px 5px 8px rgb(46 97 125 / 0.14), 0px 1px 14px rgb(46 97 125 / 0.12)',
          '0px 3px 5px -1px rgb(46 97 125 / 0.2), 0px 6px 10px rgb(46 97 125 / 0.14), 0px 1px 18px rgb(46 97 125 / 0.12)',
          '0px 4px 5px -2px rgb(46 97 125 / 0.2), 0px 7px 10px 1px rgb(46 97 125 / 0.14), 0px 2px 16px 1px rgb(46 97 125 / 0.12)',
          '0px 5px 5px -3px rgb(46 97 125 / 0.2), 0px 8px 10px 1px rgb(46 97 125 / 0.14), 0px 3px 14px 2px rgb(46 97 125 / 0.12)',
          '0px 5px 6px -3px rgb(46 97 125 / 0.2), 0px 9px 12px 1px rgb(46 97 125 / 0.14), 0px 3px 16px 2px rgb(46 97 125 / 0.12)',
          '0px 6px 6px -3px rgb(46 97 125 / 0.2), 0px 10px 14px 1px rgb(46 97 125 / 0.14), 0px 4px 18px 3px rgb(46 97 125 / 0.12)',
          '0px 6px 7px -4px rgb(46 97 125 / 0.2), 0px 11px 15px 1px rgb(46 97 125 / 0.14), 0px 4px 20px 3px rgb(46 97 125 / 0.12)',
          '0px 7px 8px -4px rgb(46 97 125 / 0.2), 0px 12px 17px 2px rgb(46 97 125 / 0.14), 0px 5px 22px 4px rgb(46 97 125 / 0.12)',
          '0px 7px 8px -4px rgb(46 97 125 / 0.2), 0px 13px 19px 2px rgb(46 97 125 / 0.14), 0px 5px 24px 4px rgb(46 97 125 / 0.12)',
          '0px 7px 9px -4px rgb(46 97 125 / 0.2), 0px 14px 21px 2px rgb(46 97 125 / 0.14), 0px 5px 26px 4px rgb(46 97 125 / 0.12)',
          '0px 8px 9px -5px rgb(46 97 125 / 0.2), 0px 15px 22px 2px rgb(46 97 125 / 0.14), 0px 6px 28px 5px rgb(46 97 125 / 0.12)',
          '0px 8px 10px -5px rgb(46 97 125 / 0.2), 0px 16px 24px 2px rgb(46 97 125 / 0.14), 0px 6px 30px 5px rgb(46 97 125 / 0.12)',
          '0px 8px 11px -5px rgb(46 97 125 / 0.2), 0px 17px 26px 2px rgb(46 97 125 / 0.14), 0px 6px 32px 5px rgb(46 97 125 / 0.12)',
          '0px 9px 11px -5px rgb(46 97 125 / 0.2), 0px 18px 28px 2px rgb(46 97 125 / 0.14), 0px 7px 34px 6px rgb(46 97 125 / 0.12)',
          '0px 9px 12px -6px rgb(46 97 125 / 0.2), 0px 19px 29px 2px rgb(46 97 125 / 0.14), 0px 7px 36px 6px rgb(46 97 125 / 0.12)',
          '0px 10px 13px -6px rgb(46 97 125 / 0.2), 0px 20px 31px 3px rgb(46 97 125 / 0.14), 0px 8px 38px 7px rgb(46 97 125 / 0.12)',
          '0px 10px 13px -6px rgb(46 97 125 / 0.2), 0px 21px 33px 3px rgb(46 97 125 / 0.14), 0px 8px 40px 7px rgb(46 97 125 / 0.12)',
          '0px 10px 14px -6px rgb(46 97 125 / 0.2), 0px 22px 35px 3px rgb(46 97 125 / 0.14), 0px 8px 42px 7px rgb(46 97 125 / 0.12)',
          '0px 11px 14px -7px rgb(46 97 125 / 0.2), 0px 23px 36px 3px rgb(46 97 125 / 0.14), 0px 9px 44px 8px rgb(46 97 125 / 0.12)',
          '0px 11px 15px -7px rgb(46 97 125 / 0.2), 0px 24px 38px 3px rgb(46 97 125 / 0.14), 0px 9px 46px 8px rgb(46 97 125 / 0.12)',
        ],
      },
      // AID
      dark: {
        palette: {
          material: MaterialPalette,
          primary: {
            main: '#DF224C',
            dark: '#B61A3D',
            light: '#E5496B',
            contrastText: '#FFFFFF',
          },
          secondary: {
            main: '#DF224C',
            dark: '#B61A3D',
            light: '#E5496B',
            contrastText: '#FFFFFF',
          },
          scrollbar: {
            track: '#2b2b2b',
            thumb: '#6b6b6b',
            active: '#959595',
            border: '#515151',
          },
          text: {
            primary: '#FFFFFF',
            secondary: '#A5AEB7',
            disabled: '#FFFFFF80',
          },
          background: {
            default: '#0B0A09',
            paper: '#121723',
            container: '#202A3E', //elevation 3
          },
          divider: '#27344C4D',
          outlinedBorder: '#465572',
          gradient: 'linear-gradient(in oklab 135deg, #07F49E 0%, #42047E 100%)',
          action: {
            active: '#DF224C',
            activeChannel: '#DF224C',
            selected: '#DF224C29',
            selectedChannel: '#DF224C',
            selectedOpacity: 0.16,
            hoverOpacity: 0.08,
            disabled: '#DF224C80',
            disabledOpacity: 0.5,
            disabledBackground: '#DF224C1F',
            focus: '#5564801F',
            focusOpacity: 0.12,
          },
          success: {
            main: '#66BB6A',
            dark: '#388E3C',
            light: '#81C784',
            contrastText: '#000000DE',
          },
          error: {
            main: '#F44336',
            dark: '#D32F2F',
            light: '#E57373',
            contrastText: '#FFFFFF',
          },
          warning: {
            main: '#FFA726',
            dark: '#F57C00',
            light: '#FFB74D',
            contrastText: '#000000DE',
          },
          info: {
            main: '#29B6F6',
            dark: '#0288D1',
            light: '#4FC3F7',
            contrastText: '#000000DE',
          },
          dataSource: {
            RASTER: 'linear-gradient(in oklab 135deg, #28B8D5 0%, #020344 100%)',
            VECTOR: 'linear-gradient(in oklab 135deg, #00CCCC 0.01%, #00171E 100%)',
            ANNOTATION: 'linear-gradient(in oklab 135deg, #00CCCC 0.01%, #00171E 100%)',
            DRAWING: 'linear-gradient(in oklab 135deg, #00CCCC 0.01%, #00171E 100%)',
            VIDEO: 'linear-gradient(in oklab 135deg, #3F51B5 0.01%, #091243 100%)',
            DOCUMENT: 'linear-gradient(in oklab 135deg, #159957 0%, #0B2D50 100%)',
            UNKNOWN: 'linear-gradient(in oklab 135deg, #607D8B 0.01%, #142228 100%)',
            SPATIAL: 'linear-gradient(in oklab 135deg, #607D8B 0.01%, #142228 100%)',
            NETCDF: 'linear-gradient(in oklab 135deg, #607D8B 0.01%, #142228 100%)',
          },
          dataSourceSimple: {
            RASTER: '#28B8D5',
            VECTOR: '#00CCCC',
            ANNOTATION: '#00CCCC',
            DRAWING: '#00CCCC',
            VIDEO: '#3F51B5',
            DOCUMENT: '#159957',
            UNKNOWN: '#607D8B',
            SPATIAL: '#607D8B',
            NETCDF: '#607D8B',
          },
          mapShadow: 'inset 0 0 200px 150px #020e17',
        },
        overlays: [
          'linear-gradient(in oklab 154.92deg, #1B2539 100%, #121723 100%)', // design elevation 0 = elevation 0
          '#121723', // design elevation 1 = elevation 1 - 6
          '#121723',
          '#121723',
          '#121723',
          '#121723',
          '#121723',
          '#121723', // design elevation 2 = elevation 7 - 12
          '#121723',
          '#121723',
          '#121723',
          '#121723',
          '#121723',
          '#202A3E', // design elevation 3 = elevation 13 - 18
          '#202A3E',
          '#202A3E',
          '#202A3E',
          '#202A3E',
          '#202A3E',
          '#27344C', // design elevation 4 = elevation 19 - 24
          '#27344C',
          '#27344C',
          '#27344C',
          '#27344C',
          '#27344C',
        ],
        shadows: [
          'none',
          '0px 2px 1px -1px rgb(0 0 0 / 0.2), 0px 1px 1px rgb(0 0 0 / 0.14), 0px 1px 3px rgb(0 0 0 / 0.12)',
          '0px 3px 1px -2px rgb(0 0 0 / 0.2), 0px 2px 2px rgb(0 0 0 / 0.14), 0px 1px 5px rgb(0 0 0 / 0.12)',
          '0px 3px 3px -2px rgb(0 0 0 / 0.2), 0px 3px 4px rgb(0 0 0 / 0.14), 0px 1px 8px rgb(0 0 0 / 0.12)',
          '0px 2px 4px -1px rgb(0 0 0 / 0.2), 0px 4px 5px rgb(0 0 0 / 0.14), 0px 1px 10px rgb(0 0 0 / 0.12)',
          '0px 3px 5px -1px rgb(0 0 0 / 0.2), 0px 5px 8px rgb(0 0 0 / 0.14), 0px 1px 14px rgb(0 0 0 / 0.12)',
          '0px 3px 5px -1px rgb(0 0 0 / 0.2), 0px 6px 10px rgb(0 0 0 / 0.14), 0px 1px 18px rgb(0 0 0 / 0.12)',
          '0px 4px 5px -2px rgb(0 0 0 / 0.2), 0px 7px 10px 1px rgb(0 0 0 / 0.14), 0px 2px 16px 1px rgb(0 0 0 / 0.12)',
          '0px 5px 5px -3px rgb(0 0 0 / 0.2), 0px 8px 10px 1px rgb(0 0 0 / 0.14), 0px 3px 14px 2px rgb(0 0 0 / 0.12)',
          '0px 5px 6px -3px rgb(0 0 0 / 0.2), 0px 9px 12px 1px rgb(0 0 0 / 0.14), 0px 3px 16px 2px rgb(0 0 0 / 0.12)',
          '0px 6px 6px -3px rgb(0 0 0 / 0.2), 0px 10px 14px 1px rgb(0 0 0 / 0.14), 0px 4px 18px 3px rgb(0 0 0 / 0.12)',
          '0px 6px 7px -4px rgb(0 0 0 / 0.2), 0px 11px 15px 1px rgb(0 0 0 / 0.14), 0px 4px 20px 3px rgb(0 0 0 / 0.12)',
          '0px 7px 8px -4px rgb(0 0 0 / 0.2), 0px 12px 17px 2px rgb(0 0 0 / 0.14), 0px 5px 22px 4px rgb(0 0 0 / 0.12)',
          '0px 7px 8px -4px rgb(0 0 0 / 0.2), 0px 13px 19px 2px rgb(0 0 0 / 0.14), 0px 5px 24px 4px rgb(0 0 0 / 0.12)',
          '0px 7px 9px -4px rgb(0 0 0 / 0.2), 0px 14px 21px 2px rgb(0 0 0 / 0.14), 0px 5px 26px 4px rgb(0 0 0 / 0.12)',
          '0px 8px 9px -5px rgb(0 0 0 / 0.2), 0px 15px 22px 2px rgb(0 0 0 / 0.14), 0px 6px 28px 5px rgb(0 0 0 / 0.12)',
          '0px 8px 10px -5px rgb(0 0 0 / 0.2), 0px 16px 24px 2px rgb(0 0 0 / 0.14), 0px 6px 30px 5px rgb(0 0 0 / 0.12)',
          '0px 8px 11px -5px rgb(0 0 0 / 0.2), 0px 17px 26px 2px rgb(0 0 0 / 0.14), 0px 6px 32px 5px rgb(0 0 0 / 0.12)',
          '0px 9px 11px -5px rgb(0 0 0 / 0.2), 0px 18px 28px 2px rgb(0 0 0 / 0.14), 0px 7px 34px 6px rgb(0 0 0 / 0.12)',
          '0px 9px 12px -6px rgb(0 0 0 / 0.2), 0px 19px 29px 2px rgb(0 0 0 / 0.14), 0px 7px 36px 6px rgb(0 0 0 / 0.12)',
          '0px 10px 13px -6px rgb(0 0 0 / 0.2), 0px 20px 31px 3px rgb(0 0 0 / 0.14), 0px 8px 38px 7px rgb(0 0 0 / 0.12)',
          '0px 10px 13px -6px rgb(0 0 0 / 0.2), 0px 21px 33px 3px rgb(0 0 0 / 0.14), 0px 8px 40px 7px rgb(0 0 0 / 0.12)',
          '0px 10px 14px -6px rgb(0 0 0 / 0.2), 0px 22px 35px 3px rgb(0 0 0 / 0.14), 0px 8px 42px 7px rgb(0 0 0 / 0.12)',
          '0px 11px 14px -7px rgb(0 0 0 / 0.2), 0px 23px 36px 3px rgb(0 0 0 / 0.14), 0px 9px 44px 8px rgb(0 0 0 / 0.12)',
          '0px 11px 15px -7px rgb(0 0 0 / 0.2), 0px 24px 38px 3px rgb(0 0 0 / 0.14), 0px 9px 46px 8px rgb(0 0 0 / 0.12)',
        ],
      },
    },
  });

const createUnionTheme = (dir?: DIRECTION): ReturnType<typeof extendTheme> => {
  const firstTheme = createFirstTheme(dir);
  const secondTheme = createSecondTheme(dir);
  return {
    ...firstTheme,
    colorSchemes: {
      ...firstTheme.colorSchemes,
      uae_federal: secondTheme.colorSchemes.light,
      aid: secondTheme.colorSchemes.dark,
    },
  };
};

// const { vars: themeVars, generateCssVars } = prepareCssVars(unionTheme, { prefix: MUI_PREFIX });

export const createTheme = (dir?: 'rtl' | 'ltr') => {
  const unionTheme = createUnionTheme(dir);
  const { vars: themeVars, generateCssVars } = prepareCssVars(unionTheme, { prefix: MUI_PREFIX });
  return {
    ...unionTheme,
    vars: themeVars,
    generateCssVars,
  };
};

export const theme = createTheme('ltr');

export const themeRTL = createTheme('rtl');

export const useThemeForProvider = () => {
  const { i18n } = useTranslation();
  const { data: session } = useSession();
  const { data: userSettings } = useUserSettings(!!session?.user.id);

  const language = useMemo(() => userSettings?.language ?? i18n.language, [i18n.language, userSettings?.language]);
  const [appliedTheme, setAppliedTheme] = useState(language === 'ar' ? themeRTL : theme);

  useEffect(() => {
    setAppliedTheme(language === 'ar' ? themeRTL : theme);
    document.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  return appliedTheme;
};

export const FADE_VARIANTS = {
  hidden: { opacity: 0 },
  enter: { opacity: 1, transition: { duration: 0.3, easing: EASING.LEGACY } },
  exit: { opacity: 0, transition: { duration: 0.2, easing: EASING.LEGACY } },
};

export const SPRING_VARIANTS = {
  hidden: { scale: 0 },
  visible: {
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 260,
      damping: 20,
    },
  },
};

export const SPRING_TRANSITIONS = {
  variants: SPRING_VARIANTS,
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

export const FADE_TRANSITIONS = {
  variants: FADE_VARIANTS,
  initial: 'hidden',
  animate: 'enter',
  exit: 'exit',
};

const DEFAULT_PAGE_VARIANTS = {
  hidden: { opacity: 0, scale: 0.99 },
  enter: { opacity: 1, scale: 1, transition: { duration: 0.3, easing: EASING.LEGACY } },
  exit: { opacity: 0, scale: 1, transition: { duration: 0.2, easing: EASING.LEGACY } },
};

export const DEFAULT_PAGE_TRANSITIONS = {
  variants: DEFAULT_PAGE_VARIANTS,
  initial: 'hidden',
  animate: 'enter',
  exit: 'exit',
};

//Transition Variants
export const CARD_VARIANTS = {
  hiddenBottom: { opacity: 0, x: 0, y: 200 },
  hiddenLeft: { opacity: 0, x: -200, y: 0 },
  hiddenRight: { opacity: 0, x: 200, y: 0 },
  enterOutside: { opacity: 1, x: 0, y: 0, transition: { duration: 0.3, easing: EASING.LEGACY } },
  enterInside: { opacity: 1, x: 0, y: 0, transition: { duration: 0.4, easing: EASING.EMPHASIZED_DECELERATE } },
  exitOutside: { opacity: 0, x: 0, y: 0, transition: { duration: 0.2, easing: EASING.LEGACY } },
  exitLeft: { opacity: 0, x: -200, y: 0, transition: { duration: 0.2, easing: EASING.EMPHASIZED_ACCELERATE } },
  exitRight: { opacity: 0, x: 200, y: 0, transition: { duration: 0.2, easing: EASING.EMPHASIZED_ACCELERATE } },
};
