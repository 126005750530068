import { Icon, IconButton } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { ReactNode } from 'react';

enum NotificationType {
  DEFAULT = 'default',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

const DEFAULT_DURATION = 5;

export type NotificationId = string | number;
type NotificationFn = (message: ReactNode, durationInSeconds?: number | false) => NotificationId;
type RiseNotificationFn = (variant: string, message: ReactNode, durationInSeconds?: number | false) => NotificationId;

export type INotification = Record<NotificationType, NotificationFn> & {
  close: (id: NotificationId) => void;
};

const dto = (variant: any, message: any, durationInSeconds: any): any => ({
  message,
  variant,
  persist: !durationInSeconds,
  autoHideDuration: durationInSeconds ? Math.round(durationInSeconds * 1000) : 0,
});

export const closeSnackbarAction = (id: NotificationId) => (
  <IconButton onClick={() => Notification.close(id)} color="inherit">
    <Icon>close</Icon>
  </IconButton>
);

const closeNotification = (id: NotificationId) => closeSnackbar(id);

const riseEvent: RiseNotificationFn = (variant, message, durationInSeconds = DEFAULT_DURATION) =>
  enqueueSnackbar(dto(variant, message, durationInSeconds));

export const Notification: INotification = {
  default: (message, durationInSeconds) => riseEvent(NotificationType.DEFAULT, message, durationInSeconds),
  info: (message, durationInSeconds) => riseEvent(NotificationType.INFO, message, durationInSeconds),
  success: (message, durationInSeconds) => riseEvent(NotificationType.SUCCESS, message, durationInSeconds),
  warning: (message, durationInSeconds) => riseEvent(NotificationType.WARNING, message, durationInSeconds),
  error: (message, durationInSeconds) => riseEvent(NotificationType.ERROR, message, durationInSeconds),
  close: (id: NotificationId) => closeNotification(id),
};
