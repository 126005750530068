import { Language, User, UserId } from '@/models';
import { userApi } from '@/cmd/UserApi';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';

const SYSTEM: User = {
  id: 'system',
  fullname: 'System',
  username: 'system',
  hasAvatar: false,
  email: '',
  tenantId: '',
  roles: [],
  active: true,
  forceResetPassword: false,
  language: Language.EN,
};
export const useUser = (userId?: UserId | null) => {
  const [user] = useUsersByIds([userId ?? undefined]);
  return user;
};

export const useUsersByIds = (userIds: (UserId | undefined)[]) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['users_by_id', userIds],
    queryFn: () => userApi().getListById(userIds),
    enabled: userIds.some((uid) => !!uid),
    staleTime: minutesToMilliseconds(15),
    placeholderData: keepPreviousData,
  });

  if (isError) return [];

  return isLoading ? [] : userIds.map((id) => (id === 'system' ? SYSTEM : data?.find((u) => u.id === id)));
};
