import { getType, isEmpty, isPrimitive } from './index';
import { Type } from '@/models';

export const runSortBy = <T = any>(key: keyof T) => {
  return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export const sortBy = <T = any>(obj: T[], key: keyof T) => {
  return obj.concat().sort(runSortBy(key));
};

export const filterBy = <T>(str: string, key: string, obj: T[]) => obj.filter((o: any) => o[key].match(new RegExp(`^.*${str}.*`, 'gi')));

export const arrayUnique = <T = any>(...arrays: any[]): T[] => [...(new Set(arrays.filter((a) => Array.isArray(a)).flat()) as any)];

export const arrayUniqueByKey = <T = any>(arr: T[], key: keyof T = 'id' as any): T[] => {
  const keys: any = {};
  const res: T[] = [];
  arr.forEach((item) => {
    if (!keys[item[key]]) {
      res.push(item);
      keys[item[key]] = true;
    }
  });
  return res;
};

export const isArraysEqualsIgnoreOrder = (a: any[], b: any[], objectKey?: string) => {
  if (a.length !== b.length) {
    return false;
  }

  for (const v of a) {
    if ((objectKey ? b.findIndex((i) => i[objectKey] === v[objectKey]) : b.indexOf(v)) < 0) {
      return false;
    }
  }
  for (const v of b) {
    if ((objectKey ? a.findIndex((i) => i[objectKey] === v[objectKey]) : a.indexOf(v)) < 0) {
      return false;
    }
  }
  return true;
};

export const isArraysEqualsSameOrder = (a: any[], b: any[], objectKey?: string) => {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (objectKey ? a[i][objectKey] !== b[i][objectKey] : a[i] !== b[i]) {
      return false;
    }
  }

  return true;
};

export const getArrayDifference = <T = any>(a: T[], b: T[], objectKey?: keyof T): T[] => {
  return a.filter((aVal: any) => !b.some((bVal: any) => (objectKey ? aVal[objectKey] === bVal[objectKey] : aVal === bVal)));
};

export const getArrayUpdates = <T = any>(a: T[], b: T[], objectKey?: keyof T): { added: T[]; removed: T[] } => {
  const removed = getArrayDifference(a, b, objectKey);
  const added = getArrayDifference(b, a, objectKey);
  return { added, removed };
};

export const arrayChunk = <T = any>(
  arr: T[],
  chunkSize: number,
  callback?: (chunk: T[], idx: number) => false | void,
): T[][] | undefined => {
  const res = [];
  const len = arr.length;
  let idx = 0;
  for (let i = 0; i < len; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    if (callback) {
      if (callback(chunk, idx++) === false) {
        break;
      }
    } else {
      res.push(chunk);
    }
  }
  return callback ? undefined : res;
};

export const arrayMerge = (...args: any[]): any[] => args.reduce((acc, arr) => (!!arr ? acc.concat(arr) : acc), []);

export const arrayMergeChunks = <T>(chunks: T[][]): T[] => ([] as T[]).concat(...chunks);

export const arrayExtractByKey = <T = any>(input: T[], key: keyof T | 'id' = 'id') => input.map((item) => (item as any)[key]);

type ArrayGetByKeysFn = {
  <T = any>(sourceArray: T[], list: string[] | number[], clearResult?: true, key?: keyof T | 'id'): T[];
  <T = any>(sourceArray: T[], list: string[] | number[], clearResult?: false, key?: keyof T | 'id'): (T | undefined)[];
};

export const arrayGetByKeys: ArrayGetByKeysFn = <T = any>(
  sourceArray: T[],
  list: string[] | number[],
  clearResult = true,
  key: keyof T | 'id' = 'id',
): T[] | (T | undefined)[] => {
  if (clearResult) {
    const clearList: T[] = [];
    list.forEach((keyValue) => {
      const item = sourceArray.find((i) => i[key as keyof T] === keyValue);
      item && clearList.push(item);
    });
    return clearList;
  } else {
    return list.map((keyValue) => sourceArray.find((i) => i[key as keyof T] === keyValue));
  }
};

export const arrayToMap = <T extends object>(input: T[] | undefined, key: keyof T | 'id' = 'id'): Record<string, T> => {
  const map: Record<string, T> = {};
  (input || []).forEach((item) => (map[(item as any)[key] + ''] = item));
  return map;
};

export const arrayRemoveByIdx = <T = any>(items: T[], idx: number, count = 1, insert: T[] = []): T[] => {
  if (idx >= 0) {
    return items.toSpliced(idx, count, ...insert);
  }
  return items;
};

type ArrayRemoveItemFn = {
  <T = any>(items: T[], removeConditionFn: (v: T) => boolean): T[];
  <T = any>(items: T[], removeCondition: any): T[];
};

export const arrayRemoveItem: ArrayRemoveItemFn = <T = any>(items: T[], valueOrFn: any): T[] => {
  let idx = -1;
  if (!items || !Array.isArray(items) || !items.length) {
    return items;
  } else if (isPrimitive(valueOrFn)) {
    idx = items.indexOf(valueOrFn);
  } else if (getType(valueOrFn) === Type.FUNCTION) {
    idx = items.findIndex(valueOrFn);
  } else {
    idx = items.findIndex((val) => {
      const keys = Object.keys(valueOrFn);
      let valid = true;
      while (valid && keys.length) {
        const curr = keys.pop()!;
        valid = (val as any)[curr] === valueOrFn[curr];
      }
      return valid;
    });
  }

  return arrayRemoveByIdx(items, idx);
};

export const arrayToggleItem = <T = any>(items: T[], item: T): T[] => {
  if (!items.length) {
    return [item];
  }

  const idx = items.findIndex((itm) => item === itm);
  return idx >= 0 ? arrayRemoveByIdx(items, idx) : [...items, item];
};

export const arrayRemoveEmpty = <T = any>(items: (T | null | undefined)[]): T[] =>
  items.filter((item) => item !== undefined && item !== null) as T[];

export const toArray = <T = any>(value?: T | T[], allowEmpty = false): T[] | undefined => {
  if (Array.isArray(value)) {
    return value;
  }
  return isEmpty(value) ? (allowEmpty ? [] : undefined) : [value!];
};

export const arrayMinMax = (arrayOfNumbers: number[]) => {
  let min = Infinity;
  let max = -Infinity;

  for (let i = 1; i < arrayOfNumbers.length; i++) {
    if (!isNaN(arrayOfNumbers[i])) {
      if (arrayOfNumbers[i] < min) {
        min = arrayOfNumbers[i];
      }
      if (arrayOfNumbers[i] > max) {
        max = arrayOfNumbers[i];
      }
    }
  }
  return [min, max];
};

export const getPrevElement = <T = any>(arr: T[], index: number, step = 1) => {
  return arr[index - step >= 0 ? index - step : index - step + arr.length];
};

export const getNextElement = <T = any>(arr: T[], index: number, step = 1) => {
  return arr[(index + step) % arr.length];
};
