export type CacheKeyFn = (...args: any[]) => string;
export type ConditionFn = (res: any) => boolean;
export type ClearCacheFn = (key?: string) => void;
export const cacheKeyFn: CacheKeyFn = (...args: any) => args.map((e: any) => JSON.stringify(e)).join('-');
export interface CacheStore {
  [key: string]: any;
}

const DEFAULT_CACHE_LIFETIME = 60;

export function cache<T>(
  keyF: CacheKeyFn,
  clearCacheFunctionName?: string,
  cacheLifetimeInSeconds: number = DEFAULT_CACHE_LIFETIME,
  cacheCondition?: ConditionFn,
) {
  return (Class: T, propName: string, propDescription: PropertyDescriptor) => {
    const func = propDescription.value;
    let store: CacheStore = {};
    if (typeof func === 'function' && typeof window !== 'undefined') {
      //only cache on client
      propDescription.value = function (...args: any[]) {
        const key = keyF.apply(this, args);
        if (store[key] === undefined) {
          if (cacheLifetimeInSeconds) {
            setTimeout(() => delete store[key], cacheLifetimeInSeconds * 1000);
          }
          const value = func.apply(this, args);
          if (!cacheCondition || cacheCondition(value)) {
            store[key] = value;
          }
          return value;
        } else {
          return store[key];
        }
      };
      if (clearCacheFunctionName) {
        // @ts-ignore
        Class[clearCacheFunctionName] = (key?: string) => {
          if (key) {
            delete store[key];
          } else {
            store = {};
          }
        };
      }
    }
  };
}
