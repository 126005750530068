import { SourceData, SourceDataId } from '@/models';
import Particle3D from '@/components/Cesium/utils/wind/Particle3D';
import { Controller } from '@/components/Cesium/Map/Controller';
import { getNetCDFFields, getNetCDFVariables, NetCDFWindOptions } from '@/components/Cesium/utils/wind/utils';
import { ingestionApi } from '@/cmd/IngestionApi';
import { cancelCatchHandler, CustomPromise } from '@/utils/CustomPromise';
import { getShaders } from '@/components/Cesium/utils/wind/shader';
import CustomPrimitive from '@/components/Cesium/utils/wind/CustomPrimitive';
import windOptionsStore from '@/stores/wind-options.store';
import { AbstractSourceDataLayer } from '@/components/Cesium/Layers/AbstractSourceDataLayer';
import type { RequestPromise } from '@/cmd/Api';
import { CesiumPrimitivesLayer } from '@/components/Cesium/Layers/models';

export interface Options {
  mapCtl: Controller;
}

export class WindLayer extends AbstractSourceDataLayer implements CesiumPrimitivesLayer {
  readonly ready = CustomPromise<any>();
  protected _show = false;
  protected options!: NetCDFWindOptions;
  protected particleInstance?: Particle3D;
  protected inputDataPromise?: RequestPromise<Blob>;
  protected mapCtl: Controller;
  protected optionsListenerDisposer: () => void;

  constructor(sourceData: SourceData, options: Options) {
    super(sourceData);

    this.mapCtl = options.mapCtl;
    this.optionsListenerDisposer = windOptionsStore.subscribe((sourceId: SourceDataId) => {
      if (this.id === sourceId) {
        void this.init();
      }
    });
    void this.init();
  }

  set show(value: boolean) {
    this.primitives.forEach((primitive) => (primitive.show = value));
    this._show = value;
  }

  get show(): boolean {
    return this._show;
  }

  protected async init() {
    this.options = windOptionsStore.get(this.id);
    this.options.fields = this.options.fields || getNetCDFFields(this.sourceData);

    if (this.mapCtl.isAvailable() && this.validateFieldsMap()) {
      await getShaders();

      const viewer = this.mapCtl.viewer;
      {
        // viewer.scene.fog.density = 0.0001;
        // viewer.scene.globe.enableLighting = false;
        // viewer.scene.skyBox.show = false;
        // if(Cesium.FeatureDetection.supportsImageRenderingPixelated()){// Check if image rendering pixelation is supported
        //   viewer.resolutionScale = window.devicePixelRatio;
        // }
        // @ts-ignore
        // viewer.scene.fxaa = true;
        // viewer.scene.postProcessStages.fxaa.enabled = true;
      }

      this.reset();
      this.isLoading = true;
      try {
        const blob = await this.fetchInputData();
        const options = { ...this.options, input: blob };
        this.particleInstance = new Particle3D(viewer, options);
        await this.particleInstance.init();
        this.particleInstance?.show();
        return this.ready.resolve([]); // Todo: return list of particles primitives
      } catch (e) {
        cancelCatchHandler(e);
        this.reset();
      } finally {
        this.isLoading = false;
      }
    }
  }

  protected fetchInputData() {
    if (!this.inputDataPromise) {
      this.inputDataPromise = ingestionApi().fetchFile(this.sourceData.fileId);
    }
    return this.inputDataPromise;
  }

  protected validateFieldsMap(): boolean {
    if (!this.options.fields || !this.options.fields['U'] || !this.options.fields['V']) {
      return false;
    }
    const variablesKeys = getNetCDFVariables(this.sourceData);
    return !Object.values(this.options.fields).find((key) => !variablesKeys.includes(key));
  }

  protected reset() {
    this.particleInstance?.remove();
    this.particleInstance = undefined;
  }

  get primitives(): CustomPrimitive[] {
    return this.particleInstance?.primitives || [];
  }

  destroy() {
    super.destroy();
    this.inputDataPromise?.cancel();
    this.optionsListenerDisposer();
    this.reset();
    this.loadingEvent.destroy();
  }
}
