/* eslint-disable no-bitwise */
import { RGBColor } from 'react-color';
import { AnyObject, HexColor, Type } from '@/models';
import {
  endOfDay,
  format as dateFormat,
  formatDuration as formatTimeDuration,
  intervalToDuration,
  isValid,
  parse as parseDate,
  startOfDay,
} from 'date-fns';
import { UTCDate } from '@date-fns/utc';
import { EMPTY, UTC_ENDING } from '@/consts';

export {
  arrayUniqueByKey,
  arrayUnique,
  arrayChunk,
  arrayExtractByKey,
  getArrayDifference,
  arrayMergeChunks,
  getArrayUpdates,
  isArraysEqualsIgnoreOrder,
} from './array';

export type ObjectEntries<T> = { [K in keyof T]: [K, T[K]] }[keyof T][];
export type ArrayOfObjects<T = unknown> = AnyObject<T>[];
export type InputDate = Date | number | string | null | undefined;
export enum IsoOptions {
  END_OF_DAY,
  START_OF_DAY,
}

export const SIMPLE_DATE_FORMAT = 'dd/MM/yyyy';
export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'dd/MMM/yyyy';
export const DATE_TIME_FORMAT = 'dd/MMM/yyyy HH:mm';

export const FULL_DATE_TIME_FORMAT = 'dd/MMM/yyyy HH:mm:ss';
export const SIMPLE_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
export const FULL_DASHED_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

const SIZE_DICT = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
const NUMBER_DICT = ['', 'K', 'M', 'B', 'T'];
const BYTE_SIZE_DICT = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const format: AnyObject<string> = {
  xSeconds: '{{c}}s',
  xMinutes: '{{c}}m',
  xHours: '{{c}}h',
  xDays: '{{c}}d',
  lessThanXSeconds: '0s',
  halfAMinute: '30s',
  lessThanXMinutes: '<{{c}}m',
  aboutXHours: '~{{c}}h',
  aboutXWeeks: '~{{c}}W',
  xWeeks: '{{c}}W',
  aboutXMonths: '~{{c}}M',
  xMonths: '{{c}}M',
  aboutXYears: '~{{c}}Y',
  xYears: '{{c}}Y',
  overXYears: '>{{c}}Y',
  almostXYears: '~{{c}}Y',
};
const locale = {
  formatDistance: (token: string, count: number) => format[token].replace('{{c}}', count.toString()),
};

export const round = (num: number, precision: number = 0) => {
  const factorOfTen = Math.pow(10, precision);
  return Math.round(num * factorOfTen) / factorOfTen;
};

export const readableNumbers = (num: number, precision: number = 2, space = ' ', dict = NUMBER_DICT): string => {
  if (num === 0) {
    return '0' + space + dict[0];
  }
  const e = Math.floor(Math.log(Math.abs(num)) / Math.log(1000));
  return round(num / Math.pow(1000, e), precision) + space + dict[e];
};

export const formatArea = (area: number) => {
  let output;
  if (area > 1e4) {
    output = (area / 1e6).toFixed(2) + ' km²';
  } else {
    output = area.toFixed(2) + ' m²';
  }
  return output;
};

export const formatNumber = (num: number, precision: number = 1) => readableNumbers(num, precision, '', SIZE_DICT);

export const formatByteSize = (num: number, precision: number = 2) => {
  return readableNumbers(num, precision, ' ', BYTE_SIZE_DICT);
};

export const formatCurrency = (value: number, currency: string = 'USD') => {
  return new Intl.NumberFormat('en-EN', { style: 'currency', currency }).format(value);
};

export const getTimezoneOffset = (date: Date = new Date(), raw = false) => {
  const offset = date.getTimezoneOffset();
  if (raw) {
    return offset;
  }
  const hours = Math.ceil(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) - hours * 60;
  return (offset > 0 ? '-' : '+') + numberPad(hours) + ':' + numberPad(minutes);
};

export const renderTimeUTC = (date: InputDate, empty: any = EMPTY, format: string = TIME_FORMAT) => {
  if (date) {
    const utcDate = new UTCDate(date);
    return renderTime(utcDate, empty, format) + UTC_ENDING;
  }
  return empty;
};
export const renderTime = (date: InputDate, empty: any = EMPTY, format: string = TIME_FORMAT) => (date ? dateFormat(date, format) : empty);
export const renderDate = (date: InputDate, empty: any = EMPTY, format: string = DATE_FORMAT) => (date ? dateFormat(date, format) : empty);

export const renderDateTime = (date: InputDate, empty: any = EMPTY, format = DATE_TIME_FORMAT) => renderDate(date, empty, format);

export const renderDateTimeUTC = (date: InputDate, empty: any = EMPTY, format: string = DATE_TIME_FORMAT) => {
  if (date) {
    const utcDate = new UTCDate(date);
    return renderDateTime(utcDate, empty, format) + UTC_ENDING;
  }
  return empty;
};

export const toIsoDate = (date: InputDate | string, options?: IsoOptions): string | undefined => {
  if (typeof date === 'number') {
    date = new Date(date);
  } else if (typeof date === 'string') {
    date = parseDate(date, DATE_FORMAT, new Date());
  }

  if (date instanceof Date && isValid(date)) {
    switch (options) {
      case IsoOptions.START_OF_DAY:
        return startOfDay(date).toISOString();

      case IsoOptions.END_OF_DAY:
        return endOfDay(date).toISOString();

      default:
        return date.toISOString();
    }
  }

  return undefined;
};

export const formatDuration = (timeInSeconds?: number) => {
  if (timeInSeconds === 0) {
    return '0m 0s';
  }

  if (typeof timeInSeconds !== 'number' || isNaN(timeInSeconds)) {
    return EMPTY;
  }

  return formatTimeDuration(intervalToDuration({ start: 0, end: timeInSeconds }), { locale });
};

export const numberPad = (num: number, targetLength: number = 2, pad: string = '0') => String(num).padStart(targetLength, pad);

/**
 * Return new object with keys from the list
 *
 * @param {Object}  obj
 * @param {string[]} list
 */
export const clearObject = <T extends Object>(obj: T, list: (keyof T)[]): Partial<T> => {
  const res: any = {};
  list.forEach((key) => (res[key] = obj[key]));
  return res;
};

export enum RemoveOption {
  NONE = 0,
  EMPTY_ARRAY = 0b001,
  EMPTY_OBJECT = 0b010,
}

type RemoveEmptyFn = {
  (value?: null, option?: RemoveOption): undefined;
  <T = any>(value: T, option?: RemoveOption): T | Partial<T>;
  <T = any>(value: (T | undefined | null | '')[], option?: RemoveOption): T[];
};

export const isEmpty = (val: any, extended = false): boolean =>
  [undefined, null, ''].concat(extended ? ([false, 0] as any) : []).includes(val);

/**
 * Remove keys with value undefined or null or empty arrays/objects when given the option
 */
export const removeEmpty: RemoveEmptyFn = (value?: any, option = RemoveOption.NONE): any => {
  const type = getType(value);
  if (!value || ![Type.ARRAY, Type.OBJECT].includes(type)) {
    return undefined;
  }
  const isArray = type === Type.ARRAY;
  const res: any = isArray ? [] : {};
  const keys = isArray ? [...Array(value.length)].map((_, idx) => idx) : Object.keys(value);
  keys.forEach((key) => {
    if (isEmpty(value[key])) {
      return;
    }
    if (option & RemoveOption.EMPTY_ARRAY && Array.isArray(value[key]) && value[key].length === 0) {
      return;
    }
    if (option & RemoveOption.EMPTY_OBJECT && isEmptyObject(value[key])) {
      return;
    }

    if (isArray) {
      res.push(value[key]);
    } else {
      res[key] = value[key];
    }
  });

  return res;
};

export const isPrimitive = (val: any): boolean => val !== Object(val);

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const getObjectKeys = <T extends {}>(obj: T) => Object.keys(obj) as Array<keyof T>;

export const getObjectEntries = <T extends {}>(obj: T) => Object.entries(obj) as ObjectEntries<T>;

export const rgbToHex = (rgb: RGBColor): HexColor => {
  const rgbHex = '#' + [rgb.r, rgb.g, rgb.b].map((c) => numberToHex(round(c))).join('');
  const alpha = 'a' in rgb && rgb.a !== undefined && rgb.a !== 1 ? numberToHex(round(rgb.a * 255)) : '';
  return rgbHex + alpha;
};

export const invertColor = <T extends RGBColor | HexColor>(color: T): T | undefined => {
  const rgb: any = typeof color === 'string' ? hexToRgb(color) : color;
  if (rgb) {
    Object.keys(rgb).forEach((key) => {
      if (key === 'a') {
        return;
      }
      rgb[key] = 255 - rgb[key];
    });
    return typeof color === 'string' ? rgbToHex(rgb) : rgb;
  }
};

export const hexToRgb = (hex: HexColor): RGBColor | undefined => {
  let res: number[] | undefined;
  if (hex) {
    hex = hex.toString().replace('#', '');
    const len = hex.length;
    if (len === 3) {
      res = hex.match(/./g)?.map((i) => parseInt(i + i, 16));
    } else if (len === 6 || len === 8) {
      res = hex.match(/.{2}/g)?.map((i) => parseInt(i, 16));
      if (res && res[3]) {
        res[3] = round(res[3] / 255, 2);
      }
    }
  }
  if (!res) {
    return undefined;
  }
  const [r, g, b, a] = res;
  return { r, g, b, a };
};

export const addOpacity = (color: HexColor, opacity: number) => {
  if (color && color.startsWith('#')) {
    const opacityHex = Math.round(opacity * 255).toString(16);
    return `${color}${opacityHex.length < 2 ? '0' : ''}${opacityHex}`;
  }
  return color;
};

const numberToHex = (num: number) => {
  const hex = Number(num).toString(16);
  return (hex.length < 2 ? '0' : '') + hex;
};

export const getColorFromRange = (startColor: RGBColor, endColor: RGBColor, rangeSize: number, offset: number): RGBColor => {
  const color = { r: 0, g: 0, b: 0 };
  const step = (1 / rangeSize) * (rangeSize - offset);

  color.r = startColor.r * step + (1 - step) * endColor.r;
  color.g = startColor.g * step + (1 - step) * endColor.g;
  color.b = startColor.b * step + (1 - step) * endColor.b;

  return color;
};

export const getColorFromRanges = (ranges: HexColor[], valuesCount: number, offset: number) => {
  const rangeLength = ranges.length;
  if (rangeLength === 2) {
    return getColorFromRange(hexToRgb(ranges[0])!, hexToRgb(ranges[1])!, valuesCount, offset);
  }
  const rangeSize = Math.ceil(valuesCount / (rangeLength - 1));
  const offsetRange = Math.floor(offset / rangeSize);
  const startColor = hexToRgb(ranges[offsetRange])!;
  const endColor = hexToRgb(ranges[offsetRange + 1])!;
  return getColorFromRange(startColor, endColor, rangeSize, offset % rangeSize);
};

type GetColorRangeFn = {
  (startColor: HexColor, endColor: HexColor, rangeSize: number): HexColor[];
  (range: HexColor[], rangeSize: number): HexColor[];
};

export const getColorRange: GetColorRangeFn = (
  startColorOrRange: HexColor | HexColor[],
  endColorOrValuesCount: HexColor | number,
  valuesCount?: number,
): HexColor[] => {
  valuesCount = typeof valuesCount === 'number' ? valuesCount : (endColorOrValuesCount as number);
  const range = Array.isArray(startColorOrRange) ? startColorOrRange : ([startColorOrRange, endColorOrValuesCount] as HexColor[]);
  const rangeLength = range.length;
  const rangeSize = Math.floor(valuesCount / (rangeLength - 1));
  let rangeTailSize = valuesCount - rangeSize * (rangeLength - 1);

  const res = [];
  for (let i = 0; i < rangeLength - 1; i++) {
    const start = hexToRgb(range[i])!;
    const end = hexToRgb(range[i + 1])!;
    const extraRange = rangeTailSize > 0 ? rangeSize + 1 : rangeSize;
    if (rangeTailSize > 0) {
      rangeTailSize--;
    }
    for (let i = 0; i < extraRange; i++) {
      res.push(rgbToHex(getColorFromRange(start, end, extraRange, i)));
    }
  }

  return res;
};

export const getHash = (value: any): number => {
  if (!value) {
    return 0;
  }
  const str = typeof value === 'string' ? value : JSON.stringify(value);
  let i;
  let chr;
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};

type RandomNumberFn = {
  (min: number, max: number, integer?: boolean): number;
  (max: number, integer?: boolean): number;
};

export const random: RandomNumberFn = (minOrMax: number, maxOrInteger?: number | boolean, integer?: boolean) => {
  integer = typeof integer === 'boolean' ? integer : typeof maxOrInteger === 'boolean' ? maxOrInteger : true;
  const min = typeof maxOrInteger === 'number' ? minOrMax : 0;
  const max = typeof maxOrInteger === 'number' ? maxOrInteger : minOrMax;
  const rand = Math.random() * (max - min) + min;
  return integer ? Math.round(rand) : rand;
};

export const numRange = (num: number, min = 0, max?: number) => Math.min(Math.max(num, min), max || Math.max(min, num));

type InRangeFn = {
  (num: number, max: number): boolean;
  (num: number, min: number, max: number): boolean;
};

export const inRange: InRangeFn = (num: number, minMax1: number, minMax2?: number) => {
  if (typeof minMax2 === undefined) {
    return num <= minMax1;
  }
  const min = Math.min(minMax1, minMax2!);
  const max = Math.max(minMax1, minMax2!);
  return num >= min && num <= max;
};

export const getType = (val?: any): Type => {
  const jType = typeof val;
  switch (jType) {
    case 'undefined':
    case 'string':
    case 'boolean':
    case 'function':
    case 'bigint':
    case 'symbol':
      return jType as Type;

    case 'number': {
      if (Number.isInteger(val)) {
        return Type.INTEGER;
      }
      if (isNaN(val)) {
        return Type.NAN;
      }
      return Type.FLOAT;
    }

    case 'object': {
      if (val === null) {
        return Type.NULL;
      }
      if (Array.isArray(val)) {
        return Type.ARRAY;
      }
      return Type.OBJECT;
    }
  }
};

export const castType = (val: any, type: Type, meaningfulType = true) => {
  switch (type) {
    case Type.NULL:
      return meaningfulType ? val : null;
    case Type.NAN:
      return meaningfulType ? val : NaN;
    case Type.UNDEFINED:
      return meaningfulType ? val : undefined;
    case Type.BOOLEAN: {
      if (typeof val === 'string' && ['false', 'true'].includes(val)) {
        return val === 'true';
      }
      return Boolean(val);
    }
    case Type.SYMBOL:
      return Symbol(val);
    case Type.STRING:
      return String(val);
    case Type.BIGINT:
      return BigInt(val);
    case Type.OBJECT:
      return Object(val);
    case Type.FUNCTION:
      return typeof val === 'function' ? val : Function(val);
    case Type.ARRAY:
      return Array.isArray(val) ? val : Array(val);
    case Type.FLOAT:
      return parseFloat(val);
    case Type.INTEGER:
      return parseInt(val);
  }
};

export const isNumber = (val: any, isStringTolerant = false) => {
  const valid = typeof val === 'number' && !Number.isNaN(val);
  if (valid) return true;
  if (!isStringTolerant) return false;
  return typeof val === 'string' && val !== '' && !isNaN(val as any);
};

export const isEmptyObject = (obj?: any) =>
  typeof obj === 'object' && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

export const createUUid = (): string => {
  if ('randomUUID' in crypto) {
    return crypto.randomUUID();
  }

  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

export const isObjectSameAs = <T extends {}>(value1: T, value2: T) => {
  const keys1 = getObjectKeys(value1);
  const keys2 = getObjectKeys(value2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  return !keys1.find((key) => !isSameAs(value1[key], value2[key]));
};

export const isArraySameAs = <T = any>(value1: T[], value2: T[]) => {
  if (value1.length === 0 && value2.length === 0) {
    return true;
  }
  if (value1.length !== value2.length) {
    return false;
  }
  return value1.findIndex((_, idx) => !isSameAs(value1[idx], value2[idx])) < 0;
};

export const isSameAs = (value1: any, value2: any): boolean => {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return isArraySameAs(value1, value2);
  }
  if (getType(value1) === Type.OBJECT && getType(value2) === Type.OBJECT) {
    return isObjectSameAs(value1, value2);
  }
  return value1 === value2;
};

export const wrapFunction =
  <T extends Function>(origFn: T, wrapper: (origFn: T, ...args: any[]) => unknown) =>
  (...args: any[]) =>
    wrapper(origFn, args);

type PassthruFn<T = any> = (data: T) => unknown;
type PassthruWrapper = {
  <T = any>(fn: PassthruFn<T>): (data: T) => T;
  <T = any>(fn: PassthruFn<T>, waitForPromise: boolean): (data: T) => Promise<T>;
};

export const passthruWrapper: PassthruWrapper = <T = any>(fn: PassthruFn<T>, waitForPromise = true) => {
  return (data: T): T | Promise<T> => {
    const res = fn(data);
    if (waitForPromise && res instanceof Promise) {
      return res.then(() => data);
    }
    return data;
  };
};

type TrimFn = {
  (val: string): string;
  (val: string[]): string[];
};
export const trim: TrimFn = (value: any) => {
  if (typeof value === 'string') {
    return value.trim();
  }
  if (Array.isArray(value)) {
    return value.map((v) => v.trim());
  }
  return value;
};

export const isClassInstance = (obj?: object): boolean =>
  !!(obj && typeof obj === 'object' && obj.constructor !== Object && !Array.isArray(obj));

export const hyphensToCamelCase = (str: string) => str.replace(/-([a-z])/gi, (g) => g[1].toUpperCase());

export const camelCaseToHyphens = (str: string) => str.replace(/([a-z])/gi, (g) => g[0] + '-' + g[1].toLowerCase());

export const getPercentageDifference = (value1: number, value2: number) => {
  if (value1 === 0) {
    return value2 === 0 ? 0 : +Infinity;
  }
  return ((value2 - value1) / Math.abs(value1)) * 100;
};

export const RandomColor = () => {
  let hash = '#';
  let zero = '0';
  let randColor = ((Math.random() * 0xffffff) << 0).toString(16);
  while (randColor.length < 6) {
    randColor = zero + randColor;
  }
  return hash + randColor;
};
