import { SourceDataId, VectorStyles } from '@/models';
import { DEFAULT_STYLES } from '@/components/Cesium/utils/styles';
import { dataSourceApi } from '@/cmd/DataSourceApi';

export interface StyleStore {
  [sourceId: string]: VectorStyles;
}

const listeners: Set<(sourceId: SourceDataId) => void> = new Set();
let styles: StyleStore = {};
const history: SourceDataId[] = [];
const historySize = 10;
let counter: number = 0;

function emitChange(sourceId: SourceDataId) {
  for (const listener of listeners) {
    listener(sourceId);
  }
}

const VectorStylesStore = {
  subscribe(listener: (sourceId: SourceDataId) => void) {
    listeners.add(listener);
    return () => {
      listeners.delete(listener);
    };
  },

  getSnapshot() {
    return styles;
  },

  set(sourceId: SourceDataId, newStyles: VectorStyles) {
    history[counter] = sourceId;
    styles = { ...styles, [sourceId]: newStyles };
    counter++;
    if (history.length > historySize) {
      history.shift();
    }
    emitChange(sourceId);
  },

  get(sourceId: SourceDataId): VectorStyles {
    return styles[sourceId] ?? DEFAULT_STYLES;
  },

  load(sourceId: SourceDataId, force = false): Promise<VectorStyles> {
    if (force || !styles[sourceId]) {
      if (force) {
        dataSourceApi().clearStyleCache();
      }
      return dataSourceApi()
        .getVectorStyles(sourceId)
        .then((newStyles) => {
          styles[sourceId] = newStyles || DEFAULT_STYLES;
          emitChange(sourceId);
          return styles[sourceId];
        });
    }
    return Promise.resolve(styles[sourceId]);
  },
};
export default VectorStylesStore;
