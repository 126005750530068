import * as React from 'react';
import { ElementType, PropsWithChildren } from 'react';
import Button from './Button';
import Popper, { PopperProps } from '@mui/material/Popper';
import { useTranslation } from 'next-i18next';
import { m } from 'framer-motion';
import { FADE_TRANSITIONS } from '@/theme';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardHeaderProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';

const StyledPopper = styled(Popper)`
  .MuiCard-root {
    background-image: ${({ theme }) => theme.vars.overlays[0]}; // elevation 0
  }
  .MuiCardHeader-action {
    align-self: initial;
    margin: 0;
  }
`;

interface CustomDialogTitle {
  title: string;
  action?: React.ReactNode;
}

export interface PopperDialogProps {
  open: boolean;
  header?: string | React.ReactNode | CardHeaderProps | CustomDialogTitle;
  actions?: React.ReactNode | false;
  onClose?: () => void;
  component?: ElementType;
  isModal?: boolean;
  PopperProps?: Omit<PopperProps, 'title' | 'children' | 'open'>;
  DialogProps?: Omit<DialogProps, 'open' | 'children'>;
  className?: string;
}

const getTitle = (title: React.ReactNode) => {
  if (React.isValidElement(title)) {
    return title;
  }
  return (
    <Typography variant="body2" color="text.primary">
      {title}
    </Typography>
  );
};

const getHeader = (options: string | React.ReactNode | CardHeaderProps | CustomDialogTitle, isModal?: boolean) => {
  if (typeof options === 'string') {
    return isModal ? <DialogTitle variant="body1">{options}</DialogTitle> : <CardHeader title={getTitle(options)} />;
  }
  if (!isModal) {
    const popperTitle = options as React.ReactNode | CardHeaderProps;
    const props: CardHeaderProps = {};
    if (React.isValidElement(popperTitle)) {
      props.title = getTitle(popperTitle);
    } else {
      Object.assign(props, popperTitle);
      if (props.title) {
        props.title = getTitle(props.title);
      }
    }
    return <CardHeader {...props} />;
  } else {
    const dialogTitle = options as CustomDialogTitle;
    return (
      <DialogTitle variant="body1" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{dialogTitle.title}</span>
        {dialogTitle.action}
      </DialogTitle>
    );
  }
};

const PopperCard: React.FC<PropsWithChildren<Pick<PopperDialogProps, 'PopperProps' | 'className' | 'open'>>> = ({
  PopperProps,
  open,
  children,
  className,
}) => {
  return (
    <StyledPopper {...PopperProps} open={open} className={className}>
      <Card key="popper-card" elevation={3} component={m.div} {...FADE_TRANSITIONS}>
        {children}
      </Card>
    </StyledPopper>
  );
};

const InnerContent = styled(m.div)`
  max-height: 60vh;
  overflow: hidden auto;
  scrollbar-gutter: stable;
`;

export const popperDialogInnerContentClass = 'popper-dialog-inner-content';

export const PopperDialog: React.FC<PropsWithChildren<PopperDialogProps>> = ({
  open,
  children,
  header,
  actions,
  onClose,
  component,
  isModal,
  PopperProps,
  DialogProps,
  className,
}) => {
  const { t } = useTranslation(['common']);
  const Layout = isModal ? Dialog : PopperCard;
  const Content = isModal ? DialogContent : CardContent;
  const Actions = isModal ? DialogActions : CardActions;

  return (
    <Layout {...(!isModal && { PopperProps })} {...(isModal && DialogProps)} open={open} className={className}>
      {!!header && getHeader(header, isModal)}
      <Content sx={{ paddingBlock: 0, paddingInlineEnd: 1 }} {...(!isModal && { component })}>
        <InnerContent className={popperDialogInnerContentClass} layoutScroll>
          {children}
        </InnerContent>
      </Content>
      {actions !== false && (
        <Actions sx={{ justifyContent: 'flex-end' }}>
          {actions ?? (
            <Button autoFocus onClick={onClose}>
              {t('Close')}
            </Button>
          )}
        </Actions>
      )}
    </Layout>
  );
};
