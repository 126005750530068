import { FC, PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

interface Props {
  message?: string;
}
const ErrorFallback: FC<PropsWithChildren<Props>> = ({ message, children }) => {
  const { t } = useTranslation('common');
  return (
    <Box height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={4}>
      <Typography variant="h1">＼⁠(⁠°⁠o⁠°⁠)⁠／</Typography>
      <Typography variant="h6" sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
        {message ?? t('common:application_error')}
      </Typography>
      {children}
    </Box>
  );
};

export default ErrorFallback;
