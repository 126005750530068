import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { AnyObject, Callback, IntervalId } from '@/models';
import { dispatchEvent } from '@/utils/events';
import { useSession } from 'next-auth/react';
import { getConfig } from '@/config';
import { Handlers } from '@/components/MessageManager/Handlers';
import { messagesApi, Options } from '@/cmd/MessagesApi';

interface Heartbeat {
  lastPing?: number;
  enabled?: boolean;
  timer?: IntervalId;
}

const NORMAL_CLOSE_CODE = 1000;
const RECONNECT_TIMEOUT_SEC = 30;
const HEARTBEAT_TIMEOUT_SEC = 30;
const HEARTBEAT_MESSAGE = 'ping';

// TODO: auth
export const MessageManager: FC<PropsWithChildren> = ({ children }) => {
  const config = getConfig();
  const { data: session } = useSession();
  const wsRef = useRef<WebSocket>();
  const heartbeatRef = useRef<Heartbeat>();

  const send = (data?: AnyObject) => wsRef.current && data && wsRef.current.send(JSON.stringify(data));

  const stopHeartbeat = () => {
    const { timer } = heartbeatRef.current || {};
    if (timer) {
      clearInterval(timer);
    }
    heartbeatRef.current = undefined;
  };

  const startHeartbeat = (onFail: Callback) => {
    stopHeartbeat();
    heartbeatRef.current = { enabled: true, lastPing: new Date().getTime() };
    heartbeatRef.current.timer = setInterval(() => {
      const heartbeat = heartbeatRef.current || {};
      if (heartbeat.enabled && heartbeat.lastPing) {
        if (new Date().getTime() - heartbeat.lastPing > HEARTBEAT_TIMEOUT_SEC * 1000) {
          onFail();
        }
      }
    }, HEARTBEAT_TIMEOUT_SEC * 1000);
  };

  const reconnect = (options: Options) => {
    disconnect();
    setTimeout(() => connect(options), options.reconnectTimeOut);
  };

  const disconnect = () => {
    stopHeartbeat();
    wsRef.current && !wsRef.current.CLOSED && !wsRef.current.CLOSING && wsRef.current.close();
    wsRef.current = undefined;
  };

  const connect = (options: Options) => {
    if (!options.url) {
      console.error('WS URL is not defined');
      return;
    }
    messagesApi()
      .createWebsocketConnection(options)
      .then((ws) => {
        wsRef.current = ws;
        ws.onopen = (e) => {
          startHeartbeat(() => {
            console.error('WS heartbeat fail');
            reconnect(options);
          });
        };

        ws.onmessage = (e) => {
          if (e.data === HEARTBEAT_MESSAGE) {
            heartbeatRef.current = { ...heartbeatRef.current, lastPing: new Date().getTime() };
            return;
          }
          try {
            const message = JSON.parse(e.data) || {};
            if ('type' in message) {
              {
                // TODO: debug websocket messages
                const debug = (globalThis as any)?.debugWS;
                if (debug === true || (Array.isArray(debug) && debug.includes(message.type))) {
                  console.log(message);
                }
              }
              dispatchEvent({ type: message.type, data: message });
            }
          } catch (e) {
            console.error(e);
          }
        };

        ws.onclose = (e) => {
          if (e.code !== NORMAL_CLOSE_CODE) {
            reconnect(options);
          }
        };

        ws.onerror = (e) => {
          console.error(e);
          // reconnect(options);
        };
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (config.wsUrl) {
      if (session?.user.token) {
        connect({ url: config.wsUrl, authUrl: config.wsAuthUrl, reconnectTimeOut: RECONNECT_TIMEOUT_SEC * 1000 });
      }

      return () => disconnect();
    }
  }, [session?.user.token]);

  return (
    <>
      {children}
      <Handlers />
    </>
  );
};
