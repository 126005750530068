import { useState } from 'react';
import { GridPaginationModel, GridFeatureMode } from '@mui/x-data-grid';
import { DEFAULT_PAGINATION } from '@/cmd/Api';

export type UseDataGridPaginationOptions = {
  paginationMode?: GridFeatureMode;
};

export const useDataGridPagination = ({ paginationMode = 'server' }: UseDataGridPaginationOptions = {}) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: DEFAULT_PAGINATION.page,
    pageSize: DEFAULT_PAGINATION.pageSize,
  });

  return {
    paginationMode,
    paginationModel,
    onPaginationChange: setPaginationModel,
  };
};
