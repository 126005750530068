import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, prepareParams, RequestPromise } from './Api';
import {
  CategoryAggregation,
  CategoryId,
  Detection,
  DetectionId,
  IngestionJob,
  ModelId,
  SourceDataId,
  UploadedFileId,
  VideoDetection,
} from '@/models';
import { singleton } from '@/utils/decorators/singleton';
import { download } from './download';

interface VideoDetectionsFilter {
  categoryIds?: CategoryId[];
  modelId?: ModelId;
  time?: number;
}

@singleton
class VideoInferenceApi extends Api {
  endpoint: string = '/api/video/inference';
  static getInstance: () => VideoInferenceApi;

  getDetection(detectionId: DetectionId, filter?: VideoDetectionsFilter): RequestPromise<Detection> {
    return this.request(METHOD.GET, '/detection/' + detectionId, { ...filter });
  }

  getDetectionsList(
    sourceDataId: SourceDataId,
    filter?: VideoDetectionsFilter,
    pagination?: PageableRequest,
  ): RequestPromise<PageableResponse<VideoDetection>> {
    return this.request(METHOD.GET, '/' + sourceDataId + '/detections', { ...filter, ...DEFAULT_PAGINATION, ...pagination });
  }

  getDownloadUrl = (fileId: UploadedFileId, force = false) => this.getFullUrl('/download/url?fileId=' + fileId, force);

  getSignedUrl = (fileId: UploadedFileId, modelId: ModelId): RequestPromise<string> => {
    const params = prepareParams({
      fileId,
      modelId,
    });
    return this.request(METHOD.GET, '/download/url' + params);
  };

  getCategoriesAggregation(sourceDataId: SourceDataId, filter?: VideoDetectionsFilter): RequestPromise<CategoryAggregation[]> {
    return this.request(METHOD.GET, '/' + sourceDataId + '/categories/aggregation', { ...filter });
  }

  download = (fileIdOrIngestionJob: UploadedFileId | IngestionJob, modelId: ModelId) => {
    let options: any;
    let fileId = fileIdOrIngestionJob as any;
    if (typeof fileIdOrIngestionJob === 'object') {
      fileId = fileIdOrIngestionJob.fileId;
      options = { name: fileIdOrIngestionJob.originalFilename };
    }
    this.getSignedUrl(fileId, modelId).then((signedUrl) => download(signedUrl, options));

    // TODO: API dosn't work with big files. Probably need to remove it on BE?
    // this.rawRequest(METHOD.GET, '/download', { fileId }, { responseType: 'blob' })
    //   .then((res) => download(res.data))
    //   .catch(console.error);
  };
}

export const videoInferenceApi = () => VideoInferenceApi.getInstance();
