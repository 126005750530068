import { Badge, CircularProgress, Icon, IconButton, Tooltip } from '@mui/material';
import useFileUploader from '@/hooks/useFileUploader';
import { UploadStatus } from '@/models';
import { useTranslation } from 'next-i18next';

const UploadIndicator = () => {
  const { t } = useTranslation(['common']);
  const { toggleOpenDialog, filesUpload, uploadInProgress } = useFileUploader();
  const color = filesUpload.length ? 'action.active' : 'text.primary';
  const uploadComplete = !!filesUpload.length && filesUpload.every((f) => f.status === UploadStatus.UPLOADED);

  return (
    <Tooltip title={t('common:upload')} placement="right">
      <IconButton sx={{ color }} onClick={toggleOpenDialog}>
        {uploadInProgress && <CircularProgress sx={{ position: 'absolute' }} size={32} />}
        <Badge color="success" variant="dot" invisible={!uploadComplete}>
          <Icon>cloud_upload</Icon>
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default UploadIndicator;
