import { singleton } from '@/utils/decorators/singleton';
import { cache, cacheKeyFn, ClearCacheFn } from '@/utils/decorators/cache';
import { TenantId, User, UserBasic, UserId, UserSettings } from '@/models';
import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, prepareParams, RequestPromise, SearchFilter } from './Api';
import { removeEmpty } from '@/utils';

export const LOGOUT_REDIRECT = (globalThis?.location?.origin ?? '') + '/login?callbackUrl=%2Fmap';

export interface BasicFilter extends SearchFilter {
  active?: boolean;
}

export interface Filter extends BasicFilter {
  userName?: string;
  fullName?: string;
  email?: string;
  tenant?: string;
}

export interface TokenResponse {
  access_token: string;
  expires_in: number;
  jti: string;
  refresh_token: string;
  scope: string;
  token_type: string;
}

type UserUpdateData = Partial<Pick<User, 'fullname' | 'language' | 'password' | 'id'>>;

@singleton
class UserApi extends Api {
  endpoint: string = '/api/users';
  oAuthEndpoint: string = '/oauth';
  static getInstance: () => UserApi;

  clearCache!: ClearCacheFn;

  @cache(cacheKeyFn, 'clearCache', 900)
  getList(filter?: Filter, pagination?: PageableRequest, tenantId?: TenantId): RequestPromise<PageableResponse<User>> {
    return this.request(METHOD.GET, '', { ...filter, ...DEFAULT_PAGINATION, ...pagination, ...(!!tenantId && { tenantId }) });
  }

  getListBasic(filter?: BasicFilter, pagination?: PageableRequest): RequestPromise<PageableResponse<UserBasic>> {
    return this.request(METHOD.GET, '/info', { ...filter, ...DEFAULT_PAGINATION, ...pagination });
  }

  search(filter?: Filter, pagination?: PageableRequest): RequestPromise<PageableResponse<User>> {
    return this.request(METHOD.GET, '/search', { ...filter, ...DEFAULT_PAGINATION, ...pagination });
  }

  getListById(userIds: (UserId | undefined)[]): RequestPromise<User[]> {
    return this.request(METHOD.GET, '/list', { ids: userIds });
  }

  create(user: Partial<User>, tenantId?: TenantId): Promise<User> {
    return this.request(METHOD.POST, tenantId ? prepareParams({ tenantId }) : '', user).then(this.responseClearCachePromise);
  }

  update(user: Partial<User>): Promise<User> {
    return this.request(METHOD.PATCH, '/' + user.id, user).then(this.responseClearCachePromise);
  }

  updateProfile(user: Partial<User>): Promise<User> {
    return this.request(METHOD.PATCH, '/' + user.id + '/profile', user).then(this.responseClearCachePromise);
  }

  block(users: UserId[]) {
    return this.request(METHOD.PUT, '/block', users).then(this.responseClearCachePromise);
  }

  unblock(users: UserId[]) {
    return this.request(METHOD.PUT, '/unblock', users).then(this.responseClearCachePromise);
  }

  uploadAvatar = (file: FormData, userid: String): RequestPromise<User> => {
    return this.request(METHOD.POST, `/${userid}/avatar`, file);
  };

  deleteAvatar = (userid: String): RequestPromise<User> => {
    return this.request(METHOD.DELETE, `/${userid}/avatar`);
  };

  getAvatarUrl = (userId: UserId) => this.getFullUrl('/' + userId + '/avatar');

  getAvatar = (userId: UserId) => {
    return this.request(METHOD.GET, '/' + userId + '/avatar', undefined, { responseType: 'blob' });
  };

  getApiKey = (userId: UserId): RequestPromise<string> => {
    return this.request(METHOD.GET, '/apikey');
  };
  genenerateApiKey = (userId: UserId): RequestPromise<string> => {
    return this.request(METHOD.POST, '/apikey');
  };

  delete(userId: UserId) {
    return this.request(METHOD.DELETE, '/' + userId).then(this.responseClearCachePromise);
  }

  // TODO: DRAG-2180
  updateSelf(user: UserUpdateData): Promise<User> {
    return this.request(METHOD.PATCH, '/' + user.id + '/profile', user);
  }

  changePassword = (currentPassword: string, newPassword: string) =>
    this.request(METHOD.POST, '/changePassword', { currentPassword, newPassword });

  initResetPassword = (organization: string, email: string) =>
    this.request(METHOD.POST, '/forgetPassword', { tenantCode: organization, email });

  resetPassword = (organization: string, email: string, code: string, newPassword: string) =>
    this.request(METHOD.POST, '/resetPassword', { tenantCode: organization, email, password: newPassword, passwordResetCode: code });

  getUserInfo = (userId: UserId): Promise<User> => this.request(METHOD.GET, '/' + userId);

  getMyInfo = (): Promise<User> => this.request(METHOD.GET, '/me', undefined, undefined, false);

  login = (organization: string, username: string, password: string, uaePassToken?: string): Promise<TokenResponse> =>
    this.requestWithEndpoint(this.oAuthEndpoint, () =>
      this.request(
        METHOD.POST,
        '/token',
        prepareParams({ grant_type: 'password', username: removeEmpty([organization, username, uaePassToken]).join('/'), password }, ''),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          auth: {
            username: process.env.CLIENT_ID ?? '',
            password: process.env.CLIENT_SECRET ?? '',
          },
        },
        false,
      ),
    );

  logout = () => this.requestWithEndpoint(this.oAuthEndpoint, () => this.request(METHOD.DELETE, '/revoke'));

  getSettings(): RequestPromise<UserSettings> {
    return this.request(METHOD.GET, '/settings');
  }

  createSettings(settings: Partial<UserSettings>): Promise<User> {
    return this.request(METHOD.POST, '/settings', settings);
  }

  getAccessCode = (): RequestPromise<string> => this.request(METHOD.POST, '/access-code');

  validateGuestAccountEmail = (email: string) => this.request(METHOD.POST, '/guest/email-verification', { email });

  createGuestAccount = (emailVerificationCode: string) =>
    this.request(METHOD.POST, '/guest?code=' + encodeURIComponent(emailVerificationCode));

  private responseClearCachePromise = <T = any>(res: T): T => {
    this.clearCache();
    return res;
  };
}

export const userApi = () => UserApi.getInstance();
