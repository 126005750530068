import useVirtuosoInfiniteQuery from '@/hooks/useVirtuosoInfiniteQuery';
import { dataMappingApi } from '@/cmd/DataMappingApi';
import { getInfinitePlaceholder, getNextPageParam, initialPageParam } from '@/hooks/queries/utils';
import { DataMappingPreset, DataMappingPresetId } from '@/models';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { minutesToMilliseconds } from 'date-fns';

// TODO
export const useDataMappingPresets = () =>
  useQuery({
    queryKey: ['data_mapping_presets_all'],
    queryFn: () => dataMappingApi().getAll((page) => dataMappingApi().getList({ page, pageSize: 100 })),
    staleTime: minutesToMilliseconds(15),
    placeholderData: keepPreviousData,
  });

export const useDataMappingPreset = (id?: DataMappingPresetId) =>
  useQuery({
    queryKey: ['data_mapping_preset', id],
    queryFn: () => dataMappingApi().get(id!),
    staleTime: minutesToMilliseconds(15),
    placeholderData: keepPreviousData,
    enabled: !!id,
  });

export const useInfiniteDataMappingPresets = () =>
  useVirtuosoInfiniteQuery({
    queryKey: ['data_mapping_presets'],
    queryFn: ({ pageParam }) => dataMappingApi().getList(pageParam),
    getNextPageParam,
    staleTime: minutesToMilliseconds(15),
    initialPageParam,
    placeholderData: getInfinitePlaceholder<DataMappingPreset>(20, true),
    refetchOnMount: 'always',
  });

export const useCreateDataMappingPreset = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (preset: Partial<DataMappingPreset>) => dataMappingApi().create(preset),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['data_mapping_presets_all'] });
      return queryClient.invalidateQueries({ queryKey: ['data_mapping_presets'] });
    },
  });
};

export const useUpdateDataMappingPreset = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: { id: DataMappingPresetId; preset: Partial<DataMappingPreset> }) =>
      dataMappingApi().update(request.id, request.preset),
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries({ queryKey: ['data_mapping_presets_all'] });
      void queryClient.invalidateQueries({ queryKey: ['data_mapping_preset', variables.id] });
      return queryClient.invalidateQueries({ queryKey: ['data_mapping_presets'] });
    },
  });
};

export const useDeleteDataMappingPreset = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: DataMappingPresetId) => dataMappingApi().delete(id),
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries({ queryKey: ['data_mapping_presets_all'] });
      return queryClient.invalidateQueries({ queryKey: ['data_mapping_presets'] });
    },
  });
};
