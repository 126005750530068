import { createContext, ReactNode, useState } from 'react';
import useDebounce from '@/hooks/useStateDebounce';
import { TenantBasic, TenantId } from '@/models';
import { useTenantsInfo } from '@/hooks/useTenants';

interface TenantContext {
  tenants: (TenantBasic | undefined)[];
  addTenant: (tenantId: TenantId) => void;
}
export const TenantContext = createContext<TenantContext | undefined>(undefined);
const TenantProvider = ({ children }: { children: ReactNode }) => {
  const [tenantIds, setTenantIds] = useState<TenantId[]>([]);
  const debouncedTenantIds = useDebounce(tenantIds, 15);
  const tenants = useTenantsInfo(debouncedTenantIds);

  const addTenant = (userId: TenantId) => {
    setTenantIds((prev) => Array.from(new Set([...prev, userId])));
  };

  return <TenantContext.Provider value={{ tenants, addTenant }}>{children}</TenantContext.Provider>;
};

export default TenantProvider;
