import { Geometry, GeometryType, IGeometry, ParseProps, Projection, WKTType } from '@/utils/geometry/Geometry';
import { Polygon } from '@/utils/geometry/Polygon';
import { KeyValue } from '@/models';
import { Point } from '@/utils/geometry/Point';
import { Line } from '@/utils/geometry/Line';
import { PSEUDO_MERCATOR, WORLD_GEODETIC_SYSTEM } from '@/consts';
import { removeEmpty } from '@/utils';

export class Multiline implements IGeometry {
  lines: Line[] = [];
  modifier?: string;
  properties: KeyValue = {};

  readonly type = GeometryType.MULTILINESTRING;
  private _projection?: string;

  constructor(lines: Line[] = [], modifier?: string) {
    this.lines = lines;
    this.modifier = modifier;
  }

  clone(): Geometry {
    const multiline = new Multiline(this.lines.map((l) => l.clone()));
    multiline.properties = { ...this.properties };
    multiline.setProjection(this.projection);
    return multiline;
  }

  mirrorY(): Geometry {
    this.lines = this.lines.map((l) => l.mirrorY());
    return this;
  }

  move(x: number | Point, y: number | undefined): Geometry {
    this.lines = this.lines.map((l) => l.move(x, y));
    return this;
  }

  multiply(x: number | Point, y: number | undefined): Geometry {
    this.lines = this.lines.map((l) => l.multiply(x, y));
    return this;
  }

  round(): Geometry {
    this.lines = this.lines.map((l) => l.round());
    return this;
  }

  toWKT(): string {
    return removeEmpty([WKTType.MULTILINESTRING, this.modifier, '(' + this.toString() + ')']).join(' ');
  }

  toString(): string {
    return this.lines.map((l) => l.toString()).join(', ');
  }

  transformBy(transformer: (p: Point) => Point): Multiline {
    this.lines = this.lines.map((l) => l.transformBy(transformer));
    return this;
  }

  transformTo(to: Projection): Multiline {
    if (!this._projection) {
      throw Error('Source projection is not specified');
    }
    return this.transform(this._projection, to);
  }

  transform(from: Projection | ParseProps = PSEUDO_MERCATOR, to: Projection = WORLD_GEODETIC_SYSTEM): Multiline {
    if (typeof from === 'object') {
      to = from.featureProjection;
      from = from.dataProjection;
    }
    if (this._projection) {
      if (from !== this._projection) {
        throw Error('Source projection mismatch');
      }
      if (to === this._projection) {
        return this;
      }
    }
    this._projection = to;
    this.lines = this.lines.map((l) => l.transform(from, to));
    return this;
  }

  get maxX(): number {
    return Math.max(...this.lines.map((l) => l.maxX));
  }

  get minX(): number {
    return Math.min(...this.lines.map((l) => l.minX));
  }

  get maxY(): number {
    return Math.max(...this.lines.map((l) => l.maxY));
  }

  get minY(): number {
    return Math.min(...this.lines.map((l) => l.minY));
  }

  get extent(): Polygon {
    const { minX, minY, maxX, maxY } = this;
    return new Polygon([
      new Point(minX, minY),
      new Point(maxX, minY),
      new Point(maxX, maxY),
      new Point(minX, maxY),
      new Point(minX, minY),
    ]).setProjection(this._projection);
  }

  get center(): Point {
    return this.extent.center;
  }

  get points(): Point[] {
    return this.lines.flatMap((l) => l.points);
  }

  get projection(): Projection | undefined {
    return this._projection;
  }

  setProjection(value: Projection | undefined) {
    this._projection = value;
    return this;
  }

  static ParseFromWKT(wkt: string, optProps?: ParseProps | Projection): Multiline {
    const data = wkt.trim().toUpperCase();
    if (data.indexOf(WKTType.MULTILINESTRING) === 0) {
      let regexp = new RegExp(WKTType.MULTILINESTRING + '\\s?([Z|M]{0,2})\\s?\\((.*)\\)', 'im');
      const wktParts = regexp.exec(data);
      if (wktParts) {
        const [, modifier, body] = wktParts;
        regexp = new RegExp('(\\(.*?\\))', 'img');
        const lines: Line[] = [];
        let lineBody;
        while ((lineBody = regexp.exec(body))) {
          lines.push(Line.ParseFromWKT([WKTType.LINESTRING, modifier, lineBody[1]].join(' ')));
        }
        const res = new Multiline(lines, modifier);
        if (res && optProps) {
          return res.transform(optProps);
        }
        return res;
      }
    }
    throw Error('Cannot parse WKT');
  }
}
