import { Sampler, ShaderSource } from 'cesium';
import { PixelFormat, PixelDatatype, TextureMinificationFilter, TextureMagnificationFilter, Cartesian3, Cartesian2 } from 'cesium';
import CustomPrimitive from './CustomPrimitive';
import DataProcess from './dataProcess';
import Util from './utils';
import { shaders } from './shader';

export default class ParticlesComputing {
  windTextures: any;
  particlesTextures: any;
  primitives: any;

  constructor(context: any, data: any, userInput: any, viewerParameters: any) {
    this.createWindTextures(context, data);
    this.createParticlesTextures(context, userInput, viewerParameters);
    this.createComputingPrimitives(data, userInput, viewerParameters);
  }

  createWindTextures(context: any, data: any) {
    const windTextureOptions = {
      context,
      width: data.dimensions.lon,
      height: data.dimensions.lat * data.dimensions.lev,
      pixelFormat: PixelFormat.RED,
      pixelDatatype: PixelDatatype.FLOAT,
      flipY: false,
      sampler: new Sampler({
        // the values of texture will not be interpolated
        minificationFilter: TextureMinificationFilter.NEAREST,
        magnificationFilter: TextureMagnificationFilter.NEAREST,
      }),
    };

    this.windTextures = {
      U: Util.createTexture(windTextureOptions, data.U.array),
      V: Util.createTexture(windTextureOptions, data.V.array),
      W: Util.createTexture(windTextureOptions, data.W.array),
      H: Util.createTexture(windTextureOptions, data.H.array),
    };
  }

  createParticlesTextures(context: any, userInput: any, viewerParameters: any) {
    const particlesTextureOptions = {
      context,
      width: userInput.particlesTextureSize,
      height: userInput.particlesTextureSize,
      pixelFormat: PixelFormat.RGBA,
      pixelDatatype: PixelDatatype.FLOAT,
      flipY: false,
      sampler: new Sampler({
        // the values of texture will not be interpolated
        minificationFilter: TextureMinificationFilter.NEAREST,
        magnificationFilter: TextureMagnificationFilter.NEAREST,
      }),
    };
    const particlesArray = DataProcess.randomizeParticles(userInput.maxParticles, viewerParameters);
    const zeroArray = new Float32Array(4 * userInput.maxParticles).fill(0);

    this.particlesTextures = {
      previousParticlesPosition: Util.createTexture(particlesTextureOptions, particlesArray),
      currentParticlesPosition: Util.createTexture(particlesTextureOptions, particlesArray),
      nextParticlesPosition: Util.createTexture(particlesTextureOptions, particlesArray),
      postProcessingPosition: Util.createTexture(particlesTextureOptions, particlesArray),
      particlesSpeed: Util.createTexture(particlesTextureOptions, zeroArray),
    };
  }

  destroyParticlesTextures() {
    Object.keys(this.particlesTextures).forEach((key) => {
      this.particlesTextures[key].destroy();
    });
  }

  createComputingPrimitives(data: any, userInput: any, viewerParameters: any) {
    const { calculateSpeedShader, updatePositionShader, postProcessingPositionShader } = shaders;
    const dimension = new Cartesian3(data.dimensions.lon, data.dimensions.lat, data.dimensions.lev);
    const minimum = new Cartesian3(data.lon.min, data.lat.min, data.lev.min);
    const maximum = new Cartesian3(data.lon.max, data.lat.max, data.lev.max);
    const interval = new Cartesian3(
      (maximum.x - minimum.x) / (dimension.x - 1),
      (maximum.y - minimum.y) / (dimension.y - 1),
      dimension.z > 1 ? (maximum.z - minimum.z) / (dimension.z - 1) : 1.0,
    );
    const lonRange = new Cartesian2(data.lon.min, data.lon.max);
    const latRange = new Cartesian2(data.lat.min, data.lat.max);
    const uSpeedRange = new Cartesian2(data.U.min, data.U.max);
    const vSpeedRange = new Cartesian2(data.V.min, data.V.max);
    const wSpeedRange = new Cartesian2(data.W.min, data.W.max);

    const that = this;

    const calculateSpeedOptions = {
      commandType: 'Compute',
      uniformMap: {
        U() {
          return that.windTextures.U;
        },
        V() {
          return that.windTextures.V;
        },
        W() {
          return that.windTextures.W;
        },
        currentParticlesPosition() {
          return that.particlesTextures.currentParticlesPosition;
        },
        dimension() {
          return dimension;
        },
        minimum() {
          return minimum;
        },
        maximum() {
          return maximum;
        },
        interval() {
          return interval;
        },
        uSpeedRange() {
          return uSpeedRange;
        },
        vSpeedRange() {
          return vSpeedRange;
        },
        wSpeedRange() {
          return wSpeedRange;
        },
        speedScaleFactor() {
          return viewerParameters.pixelSize * userInput.speedFactor;
        },
      },
      fragmentShaderSource: new ShaderSource({
        sources: [calculateSpeedShader],
      }),
      outputTexture: this.particlesTextures.particlesSpeed,
      preExecute() {
        // swap textures before binding
        if (userInput !== undefined && userInput.dynamic) {
          const temp = that.particlesTextures.previousParticlesPosition;
          that.particlesTextures.previousParticlesPosition = that.particlesTextures.currentParticlesPosition;
          that.particlesTextures.currentParticlesPosition = that.particlesTextures.postProcessingPosition;
          that.particlesTextures.postProcessingPosition = temp;
        }

        // keep the outputTexture up to date
        that.primitives.calculateSpeed.commandToExecute.outputTexture = that.particlesTextures.particlesSpeed;
      },
    };

    const updatePositionOptions = {
      commandType: 'Compute',
      uniformMap: {
        currentParticlesPosition() {
          return that.particlesTextures.currentParticlesPosition;
        },
        particlesSpeed() {
          return that.particlesTextures.particlesSpeed;
        },
      },
      fragmentShaderSource: new ShaderSource({
        sources: [updatePositionShader],
      }),
      outputTexture: this.particlesTextures.nextParticlesPosition,
      preExecute() {
        // keep the outputTexture up to date
        that.primitives.updatePosition.commandToExecute.outputTexture = that.particlesTextures.nextParticlesPosition;
      },
    };

    const postProcessingPositionOptions = {
      commandType: 'Compute',
      uniformMap: {
        nextParticlesPosition() {
          return that.particlesTextures.nextParticlesPosition;
        },
        particlesSpeed() {
          return that.particlesTextures.particlesSpeed;
        },
        viewerLonRange() {
          return viewerParameters.lonRange;
        },
        viewerLatRange() {
          return viewerParameters.latRange;
        },
        lonRange() {
          return lonRange;
        },
        latRange() {
          return latRange;
        },
        dimension() {
          return dimension;
        },
        minimum() {
          return minimum;
        },
        maximum() {
          return maximum;
        },
        interval() {
          return interval;
        },
        H() {
          return that.windTextures.H;
        },
        randomCoefficient() {
          return Math.random();
        },
        dropRate() {
          return userInput.dropRate;
        },
        dropRateBump() {
          return userInput.dropRateBump;
        },
      },
      fragmentShaderSource: new ShaderSource({
        sources: [postProcessingPositionShader],
      }),
      outputTexture: this.particlesTextures.postProcessingPosition,
      preExecute() {
        // keep the outputTexture up to date
        that.primitives.postProcessingPosition.commandToExecute.outputTexture = that.particlesTextures.postProcessingPosition;
      },
    };

    this.primitives = {
      calculateSpeed: new CustomPrimitive(calculateSpeedOptions),
      updatePosition: new CustomPrimitive(updatePositionOptions),
      postProcessingPosition: new CustomPrimitive(postProcessingPositionOptions),
    };
  }
}
