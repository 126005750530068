import { useRef } from 'react';
import { DropDownMenu, DropDownMenuRef } from '@/components/DropDownMenu';
import { Avatar } from '@/components/Users/Avatar';
import Link from '@/components/Link';
import { handleSignOut } from '@/components/Login/helper';
import { Icon, ListItemIcon, ListItemText, MenuItem, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { getConfig } from '@/config';

const config = getConfig();

const AvatarMenu = () => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const accountMenuRef = useRef<DropDownMenuRef>(null);
  return (
    <DropDownMenu
      opener={<Avatar />}
      ref={accountMenuRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: theme.direction === 'rtl' ? 'left' : 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: theme.direction === 'rtl' ? 'right' : 'left',
      }}
      sx={{
        ...(theme.direction === 'rtl' ? { marginInlineEnd: -5.5 } : { marginInlineStart: 5.5 }),
      }}
    >
      <Link href="/user/profile" color="text.primary" underline="none">
        <MenuItem key="profile">
          <ListItemIcon>
            <Icon>account_circle</Icon>
          </ListItemIcon>
          <ListItemText>{t('common:profile')}</ListItemText>
        </MenuItem>
      </Link>
      <MenuItem onClick={handleSignOut}>
        <ListItemIcon>
          <Icon>logout</Icon>
        </ListItemIcon>
        <ListItemText>{t('logout')}</ListItemText>
      </MenuItem>
      <MenuItem disabled sx={{ '&.Mui-disabled': { opacity: 1 } }}>
        <ListItemText primaryTypographyProps={{ variant: 'caption', sx: { textAlign: 'end' } }}>{config.version}</ListItemText>
      </MenuItem>
    </DropDownMenu>
  );
};

export default AvatarMenu;
