import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, RequestPromise, Response, SearchFilter } from './Api';
import { singleton } from '@/utils/decorators/singleton';
import {
  FormattedDateTime,
  IngestionJob,
  IngestionJobId,
  IngestionStatus,
  SourceData,
  SourceDataId,
  UploadedFileId,
  UrlString,
} from '@/models';
import { download } from './download';
import { cancelCatchHandler, CustomPromise, CustomPromiseState, setDataProcessor } from '@/utils/CustomPromise';
import { Request } from 'cesium';
import { wrapFunction } from '@/utils';

export interface Filter extends SearchFilter {
  statuses?: IngestionStatus[];
  minIngestionDate?: FormattedDateTime;
  maxIngestionDate?: FormattedDateTime;
  fileIds?: UploadedFileId[];
  minFileSize?: number;
  maxFileSize?: number;
}

@singleton
class IngestionApi extends Api {
  endpoint: string = '/api/ingestion';
  static getInstance: () => IngestionApi;

  getList(filter?: Filter, pagination?: PageableRequest): RequestPromise<PageableResponse<IngestionJob>> {
    return this.request(METHOD.GET, '', { ...filter, ...DEFAULT_PAGINATION, ...pagination });
  }

  getIngestion(id: IngestionJobId): RequestPromise<IngestionJob> {
    return this.request(METHOD.GET, '/' + id);
  }

  retryIngestion(id: string): RequestPromise<IngestionJob> {
    return this.request(METHOD.POST, `/${id}/retry`, {});
  }

  download = (fileIdOrIngestionJob: UploadedFileId | IngestionJob) => {
    let options: any;
    let fileId = fileIdOrIngestionJob as any;
    if (typeof fileIdOrIngestionJob === 'object') {
      fileId = fileIdOrIngestionJob.fileId;
      options = { name: fileIdOrIngestionJob.originalFilename };
    }
    this.getSignedUrl(fileId).then((signedUrl) => download(signedUrl, options));

    // TODO: API dosn't work with big files. Probably need to remove it on BE?
    // this.rawRequest(METHOD.GET, '/download', { fileId }, { responseType: 'blob' })
    //   .then((res) => download(res.data))
    //   .catch(console.error);
  };

  fetchFile = (fileId: UploadedFileId): RequestPromise<Blob> => {
    const promise = CustomPromise();
    this.getSignedUrl(fileId).then((signedUrl) => {
      const request = this.request(METHOD.GET, signedUrl, undefined, {
        responseType: 'blob',
        skipAuthorization: true,
      });
      promise.cancel = wrapFunction(promise.cancel, (promiseCancel) => {
        request.cancel();
        promiseCancel();
      });
      request.then((res) => promise.resolve(res.data)).catch(cancelCatchHandler);
    });
    return promise;
  };

  getDownloadUrl = (fileId: UploadedFileId, force = false) => this.getFullUrl('/download/url?fileId=' + fileId, force);

  getSignedUrl = (fileId: UploadedFileId): RequestPromise<string> => this.request(METHOD.GET, '/download/url?fileId=' + fileId);

  getPreview = (fileId: UploadedFileId, force = false) => this.getFullUrl('/download?fileId=' + fileId, force);

  getDocumentFile = (fileId: UploadedFileId) => this.request(METHOD.GET, '/download?fileId=' + fileId, undefined, { responseType: 'blob' });

  delete = (fileId: UploadedFileId): RequestPromise<any> => this.request(METHOD.DELETE, '/' + fileId);

  getThumbnailUrl = (sourceDataId: SourceDataId, resolution: string): UrlString => {
    return this.getFullUrl('/download/thumbnail?sourceId=' + sourceDataId + '&resolution=' + resolution);
  };
}

export const ingestionApi = () => IngestionApi.getInstance();
