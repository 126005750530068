import React, { FC } from 'react';
import { useSession } from 'next-auth/react';
import { Avatar as MuiAvatar, AvatarProps, Tooltip, Typography } from '@mui/material';
import useAvatar from '@/hooks/useAvatar';

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 6) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (name: string): Partial<AvatarProps> => {
  const [firstName = '', lastName = ''] = name.split(' ');
  const shortName = firstName.substring(0, 1) + lastName.substring(0, 1);
  const bgColor = stringToColor(name);
  return {
    sx: {
      bgcolor: bgColor,
      color: (theme) => theme.palette.getContrastText(bgColor),
      width: 32,
      height: 32,
      lineHeight: 32,
    },
    children: <Typography variant="h7">{shortName.toUpperCase()}</Typography>,
  };
};

export const Avatar: FC = () => {
  const { data: session } = useSession();
  const avatarSrc = useAvatar(session?.user.id);

  return (
    <Tooltip title={session?.user.fullname} placement="right">
      <MuiAvatar src={avatarSrc} {...stringAvatar(session?.user.fullname || '')} />
    </Tooltip>
  );
};
