import { createContext, ReactNode, useState } from 'react';
import { useUsersByIds } from '@/hooks/useUser';
import useDebounce from '@/hooks/useStateDebounce';
import { User, UserId } from '@/models';

interface UsernameContext {
  users: (User | undefined)[];
  addUser: (userId: UserId) => void;
}
export const UsernameContext = createContext<UsernameContext | undefined>(undefined);
const UsernameProvider = ({ children }: { children: ReactNode }) => {
  const [userIds, setUserIds] = useState<UserId[]>([]);
  const debouncedUserIds = useDebounce(userIds, 15);
  const users = useUsersByIds(debouncedUserIds);

  const addUser = (userId: UserId) => {
    setUserIds((prev) => Array.from(new Set([...prev, userId])));
  };

  return <UsernameContext.Provider value={{ users, addUser }}>{children}</UsernameContext.Provider>;
};

export default UsernameProvider;
