import 'cesium/Source/Widgets/widgets.css';
import { PureComponent, PropsWithChildren, MutableRefObject } from 'react';
import { Cartesian3, Color, GeographicProjection, ImageryLayer, Viewer } from 'cesium';
import styled from '@emotion/styled';
import { Controller, Location } from './Map/Controller';
import { RasterLayer } from './Layers/RasterLayer';
import 'cesium/Source/Widgets/widgets.css';

import { TIME_SERIES_3D_MODEL_ID } from '@/consts';
import { Box } from '@mui/material';

export { Controller } from './Map/Controller';

const Layout = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;

  .cesium-performanceDisplay-defaultContainer {
    top: 200px;
  }
`;

export const DEFAULT_CONTAINER_ID = 'cesiumContainer';

export const DEFAULT_OPTIONS = {
  animation: false,
  shouldAnimate: false,
  imageryProvider: false as any,
  baseLayerPicker: false,
  geocoder: false,
  timeline: false,
  fullscreenButton: false,
  homeButton: false,
  navigationHelpButton: false,
  selectionIndicator: false,
  infoBox: false,
  requestRenderMode: true,
  maximumRenderTimeChange: Infinity,
  showFPS: true,
};

interface ViewerOptions extends Viewer.ConstructorOptions {
  showFPS?: boolean;
}

interface Props {
  container?: string;
  viewerOptions?: ViewerOptions;
  baseLayer?: ImageryLayer | Promise<ImageryLayer> | false;
  layers?: RasterLayer[];
  onLoaded?: MutableRefObject<Controller | undefined> | ((controller: Controller) => void);
  onCameraChange?: (position: Cartesian3, heading: number, pitch: number, roll: number) => void;
  startLocation?: Location;
}

class Map extends PureComponent<PropsWithChildren<Props>> {
  static defaultProps: Partial<Props> = {
    viewerOptions: DEFAULT_OPTIONS,
    container: DEFAULT_CONTAINER_ID,
  };

  viewer?: Viewer;
  controller?: Controller;

  async componentDidMount() {
    const { onLoaded } = this.props;
    await this.init();
    if (onLoaded) {
      if (typeof onLoaded === 'function') {
        onLoaded(this.controller!);
      } else {
        onLoaded.current = this.controller!;
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { layers } = this.props;
    if (layers !== prevProps.layers || layers?.length !== prevProps.layers?.length) {
      this.controller?.setLayers(layers || []);
    }
  }

  trackedEntityHandler = () => {
    if (!!this.viewer!.trackedEntity && this.viewer!.trackedEntity.id !== TIME_SERIES_3D_MODEL_ID) this.viewer!.trackedEntity = undefined;
  };

  async init() {
    const { container, startLocation, baseLayer, layers } = this.props;
    const { showFPS, ...viewerOptions } = { ...DEFAULT_OPTIONS, ...this.props.viewerOptions };
    const Viewer = (await import('cesium')).Viewer;
    this.viewer = new Viewer(container!, viewerOptions);
    this.viewer.scene.globe.baseColor = Color.BLACK;
    // Todo: check the performance of a tile cache
    // this.viewer.scene.globe.tileCacheSize = 1000;
    this.controller = new Controller(this.viewer);
    if (baseLayer) {
      if (baseLayer instanceof Promise) {
        baseLayer.then((layer) => layer && this.controller && (this.controller.baseLayer = layer));
      } else {
        this.controller.baseLayer = baseLayer;
      }
    }
    layers && this.controller.setLayers(layers);
    const camera = this.viewer.camera;
    camera.changed.addEventListener(this.cameraChangeHandler);
    if (showFPS) {
      this.viewer.scene.debugShowFramesPerSecond = true;
    }
    camera.percentageChanged = 0.1;
    if (startLocation) {
      this.controller.setPosition(startLocation);
    }

    // Remove double-click entity tracking
    this.viewer.trackedEntityChanged.addEventListener(this.trackedEntityHandler);

    this.updateViewPort();
  }

  cameraChangeHandler = () => {
    const { onCameraChange } = this.props;
    const { position, heading, pitch, roll } = this.viewer!.camera;
    this.updateViewPort();

    if (onCameraChange) {
      onCameraChange(position, heading, pitch, roll);
    }
  };

  updateViewPort = () => {
    if (this.controller) {
      const viewPort = this.controller.getViewPort();
      if (viewPort) {
        this.controller.viewPort = viewPort;
      }
    }
  };

  componentWillUnmount() {
    if (this.viewer) {
      this.viewer.destroy();
      if (this.viewer.cesiumWidget) {
        this.viewer.camera.changed.removeEventListener(this.cameraChangeHandler);
        this.viewer.trackedEntityChanged.removeEventListener(this.trackedEntityHandler);
      }
    }
  }

  render() {
    const { container, children } = this.props;
    return (
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Layout className="map-layout" id={container}>
          {children}
        </Layout>
      </Box>
    );
  }
}

export default Map;
