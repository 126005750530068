import { Icon as MuiIcon, IconProps, styled } from '@mui/material';

export type Variant = 'outlined' | 'filled';

export interface AnimatedIconProps extends IconProps {
  variant?: Variant;
}

const AnimatedIcon = styled(MuiIcon, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'sx',
  name: 'AnimatedIcon',
  slot: 'Root',
})<AnimatedIconProps>(({ theme }) => ({}));

export default AnimatedIcon;
