import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ModuleId, Tenant, TenantId } from '@/models';
import { Filter, tenantApi } from '@/cmd/TenantApi';
import { minutesToMilliseconds } from 'date-fns';
import { OnlyRequired } from '@/utils/types';
import { countryApi } from '@/cmd/CountryApi';
import { PageableRequest } from '@/cmd/Api';

export const tenantKeys = {
  all: ['tenant'] as const,
  single: (tenantId?: TenantId) => [...tenantKeys.all, tenantId] as const,
  byIdsAll: () => [...tenantKeys.all, 'byIds'] as const,
  byIds: (ids?: (TenantId | undefined)[]) => [...tenantKeys.byIdsAll(), ids] as const,
  lists: () => [...tenantKeys.all, 'list'] as const,
  list: (filter?: Filter, pagination?: PageableRequest) => [...tenantKeys.lists(), filter, pagination] as const,
  countries: () => [...tenantKeys.all, 'countries'],
};

export const useTenantPage = (filter?: Filter, pagination?: PageableRequest) =>
  useQuery({
    queryKey: tenantKeys.list(filter, pagination),
    queryFn: () => tenantApi().getList(filter, pagination),
    staleTime: minutesToMilliseconds(15),
  });
export const useTenant = (id?: TenantId) =>
  useQuery({
    queryKey: tenantKeys.single(id),
    queryFn: () => tenantApi().getTenantInfo(id!),
    enabled: !!id,
    staleTime: minutesToMilliseconds(15),
  });

export const useUserTenant = (enabled: boolean) =>
  useQuery({
    queryKey: ['userTenant'],
    queryFn: () => tenantApi().getMyTenantInfo(),
    staleTime: minutesToMilliseconds(15),
    enabled,
  });

export const useCreateOrUpdateTenant = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: OnlyRequired<Tenant, 'id'>) => {
      const { id, ...payload } = request;
      if (id) {
        return tenantApi().update(id, payload);
      } else {
        return tenantApi().create(payload);
      }
    },
    onSuccess: (_data, variables) => {
      if (variables.id) queryClient.invalidateQueries({ queryKey: tenantKeys.single(variables.id) });
      queryClient.invalidateQueries({ queryKey: tenantKeys.lists() });
      queryClient.invalidateQueries({ queryKey: tenantKeys.byIdsAll() });
    },
  });
};

export const useDeleteTenant = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: OnlyRequired<Tenant, 'id'>) => tenantApi().delete(request.id),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: tenantKeys.lists() });
      queryClient.invalidateQueries({ queryKey: tenantKeys.byIdsAll() });
    },
  });
};

export const useUpdateModules = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: { tenantId: TenantId; modules: ModuleId[] }) => tenantApi().updateModules(request.tenantId, request.modules),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: tenantKeys.single(variables.tenantId) });
      queryClient.invalidateQueries({ queryKey: tenantKeys.lists() });
      queryClient.invalidateQueries({ queryKey: tenantKeys.byIdsAll() });
    },
  });
};

export const useCountryCodes = () => {
  return useQuery({
    queryKey: tenantKeys.countries(),
    queryFn: () => countryApi().getCountryCodes(),
    staleTime: minutesToMilliseconds(15),
    placeholderData: keepPreviousData,
  });
};
