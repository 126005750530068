import { InferenceJobId, IngestionJobId, JobId, KeyValue, ModelId, SourceDataId, UploadedFileId, WorkspaceId } from '@/models';
import { BaseEvent } from '@/utils/events';
import ModelVersionId from '@/pages/marketplace/create/[modelVersionId]';

export enum MessageAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum MessageType {
  INGESTION_JOB = 'INGESTION_JOB',
  INFERENCE_JOB = 'INFERENCE_JOB',
  ANALYTICS_JOB = 'ANALYTICS_JOB',
  SOURCE = 'SOURCE',
  WORKSPACE = 'WORK_SPACE',
  NOTIFICATION = 'NOTIFICATION',
}

export interface Message {
  type: MessageType;
  action: MessageAction;
  entityId?: string;
  properties?: KeyValue;
}

export interface MessageEvent<T extends Message> extends BaseEvent {
  type: T['type'];
  data: T;
}

export interface JobMessage extends Message {
  type: MessageType.ANALYTICS_JOB;
  action: MessageAction;
  entityId: JobId;
  properties: {
    sourceIds: SourceDataId[];
  };
}

export interface JobCreateMessage extends JobMessage {
  action: MessageAction.CREATE;
}

export interface JobUpdateMessage extends JobMessage {
  action: MessageAction.UPDATE;
}

export interface SourceDataMessage extends Message {
  type: MessageType.SOURCE;
  entityId: SourceDataId;
}

export interface SourceDataCreateMessage extends SourceDataMessage {
  action: MessageAction.CREATE;
}

export interface SourceDataUpdateMessage extends SourceDataMessage {
  action: MessageAction.UPDATE;
}

export interface WorkspaceMessage extends Message {
  type: MessageType.WORKSPACE;
  entityId: WorkspaceId;
}

export interface WorkspaceCreateMessage extends WorkspaceMessage {
  action: MessageAction.CREATE;
}

export interface WorkspaceUpdateMessage extends WorkspaceMessage {
  action: MessageAction.UPDATE;
}

export interface NotificationMessage extends Message {
  type: MessageType.NOTIFICATION;
}

export interface IngestionJobMessage extends Message {
  type: MessageType.INGESTION_JOB;
  entityId: IngestionJobId;
  properties: {
    fileId: UploadedFileId;
  };
}

export interface IngestionJobCreateMessage extends IngestionJobMessage {
  action: MessageAction.CREATE;
}

export interface IngestionJobUpdateMessage extends IngestionJobMessage {
  action: MessageAction.UPDATE;
  properties: {
    fileId: UploadedFileId;
    sourceId?: SourceDataId;
  };
}

export interface InferenceJobMessage extends Message {
  type: MessageType.INGESTION_JOB;
  entityId: InferenceJobId;
  properties: {
    sourceId: SourceDataId;
    modelId: ModelId;
    progress: number;
  };
}

export interface InferenceJobCreateMessage extends InferenceJobMessage {
  action: MessageAction.CREATE;
}

export interface InferenceJobUpdateMessage extends InferenceJobMessage {
  action: MessageAction.UPDATE;
}
