import { singleton } from '@/utils/decorators/singleton';
import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, RequestPromise } from '@/cmd/Api';
import { DataMappingPreset, DataMappingPresetId } from '@/models';

// TODO
@singleton
class DataMappingApi extends Api {
  endpoint: string = '/api/datamappingpreset';
  static getInstance: () => DataMappingApi;

  getList(pagination?: PageableRequest): RequestPromise<PageableResponse<DataMappingPreset>> {
    return this.request(METHOD.GET, '', { ...DEFAULT_PAGINATION, ...pagination });
  }

  get(dataMappingPresetId: DataMappingPresetId): Promise<DataMappingPreset> {
    return this.request(METHOD.GET, '/' + dataMappingPresetId);
  }

  create(dataMappingPreset: Partial<DataMappingPreset>): Promise<DataMappingPreset> {
    return this.request(METHOD.POST, '', dataMappingPreset);
  }

  update(dataMappingPresetId: DataMappingPresetId, dataMappingPreset: Partial<DataMappingPreset>): Promise<DataMappingPreset> {
    return this.request(METHOD.PATCH, '/' + dataMappingPresetId, dataMappingPreset);
  }

  delete(dataMappingPresetId: DataMappingPresetId) {
    return this.request(METHOD.DELETE, '/' + dataMappingPresetId);
  }
}

export const dataMappingApi = () => DataMappingApi.getInstance();
