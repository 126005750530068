import { FC } from 'react';
import { LinearProgress, styled } from '@mui/material';
import { DataGrid as MuiDataGrid, DataGridProps, gridClasses } from '@mui/x-data-grid';

export * from './useDataGridSort';
export * from './useDataGridPagination';

const CleanGrid = styled(MuiDataGrid)(({ theme }) => ({
  flex: 1,
  minHeight: 214, //show part in Attributes Tab
  ['.MuiDataGrid-iconSeparator']: {
    display: 'none',
  },
  [`.${gridClasses.row}.even`]: {
    backgroundColor: theme.vars.palette.action.hover,
    ['&.Mui-selected']: {
      backgroundColor: `rgb(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.selectedOpacity})`,
    },
  },
  border: 0,
  ['.' + gridClasses.row]: {
    border: 0,
    '&:hover, &.Mui-hovered': {
      backgroundColor: `rgb(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.hoverOpacity})`,
    },
  },
  ['.' + gridClasses.cell]: {
    border: 0,
  },

  ['.' + gridClasses.virtualScroller]: {
    flex: 1,
  },
}));

const DataGrid: FC<DataGridProps> = ({ slots, ...rest }) => {
  return (
    <CleanGrid
      density="compact"
      disableColumnMenu
      disableRowSelectionOnClick
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      {...rest}
      slots={{
        loadingOverlay: LinearProgress,
        ...slots,
      }}
    />
  );
};

export default DataGrid;
