// noinspection JSUnusedGlobalSymbols

import { Line, Multipolygon, Point, Polygon, Circle } from '.';
import { getObjectKeys, getType } from '..';
import { KeyValue, Type } from '@/models';
import { Multiline } from '@/utils/geometry/Multiline';

export interface ParseProps {
  dataProjection: string;
  featureProjection: string;
}

export type Projection = string;

export enum WKTType {
  POINT = 'POINT',
  LINESTRING = 'LINESTRING',
  POLYGON = 'POLYGON',
  MULTIPOLYGON = 'MULTIPOLYGON',
  MULTILINESTRING = 'MULTILINESTRING',
  GEOMETRYCOLLECTION = 'GEOMETRYCOLLECTION',
}

export enum GeometryType {
  POLYGON = 'POLYGON',
  POINT = 'POINT',
  LINESTRING = 'LINESTRING',
  MULTIPOLYGON = 'MULTIPOLYGON',
  MULTILINESTRING = 'MULTILINESTRING',
  CIRCLE = 'CIRCLE',
}

const WKT_TYPE_REGEXP = new RegExp('^(' + getObjectKeys(WKTType).join('|') + ')\\s*[ZM]{0,2}\\s*\\(.*\\)');

export type Geometry = Point | Polygon | Multipolygon | Line | Multiline | Circle;

export interface IGeometry {
  readonly type: GeometryType;
  clone: () => Geometry;
  toWKT: () => string;
  transformBy: (transformer: (p: Point) => Point) => Geometry;
  transform(optProps: ParseProps): Geometry;
  transform(from?: string, to?: string): Geometry;
  transformTo(toProjection: Projection): Geometry;
  toString: () => string;
  move: (x: number | Point, y?: number) => Geometry;
  multiply: (x: number | Point, y?: number) => Geometry;
  mirrorY: () => Geometry;
  round: () => Geometry;
  center: Point;
  extent: Polygon;
  points: Point[];
  projection?: string;
  properties: KeyValue;
}

export const getWKTType = (wkt: string): WKTType | undefined => {
  if (wkt) {
    wkt = (wkt + '').trim().toUpperCase();
    if (WKT_TYPE_REGEXP.test(wkt)) {
      const parts = wkt.split(/\s|\(/, 1);
      const type = parts.length ? (parts[0].trim() as WKTType) : undefined;
      return type && Object.values(WKTType).includes(type) ? type : undefined;
    }
  }
};

export const parseFromWKT = (wkt: string, optProps?: ParseProps | Projection): Geometry | undefined => {
  const wktType = getWKTType(wkt);
  switch (wktType) {
    case WKTType.POINT:
      return Point.ParseFromWKT(wkt, optProps);
    case WKTType.POLYGON:
      return Polygon.ParseFromWKT(wkt, optProps);
    case WKTType.LINESTRING:
      return Line.ParseFromWKT(wkt, optProps);
    case WKTType.MULTIPOLYGON:
      return Multipolygon.ParseFromWKT(wkt, optProps);
    case WKTType.MULTILINESTRING:
      return Multiline.ParseFromWKT(wkt, optProps);
  }
  console.error(`Geometry type "${wktType}" is not supported`);
};

export const scale = (points: Point[], factor: number | Point, anchor: Point) => {
  const factorPoint = factor instanceof Point ? factor : new Point(factor, factor);
  return points.map((p) => {
    const deltaX = p.x - anchor.x;
    const deltaY = p.y - anchor.y;
    return new Point(anchor.x + factorPoint.x * deltaX, anchor.y + factorPoint.y * deltaY);
  });
};

export const rotate = (points: Point[], angle: number, anchor: Point) => {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  return points.map((p) => {
    const deltaX = p.x - anchor.x;
    const deltaY = p.y - anchor.y;
    return new Point(anchor.x + deltaX * cos - deltaY * sin, anchor.y + deltaX * sin + deltaY * cos);
  });
};

export const isGeometry = (geometry: any): geometry is Geometry => getType(geometry) === Type.OBJECT && 'toWKT' in geometry;
