import '@/styles/global.css';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import { FC, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { SessionProvider, useSession } from 'next-auth/react';
import { EmotionCache } from '@emotion/react';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import { CssBaseline } from '@mui/material';
import { appWithTranslation, i18n, SSRConfig, useTranslation } from 'next-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Head from 'next/head';
import { LazyMotion } from 'framer-motion';
import MotionProvider from '@/hooks/provider/motionProvider';
import DefaultLayout from '@/components/Layouts/Default';
import { getConfig } from '@/config';
import { closeSnackbarAction, Notification } from '@/components/Notification';
import { HydrationBoundary, QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import UsernameProvider from '@/hooks/provider/usernameProvider';
import TenantProvider from '@/hooks/provider/tenantProvider';
import Script from 'next/script';
import { ibmPlexSans, materialSymbols } from '@/assets/fonts';
import FileUploaderProvider from '@/hooks/provider/fileUploaderProvider';
import ThemeProvider from '@/ThemeProvider';
import { useColorScheme } from '@/hooks/useColorScheme';
import { DEFAULT_COLOR_SCHEME } from '@/consts';
import { SupportedColorScheme } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { readableNumbers } from '@/utils';
import { MessageManager } from '@/components/MessageManager';
import { useUserSettings } from '@/hooks/useSettings';
import { logout } from '@/auth-provider/helper';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@/components/ErrorFallback';
import { cache, rtlCache } from '@/createEmotionCache';
import { useUserTenant } from '@/components/Organizations/queries';
import GuestDialog from '@/components/GuestDialog';
import { setAccessToken } from '@/cmd/Api';

if (typeof document !== 'undefined') {
  // Support Test
  const supportsContainerQueries = 'container' in document.documentElement.style;

  // Conditional Import
  if (!supportsContainerQueries) {
    // @ts-ignore
    import('container-query-polyfill');
  }
}

export type MyAppProps<P = {}> = AppProps<P> & {
  emotionCache?: EmotionCache;
  Component: PageWithLayout<P>;
  session?: Session;
};

type GetLayout = (page: ReactNode) => ReactNode;

export type PageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: GetLayout;
  title?: string;
};

const defaultGetLayout: GetLayout = (page) => {
  return (
    <DefaultLayout>
      <ErrorBoundary fallback={<ErrorFallback />}>{page}</ErrorBoundary>
    </DefaultLayout>
  );
};

const config = getConfig();

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 0, refetchOnWindowFocus: false } } });

function MyApp({
  Component,
  pageProps,
  session,
  emotionCache,
  router,
}: MyAppProps<{ hydrationData?: Record<string, unknown>; dehydratedState?: any } & SSRConfig>) {
  i18n?.services.formatter?.add('readableNumbers', (value, lng, options) => readableNumbers(value, 1, ''));
  // there is no window object on the server
  const getLayout = Component.getLayout ?? defaultGetLayout;
  const notistackRef = useRef<SnackbarProvider>(null);
  const { t } = useTranslation(['common']);
  const title = [config.appTitle].concat(Component.title ? [t(Component.title)] : []).join(' | ');

  const appliedCache = emotionCache ?? (router.locale === 'ar' ? rtlCache : cache);

  const snackbarProps: SnackbarProviderProps = {
    ref: notistackRef,
    action: closeSnackbarAction,
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    classes: {},
    autoHideDuration: 6000,
    maxSnack: 3,
  };

  // This is to clean up cache from previous versions of the app
  // TODO to remove this eventually
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const clearCache = async () => {
        if ('serviceWorker' in navigator) {
          const registration = await navigator.serviceWorker.getRegistration();
          await registration?.unregister();
        }
        const keys = await caches.keys();
        if (keys.length > 0) {
          for (const k of keys) {
            await caches.delete(k);
          }
          window.location.reload();
        }
      };
      void clearCache();
    }
  }, []);

  return (
    <>
      <style jsx global>{`
        :root {
          --material-symbols: ${materialSymbols.style.fontFamily};
        }
        html {
          font-family: ${ibmPlexSans.style.fontFamily};
        }
      `}</style>
      <Script id="detect_avif">
        {
          // eslint-disable-next-line max-len
          'function addClass(A){document.documentElement.classList.add(A)}var avif=new Image;function check_webp_feature(a){var e=new Image;e.onload=function(){var A=0<e.width&&0<e.height;a(A)},e.onerror=function(){a(!1)},e.src="data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA=="}avif.src="data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=",avif.onload=function(){addClass("avif")},avif.onerror=function(){check_webp_feature(function(A){return addClass(A?"webp":"fallback")})};'
        }
      </Script>
      <Head>
        <title>{title}</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <SessionProvider session={session} refetchInterval={5 * 60} refetchOnWindowFocus>
            <AppCacheProvider emotionCache={appliedCache}>
              <MessageManager>
                <ThemeProvider>
                  <CssBaseline enableColorScheme />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SnackbarProvider {...snackbarProps}>
                      <MotionProvider>
                        <FileUploaderProvider>
                          <TenantProvider>
                            <UsernameProvider>
                              <Wrapper>{getLayout(<Component {...pageProps} key={router.route} />)}</Wrapper>
                              <DevTool />
                            </UsernameProvider>
                          </TenantProvider>
                        </FileUploaderProvider>
                      </MotionProvider>
                    </SnackbarProvider>
                  </LocalizationProvider>
                </ThemeProvider>
              </MessageManager>
            </AppCacheProvider>
          </SessionProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </>
  );
}

const DevTool = () => {
  const theme = useTheme();
  return <ReactQueryDevtools initialIsOpen={false} buttonPosition={theme.direction === 'rtl' ? 'bottom-left' : 'bottom-right'} />;
};

const loadFeatures = () => import('@/features.js').then((res) => res.default);

const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { data: session, update, status } = useSession();
  const { data: userSettings, isLoading: isUserSettingsLoading } = useUserSettings(!!session?.user?.id);
  const { data: userTenant } = useUserTenant(!!session?.user);
  const [guestFirstLogin, setGuestFirstLogin] = useState<boolean>(false);

  const {
    mode,
    setMode,
    setColorScheme,
    colorScheme,
    userSessionColorScheme,
    userSettingsColorScheme,
    tenantSettingsColorScheme,
    isLoading,
  } = useColorScheme();

  useEffect(() => {
    if (status === 'authenticated') {
      setAccessToken(session.user.token);
    }
    if (status === 'unauthenticated') {
      setAccessToken(undefined);
    }
  }, [session?.user.token, status]);

  // Force mode to dark
  useEffect(() => {
    if (typeof window !== 'undefined' && mode !== 'dark') {
      setMode('dark');
    }
  }, [mode, setMode]);

  useEffect(() => {
    if (typeof window !== 'undefined' && !isLoading) {
      // Color Scheme reconciliation
      if (!!userSettingsColorScheme && userSettingsColorScheme !== userSessionColorScheme) {
        void update();
      }
    }
  }, [isLoading, userSessionColorScheme, userSettingsColorScheme]);

  useEffect(() => {
    if (typeof window !== 'undefined' && !isLoading) {
      let applicableColorScheme: SupportedColorScheme | undefined;
      if (userSettingsColorScheme === 'same_as_tenant') {
        applicableColorScheme = tenantSettingsColorScheme;
      } else {
        applicableColorScheme = userSettingsColorScheme ?? tenantSettingsColorScheme;
      }
      if (!applicableColorScheme && DEFAULT_COLOR_SCHEME !== colorScheme) {
        setColorScheme({ dark: DEFAULT_COLOR_SCHEME });
      }
      if (!!applicableColorScheme && applicableColorScheme !== colorScheme) {
        setColorScheme({ dark: applicableColorScheme });
      }
    }
  }, [isLoading, setColorScheme, userSettingsColorScheme, tenantSettingsColorScheme]);

  useEffect(() => {
    const isClientRuntime = typeof window !== 'undefined';

    if (!isClientRuntime || isUserSettingsLoading || !userSettings?.language) {
      return;
    }

    if (userSettings.language !== router.locale) {
      router.push(router.asPath, router.asPath, { locale: userSettings.language });
    }
  }, [isUserSettingsLoading, userSettings?.language, router]);

  useEffect(() => {
    if (session?.error) {
      Notification.error('common:invalid_session');
      void logout();
    }
  }, [session?.error]);

  useEffect(() => {
    if (!session) {
      void queryClient.invalidateQueries({ refetchType: 'none' });
    }
  }, [session]);

  useEffect(() => {
    if (userTenant?.tenantCategory === 'GUEST') {
      if (globalThis.localStorage.getItem('isGuestFirstLogin:' + session?.user.id) !== 'true') {
        setGuestFirstLogin(true);
      }
    }
  }, [userTenant]);

  const handleClose = (doNotShowAgain: boolean) => {
    if (doNotShowAgain) {
      globalThis.localStorage.setItem('isGuestFirstLogin:' + session?.user.id, 'true');
    }
    setGuestFirstLogin(false);
  };

  return (
    <LazyMotion features={loadFeatures} strict>
      {children}
      <GuestDialog open={guestFirstLogin} onClose={handleClose} />
    </LazyMotion>
  );
};

export default appWithTranslation(MyApp);
