import { KeyboardEventModifier, ScreenSpaceEventType, ScreenSpaceEventHandler, Viewer } from 'cesium';
import { createUUid } from '@/utils';

type Callback =
  | ScreenSpaceEventHandler.PositionedEventCallback
  | ScreenSpaceEventHandler.MotionEventCallback
  | ScreenSpaceEventHandler.WheelEventCallback
  | ScreenSpaceEventHandler.TwoPointEventCallback
  | ScreenSpaceEventHandler.TwoPointMotionEventCallback;

type Subscription = [id: string, callback: Callback];

interface IScreenEvent {
  removeEventListener(callback: Callback, type: ScreenSpaceEventType, modifier?: KeyboardEventModifier): void;
  removeEventListener(eventId: string): void;
}

interface Index {
  type: ScreenSpaceEventType;
  modifier?: KeyboardEventModifier;
  callback: Callback;
}

export class ScreenEvent implements IScreenEvent {
  protected screenSpaceEventHandler: ScreenSpaceEventHandler;
  protected actionCallbacks: { [key: string]: Callback } = {};
  protected subscribers: { [key: string]: Subscription[] } = {};
  protected index: { [id: string]: Index } = {};
  protected viewer: Viewer;

  constructor(viewer: Viewer) {
    this.viewer = viewer;
    this.screenSpaceEventHandler = new ScreenSpaceEventHandler(viewer.scene.canvas);
  }

  protected getKey = (type: ScreenSpaceEventType, modifier?: KeyboardEventModifier) => [type, modifier || '-'].join(':');

  protected createCallback = (key: string) => {
    return (...args: any[]) => this.subscribers[key].forEach(([id, callback]) => (callback as any)(...args));
  };

  addEventListener(callback: Callback, type: ScreenSpaceEventType, modifier?: KeyboardEventModifier): string | undefined {
    if (this.viewer && this.viewer.cesiumWidget) {
      const key = this.getKey(type, modifier);
      if (!this.actionCallbacks[key]) {
        this.actionCallbacks[key] = this.createCallback(key);
        this.screenSpaceEventHandler.setInputAction(this.actionCallbacks[key], type, modifier);
        this.subscribers[key] = [];
      }

      const id = createUUid();
      this.index[id] = { type, modifier, callback };
      this.subscribers[key].push([id, callback]);
      return id;
    }
  }

  removeEventListener(callbackOrId: string | Callback, type?: ScreenSpaceEventType, modifier?: KeyboardEventModifier) {
    if (this.viewer && this.viewer.cesiumWidget) {
      if (typeof callbackOrId === 'string') {
        const index = this.index[callbackOrId];
        if (index) {
          const { type, modifier, callback } = index;
          this.removeEventListener(callback, type, modifier);
          delete this.index[callbackOrId];
          return true;
        }
      } else if (typeof type === 'number') {
        const callback = callbackOrId;
        const key = this.getKey(type, modifier);
        if (this.actionCallbacks[key]) {
          const idx = this.subscribers[key].findIndex((c) => c[1] === callback);
          if (idx >= 0) {
            const [subscriptionId] = this.subscribers[key].splice(idx, 1)[0];
            delete this.index[subscriptionId];
            if (!this.subscribers[key].length) {
              this.screenSpaceEventHandler.removeInputAction(type, modifier);
              delete this.subscribers[key];
              delete this.actionCallbacks[key];
            }
          }
          return !!this.subscribers[key]?.splice(idx, 1).length;
        }
      }

      return false;
    }
  }

  destroy() {
    this.screenSpaceEventHandler.destroy();
    this.actionCallbacks = {};
    this.actionCallbacks = {};
    this.subscribers = {};
    this.index = {};
  }
}
