import { singleton } from '@/utils/decorators/singleton';
import { Api, METHOD } from './Api';
import { MarketplaceItemSubscriptionId } from '@/models';

@singleton
class ModelSubscriptionApi extends Api {
  endpoint: string = '/api/model-subscription-requests';
  static getInstance: () => ModelSubscriptionApi;

  approve = (marketplaceItemSubscriptionId: MarketplaceItemSubscriptionId) =>
    this.request(METHOD.POST, '/' + marketplaceItemSubscriptionId + '/approve');

  reject = (marketplaceItemSubscriptionId: MarketplaceItemSubscriptionId) =>
    this.request(METHOD.POST, '/' + marketplaceItemSubscriptionId + '/reject');
}

export const modelSubscriptionApi = () => ModelSubscriptionApi.getInstance();
