import { Controller } from './Controller';
import { Geometry, GeometryType, hasIntersection, isRectangle, Polygon } from '@/utils/geometry';
import { MapTool, WKT } from '@/models';
import { throttle } from '@/hooks/useThrottle';
import { KEYS } from '@/consts';
import { FeaturesLayer } from '@/components/Cesium/DataSource/FeaturesLayer';
import { arrayMerge, getNextElement, getPrevElement } from '@/utils/array';
import { CesiumEvent } from '@/components/Cesium/Event';
import {
  CallbackProperty,
  Cartesian2,
  Cartesian3,
  Color,
  Entity,
  PolygonHierarchy,
  PolylineOutlineMaterialProperty,
  Rectangle,
  ScreenSpaceEventType,
  Viewer,
  Cartographic,
  EllipsoidRhumbLine,
} from 'cesium';
import {
  cartesianToPoint,
  cloneEntity,
  coordinateEquals,
  getCoordinatesTolerance,
  getEntityFromPointsOrCallback,
  getMiddelPointBetweenTwoPoints,
  getPointsFromEntity,
  getZoomDistance,
  julianDate,
  pointToCartesian3,
  removeCallbackFromEntity,
} from '@/components/Cesium/utils';
import { PointGraphics } from 'cesium';
import { WKTType } from '@/utils/geometry/Geometry';
import { PositionProperty } from 'cesium';
import { PropertyBag } from 'cesium';
import { color } from 'framer-motion';
import { ConstantProperty } from 'cesium';

export const SUPPORTED_TOOLS = [MapTool.POLYGON, MapTool.POINT, MapTool.RECTANGLE, MapTool.CIRCLE, MapTool.LINESTRING, MapTool.SELECT];
enum EditingPointTypes {
  VERTEX = 'VERTEX',
  MIDDEL = 'MIDDEL',
  SELECTED = 'SELECTED',
}
export const WKTTypeToMapTool: any = {
  [WKTType.LINESTRING]: MapTool.LINESTRING,
  [WKTType.POLYGON]: MapTool.POLYGON,
  [WKTType.POINT]: MapTool.POINT,
};
export const geometryToTool = (geometry?: Geometry | null): MapTool => {
  if (!geometry) {
    return MapTool.POINT;
  }

  switch (geometry.type) {
    case GeometryType.LINESTRING:
      return MapTool.LINESTRING;
    case GeometryType.MULTILINESTRING:
      return MapTool.LINESTRING;
    case GeometryType.POINT:
      return MapTool.POINT;
    case GeometryType.CIRCLE:
      return MapTool.LINESTRING;
    case GeometryType.POLYGON:
    case GeometryType.MULTIPOLYGON:
      return isRectangle(geometry) ? MapTool.RECTANGLE : MapTool.POLYGON;
  }
};

const POINT_DISTANCE_TOLERANCE = 8; // pixels

const DEFAULT_STYLES = {
  [GeometryType.POINT]: {
    pixelSize: 7,
    color: Color.fromCssColorString('#00d1fa'),
    outlineColor: Color.fromCssColorString('#ffffff'),
    outlineWidth: 1,
  },
  [GeometryType.LINESTRING]: {
    color: Color.fromCssColorString('#00d1fa'),
    outlineColor: Color.fromCssColorString('#ffffff'),
    outlineWidth: 2,
    width: 3,
  },
  [GeometryType.POLYGON]: {
    color: Color.fromCssColorString('#00d1fa50'),
  },
};
const EDIT_VERTIX_STYLES = {
  [EditingPointTypes.MIDDEL]: {
    pixelSize: 7,

    color: Color.fromCssColorString('#00ff00'),
    outlineColor: Color.fromCssColorString('#ffffff'),
    outlineWidth: 1,
  },
  [EditingPointTypes.VERTEX]: {
    pixelSize: 7,

    color: Color.fromCssColorString('#ff0000'),
    outlineColor: Color.fromCssColorString('#ffffff'),
    outlineWidth: 1,
  },
  [EditingPointTypes.SELECTED]: {
    pixelSize: 7,
    color: Color.fromCssColorString('#0000ff'),
    outlineColor: Color.fromCssColorString('#ffffff'),
    outlineWidth: 1,
  },
};
export enum EventType {
  DRAW_START = 'DRAW_START',
  DRAW_EDITED = 'DRAW_EDITED',
  DRAW_FINISHED = 'DRAW_FINISHED',
  DRAW = 'DRAW',
  DRAW_CANCELLED = 'DRAW_CANCELLED',
  DRAW_NOT_ALLOWED = 'DRAW_NOT_ALLOWED',
}

interface Props<T extends FeaturesLayer = FeaturesLayer> {
  layer?: T;
  allowedAreas?: (Polygon | WKT)[];
  addLayer?: boolean;
  features?: Geometry[];
  styles?: any;
  enabled?: boolean;
  tool?: MapTool;
}

export class DrawInteraction<T extends FeaturesLayer = FeaturesLayer> {
  readonly layer: T;
  protected _allowedAreas?: Polygon[];
  protected _tool?: MapTool;
  protected _enabled: boolean = false;
  protected _entity?: Entity;
  protected geometryEditVertexes: Entity[] = [];
  protected viewer: Viewer;
  protected points: Cartesian3[] = [];
  protected pointsFeatures: Entity[] = [];
  protected clickPos?: Cartesian2;
  protected cursor?: Cartesian3;
  protected mapCtl: Controller;
  protected styles = DEFAULT_STYLES;
  protected _draggerEntityPoint?: Entity;
  protected _editableEntity?: Entity;
  protected _selectedEditPoint?: Entity;
  readonly event = new CesiumEvent();

  constructor(mapCtl: Controller, options?: Props<T>) {
    this.mapCtl = mapCtl;
    this.layer = options?.layer || (new FeaturesLayer('_DRAW_') as T);
    this.viewer = options?.layer?.viewer || mapCtl.viewer;
    this.enabled = options?.enabled ?? true;
    this._tool = options?.tool || this._tool;
    this.styles = options?.styles || DEFAULT_STYLES;
    this._allowedAreas = options?.allowedAreas?.map((a) => (typeof a === 'string' ? Polygon.ParseFromWKT(a) : a));
    (options?.addLayer ?? true) && mapCtl.addLayer(this.layer);
    options?.features && this.addFeatures(options.features);
    this.viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
  }

  getPoints = (withCursor = true) => (withCursor ? arrayMerge(this.points, this.cursor) : this.points);

  protected getLineMaterial = () =>
    new PolylineOutlineMaterialProperty({
      color: this.styles[GeometryType.LINESTRING].color,
      outlineColor: this.styles[GeometryType.LINESTRING].outlineColor,
      outlineWidth: this.styles[GeometryType.LINESTRING].outlineWidth,
    });

  protected getPolygonHierarchy = (tool: MapTool, points: Cartesian3[]) => {
    const length = points.length;
    switch (tool) {
      case MapTool.RECTANGLE:
        if (length < 1) {
          return undefined;
        }
        return Rectangle.fromCartesianArray(points);

      case MapTool.POLYGON:
        if (length < 2) {
          return undefined;
        }

        return new PolygonHierarchy(points);
    }
  };

  protected getLinePosition = (tool: MapTool, points: Cartesian3[]) => {
    const length = points.length;
    switch (tool) {
      case MapTool.RECTANGLE:
        if (length < 1) {
          return undefined;
        }
        return this.getRectanglePoints(points);

      default:
        if (length < 2) {
          return undefined;
        }
        return points;
    }
  };
  protected getCircleRadius = (points: Cartesian3[]) => {
    return new EllipsoidRhumbLine(Cartographic.fromCartesian(points[0]), Cartographic.fromCartesian(points[1])).surfaceDistance;
  };

  public getEntityFromPoints(tool: MapTool = this._tool!, points?: Cartesian3[]): Entity | undefined {
    if (this.viewer && this.viewer.cesiumWidget) {
      return getEntityFromPointsOrCallback(tool, points ? points : new CallbackProperty(() => this.getPoints(), false), this.styles);
    }
  }

  protected getRectanglePoints = (points: Cartesian3[] = this.getPoints()) =>
    new Polygon(points.map(cartesianToPoint)).extent.points.map((p) => pointToCartesian3(p));

  protected mouseEventToCoordinate = (e: any): Cartesian3 | undefined => {
    if (this.viewer && this.viewer.cesiumWidget) {
      const ellipsoid = this.viewer.scene.globe.ellipsoid;
      const coordinate = this.viewer.camera.pickEllipsoid(e.endPosition, ellipsoid);
      return coordinate || this.cursor;
    }
  };

  protected addCallBackforMiddelPoint(entity: Entity) {
    const index = this.geometryEditVertexes.findIndex((ele) => ele.id === entity.id);

    if (index > -1) {
      const prevEntity = getPrevElement<Entity>(this.geometryEditVertexes, index, 2);
      const nextEntity = getNextElement<Entity>(this.geometryEditVertexes, index, 2);
      const prevMiddelEntity = getPrevElement<Entity>(this.geometryEditVertexes, index);
      const nexMiddeltEntity = getNextElement<Entity>(this.geometryEditVertexes, index);
      if (prevMiddelEntity.properties?.getValue(julianDate).pointType === EditingPointTypes.MIDDEL) {
        prevMiddelEntity.position = new CallbackProperty(
          () => getMiddelPointBetweenTwoPoints(entity.position?.getValue(julianDate)!, prevEntity.position?.getValue(julianDate)!),
          false,
        ) as any;
      }
      if (nexMiddeltEntity.properties?.getValue(julianDate).pointType === EditingPointTypes.MIDDEL) {
        nexMiddeltEntity.position = new CallbackProperty(
          () => getMiddelPointBetweenTwoPoints(entity.position?.getValue(julianDate)!, nextEntity.position?.getValue(julianDate)!),
          false,
        ) as any;
      }
    }
  }

  protected middelPointToVertex(entity: Entity) {
    entity.properties = new PropertyBag({ pointType: EditingPointTypes.VERTEX });
    const index = this.geometryEditVertexes.findIndex((ele) => ele.id === entity.id);
    if (entity.point?.color) {
      entity.point.color = new ConstantProperty(Color.fromCssColorString('#ff0000'));
    }
    if (index > -1) {
      const array1: Entity[] = this.geometryEditVertexes.slice(0, index);
      const array2: Entity[] = this.geometryEditVertexes.slice(index + 1);
      const prevEntity = getPrevElement<Entity>(this.geometryEditVertexes, index);
      const nextEntity = getNextElement<Entity>(this.geometryEditVertexes, index);
      const point1 = this.createEditingPointEntity(
        EditingPointTypes.MIDDEL,
        new CallbackProperty(
          () => getMiddelPointBetweenTwoPoints(entity.position?.getValue(julianDate)!, prevEntity.position?.getValue(julianDate)!),
          false,
        ) as any,
      );
      this.layer.entities.add(point1);
      const point2 = this.createEditingPointEntity(
        EditingPointTypes.MIDDEL,
        new CallbackProperty(
          () => getMiddelPointBetweenTwoPoints(entity.position?.getValue(julianDate)!, nextEntity.position?.getValue(julianDate)!),
          false,
        ) as any,
      );
      this.layer.entities.add(point2);
      this.geometryEditVertexes = [...array1, point1, this.geometryEditVertexes[index], point2, ...array2];
    }
  }

  protected setSelectedEditPoint(entity: Entity) {
    if (this._selectedEditPoint?.point?.color) {
      this._selectedEditPoint.point = new PointGraphics(EDIT_VERTIX_STYLES[EditingPointTypes.VERTEX]);
    }
    if (entity?.point) {
      entity.point = new PointGraphics(EDIT_VERTIX_STYLES[EditingPointTypes.SELECTED]);
    }
    this._selectedEditPoint = entity;
  }

  protected addCallBackToEditableEntity() {
    if (this._editableEntity?.polygon?.hierarchy) {
      this._editableEntity.polygon.hierarchy = new CallbackProperty(() => {
        const tempArray = this.geometryEditVertexes
          .filter((ele) => ele.properties?.getValue(julianDate).pointType === EditingPointTypes.VERTEX)
          .map((ele) => ele.position?.getValue(julianDate) as any);
        return new PolygonHierarchy([...tempArray, tempArray[0]]);
      }, false) as any;

      if (this._editableEntity?.polyline?.positions) {
        this._editableEntity.polyline.positions = new CallbackProperty(
          () => [
            ...this.geometryEditVertexes
              .filter((ele) => ele.properties?.getValue(julianDate).pointType === EditingPointTypes.VERTEX)
              .map((ele) => ele.position?.getValue(julianDate)),
            this.geometryEditVertexes[0].position?.getValue(julianDate),
          ],
          false,
        ) as any;
      }
    } else if (this._editableEntity?.polyline?.positions) {
      this._editableEntity.polyline.positions = new CallbackProperty(
        () =>
          this.geometryEditVertexes
            .filter((ele) => ele.properties?.getValue(julianDate).pointType === EditingPointTypes.VERTEX)
            .map((ele) => ele.position?.getValue(julianDate)),
        false,
      ) as any;
    } else if (this._editableEntity?.position) {
      this._editableEntity.position = new CallbackProperty(() => this.geometryEditVertexes[0].position?.getValue(julianDate), false) as any;
    }
  }

  protected clickHandler = (pos: any) => {
    if (this._editableEntity) {
      const entitys = this.mapCtl.drillPick(pos.position) as Entity[];
      const entity = entitys.filter((ele) => ele?.properties?.pointType?.getValue()).pop();
      if (entity && this._editableEntity) {
        if (entity.properties?.getValue(julianDate).pointType === EditingPointTypes.MIDDEL) {
          this.middelPointToVertex(entity);
        } else {
          this.addCallBackforMiddelPoint(entity);
        }
        this.setSelectedEditPoint(entity);
        this.mapCtl.enableScreenSpaceCameraController(false);

        this._draggerEntityPoint = entity;
        this.addCallBackToEditableEntity();
        entity.position = new CallbackProperty(() => this.cursor, false) as any;
      }
    } else {
      this.clickPos = pos.position;
    }
  };

  protected isSamePoint = (point: Cartesian2, secondPointIndex = this.points.length - 1) => {
    if (!point || !this.points[secondPointIndex]) {
      return false;
    }
    const lastPointPosition = this.mapCtl.getPositionByCoordinate(this.points[secondPointIndex]);
    return lastPointPosition && coordinateEquals(point, lastPointPosition, POINT_DISTANCE_TOLERANCE);
  };

  protected validateDrawingAreas = (point: Cartesian3): boolean => {
    if (!this._allowedAreas) {
      return true;
    }
    const gPoint = cartesianToPoint(point);
    return !!this._allowedAreas.find((area) => hasIntersection(area, gPoint));
  };

  protected mouseUpHandler = ({ position }: any) => {
    const isSameCoordinate = coordinateEquals(this.clickPos!, position);
    const isDragging = this._editableEntity && !isSameCoordinate;
    if (this._tool && (!this.clickPos || !isDragging) && this._tool !== MapTool.SELECT) {
      const point = this.mapCtl.getCoordinateByPosition(position);
      if (!point) {
        return;
      } else if (!this.validateDrawingAreas(point)) {
        this.event.raiseEvent(EventType.DRAW_NOT_ALLOWED as any, point as any, this as any);
        return;
      } else if (this.isSamePoint(position, this.points.length - 1)) {
        return;
      }
      this.points.push(point);
      this.points.length === 1 && this.drawStart();
      this.event.raiseEvent(EventType.DRAW as any, this.points as any, this as any);
      switch (this._tool) {
        case MapTool.POINT:
          return this.drawFinished();

        case MapTool.RECTANGLE:
          if (this.points.length >= 2) {
            this.points = this.getRectanglePoints();
            return this.drawFinished();
          }
          break;
        case MapTool.CIRCLE:
          if (this.points.length >= 2) {
            return this.drawFinished();
          }
          break;
        case MapTool.POLYGON:
          if (this.points.length > 2 && this.isSamePoint(position, 0)) {
            this.points[this.points.length - 1] = this.points[0].clone();
            return this.drawFinished();
          }
          break;
      }
      const pointEntity = this.layer.entities.add({
        position: point,
        point: {
          pixelSize: this.styles[GeometryType.POINT].pixelSize,
          color: this.styles[GeometryType.POINT].color,
          outlineColor: this.styles[GeometryType.POINT].outlineColor,
          outlineWidth: this.styles[GeometryType.POINT].outlineWidth,
        },
      });
      this.pointsFeatures.push(pointEntity);
    } else {
      this.mapCtl.enableScreenSpaceCameraController(true);
      this.geometryEditVertexes.forEach((ele) => {
        removeCallbackFromEntity(ele);
      });
      if (this._draggerEntityPoint) {
        this._draggerEntityPoint.position = this._draggerEntityPoint.position?.getValue(julianDate)?.clone() as any;
        this._editableEntity && removeCallbackFromEntity(this._editableEntity);
        if (!isSameCoordinate) {
          this.event.raiseEvent(
            EventType.DRAW_EDITED as any,
            getPointsFromEntity(this._editableEntity?.properties?.feature.type, this._editableEntity!),
          );
        }
      }
    }
  };

  protected mouseDoubleClickHandler = () => {
    const length = this.points.length;
    switch (this._tool) {
      case MapTool.POLYGON: {
        if (length > 2) {
          if (!coordinateEquals(this.points[length - 1], this.points[0], getCoordinatesTolerance(getZoomDistance(this.viewer)))) {
            this.points.push(this.points[0].clone());
          }
          this.drawFinished();
        }
        break;
      }
      case MapTool.LINESTRING: {
        if (length > 1) {
          this.drawFinished();
        }
        break;
      }
    }
  };

  protected windowKeyDownHandler = (e: KeyboardEvent) => {
    switch (e.key) {
      case KEYS.BACKSPACE: {
        if (this.points.length) {
          this.points.pop();
          const pointFeature = this.pointsFeatures.pop();
          pointFeature && this.layer.entities.remove(pointFeature);
          this.event.raiseEvent(EventType.DRAW as any, this.points as any, this as any);
        }
        if (this._editableEntity && this._selectedEditPoint) {
          this.addCallBackToEditableEntity();
          this.deleteSelectedEditPoint();
        }
        break;
      }
    }
  };
  protected deleteSelectedEditPoint() {
    // const index = this.geometryEditVertexes.findIndex((ele) => ele.id === this._selectedEditPoint?.id);
    if (
      this._editableEntity?.properties?.feature.type !== WKTType.POINT &&
      ((this._editableEntity?.properties?.feature.type === WKTType.LINESTRING && this.geometryEditVertexes.length > 3) ||
        (this._editableEntity?.properties?.feature.type === WKTType.POLYGON && this.geometryEditVertexes.length > 6))
    ) {
      this.geometryEditVertexes.forEach((ele) => this.layer.entities.remove(ele));
      this.layer.entities.remove(this._selectedEditPoint!);
      this.geometryEditVertexes = this.geometryEditVertexes.filter(
        (ele) => ele.properties?.getValue(julianDate).pointType === EditingPointTypes.VERTEX && ele.id !== this._selectedEditPoint?.id,
      );
      removeCallbackFromEntity(this._editableEntity!);
      this.event.raiseEvent(
        EventType.DRAW_EDITED as any,
        getPointsFromEntity(this._editableEntity?.properties?.feature.type, this._editableEntity!),
      );
      this.enableGeometryEditToEntity(this._editableEntity!);
    }
  }
  protected windowKeyUpHandler = (e: KeyboardEvent) => {
    switch (e.key) {
      case KEYS.ESCAPE: {
        if (this.points.length) {
          this.event.raiseEvent(EventType.DRAW_CANCELLED as any);
          this.reset();
        }
        break;
      }
    }
  };

  changeCoordinateHandler = throttle((e: any) => (this.cursor = this.mouseEventToCoordinate(e)) && this.render(), 50);

  render = throttle(() => this.mapCtl.renderScene(), 50);

  addFeatures = (features: Geometry[]) => {
    features.forEach((geo) => {
      const entity = this.getEntityFromPoints(
        geometryToTool(geo),
        (geo.type !== GeometryType.CIRCLE && geo.points.map(pointToCartesian3)) || [],
      );
      entity && this.layer.entities.add(entity);
    });
  };

  enableGeometryEditToEntity(entity: Entity | undefined) {
    this.removeGeometryEditVertexes();
    if (entity?.properties?.feature.type === WKTType.POLYGON && entity?.polygon?.hierarchy) {
      const positions = entity.polygon?.hierarchy?.getValue(julianDate) as PolygonHierarchy;
      positions.positions.forEach((postion, index) => {
        if (index !== positions.positions.length - 1) {
          const tempPointEntity = this.createEditingPointEntity(EditingPointTypes.VERTEX, postion);

          this.layer.entities.add(tempPointEntity);
          this.geometryEditVertexes.push(tempPointEntity);
          const virtualPointEntity = this.createEditingPointEntity(
            EditingPointTypes.MIDDEL,
            getMiddelPointBetweenTwoPoints(postion, getNextElement(positions.positions, index)),
          );

          this.layer.entities.add(virtualPointEntity);
          this.geometryEditVertexes.push(virtualPointEntity);
        }
      });
    }

    if (entity?.properties?.feature.type === WKTType.LINESTRING && entity?.polyline?.positions) {
      const positions = entity.polyline?.positions?.getValue(julianDate) as Cartesian3[];
      positions.forEach((postion, index) => {
        const tempPointEntity = this.createEditingPointEntity(EditingPointTypes.VERTEX, postion);

        this.layer.entities.add(tempPointEntity);
        this.geometryEditVertexes.push(tempPointEntity);
        if (index !== positions.length - 1) {
          const virtualPointEntity = this.createEditingPointEntity(
            EditingPointTypes.MIDDEL,
            getMiddelPointBetweenTwoPoints(postion, getNextElement(positions, index)),
          );
          this.layer.entities.add(virtualPointEntity);
          this.geometryEditVertexes.push(virtualPointEntity);
        }
      });
    }
    if (entity?.properties?.feature.type === WKTType.POINT && entity?.position) {
      const position = entity?.position.getValue(julianDate) as Cartesian3;

      const tempPointEntity = this.createEditingPointEntity(EditingPointTypes.VERTEX, position);

      this.layer.entities.add(tempPointEntity);
      this.geometryEditVertexes.push(tempPointEntity);
    }
    this._editableEntity = entity;
    this.render();
  }

  enableEvents(enable: boolean = true) {
    if (enable === this._enabled) {
      return;
    }
    this.clickPos = undefined;
    this.cursor = undefined;
    if (enable) {
      window?.addEventListener('keydown', this.windowKeyDownHandler);
      window?.addEventListener('keyup', this.windowKeyUpHandler);
      if (this.viewer && this.viewer.cesiumWidget) {
        // this.viewer.scene.canvas.addEventListener('mousemove', this.changeCoordinateHandler);
        this.viewer.screenSpaceEventHandler.setInputAction(this.clickHandler, ScreenSpaceEventType.LEFT_DOWN);
        this.viewer.screenSpaceEventHandler.setInputAction(this.changeCoordinateHandler, ScreenSpaceEventType.MOUSE_MOVE);
        this.viewer.screenSpaceEventHandler.setInputAction(this.mouseUpHandler, ScreenSpaceEventType.LEFT_UP);
        this.viewer.screenSpaceEventHandler.setInputAction(this.mouseDoubleClickHandler, ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      }
    } else {
      window?.removeEventListener('keydown', this.windowKeyDownHandler);
      window?.removeEventListener('keyup', this.windowKeyUpHandler);
      if (this.viewer && this.viewer.cesiumWidget) {
        this.viewer.scene.canvas.removeEventListener('mousemove', this.changeCoordinateHandler);
        // this.viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
        this.viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOWN);
        this.viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
        this.viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_UP);
        this.viewer.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      }
    }
  }

  protected drawStart() {
    this._entity = this.getEntityFromPoints();
    this.event.raiseEvent(EventType.DRAW_START as any, this.points as any, this as any);
    this._entity && this.layer.entities.add(this._entity);
  }

  drawFinished() {
    this.cursor = undefined;
    const entity = this._entity && cloneEntity(this._entity);
    entity && this.layer.entities.add(entity);
    this.event.raiseEvent(EventType.DRAW_FINISHED as any, this.points as any, entity as any, this as any);
    this.reset();
  }

  clear() {
    this.layer.removeFeatures();
    this.render();
  }

  protected removePointsEntities() {
    this.pointsFeatures.forEach((e) => this.layer.entities.remove(e));
    this.pointsFeatures = [];
  }
  protected removeGeometryEditVertexes() {
    this.geometryEditVertexes.forEach((e) => this.layer.entities.remove(e));
    this.geometryEditVertexes = [];
  }

  reset(doRender = true) {
    this.points = [];

    if (this.viewer && this.viewer.cesiumWidget) {
      this._entity && this.layer.entities.remove(this._entity);
      this._entity = undefined;
      this.removePointsEntities();
      this.removeGeometryEditVertexes();
    }
    doRender && this.render();
  }

  get tool(): MapTool | undefined {
    return this._tool;
  }

  set tool(mapTool: MapTool | undefined) {
    if (mapTool && SUPPORTED_TOOLS.includes(mapTool)) {
      this.reset();
      this._tool = mapTool;
    } else {
      this._tool = undefined;
    }
  }

  get enabled(): boolean {
    return this._enabled;
  }

  set enabled(enabled: boolean) {
    if (this._enabled && !enabled) {
      this.enableEvents(false);
      this.removePointsEntities();
    } else if (!this._enabled && enabled) {
      this.enableEvents();
    }
    this._enabled = enabled;
  }

  get show(): boolean {
    return this.layer.show;
  }

  set show(show: boolean) {
    this.layer.show = show;
  }

  get entity(): Entity | undefined {
    return this._entity;
  }

  destroy(removeLayer = true) {
    this.event.destroy();
    this.reset(false);
    this.enableEvents(false);
    removeLayer && this.mapCtl?.removeLayer(this.layer);
  }

  protected createEditingPointEntity(type: EditingPointTypes, position: Cartesian3 | PositionProperty | undefined) {
    return new Entity({
      position,
      point: EDIT_VERTIX_STYLES[type],
      properties: {
        pointType: type,
      },
      // parent: this._editableEntity,
    });
  }
}
