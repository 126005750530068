import { Permission, PermissionItem, User } from '@/models';
import { useSession } from 'next-auth/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { userApi } from '@/cmd/UserApi';
import { ACQUISITION_PERMISSION, MARKET_PERMISSION, MODELS_PERMISSION, WORKSPACE_PERMISSION } from './permissions';

export const hasPermission = (userPermissions: PermissionItem[], permissions: Permission | Permission[]): boolean =>
  userPermissions.findIndex((p) => (Array.isArray(permissions) ? permissions.includes(p.name) : p.name === permissions)) >= 0;

export const hasUserAdminAccess = (userPermissions: PermissionItem[]) => hasPermission(userPermissions, Permission.USERS_ADMIN);
export const hasPlatformAdminAccess = (userPermissions: PermissionItem[]) => hasPermission(userPermissions, Permission.PLATFORM_ADMIN);
export const hasCategoriesAdminCreatorAccess = (userPermissions: PermissionItem[]) =>
  hasPermission(userPermissions, [Permission.CATEGORIES_ADMIN, Permission.CATEGORIES_CREATOR]);
export const hasModelAccess = (userPermissions: PermissionItem[]) => hasPermission(userPermissions, MODELS_PERMISSION);
export const hasWorkspaceAccess = (userPermissions: PermissionItem[]) => hasPermission(userPermissions, WORKSPACE_PERMISSION);
export const hasAcquisitionAccess = (userPermissions: PermissionItem[]) => hasPermission(userPermissions, ACQUISITION_PERMISSION);
export const hasMarketplaceAccess = (userPermissions: PermissionItem[]) => hasPermission(userPermissions, MARKET_PERMISSION);
export const hasJobsAccess = (userPermissions: PermissionItem[]) => hasPermission(userPermissions, Permission.JOBS_ADMIN);
export const hasLabsAccess = (userPermissions: PermissionItem[]) => hasPermission(userPermissions, Permission.LABS_VIEW);

const getPermission = (data: User | undefined) => {
  if (data) {
    return Array.from(new Set(data.roles.flatMap((r) => r.permissions)));
  }
  return [];
};

export const sessionKeys = {
  all: ['session'] as const,
  info: () => [...sessionKeys.all, 'info'] as const,
  permissions: () => [...sessionKeys.all, 'permission'] as const,
};

export const useSessionPermissions = () => {
  const { status } = useSession();
  const { data } = useQuery({
    queryKey: sessionKeys.permissions(),
    queryFn: () => userApi().getMyInfo(),
    enabled: status === 'authenticated',
    staleTime: Infinity,
    select: getPermission,
  });
  return data ?? [];
};

export const useSessionInfo = () => {
  const { status } = useSession();
  const { data } = useQuery({
    queryKey: sessionKeys.info(),
    queryFn: () => userApi().getMyInfo(),
    enabled: status === 'authenticated',
    staleTime: Infinity,
  });
  return data;
};

export const useUpdateSession = () => {
  const queryClient = useQueryClient();
  const { update } = useSession();
  return useMutation({
    mutationFn: (request?: User) => update(request),
    onSuccess: (data, variables) => {
      return queryClient.invalidateQueries({ queryKey: sessionKeys.all });
    },
  });
};
