// noinspection JSUnusedGlobalSymbols
import JSOG from 'jsog';
import * as https from 'https';
import { AxiosResponse, RawAxiosRequestConfig } from 'axios';
import { axiosInstance as axios } from '@/cmd/axios';
import { KeyValue, Pageable } from '@/models';
import { CustomPromise, CustomPromiseProps, CustomPromiseState, setDataProcessor } from '@/utils/CustomPromise';
import { Notification } from '@/components/Notification';
import { getConfig } from '@/config';
import { GridSortDirection } from '@mui/x-data-grid';
import { getSession } from 'next-auth/react';

export type Response<T> = AxiosResponse<T>;
export type RequestPromise<T = any> = CustomPromiseProps<T>;

const DEFAULT_PAGE_SIZE = 1000;

const JsonTypes = ['application/json', 'application/geo+json'];
const TextType = 'text/plain';

export enum HTTP_CODE {
  OK = 200,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_ERROR = 500,
}

export enum METHOD {
  HEAD = 'HEAD',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

export const DEFAULT_PAGINATION: Required<PageableRequest> = {
  page: 0,
  pageSize: 20,
  sort: 'createdAt',
  direction: SORT_DIRECTION.DESC,
};

export type RequestParams = Record<string, string | string[] | number | number[] | boolean | undefined | null>;
export interface SearchFilter {
  search?: string;
}

export interface PageableRequest {
  page?: number;
  pageSize?: number;
  sort?: string;
  direction?: SORT_DIRECTION | GridSortDirection;
}

export interface LoadingData<T> {
  data?: T;
  error: boolean;
  loading: boolean;
}

export interface PageableResponse<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: { offset: number; pageNumber: number; pageSize: number };
  size: number;
  sort: { unsorted: boolean; sorted: boolean; empty: boolean };
  totalElements: number;
  totalPages: number;
}

export interface ExtraData extends RawAxiosRequestConfig, Record<string, unknown> {
  abortController?: AbortController;
  headers?: KeyValue<string | undefined>;
  skipAuthorization?: boolean;
  forceUrlLocation?: boolean;
}

export const pageableResponse = <T = any>(updated?: Partial<Pageable<T>>): Pageable<T> => ({
  list: [],
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  total: 0,
  totalPages: 1,
  loading: false,
  error: false,
  ...updated,
});

export const dataLoadingResponse = <T = any>(updated?: Partial<Pageable<T>>): LoadingData<T> => ({
  loading: false,
  error: false,
  ...updated,
});

export const pageableResult = <T = any>(promise: RequestPromise<PageableResponse<T>>, callback: (res: Partial<Pageable<T>>) => any) => {
  callback({ loading: true });
  return promise
    .then((res) => {
      const cbRes = callback(getPageable(res));
      return cbRes instanceof Promise ? cbRes : res.content;
    })
    .catch(() => callback({ loading: false, error: true }));
};

export const getPageable = <T = any>(res: PageableResponse<T>): Pageable<T> => {
  return {
    list: res.content,
    page: res.number,
    pageSize: res.size,
    total: res.totalElements,
    totalPages: res.totalPages,
    loading: false,
    error: false,
  };
};

export const loadingStateHandler = <T = any>(promise: Promise<T>, callback: (res: LoadingData<T>) => void) => {
  callback({ loading: true, error: false });
  return promise
    .then((res: T) => {
      callback({ data: res, loading: false, error: false });
      return res;
    })
    .catch(() => callback({ loading: false, error: true }));
};

export const prepareParams = (params?: RequestParams, prefix = '?') => {
  if (!params) {
    return '';
  }
  const keys = Object.keys(params);
  if (keys.length) {
    return (
      prefix +
      keys
        .filter((key: string) => params[key] !== undefined)
        .map((k: any) => {
          if (params[k] === undefined || params[k] === null) {
            return '';
          }
          if (typeof params[k] === 'boolean') {
            return k + '=' + (params[k] ? 1 : 0);
          }
          if (Array.isArray(params[k])) {
            return `${k}=${(params[k] as unknown as string[]).map((v: any) => encodeURIComponent(v)).join(',')}`;
          }
          return k + '=' + encodeURIComponent(params[k]!.toString());
        })
        .join('&')
    );
  }
  return '';
};

let token: string | undefined = undefined;

export const setAccessToken = (newToken: string | undefined) => {
  token = newToken;
};

export const getAccessToken = (): string | undefined => {
  return token;
};

/**
 * Add ?throw_exception=true to trigger API exception
 */
export class Api {
  endpoint: string = '';
  protected token?: string = token;
  protected oneUseToken?: string = undefined;
  protected agent = new https.Agent({ rejectUnauthorized: false });
  protected skipAuthorization = false;

  getFullUrl(url: string = '', forceUrlLocation = false) {
    if (/^https?:/.test(url)) {
      return url;
    }
    let appEndpoint = typeof window === 'undefined' ? process.env.INTERNAL_APP_ENDPOINT : getConfig().appEndpoint;
    if ((!appEndpoint || forceUrlLocation) && typeof window !== 'undefined') {
      appEndpoint = window.location.origin;
    }
    return (appEndpoint ?? '') + this.endpoint + url;
  }

  requestWithEndpoint(endpoint: string, callback: (...args: any) => any) {
    const originalEndpoint = this.endpoint;
    this.endpoint = endpoint;
    const res = callback();
    this.endpoint = originalEndpoint;
    return res;
  }

  request<T = any>(method: METHOD, url?: string, data?: any, extraData?: ExtraData, notification: boolean = true): RequestPromise<T> {
    const promise = this.rawRequest<T>(method, url, data, extraData, notification);
    return setDataProcessor<Response<T>, T>(promise, this.handleResponse);
  }

  rawRequest<T = any>(
    method: METHOD,
    url: string = '',
    data?: any,
    extraData: ExtraData = {},
    notification = true,
  ): RequestPromise<Response<T>> {
    const {
      headers = {},
      forceUrlLocation,
      skipAuthorization = this.skipAuthorization,
      abortController = new AbortController(),
      ...config
    } = extraData;
    const result = CustomPromise() as RequestPromise<Response<T>>;
    const signal = abortController.signal;
    const cancel = result.cancel.bind(result);
    result.cancel = () => {
      cancel();
      abortController.abort();
    };
    headers['Skip-Authorization'] = skipAuthorization.toString();
    if (!skipAuthorization) {
      headers.Authorization = headers?.Authorization ?? this.oneUseToken ?? this.token ?? '';
    }
    config.httpsAgent = this.agent;
    url = this.getFullUrl(url, !!forceUrlLocation);
    url = ['GET', 'DELETE'].includes(method) && data ? url + prepareParams(data, url.indexOf('?') === -1 ? '?' : '&') : url;
    axios
      .request({ url, method, data, signal, headers, ...config })
      .then((res) => result.resolve(res))
      .catch(async (err) => {
        let data = err?.response?.data;
        if (data && JsonTypes.includes(data.type)) {
          data = JSON.parse(await err.response.data.text());
        }
        if (notification && result.status !== CustomPromiseState.CANCELLED && typeof window !== 'undefined') {
          Notification.error(data?.message || data?.error || err?.message || err);
        }
        // console.error(err);
        // result.reject('Internal Server Error');
        result.reject(err?.data ?? err);
      })
      .finally(() => {
        this.oneUseToken = undefined;
      });

    return result;
  }

  getAll = <T = any>(
    apiFn: (page: number) => RequestPromise<PageableResponse<T>>,
    batchCallback?: (batch: T[]) => void,
  ): RequestPromise<T[]> => {
    let page = 0;
    const promise = CustomPromise();
    const list: T[] = [];
    const load: any = (p: number) =>
      apiFn(p).then((res) => {
        if (promise.status === CustomPromiseState.WAITING) {
          list.push(...res.content);
          batchCallback && batchCallback(res.content);
          return res.last ? list : load(++page);
        }
      });

    load(page)
      .then(promise.resolve)
      .catch((e: any) => (promise.status === CustomPromiseState.CANCELLED ? false : console.error(e)));

    return promise;
  };

  init(preconditions: () => any): this {
    preconditions();
    return this;
  }

  initPromise<T>(preconditions: () => Promise<T>): Promise<this> {
    const promise = CustomPromise<this>();
    preconditions()
      .then(() => promise.resolve(this))
      .catch(promise.reject);
    return promise;
  }

  handleResponse = <T = any>(res: Response<T>): T => {
    if (res.status === HTTP_CODE.NO_CONTENT) {
      return undefined as any;
    }
    if (JsonTypes.find((t) => (res.headers['content-type'] || '').indexOf(t) !== -1)) {
      if (typeof res.data !== 'object') {
        throw new Error('Invalid response');
      }
      return JSOG.decode(res.data);
    }
    if ((res.headers['content-type'] || '').indexOf(TextType) !== -1) {
      return res.data;
    }
    return res as any;
  };

  useToken(token?: string) {
    this.oneUseToken = token;
    return this;
  }
}
