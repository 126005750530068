import { Api, METHOD, RequestPromise } from './Api';
import { singleton } from '@/utils/decorators/singleton';
import { UploadedFileId, UploadedResource } from '@/models';

@singleton
class ResourceApi extends Api {
  endpoint: string = '/api/resource-storage';
  static getInstance: () => ResourceApi;

  upload(file: File): RequestPromise<UploadedResource> {
    const formData = new FormData();
    formData.append('resource', file);
    return this.request(METHOD.POST, '/upload', formData);
  }

  getUrl(id: UploadedFileId): RequestPromise<string> {
    return this.request(METHOD.GET, '/download/url?id=' + id);
  }

  delete(id: UploadedFileId) {
    return this.request(METHOD.DELETE, '/' + id);
  }
}

export const resourceApi = () => ResourceApi.getInstance();
