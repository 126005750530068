// noinspection JSUnusedGlobalSymbols

import { GeometryType, IGeometry, ParseProps, Point, Polygon, Projection } from '.';
import { PSEUDO_MERCATOR, WORLD_GEODETIC_SYSTEM } from '@/consts';
import { KeyValue } from '@/models';
import { WKTType } from '@/utils/geometry/Geometry';

export class Multipolygon implements IGeometry {
  polygons: Polygon[] = [];
  modifier?: string;
  properties: KeyValue = {};

  readonly type = GeometryType.MULTIPOLYGON;
  private _projection?: string;

  constructor(polygons: Polygon[] = [], modifier?: string) {
    this.polygons = polygons;
    this.modifier = modifier;
  }

  clone(): Multipolygon {
    const mPolygon = new Multipolygon(this.polygons.map((p) => p.clone()));
    mPolygon.properties = { ...this.properties };
    mPolygon.setProjection(this.projection);
    return mPolygon;
  }

  transformTo(to: Projection) {
    if (!this._projection) {
      throw Error('Source projection is not specified');
    }
    return this.transform(this._projection, to);
  }

  transform(from: Projection | ParseProps = PSEUDO_MERCATOR, to: Projection = WORLD_GEODETIC_SYSTEM) {
    if (typeof from === 'object') {
      to = from.featureProjection;
      from = from.dataProjection;
    }
    if (this._projection) {
      if (from !== this._projection) {
        throw Error('Source projection mismatch');
      }
      if (to === this._projection) {
        return this;
      }
    }
    this._projection = to;
    this.polygons = this.polygons.map((p: Polygon) => p.transform(from, to));
    return this;
  }

  transformBy(transformer: (p: Point) => Point) {
    this.polygons = this.polygons.map((poly: Polygon) => poly.transformBy(transformer));
    return this;
  }

  move(x: number | Point, y?: number | undefined) {
    this.polygons = this.polygons.map((p) => p.move(x, y));
    return this;
  }

  multiply(x: number | Point, y?: number | undefined) {
    this.polygons = this.polygons.map((p) => p.multiply(x, y));
    return this;
  }

  round(): Multipolygon {
    this.polygons = this.polygons.map((p) => p.round());
    return this;
  }

  mirrorY() {
    this.polygons = this.polygons.map((p) => p.mirrorY());
    return this;
  }

  toWKT() {
    return 'MULTIPOLYGON ' + (this.modifier ? this.modifier + ' ' : '') + '(' + this.toString() + ')';
  }

  toString() {
    return this.polygons.map((p) => '(' + p.toString() + ')').join(', ');
  }

  get maxX(): number {
    return Math.max(...this.polygons.map((p) => p.maxX));
  }

  get minX(): number {
    return Math.min(...this.polygons.map((p) => p.minX));
  }

  get maxY(): number {
    return Math.max(...this.polygons.map((p) => p.maxY));
  }

  get minY(): number {
    return Math.min(...this.polygons.map((p) => p.minY));
  }

  get extent(): Polygon {
    const { minX, minY, maxX, maxY } = this;
    return new Polygon([
      new Point(minX, minY),
      new Point(maxX, minY),
      new Point(maxX, maxY),
      new Point(minX, maxY),
      new Point(minX, minY),
    ]).setProjection(this._projection);
  }

  get center(): Point {
    return this.extent.center;
  }

  get points(): Point[] {
    return this.polygons.flatMap((p) => p.points);
  }

  get projection(): Projection | undefined {
    return this._projection;
  }

  setProjection(value: Projection | undefined) {
    this._projection = value;
    return this;
  }

  static ParseFromWKT(wkt: string, optProps?: ParseProps | Projection): Multipolygon | undefined {
    const data = wkt.trim().toUpperCase();
    if (data.indexOf(WKTType.MULTIPOLYGON) === 0) {
      let regexp = new RegExp('MULTIPOLYGON\\s?([Z|M]{0,2})\\s?\\((.*)\\)', 'im');
      let reg = regexp.exec(data);
      const body = reg![2];
      const modifier = reg![1];
      regexp = new RegExp('(\\(\\(.*?\\)\\))', 'img');
      const polygons: Polygon[] = [];
      while ((reg = regexp.exec(body))) {
        polygons.push(Polygon.ParseFromWKT(`POLYGON ${modifier} ${reg[1]}`));
      }
      const res = new Multipolygon(polygons, modifier);
      if (res && optProps) {
        return res.transform(optProps);
      }
      return res;
    }
  }
}
