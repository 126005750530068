import React, { FC, PropsWithChildren, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  styled,
  IconButton,
  Icon,
  Typography,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import Button from './Button';
import YoutubeCard from './Home/HowWorksPanel/YoutubeCard';
import { getConfig } from '@/config';
import Link from './Link';

const VideoGrid = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${({ theme }) => theme.spacing(6)};
  row-gap: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`;

export interface GuestDialogRawProps {
  open: boolean;
  onClose: (doNotShowAgain: boolean) => void;
}

const GuestDialog: FC<PropsWithChildren<GuestDialogRawProps>> = ({ onClose, open, ...other }) => {
  const { t } = useTranslation();
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const config = getConfig();

  const handleClose = () => {
    onClose(doNotShowAgain);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} {...other}>
      <DialogTitle>{t('common:guest_first_login_dialog')}</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 4, whiteSpace: 'pre-line' }}>
        {t('common:guest_first_login_content')}
        <VideoGrid>
          <YoutubeCard title={t('common:guest_login_video_2')} videoid="Z0Pg4QrKOB4" />
          <YoutubeCard title={t('common:guest_login_video_1')} videoid="HJrWGZXpIvs" />
          <YoutubeCard title={t('common:guest_login_video_3')} videoid="akBiUDRypPs" />
          <YoutubeCard title={t('common:guest_login_video_4')} videoid="JLbrQgFAYmo" />
        </VideoGrid>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {t('common:go_to_documentation')}
          {config.documentationUrl && (
            <Link href={config.documentationUrl}>
              <IconButton>
                <Icon>open_in_new</Icon>
              </IconButton>
            </Link>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={<Checkbox checked={doNotShowAgain} onChange={(e) => setDoNotShowAgain(e.target.checked)} />}
          label={t('common:do_not_show_again')}
        />
        <Button onClick={handleClose}>{t('common:close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GuestDialog;
