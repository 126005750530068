import { GEO_PRECISION, WGS } from '@/consts';
import { Numbers } from '@/utils/geometry/Numbers';
import { Point } from '@/utils/geometry';

export enum CoordinatesFormat {
  DD = 'DD',
  DMS = 'DMS',
  MGRS = 'MGRS',
  UTM = 'UTM',
}

// constants for WGS84
const a = 6378137;
const eccSquared = 0.00669438;
// ---

export const DMS_REGEX =
  // eslint-disable-next-line max-len
  /^([NS]?)\s?([0-9]{1,2})[:°]\s?([0-9]{1,2})[:'′]?\s?([0-9]{1,2}(?:\.[0-9]+)?)?["″]?\s?([NS]?),?\s?([EW]?)\s?([0-9]{1,3})[:°]\s?([0-9]{1,2})[:'′]?\s?([0-9]{1,2}(?:\.[0-9]+)?)?["″]?\s?([EW]?)$/i;
export const MGRS_REGEX = /^(\d{1,2})([A-Z])([A-Z]{2})\s([+-]?[0-9]*[.]?[0-9]+)\s([+-]?[0-9]*[.]?[0-9]+)$/i;
export const UTM_REGEX = /^(\d{1,2})\s?([A-Z])\s([+-]?[0-9]*[.]?[0-9]+)\s([+-]?[0-9]*[.]?[0-9]+)$/i;
export const DECIMAL_DEGREES_REGEX = /^([+-]?[0-9]*[.]?[0-9]+)\s([+-]?[0-9]*[.]?[0-9]+)$/i;

export const toDecimalDegree = (coordinate: string): [number, number] | undefined => {
  coordinate = coordinate.toString().trim();
  const format = detectCoordinatesFormat(coordinate);
  switch (format) {
    case CoordinatesFormat.DMS:
      return DMSToDecimalDegrees(coordinate);
    case CoordinatesFormat.MGRS:
      return MGRSToDecimalDegrees(coordinate);
    case CoordinatesFormat.UTM:
      return UTMToDecimalDegrees(coordinate);
    case CoordinatesFormat.DD:
      return coordinate
        .match(DECIMAL_DEGREES_REGEX)!
        .slice(1)
        .map((e) => Number(e)) as any;
  }
};

export const transformFromDecimalDegree = (coordinate: [number, number], toFormat: CoordinatesFormat): string | undefined => {
  switch (toFormat) {
    case CoordinatesFormat.DMS:
      return decimalDegreeToDMS(coordinate[0], coordinate[1]);
    case CoordinatesFormat.MGRS:
      return decimalDegreesToMGRS(coordinate[0], coordinate[1]);
    case CoordinatesFormat.UTM:
      return decimalDegreesToUTM(coordinate[0], coordinate[1]);
    case CoordinatesFormat.DD:
      return coordinate.map((e) => e.toFixed(GEO_PRECISION)).join(' ');
  }
};

export const detectCoordinatesFormat = (coordinates: string): CoordinatesFormat | undefined => {
  coordinates = coordinates.toString().trim();
  const regexList = {
    [CoordinatesFormat.DD]: DECIMAL_DEGREES_REGEX,
    [CoordinatesFormat.DMS]: DMS_REGEX,
    [CoordinatesFormat.MGRS]: MGRS_REGEX,
    [CoordinatesFormat.UTM]: UTM_REGEX,
  };
  return (Object.keys(regexList) as CoordinatesFormat[]).find((format) => regexList[format].test(coordinates));
};

export const pointToDecimalCoordinates = (p: Point) => `N${p.y.toFixed(6)}, E${p.x.toFixed(6)}`;
export const pointToDMSCoordinates = (p: Point) => decimalDegreeToDMS(p.y, p.x);
export const pointToMGRSCoordinates = (p: Point) => decimalDegreesToMGRS(p.y, p.x);

const decimalCoordinateToDMS = (coordinate: number) => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
  return [degrees, minutes, seconds];
};

const DMSCoordinateToDDCoordinate = (degrees: number, minutes: number, seconds: number, direction: string) => {
  let dd = degrees + minutes / 60 + seconds / (60 * 60);

  if (direction === 'S' || direction === 'W') {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
};

export const DMSToDecimalDegrees = (dms: string): [number, number] => {
  const match = dms.toString().trim().match(DMS_REGEX);
  if (!match) {
    throw Error('Invalid DMS coordinates format');
  }
  const [, fLatDirection, latDegrees, latMinutes, latSeconds, lLatDirection, ...rest] = match;
  const [fLonDirection, lonDegrees, lonMinutes, lonSeconds, lLonDirection] = rest;
  const latDirection = fLatDirection || lLatDirection;
  const lonDirection = fLonDirection || lLonDirection;
  const lat = DMSCoordinateToDDCoordinate(Number(latDegrees), Number(latMinutes), Number(latSeconds), latDirection);
  const lon = DMSCoordinateToDDCoordinate(Number(lonDegrees), Number(lonMinutes), Number(lonSeconds), lonDirection);

  return [lat, lon];
};

export const decimalDegreeToDMS = (lat: number, lon: number): string => {
  const [latD, latM, latS] = decimalCoordinateToDMS(lat);
  const [lonD, lonM, lonS] = decimalCoordinateToDMS(lon);
  return `${latD}°${latM}′${latS}″${lat >= 0 ? 'N' : 'S'}, ${lonD}°${lonM}′${lonS}″${lon >= 0 ? 'E' : 'W'}`;
};

export const decimalDegreesToMGRS = (lat: number, lon: number, accuracy = 5): string | undefined => {
  if (lat < -80) {
    console.error('MGRS error: Too far South');
    return undefined;
  }
  if (lat > 84) {
    console.error('MGRS error: Too far South');
    return undefined;
  }

  const c = 1 + Math.floor((lon + 180) / 6);
  const e = c * 6 - 183;
  const k = (lat * Math.PI) / 180;
  const l = (lon * Math.PI) / 180;
  const m = (e * Math.PI) / 180;
  const n = Math.cos(k);
  const o = 0.006739496819936062 * Math.pow(n, 2);
  const p = 40680631590769 / (6356752.314 * Math.sqrt(1 + o));
  const q = Math.tan(k);
  const r = q * q;
  const s = r * r * r - Math.pow(q, 6);
  const t = l - m;
  const u = 1.0 - r + o;
  const v = 5.0 - r + 9 * o + 4.0 * (o * o);
  const w = 5.0 - 18.0 * r + r * r + 14.0 * o - 58.0 * r * o;
  const x = 61.0 - 58.0 * r + r * r + 270.0 * o - 330.0 * r * o;
  const y = 61.0 - 479.0 * r + 179.0 * (r * r) - r * r * r;
  const z = 1385.0 - 3111.0 * r + 543.0 * (r * r) - r * r * r;
  let aa =
    p * n * t +
    (p / 6.0) * Math.pow(n, 3) * u * Math.pow(t, 3) +
    (p / 120.0) * Math.pow(n, 5) * w * Math.pow(t, 5) +
    (p / 5040.0) * Math.pow(n, 7) * y * Math.pow(t, 7);
  let ab =
    6367449.14570093 *
      (k -
        0.00251882794504 * Math.sin(2 * k) +
        0.00000264354112 * Math.sin(4 * k) -
        0.00000000345262 * Math.sin(6 * k) +
        0.000000000004892 * Math.sin(8 * k)) +
    (q / 2.0) * p * Math.pow(n, 2) * Math.pow(t, 2) +
    (q / 24.0) * p * Math.pow(n, 4) * v * Math.pow(t, 4) +
    (q / 720.0) * p * Math.pow(n, 6) * x * Math.pow(t, 6) +
    (q / 40320.0) * p * Math.pow(n, 8) * z * Math.pow(t, 8);
  aa = aa * 0.9996 + 500000.0;
  ab = ab * 0.9996;
  if (ab < 0.0) {
    ab += 10000000.0;
  }
  const ad = 'CDEFGHJKLMNPQRSTUVWXX'.charAt(Math.floor(lat / 8 + 10));
  const ae = Math.floor(aa / 100000);
  const af = ['ABCDEFGH', 'JKLMNPQR', 'STUVWXYZ'][(c - 1) % 3].charAt(ae - 1);
  const ag = Math.floor(ab / 100000) % 20;
  const ah = ['ABCDEFGHJKLMNPQRSTUV', 'FGHJKLMNPQRSTUVABCDE'][(c - 1) % 2].charAt(ag);
  aa = Math.floor(aa % 100000);
  ab = Math.floor(ab % 100000);
  const aaStr = aa.toString().padStart(5, '0').substring(0, accuracy);
  const abStr = ab.toString().padStart(5, '0').substring(0, accuracy);
  return c + ad + af + ah + ' ' + aaStr + ' ' + abStr;
};

export const MGRSToDecimalDegrees = (mgrs: string): [number, number] => {
  let b = mgrs.toString().trim().match(MGRS_REGEX);
  if (!b) {
    throw Error('Invalid MGRS coordinates format');
  }
  b = [`${b[1]}${b[2]}`, b[3], b[4], b[5], b[6]];
  const c: number = Number(b[0].length < 3 ? b[0][0] : b[0].slice(0, 2));
  const d = b[0].length < 3 ? b[0][1] : b[0][2];
  const e = ((c * 6 - 183) * Math.PI) / 180;
  const f = ['ABCDEFGH', 'JKLMNPQR', 'STUVWXYZ'][(c - 1) % 3].indexOf(b[1][0]) + 1;
  const g = 'CDEFGHJKLMNPQRSTUVWXX'.indexOf(d);
  const h = ['ABCDEFGHJKLMNPQRSTUV', 'FGHJKLMNPQRSTUVABCDE'][(c - 1) % 2].indexOf(b[1][1]);
  const i = [1.1, 2.0, 2.8, 3.7, 4.6, 5.5, 6.4, 7.3, 8.2, 9.1, 0, 0.8, 1.7, 2.6, 3.5, 4.4, 5.3, 6.2, 7.0, 7.9];
  const j = [0, 2, 2, 2, 4, 4, 6, 6, 8, 8, 0, 0, 0, 2, 2, 4, 4, 6, 6, 6];
  const k = i[g];
  let l = Number(j[g]) + h / 10;
  if (l < k) {
    l += 2;
  }
  let m = f * 100000.0 + Number(b[2]);
  let n = l * 1000000 + Number(b[3]);
  m -= 500000.0;
  if (d < 'N') {
    n -= 10000000.0;
  }
  m /= 0.9996;
  n /= 0.9996;
  const o = n / 6367449.14570093;
  const p =
    o +
    0.0025188266133249035 * Math.sin(2.0 * o) +
    0.0000037009491206268 * Math.sin(4.0 * o) +
    0.0000000074477705265 * Math.sin(6.0 * o) +
    0.000000000017035994 * Math.sin(8.0 * o);
  const q = Math.tan(p);
  const r = q * q;
  const s = r * r;
  const t = Math.cos(p);
  const u = 0.006739496819936062 * Math.pow(t, 2);
  const v = 40680631590769 / (6356752.314 * Math.sqrt(1 + u));
  let w = v;
  const x = 1.0 / (w * t);
  w *= v;
  const y = q / (2.0 * w);
  w *= v;
  const z = 1.0 / (6.0 * w * t);
  w *= v;
  const aa = q / (24.0 * w);
  w *= v;
  const ab = 1.0 / (120.0 * w * t);
  w *= v;
  const ac = q / (720.0 * w);
  w *= v;
  const ad = 1.0 / (5040.0 * w * t);
  w *= v;
  const ae = q / (40320.0 * w);
  const af = -1.0 - u;
  const ag = -1.0 - 2 * r - u;
  const ah = 5.0 + 3.0 * r + 6.0 * u - 6.0 * r * u - 3.0 * (u * u) - 9.0 * r * (u * u);
  const ai = 5.0 + 28.0 * r + 24.0 * s + 6.0 * u + 8.0 * r * u;
  const aj = -61.0 - 90.0 * r - 45.0 * s - 107.0 * u + 162.0 * r * u;
  const ak = -61.0 - 662.0 * r - 1320.0 * s - 720.0 * (s * r);
  const al = 1385.0 + 3633.0 * r + 4095.0 * s + 1575 * (s * r);
  let lat = p + y * af * (m * m) + aa * ah * Math.pow(m, 4) + ac * aj * Math.pow(m, 6) + ae * al * Math.pow(m, 8);
  let lon = e + x * m + z * ag * Math.pow(m, 3) + ab * ai * Math.pow(m, 5) + ad * ak * Math.pow(m, 7);
  lat = (lat * 180) / Math.PI;
  lon = (lon * 180) / Math.PI;
  return [lat, lon];
};

export const decimalDegreesToUTM = (latitude: number, longitude: number, precision: number = 0) => {
  const round = (num: number, precision = 0) => {
    const factorOfTen = Math.pow(10, precision);
    return Math.round(num * factorOfTen) / factorOfTen;
  };
  let ZoneNumber;
  const LongTemp = longitude;
  const LatRad = Numbers.deg2Rad(latitude);
  const LongRad = Numbers.deg2Rad(LongTemp);

  if (LongTemp >= 8 && LongTemp <= 13 && latitude > 54.5 && latitude < 58) {
    ZoneNumber = 32;
  } else if (latitude >= 56.0 && latitude < 64.0 && LongTemp >= 3.0 && LongTemp < 12.0) {
    ZoneNumber = 32;
  } else {
    ZoneNumber = (LongTemp + 180) / 6 + 1;

    if (latitude >= 72.0 && latitude < 84.0) {
      if (LongTemp >= 0.0 && LongTemp < 9.0) {
        ZoneNumber = 31;
      } else if (LongTemp >= 9.0 && LongTemp < 21.0) {
        ZoneNumber = 33;
      } else if (LongTemp >= 21.0 && LongTemp < 33.0) {
        ZoneNumber = 35;
      } else if (LongTemp >= 33.0 && LongTemp < 42.0) {
        ZoneNumber = 37;
      }
    }
  }
  ZoneNumber = parseInt(ZoneNumber.toString(), 10);

  const LongOrigin = (ZoneNumber - 1) * 6 - 180 + 3; // +3 puts origin in middle of zone
  const LongOriginRad = Numbers.deg2Rad(LongOrigin);
  const UTMZone = getUtmLetterDesignator(latitude);
  const eccPrimeSquared = eccSquared / (1 - eccSquared);
  const N = a / Math.sqrt(1 - eccSquared * Math.sin(LatRad) * Math.sin(LatRad));
  const T = Math.tan(LatRad) * Math.tan(LatRad);
  const C = eccPrimeSquared * Math.cos(LatRad) * Math.cos(LatRad);
  const A = Math.cos(LatRad) * (LongRad - LongOriginRad);

  const M =
    a *
    ((1 - eccSquared / 4 - (3 * eccSquared * eccSquared) / 64 - (5 * eccSquared * eccSquared * eccSquared) / 256) * LatRad -
      ((3 * eccSquared) / 8 + (3 * eccSquared * eccSquared) / 32 + (45 * eccSquared * eccSquared * eccSquared) / 1024) *
        Math.sin(2 * LatRad) +
      ((15 * eccSquared * eccSquared) / 256 + (45 * eccSquared * eccSquared * eccSquared) / 1024) * Math.sin(4 * LatRad) -
      ((35 * eccSquared * eccSquared * eccSquared) / 3072) * Math.sin(6 * LatRad));

  let UTMEasting =
    0.9996 * N * (A + ((1 - T + C) * A * A * A) / 6 + ((5 - 18 * T + T * T + 72 * C - 58 * eccPrimeSquared) * A * A * A * A * A) / 120) +
    500000.0;

  let UTMNorthing =
    0.9996 *
    (M +
      N *
        Math.tan(LatRad) *
        ((A * A) / 2 +
          ((5 - T + 9 * C + 4 * C * C) * A * A * A * A) / 24 +
          ((61 - 58 * T + T * T + 600 * C - 330 * eccPrimeSquared) * A * A * A * A * A * A) / 720));

  if (latitude < 0) {
    UTMNorthing += 10000000.0;
  }
  UTMNorthing = round(UTMNorthing, precision);
  UTMEasting = round(UTMEasting, precision);
  return `${ZoneNumber}${UTMZone} ${UTMEasting} ${UTMNorthing}`;
  // return { Easting: UTMEasting, Northing: UTMNorthing, ZoneNumber: parseInt(ZoneNumber, 10), ZoneLetter: UTMZone };
};

export const UTMToDecimalDegrees = (utm: string): [number, number] => {
  const matches = utm.toString().trim().match(UTM_REGEX);
  if (!matches) {
    throw Error('Invalid UTM coordinates format');
  }
  const [, UTMZoneNumber, UTMZoneLetter, UTMEasting, UTMNorthing] = matches;
  const e1 = (1 - Math.sqrt(1 - eccSquared)) / (1 + Math.sqrt(1 - eccSquared));
  const x = Number(UTMEasting) - 500000.0; // remove 500,000 meter offset for longitude
  let NorthernHemisphere;
  let y = Number(UTMNorthing);
  const ZoneNumber = Number(UTMZoneNumber);
  if (['N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].indexOf(UTMZoneLetter) !== -1) {
    NorthernHemisphere = 1;
  } else {
    NorthernHemisphere = 0;
    y -= 10000000.0;
  }

  const LongOrigin = (ZoneNumber - 1) * 6 - 180 + 3;

  const eccPrimeSquared = eccSquared / (1 - eccSquared);

  const M = y / 0.9996;
  const mu = M / (a * (1 - eccSquared / 4 - (3 * eccSquared * eccSquared) / 64 - (5 * eccSquared * eccSquared * eccSquared) / 256));

  const phi1Rad =
    mu +
    ((3 * e1) / 2 - (27 * e1 * e1 * e1) / 32) * Math.sin(2 * mu) +
    ((21 * e1 * e1) / 16 - (55 * e1 * e1 * e1 * e1) / 32) * Math.sin(4 * mu) +
    ((151 * e1 * e1 * e1) / 96) * Math.sin(6 * mu);
  // const phi1 = Numbers.rad2Deg(phi1Rad);

  const N1 = a / Math.sqrt(1 - eccSquared * Math.sin(phi1Rad) * Math.sin(phi1Rad));
  const T1 = Math.tan(phi1Rad) * Math.tan(phi1Rad);
  const C1 = eccPrimeSquared * Math.cos(phi1Rad) * Math.cos(phi1Rad);
  const R1 = (a * (1 - eccSquared)) / Math.pow(1 - eccSquared * Math.sin(phi1Rad) * Math.sin(phi1Rad), 1.5);
  const D = x / (N1 * 0.9996);

  let Lat =
    phi1Rad -
    ((N1 * Math.tan(phi1Rad)) / R1) *
      ((D * D) / 2 -
        ((5 + 3 * T1 + 10 * C1 - 4 * C1 * C1 - 9 * eccPrimeSquared) * D * D * D * D) / 24 +
        ((61 + 90 * T1 + 298 * C1 + 45 * T1 * T1 - 252 * eccPrimeSquared - 3 * C1 * C1) * D * D * D * D * D * D) / 720);
  Lat = Numbers.rad2Deg(Lat);

  let Long =
    (D -
      ((1 + 2 * T1 + C1) * D * D * D) / 6 +
      ((5 - 2 * C1 + 28 * T1 - 3 * C1 * C1 + 8 * eccPrimeSquared + 24 * T1 * T1) * D * D * D * D * D) / 120) /
    Math.cos(phi1Rad);
  Long = LongOrigin + Numbers.rad2Deg(Long);
  return [Lat, Long];
};

const getUtmLetterDesignator = (latitude: number) => {
  switch (true) {
    case 84 >= latitude && latitude >= 72:
      return 'X';
    case 72 > latitude && latitude >= 64:
      return 'W';
    case 64 > latitude && latitude >= 56:
      return 'V';
    case 56 > latitude && latitude >= 48:
      return 'U';
    case 48 > latitude && latitude >= 40:
      return 'T';
    case 40 > latitude && latitude >= 32:
      return 'S';
    case latitude >= 24 && latitude < 32:
      return 'R';
    case 24 > latitude && latitude >= 16:
      return 'Q';
    case 16 > latitude && latitude >= 8:
      return 'P';
    case latitude >= 0 && latitude < 8:
      return 'N';
    case 0 > latitude && latitude >= -8:
      return 'M';
    case -8 > latitude && latitude >= -16:
      return 'L';
    case -16 > latitude && latitude >= -24:
      return 'K';
    case -24 > latitude && latitude >= -32:
      return 'J';
    case -32 > latitude && latitude >= -40:
      return 'H';
    case -40 > latitude && latitude >= -48:
      return 'G';
    case -48 > latitude && latitude >= -56:
      return 'F';
    case -56 > latitude && latitude >= -64:
      return 'E';
    case latitude < -64 && latitude >= -72:
      return 'D';
    case latitude >= -80 && latitude < -72:
      return 'C';
    default:
      return 'Z';
  }
};

export const coordinatesToPoint = (data: string): Point | undefined => {
  const coordinates = toDecimalDegree(data);
  if (coordinates) {
    if (Math.abs(coordinates[1]) > 90) {
      [coordinates[0], coordinates[1]] = [coordinates[1], coordinates[0]];
    }
    return new Point(...coordinates).setProjection(WGS);
  }
};
