import { FC } from 'react';
import { Box, Paper, styled, Typography } from '@mui/material';

import { YouTubeEmbed } from '@next/third-parties/google';

interface Props {
  title: string;
  videoid: string;
  params?: string;
  background?: string;
}

const StyledPaper = styled(Paper)`
  border-radius: 12;
  position: relative;
  &:has(iframe) {
    .text-box {
      display: none;
    }
  }
`;

const YoutubeCard: FC<Props> = ({ title, videoid, params, background }) => {
  return (
    <StyledPaper>
      <YouTubeEmbed
        videoid={videoid}
        params={params && params}
        style={`border-radius:12px; ${background ? 'background-image: url(' + background + ')' : ''} `}
      />
      <Box className="text-box" sx={{ position: 'absolute', zIndex: 1, bottom: '20px', left: '20px' }}>
        <Typography color="text.primary" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', zIndex: 1 }}>
          {title}
        </Typography>
      </Box>
    </StyledPaper>
  );
};

export default YoutubeCard;
