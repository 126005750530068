import { Math as CMath } from 'cesium';
import { Geometry, GeometryType, parseFromWKT } from './Geometry';
import { Line } from './Line';
import { Point } from './Point';
import { Polygon } from './Polygon';
import { WKT } from '@/models';
import { Numbers } from './Numbers';
import lineIntersect from '@turf/line-intersect';
import intersect from '@turf/intersect';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import {
  Feature as TFeature,
  lineString as turfLine,
  Point as TPoint,
  point as turfPoint,
  polygon as turfPolygon,
  Polygon as TPolygon,
} from '@turf/helpers';
import { round } from '@/utils';
import { toArray } from '@/utils/array';
import { MERCATOR_TO_WGS, WGS_TO_MERCATOR } from '@/consts';

export { Point } from './Point';
export { Circle } from './Circle';
export { Line } from './Line';
export { Polygon } from './Polygon';
export { Multipolygon } from './Multipolygon';
export { SimpleLine } from './SimpleLine';
export { parseFromWKT, rotate, scale, GeometryType } from './Geometry';
export type { Geometry, IGeometry, ParseProps, Projection } from './Geometry';

export type Coordinate = Coordinate2 | Coordinate3;
export type Coordinate2 = [number, number];
export type Coordinate3 = [number, number, number];
export type Size = number | [number, number] | Point;

enum GeoJSONTypes {
  FeatureCollection = 'FeatureCollection',
}

enum GeoJSONFeatureTypes {
  Feature = 'Feature',
}

enum GeoJSONGeometryTypes {
  MultiPolygon = 'MultiPolygon',
  Polygon = 'Polygon',
  Point = 'Point',
  Line = 'LineString',
  Unknown = 'Unknown',
}

interface GeoJSONFeature {
  type: GeoJSONFeatureTypes;
  properties: {
    CategoryId: string;
    Height: number;
  };
  id: string;
  geometry: {
    type: GeoJSONGeometryTypes;
    coordinates: Coordinate | Coordinate[] | Coordinate[][] | Coordinate[][][];
  };
}

interface GeoJsonFeatureCollection {
  type: GeoJSONTypes;
  features: GeoJSONFeature[];
}

export const getPolygon = (polygonOrBoundary: WKT | Polygon): Polygon =>
  polygonOrBoundary instanceof Polygon ? polygonOrBoundary : Polygon.ParseFromWKT(polygonOrBoundary);

export const getPolygonAngle = (polygonOrBoundary: string | Polygon, closest?: number) => {
  const polygon = getPolygon(polygonOrBoundary);
  if (polygon.points.length > 4) {
    const points = polygon.points;
    const fi = points[0].getFi(points[1]);
    return fi - Math.PI / 2;
  }
  return 0;
};

export const fixPolygonAspectRatio = (polygonOrBoundary: string | Polygon, canvasSize: Size, angle?: number) => {
  const size = Point.CreateFromSize(canvasSize);
  const polygon = getPolygon(polygonOrBoundary).clone();
  const fi = getPolygonAngle(polygon, angle);
  polygon.rotate(fi);
  const sizeRatio = size.w / size.h;
  const polygonRatio = polygon.w / polygon.h;
  if (polygonRatio === 0) {
    return polygon;
  }

  let xScale = 1;
  let yScale = 1;
  let scaleFactor = sizeRatio / polygonRatio;
  scaleFactor = scaleFactor > 1 ? scaleFactor : 1 / scaleFactor;
  if (sizeRatio > polygonRatio) {
    xScale = scaleFactor;
  } else {
    yScale = scaleFactor;
  }
  polygon.scale(new Point(xScale, yScale));

  return polygon.rotate(-fi);
};

export const normalizeMeridian = (point: Point) => {
  point.x -= Math.abs(point.x) > 180 ? -360 : 0;
  return point;
};

// https://macwright.com/2016/09/26/the-180th-meridian.html
export const fixMeridianCrossViewPort = (viewPort: Polygon) => {
  const [topLeft, topRight, bottomRight, bottomLeft] = viewPort.points;
  topRight.x += topRight.x < topLeft.x ? 360 : 0;
  bottomRight.x += bottomRight.x < bottomLeft.x ? 360 : 0;
  return viewPort;
};

export const parseGeoJSON = (s: string | GeoJsonFeatureCollection): Geometry[] => {
  const data = (typeof s === 'string' ? JSON.parse(s) : s) as GeoJsonFeatureCollection;
  const parsePolygon = (coordinates: Coordinate[][]) => {
    const res = Polygon.ParseFromCoords(coordinates[0]);
    for (let i = 1; i < coordinates.length; i++) {
      res.holes.push(Polygon.ParseFromCoords(coordinates[i]));
    }
    return res;
  };
  if (data.type === GeoJSONTypes.FeatureCollection) {
    return data.features
      .map((r: GeoJSONFeature) => {
        switch (r?.geometry?.type) {
          case GeoJSONGeometryTypes.Polygon: {
            const polygon = parsePolygon(r.geometry.coordinates as Coordinate[][]);
            polygon.properties = r.properties;
            polygon.properties.id = r.id;
            return polygon;
          }
          case GeoJSONGeometryTypes.MultiPolygon: {
            return r.geometry.coordinates.map((coordinates) => {
              const polygon = parsePolygon(coordinates as Coordinate[][]);
              polygon.properties = r.properties;
              polygon.properties.id = r.id;
              return polygon;
            });
          }
          case GeoJSONGeometryTypes.Line: {
            const line = Line.ParseFromCoords(r.geometry.coordinates as Coordinate[]);
            line.properties = r.properties;
            line.properties.id = r.id;
            return line;
          }
          case GeoJSONGeometryTypes.Point: {
            const point = Point.Parse(r.geometry.coordinates as Coordinate);
            point.properties = r.properties;
            point.properties.id = r.id;
            return point;
          }
          default:
            return null;
        }
      })
      .flat()
      .filter((r: Geometry | null) => r) as Geometry[];
  }
  return [];
};

export const isRectangle = (geom: Geometry, tolerance = CMath.EPSILON4): boolean => {
  return (
    geom.type === GeometryType.POLYGON &&
    geom.points.length === 5 &&
    Numbers.like(geom.p(0).x, geom.p(3).x, tolerance) &&
    Numbers.like(geom.p(0).y, geom.p(1).y, tolerance) &&
    Numbers.like(geom.p(1).x, geom.p(2).x, tolerance) &&
    Numbers.like(geom.p(2).y, geom.p(3).y, tolerance)
  );
};

export const hasIntersection = (geoOrWkt1: Geometry | WKT, geoOrWkt2: Geometry | WKT): boolean => {
  const geo1 = typeof geoOrWkt1 === 'string' ? parseFromWKT(geoOrWkt1) : geoOrWkt1;
  const geo2 = typeof geoOrWkt2 === 'string' ? parseFromWKT(geoOrWkt2) : geoOrWkt2;
  if (!geo1 || !geo2) {
    throw Error('Cannot find intersection. Wrong WKT');
  }
  const geo1turf = toTurf(geo1);
  const geo2turf = toTurf(geo2);

  if (geo1 instanceof Polygon && geo2 instanceof Polygon) {
    return !!intersect(geo1turf as TFeature<TPolygon>, geo2turf as TFeature<TPolygon>);
  } else if ((geo1 instanceof Line || geo1 instanceof Polygon) && (geo2 instanceof Line || geo2 instanceof Polygon)) {
    return !!lineIntersect(geo1turf as any, geo2turf as any);
  } else if (geo1 instanceof Point && geo2 instanceof Polygon) {
    return booleanPointInPolygon(geo1turf as TFeature<TPoint>, geo2turf as TFeature<TPolygon>);
  } else if (geo1 instanceof Polygon && geo2 instanceof Point) {
    return booleanPointInPolygon(geo2turf as TFeature<TPoint>, geo1turf as TFeature<TPolygon>);
  }

  return false;
};

export const toTurf = (geometry: Geometry) => {
  if (geometry instanceof Polygon) {
    return turfPolygon([geometry.getCoordinates()]);
  } else if (geometry instanceof Line) {
    return turfLine(geometry.getCoordinates());
  } else if (geometry instanceof Point) {
    return turfPoint(geometry.getCoordinates());
  }
  throw Error('This type of geometry is not supported');
};

export const getPolygonOverlap = (polygonOrWkt1: Polygon | WKT, polygonOrWkt2: Polygon | WKT): [number, number] => {
  const polygon1 = typeof polygonOrWkt1 === 'string' ? Polygon.ParseFromWKT(polygonOrWkt1) : polygonOrWkt1;
  const polygon2 = typeof polygonOrWkt2 === 'string' ? Polygon.ParseFromWKT(polygonOrWkt2) : polygonOrWkt2;
  const intersectArea = toArray(polygon1.simpleIntersection(polygon2))?.reduce((sum, p) => p.area + sum, 0);
  if (!intersectArea) {
    return [0, 0];
  }
  const overlapPercent1 = round((intersectArea / polygon1.area) * 100, 2);
  const overlapPercent2 = round((intersectArea / polygon2.area) * 100, 2);
  return [overlapPercent1, overlapPercent2];
};

export const findPerpendicularPointsByDistance = (x1: number, y1: number, x2: number, y2: number, distance: number) => {
  let x3, y3, x4, y4;

  // Check if the line is vertical (x1 - x2 is zero)
  if (x1 === x2) {
    // For a vertical line, move horizontally by the given distance
    x3 = x1 + distance;
    x4 = x1 - distance;
    y3 = y1;
    y4 = y1;
  } else {
    // Calculate the slope for non-vertical lines
    const slope = (y2 - y1) / (x2 - x1);

    // Find the negative reciprocal of the slope
    const perpendicularSlope = -1 / slope;

    // Calculate the perpendicular offsets for x and y based on the given distance
    const xOffset = distance / Math.sqrt(1 + perpendicularSlope ** 2);
    const yOffset = perpendicularSlope * xOffset;

    // Calculate the coordinates of the two perpendicular points
    x3 = x2 + xOffset;
    y3 = y2 + yOffset;
    x4 = x2 - xOffset;
    y4 = y2 - yOffset;
  }

  // Return the coordinates of the two perpendicular points
  return [new Point(x3, y3).transform(MERCATOR_TO_WGS), new Point(x4, y4).transform(MERCATOR_TO_WGS)];
};

export const findPointInSameLine = (x1: number, y1: number, x2: number, y2: number, distance: number) => {
  let x3, y3;

  if (x1 === x2) {
    // For a vertical line, move horizontally by the given distance
    x3 = x1;
    y3 = y1 + (distance * (y1 - y2)) / Math.abs(y1 - y2);
  } else {
    // Calculate the slope for non-vertical lines
    const slope = (y2 - y1) / (x2 - x1);

    // Find angle of line
    const theta = Math.atan(slope);
    // the coordinates of the A3 Point
    x3 = x2 + ((x2 - x1) / Math.abs(x2 - x1)) * distance * Math.cos(theta);
    y3 = y2 + ((x2 - x1) / Math.abs(x2 - x1)) * distance * Math.sin(theta);
    // Find the negative reciprocal of the slope

    // Return the coordinates of the new point
  }
  return new Point(x3, y3).transform(MERCATOR_TO_WGS);
};

/**
 * Calculates the corner coordinates of a parallelogram and returns a Polygon object.
 *
 * @param {Point} center - The center point of the parallelogram. It's assumed this is in latitude/longitude (WGS84) coordinates.
 * @param {number} b - The base length of the parallelogram in meters.
 * @param {number} h - The height of the parallelogram in meters.
 * @param {number} angle1 - The acute angle of the parallelogram in degrees.
 * @returns {Polygon} A Polygon object representing the parallelogram, with coordinates in latitude/longitude (WGS84).
 */
export const getParallelogram = (center: Point, b: number, h: number, angle1: number): Polygon => {
  const theta1 = (angle1 * Math.PI) / 180;

  const a = h / Math.sin(theta1);

  const b1 = Math.sqrt(Math.pow(a, 2) - Math.pow(h, 2));

  const xc = (b + b1) / 2;
  const yc = h / 2;

  const corner1 = center.clone().transform(WGS_TO_MERCATOR).move(-xc, -yc).transform(MERCATOR_TO_WGS);
  const corner2 = center
    .clone()
    .transform(WGS_TO_MERCATOR)
    .move(b - xc, -yc)
    .transform(MERCATOR_TO_WGS);
  const corner3 = center.clone().transform(WGS_TO_MERCATOR).move(xc, yc).transform(MERCATOR_TO_WGS);
  const corner4 = center
    .clone()
    .transform(WGS_TO_MERCATOR)
    .move(xc - b, yc)
    .transform(MERCATOR_TO_WGS);
  return new Polygon([corner1, corner2, corner3, corner4, corner1]);
};
