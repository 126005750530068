import { FC, PropsWithChildren } from 'react';
import { Experimental_CssVarsProvider as CssVarsProvider, SupportedColorScheme } from '@mui/material/styles';
import { useThemeForProvider } from '@/theme';
import { useSession } from 'next-auth/react';
import { useTenantSettings, useUserSettings } from '@/hooks/useSettings';
import { DEFAULT_COLOR_SCHEME } from '@/consts';

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: session } = useSession();
  const { data: userSettings } = useUserSettings(!!session?.user?.id);
  const { data: tenantSettings } = useTenantSettings(session?.user?.tenantId);
  const theme = useThemeForProvider();

  let applicableColor: SupportedColorScheme;
  if (userSettings?.colorScheme === 'same_as_tenant') {
    applicableColor = tenantSettings?.colorScheme ?? DEFAULT_COLOR_SCHEME;
  } else {
    applicableColor = userSettings?.colorScheme ?? tenantSettings?.colorScheme ?? DEFAULT_COLOR_SCHEME;
  }

  return (
    <CssVarsProvider
      theme={theme}
      defaultColorScheme={{
        light: 'light',
        dark: applicableColor,
      }}
      defaultMode="dark"
    >
      {children}
    </CssVarsProvider>
  );
};

export default ThemeProvider;
