import { ColumnMapping, DataMapping, DataMappingPresetId, DataMappingRow } from '@/models';
import { GridColDef } from '@mui/x-data-grid';
import { ColumnDelimiter, ColumnDelimiterOption } from '@/consts';
import { renderHeader } from '@/components/FileUpload/DataMapping/HeaderCell';
import { parse, ParseResult } from 'papaparse';

export enum TimestampFormats {
  DATE_AND_TIME_TOGETHER = 'DATETIME',
  DATE_AND_TIME_SEPARATE = 'DATE_TIME',
  UNIX_TIME = 'UNIXTIMESTAMP',
}

export enum TimestampOptions {
  DATE_AND_TIME = 'dateTimeColumn',
  DATE = 'dateColumn',
  TIME = 'timeColumn',
  UNIX_TIME = 'unixTimestampColumn',
}

export enum LocationFormats {
  LAT_LONG_SEPARATE = 'LAT_LONG',
  LAT_LONG_TOGETHER = 'LATLONG',
  HEIGHT = 'HEIGHT',
}

export enum LocationOptions {
  LAT = 'latitudeColumn',
  LONG = 'longitudeColumn',
  HEIGHT = 'heightColumn',
  LATLONG = 'latitudeLongitudeColumn',
}

const getTimestampOptions = (format: TimestampFormats | ''): TimestampOptions[] => {
  switch (format) {
    case TimestampFormats.DATE_AND_TIME_TOGETHER:
      return [TimestampOptions.DATE_AND_TIME];
    case TimestampFormats.DATE_AND_TIME_SEPARATE:
      return [TimestampOptions.DATE, TimestampOptions.TIME];
    case TimestampFormats.UNIX_TIME:
      return [TimestampOptions.UNIX_TIME];
    default:
      return [];
  }
};
const getLocationOptions = (format: LocationFormats | '', hasHeight: boolean): LocationOptions[] => {
  let options: LocationOptions[];
  switch (format) {
    case LocationFormats.LAT_LONG_SEPARATE:
      options = [LocationOptions.LAT, LocationOptions.LONG];
      break;
    case LocationFormats.LAT_LONG_TOGETHER:
      options = [LocationOptions.LATLONG];
      break;
    default:
      options = [];
      break;
  }
  if (hasHeight) options.push(LocationOptions.HEIGHT);
  return options;
};
export const getOptions = (
  timeStampFormat: TimestampFormats | '',
  locationFormat: LocationFormats | '',
  hasHeight: boolean,
): (TimestampOptions | LocationOptions)[] => {
  return [...getTimestampOptions(timeStampFormat), ...getLocationOptions(locationFormat, hasHeight)];
};

export const getDelimiter = (option: ColumnDelimiterOption): ColumnDelimiter | string => {
  switch (option) {
    case ColumnDelimiterOption.SEMICOLON:
      return ColumnDelimiter.SEMICOLON;
    case ColumnDelimiterOption.TAB:
      return ColumnDelimiter.TAB;
    case ColumnDelimiterOption.SPACE:
      return ColumnDelimiter.SPACE;
    case ColumnDelimiterOption.PIPE:
      return ColumnDelimiter.PIPE;
    case ColumnDelimiterOption.OTHER:
      return '';
    default:
      return ColumnDelimiter.COMMA;
  }
};

export const columnHandler = (key: any): GridColDef => ({
  field: key,
  headerName: key,
  width: 200,
  sortable: false,
  renderHeader,
});

export const readCsvFile = (
  file: File,
  delimiter: ColumnDelimiter | string,
  isFirstRowHeader: boolean,
  handleReady: (values: { rows: DataMappingRow[]; columns: GridColDef[] }) => void,
) => {
  parse(file, {
    delimiter,
    header: isFirstRowHeader,
    worker: true,
    preview: 6,
    complete: (results: ParseResult<unknown[] | Record<string, unknown>>, file: File) => {
      if (isFirstRowHeader) {
        const rows = (results.data as Record<string, unknown>[]).map((row, index) => ({ internalGridId: index.toString(), ...row }));
        const columns = results.meta.fields!.map(columnHandler);
        handleReady({ rows, columns });
      } else {
        const body = results.data as unknown[][];
        const headers = Array.from({ length: body[0].length }, (_, i) => getColumn(i));
        const columns = headers.map((i) => columnHandler(i));

        const rows = body.map((row, rowIndex) =>
          row.reduce((acc: DataMappingRow, current, columnIndex) => {
            if (!acc.internalGridId) acc.internalGridId = rowIndex.toString();
            acc[headers[columnIndex] as string] = current;
            return acc;
          }, {} as DataMappingRow),
        );
        handleReady({ rows, columns });
      }
    },
  });
};

export const readGeoJsonFile =
  (handleReady: (values: { rows: Record<string, unknown>[]; columns: GridColDef[] }) => void) => (event: FileReaderEventMap['load']) => {
    const json = JSON.parse(event.target!.result as string);
    const headers = Object.keys(json.features[0].properties);
    const columns: GridColDef[] = headers.map(columnHandler);
    const rows = json.features
      .slice(0, 5)
      .map((f: { properties: Record<string, unknown> }, index: number) => ({ internalGridId: index, ...f.properties }));
    handleReady({ rows, columns });
  };

const getColumn = (index: number) => `Column ${index}`;

export const toDataMapping = (
  mapping: ColumnMapping,
  timestampFormat: TimestampFormats | '',
  locationFormat: LocationFormats | '',
  isFirstRowHeaders: boolean,
): DataMapping => {
  return Object.keys(mapping).reduce(
    (acc, current) => {
      if (isTimestampOption(mapping[current]) && !!acc.timestampFormat) {
        return {
          ...acc,
          timestampFormat: {
            ...acc.timestampFormat,
            [mapping[current]]: current,
          },
        };
      } else if (!isTimestampOption(mapping[current]) && !!acc.locationFormat) {
        return {
          ...acc,
          locationFormat: {
            ...acc.locationFormat,
            [mapping[current]]: current,
          },
        };
      } else {
        return acc;
      }
    },
    {
      isFirstRowHeaders,
      ...(!!timestampFormat
        ? {
            timestampFormat: {
              type: timestampFormat,
            },
          }
        : { timestampFormat: null }),
      ...(!!locationFormat
        ? {
            locationFormat: {
              type: locationFormat,
            },
          }
        : { locationFormat: null }),
    } as DataMapping,
  );
};

export const toColumnMapping = (mapping: DataMapping, columns: string[]): ColumnMapping => {
  const result: ColumnMapping = {};

  Object.keys(mapping).forEach((format) => {
    const formatObj = mapping[format as keyof typeof mapping];
    if (formatObj) {
      Object.keys(formatObj).forEach((param) => {
        const mappedColumn = formatObj[param as keyof typeof formatObj] as string;
        if (param !== 'type' && !!mappedColumn && columns.includes(mappedColumn)) {
          result[mappedColumn] = param as TimestampOptions | LocationOptions;
        }
      });
    }
  });

  return result;
};
export const isTimestampOption = (option: TimestampOptions | LocationOptions): option is TimestampOptions => {
  return Object.values(TimestampOptions).includes(option as TimestampOptions);
};

export const isPresetBeingEdited = (
  presetId: DataMappingPresetId | undefined,
  editMode: boolean,
  toEditId: DataMappingPresetId | undefined,
) => {
  return editMode && (toEditId === presetId || !presetId);
};

export const partialClearMapping = (options: string[]) => (prev: Record<string, TimestampOptions | LocationOptions>) => {
  const updated: Record<string, TimestampOptions | LocationOptions> = {};
  const keys = Object.keys(prev);
  keys.forEach((key) => {
    if (!Object.values(options).includes(prev[key])) {
      updated[key] = prev[key];
    }
  });
  return updated;
};
