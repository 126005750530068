import { Status, UploadStatus } from '@/models';

export const getStatusIcon = (status: UploadStatus) => {
  switch (status) {
    case UploadStatus.UPLOADED:
      return 'check_circle';
    case UploadStatus.FAILED:
      return 'error';
    case UploadStatus.UPLOADING:
      return 'cloud_upload';
    case UploadStatus.QUEUED:
      return 'hourglass_empty';
    case UploadStatus.CANCELLED:
      return 'close';
    case UploadStatus.SKIPPED:
      return 'block';
  }
};

export const getStatusColor = (status: UploadStatus) => {
  switch (status) {
    case UploadStatus.UPLOADED:
      return Status.SUCCESS;
    case UploadStatus.FAILED:
    case UploadStatus.CANCELLED:
      return Status.ERROR;
    default:
      return Status.INFO;
  }
};
