import { FC, useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Icon, MenuItem, SelectChangeEvent } from '@mui/material';
import { stopEvent } from '@/utils/dom';
import { Select } from '@/components/Select';
import { getOptions, LocationOptions, TimestampOptions } from '@/components/FileUpload/DataMapping/utils';
import { DataMappingContext } from '@/components/FileUpload/DataMapping/DataMappingDialog';
import { GridColumnHeaderParams } from '@mui/x-data-grid';

export const HeaderCell: FC<{ columnName: string }> = ({ columnName }) => {
  const { t } = useTranslation(['common', 'upload']);
  const { timestampFormat, locationFormat, hasHeight, mapping, handleMapping } = useContext(DataMappingContext)!;

  const optionValue = mapping[columnName] ?? '';

  const handleOption = (e: SelectChangeEvent<TimestampOptions | LocationOptions>) => {
    handleMapping({ [columnName]: e.target.value as TimestampOptions | LocationOptions });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      width={'100%'}
      pt={2}
      onFocus={stopEvent}
      onBlur={stopEvent}
      onClick={stopEvent}
      onMouseOver={stopEvent}
      onMouseOut={stopEvent}
      onMouseEnter={stopEvent}
      onMouseLeave={stopEvent}
    >
      <Select label={'Select field'} fullWidth disabled={!timestampFormat && !locationFormat} value={optionValue} onChange={handleOption}>
        <MenuItem value="">{t('common:none')}</MenuItem>
        {getOptions(timestampFormat, locationFormat, hasHeight)
          .filter((o) => !Object.values(mapping).includes(o) || optionValue === o)
          .map((o) => (
            <MenuItem key={o} value={o}>
              {t('upload:option_' + o)}
            </MenuItem>
          )) ?? []}
      </Select>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {!!optionValue && <Icon color="success">check_circle</Icon>}
        <Box component="span" sx={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {columnName}
        </Box>
      </Box>
    </Box>
  );
};

export function renderHeader(params: GridColumnHeaderParams) {
  return <HeaderCell columnName={params.field} />;
}
