import { Event } from 'cesium';
import { Callback } from '@/models';

interface CesiumEventProps {
  _listeners: Callback[];
  _scopes: any[];
}

export class CesiumEvent extends Event {
  removeAll() {
    const { _listeners, _scopes } = this as unknown as CesiumEventProps;
    _listeners.forEach((listener, idx) => {
      this.removeEventListener(listener, _scopes[idx]);
    });
  }

  destroy() {
    this.removeAll();
  }
}
