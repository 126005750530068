import getRuntimeConfig from 'next/config';
import { BASE_LAYER } from './components/Cesium/tileServer';
import { Product, TileServerSettings } from '@/models';
import { SupportedColorScheme } from '@mui/material/styles';

interface Config {
  version: string;
  tileServer: { [key in BASE_LAYER]: TileServerSettings };
  appTitle?: string;
  chunkSize?: string;
  concurrentConnection?: string;
  appEndpoint: string;
  isWindDemoEnabled: boolean;
  uaePassEndpoint?: string;
  uaePassClient?: string;
  showUaesa: boolean;
  showUaePass: boolean;
  minioUrl?: string;
  fileServerUrl?: string;
  frontendUrl: string;
  isProduction: boolean;
  defaultColorScheme: SupportedColorScheme;
  defaultProduct: Product;
  planetAuthorization?: string;
  documentationUrl?: string;
  wsUrl?: string;
  wsAuthUrl?: string;
  isGuestAccountEnabled: boolean;
}

const { publicRuntimeConfig } = getRuntimeConfig() || {};
export const getConfig = (): Config => publicRuntimeConfig || {};
