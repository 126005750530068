import NavigationRail from '@/components/Navigation/NavigationRail';
import { MultiNavigationButton, NavigationButton } from '@/components/Navigation/NavigationButton';
import AvatarMenu from '@/components/Navigation/AvatarMenu';
import NotificationIndicator from '@/components/Notifications/NotificationIndicator';
import UploadIndicator from '@/components/FileUpload/Indicator';
import HelpMenu from '@/components/Navigation/HelpMenu';
import { Box, Divider } from '@mui/material';
import { useAccessMultiNavigation, useAccessNavigationMap } from '@/components/Navigation/utils';
import { AdminNavigation, NavigationMap, SystemNavigation } from '@/components/Navigation/routes';
import LanguageMenu from '@/components/Navigation/LanguageMenu';

const TopNavigation = () => {
  const list = useAccessNavigationMap(NavigationMap);
  const admin = useAccessMultiNavigation(AdminNavigation);
  const system = useAccessMultiNavigation(SystemNavigation);
  return (
    <NavigationRail>
      {list.map((n) => ('subNavigation' in n ? <MultiNavigationButton key={n.id} {...n} /> : <NavigationButton key={n.id} {...n} />))}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, marginTop: 'auto' }}>
        {!!admin && <MultiNavigationButton {...admin} />}
        {!!system && <MultiNavigationButton {...system} />}
        {(!!admin || !!system) && <Divider flexItem sx={{ marginBlock: 0.5 }} />}
        <NotificationIndicator />
        <UploadIndicator />
        <HelpMenu />
        <LanguageMenu />
        <AvatarMenu />
      </Box>
    </NavigationRail>
  );
};

export default TopNavigation;
