import { styled } from '@mui/material';
import { THUMBNAIL_SIZE } from '@/consts';

export const ThumbnailHolder = styled('div', { shouldForwardProp: (prop) => prop !== 'size' })<{ size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size ?? THUMBNAIL_SIZE}px;
  height: ${({ size }) => size ?? THUMBNAIL_SIZE}px;
  min-width: ${({ size }) => size ?? THUMBNAIL_SIZE}px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme }) => theme.vars.palette.common.white};
  background-color: ${({ theme }) => theme.vars.palette.background.paper};
  background-image: ${({ theme }) => 'linear-gradient(in oklab 135deg, #107E69 0%, #133A94 100%)'};
`;
