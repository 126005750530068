import { CustomDataSource, Entity, Viewer } from 'cesium';

export class FeaturesLayer extends CustomDataSource {
  viewer?: Viewer;
  readonly id?: string;
  readonly isTemporal = false;
  protected destroyed = false;

  constructor(id?: string, name?: string) {
    super(name);
    this.id = id;
  }

  // @ts-ignore
  set show(value: boolean) {
    // @ts-ignore
    super.show = value;
    this.changedEvent.raiseEvent(this as any);
  }

  get show(): boolean {
    // @ts-ignore
    return super.show;
  }

  setShow(show: boolean) {
    this.show = show;
  }

  getEntityById(entityId: string) {
    return this.entities.getById(entityId);
  }

  removeFeatures(suspendEvents = false) {
    suspendEvents && this.entities.suspendEvents();
    this.entities.removeAll();
    suspendEvents && this.entities.resumeEvents();
    return this;
  }

  removeFeature(idOrEntity: string | Entity) {
    return typeof idOrEntity === 'string' ? this.entities.removeById(idOrEntity) : this.entities.remove(idOrEntity);
  }

  destroy() {
    this.destroyed = true;
    this.removeFeatures(true);
  }
}
