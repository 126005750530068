import * as React from 'react';
import { useEffect, useState } from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { useTranslation } from 'next-i18next';
import { CircularProgress } from '@mui/material';

export interface ButtonProps extends Omit<LoadingButtonProps, 'onClick'> {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any | Promise<any>;
  onLoading?: (loading: boolean) => void;
}

const defaultLoadingIndicator = <CircularProgress color="inherit" size={16} />;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { onClick, loading: propLoading, onLoading, loadingPosition, loadingIndicator = defaultLoadingIndicator, ...rest },
  ref,
) {
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(propLoading ?? false);

  useEffect(() => {
    onLoading?.(loading);
  }, [loading, onLoading]);

  useEffect(() => {
    if (propLoading !== undefined) {
      setLoading(propLoading);
    }
  }, [propLoading]);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    try {
      const result = onClick?.(e);
      if (result instanceof Promise) {
        setLoading(true);
        await result;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const lp = rest.startIcon ? 'start' : rest.endIcon ? 'end' : loadingPosition;

  return (
    <LoadingButton
      ref={ref}
      onClick={handleClick}
      loading={loading}
      loadingIndicator={!lp ? t('loading_ellipsis') : loadingIndicator}
      loadingPosition={lp}
      {...rest}
    />
  );
});

export default Button;
