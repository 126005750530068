import {
  Box,
  Icon,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  listItemSecondaryActionClasses,
  ListItemText,
  Skeleton,
  styled,
  TextField,
  Tooltip,
} from '@mui/material';
import { DataMappingPreset, DataMappingPresetId } from '@/models';
import { ChangeEvent, FC, useState } from 'react';
import { useCreateDataMappingPreset, useUpdateDataMappingPreset } from '@/components/FileUpload/DataMapping/queries';
import { handleInputCharacterLimit } from '@/utils/input';
import { ThumbnailHolder } from '@/components/AnnotationProject/ThumbnailHolder';
import { THUMBNAIL_SMALL_SIZE } from '@/consts';
import { useTranslation } from 'next-i18next';
import { stopEvent } from '@/utils/dom';

const StyledListItemButton = styled(ListItemButton)`
  gap: ${({ theme }) => theme.spacing(3)};
  padding-inline-end: ${({ theme }) => theme.spacing(12)};

  &:has(+ .${listItemSecondaryActionClasses.root} button:nth-of-type(2)) {
    padding-inline-end: ${({ theme }) => theme.spacing(22)};
  }

  &:has(+ .${listItemSecondaryActionClasses.root} button:nth-of-type(3)) {
    padding-inline-end: ${({ theme }) => theme.spacing(32)};
  }
`;

interface ItemProps {
  item: DataMappingPreset;
  selected: boolean;
  onSelect: (preset: DataMappingPreset) => void;
  onDelete: (preset: DataMappingPreset) => void;
  onEdit: (id?: DataMappingPresetId) => void;
  edit: boolean;
  disabled: boolean;
}

export const PresetItem: FC<ItemProps> = ({ item, selected, onSelect, onDelete, onEdit, edit, disabled }) => {
  const { t } = useTranslation(['common']);
  const createDataMappingPreset = useCreateDataMappingPreset();
  const updateDataMappingPreset = useUpdateDataMappingPreset();
  const [newName, setNewName] = useState(item.name ?? '');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setNewName(handleInputCharacterLimit(newValue));
  };

  const handleEdit = () => {
    onEdit(item.id);
  };

  const handleSelect = () => !disabled && onSelect(item);

  const handleSave = () => {
    if (item.id) {
      updateDataMappingPreset.mutate(
        { id: item.id, preset: { name: newName.trim() } },
        {
          onSuccess: () => onEdit(),
        },
      );
    } else {
      createDataMappingPreset.mutate(
        { name: newName.trim() },
        {
          onSuccess: (data) => {
            onEdit();
            onSelect(data);
          },
        },
      );
    }
  };
  const handleDelete = () => onDelete(item);

  return (
    <>
      <StyledListItemButton selected={selected} onClick={handleSelect}>
        <ListItemAvatar sx={{ minWidth: THUMBNAIL_SMALL_SIZE }}>
          <ThumbnailHolder size={THUMBNAIL_SMALL_SIZE}>
            <Icon fontSize="small">tableRows</Icon>
          </ThumbnailHolder>
        </ListItemAvatar>
        {edit ? (
          <TextField
            placeholder="Untitled Preset"
            value={newName}
            onChange={handleChange}
            sx={{ marginInlineEnd: 3 }}
            onClick={stopEvent}
            autoFocus
            disabled={disabled}
          />
        ) : (
          <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{item.name}</ListItemText>
        )}
      </StyledListItemButton>
      <ListItemSecondaryAction>
        {!edit ? (
          <Box>
            <Tooltip title={t('common:delete')}>
              <span>
                <IconButton onClick={handleDelete} disabled={disabled}>
                  <Icon>delete</Icon>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('common:rename')}>
              <span>
                <IconButton onClick={handleEdit} disabled={disabled}>
                  <Icon>edit</Icon>
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        ) : (
          <Tooltip title={t('common:save')}>
            <span>
              <IconButton
                onClick={handleSave}
                disabled={createDataMappingPreset.isPending || updateDataMappingPreset.isPending || !newName || disabled}
              >
                <Icon>save</Icon>
              </IconButton>
            </span>
          </Tooltip>
        )}
      </ListItemSecondaryAction>
    </>
  );
};
export const PlaceholderItem = () => {
  return (
    <Box display="flex" alignItems="center" gap={3}>
      <Skeleton variant="rounded" width={28} height={28} />
      <Skeleton variant="text" height={18} width="50%" />
    </Box>
  );
};
