import { ChangeEvent, FC, PropsWithChildren, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Icon, ListItemText, MenuItem, SelectChangeEvent, TextField, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import useFileUploader from '@/hooks/useFileUploader';
import { ColumnDelimiter, ColumnDelimiterOption } from '@/consts';
import { getDelimiter, readCsvFile, readGeoJsonFile } from '@/components/FileUpload/DataMapping/utils';
import { Select } from '@/components/Select';
import Button from '@/components/Button';
import { DataMappingRow } from '@/models';

interface ReadFileProps {
  isCSV: boolean;
  isFirstRowHeader: boolean;
  onReady: (values: { rows: DataMappingRow[]; columns: GridColDef[] }) => void;
  onLoading: () => void;
  disabled: boolean;
}

export const ReadFileOptions: FC<PropsWithChildren<ReadFileProps>> = ({
  isCSV,
  isFirstRowHeader,
  onReady,
  onLoading,
  disabled,
  children,
}) => {
  const { t } = useTranslation(['common', 'upload']);
  const { fileToMapName, filesUpload } = useFileUploader();
  const [columnDelimiterOption, setColumnDelimiterOption] = useState(ColumnDelimiterOption.COMMA);
  const [columnDelimiter, setColumnDelimiter] = useState<ColumnDelimiter | string>(ColumnDelimiter.COMMA);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted && fileToMapName) {
      const file = filesUpload.find((f) => f.name === fileToMapName);
      if (file) {
        onLoading();
        setMounted(true);

        if (isCSV) {
          readCsvFile(file.file, columnDelimiter, isFirstRowHeader, onReady);
        } else {
          const listener = readGeoJsonFile(onReady);

          const reader = new FileReader();
          reader.addEventListener('load', listener, false);
          reader.readAsText(file.file);

          return () => {
            reader.removeEventListener('load', listener);
          };
        }
      }
    }
  }, []);

  const handleRefresh = () => {
    if (fileToMapName) {
      const file = filesUpload.find((f) => f.name === fileToMapName);
      if (file) {
        onLoading();
        readCsvFile(file.file, columnDelimiter, isFirstRowHeader, onReady);
      }
    }
  };

  const handleSelectChange = (e: SelectChangeEvent<ColumnDelimiterOption>) => {
    setColumnDelimiterOption(e.target.value as ColumnDelimiterOption);
    setColumnDelimiter(getDelimiter(e.target.value as ColumnDelimiterOption));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setColumnDelimiter(e.target.value);
  };

  if (!isCSV) return false;

  return (
    <>
      <Typography>{t('upload:read_csv_options')}</Typography>
      <Box display="flex" gap={4} justifyContent="flex-start">
        <Select
          disabled={disabled}
          label={t('upload:delimiter_option')}
          value={columnDelimiterOption}
          onChange={handleSelectChange}
          sx={{ maxWidth: 240 }}
          renderValue={(value) => t('upload:delimiter_' + value)}
        >
          {Object.values(ColumnDelimiterOption).map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemText>{t('upload:delimiter_' + option)}</ListItemText>
              <Typography variant="body2" color="text.secondary">
                {getDelimiter(option)}
              </Typography>
            </MenuItem>
          ))}
        </Select>
        {columnDelimiterOption === ColumnDelimiterOption.OTHER && (
          <TextField disabled={disabled} value={columnDelimiter} onChange={handleInputChange} label={t('upload:delimiter')} />
        )}
        {children}
        <Tooltip title={t('upload:read_again_info')}>
          <Button disabled={disabled} color="action" variant="outlined" onClick={handleRefresh} startIcon={<Icon>refresh</Icon>}>
            {t('upload:read_again')}
          </Button>
        </Tooltip>
      </Box>
    </>
  );
};
