export const renderProperty = (property: any) => {
  switch (typeof property) {
    case 'number':
      return Number.isInteger(property) ? property : property.toFixed(4);

    case 'boolean':
      return property ? 'True' : 'False';

    case 'object':
      return property === null ? '[NULL]' : JSON.stringify(property, undefined, 2);
  }

  return property;
};

export const extractFileExtension = (filename: string) => filename.split('.').pop()?.toLowerCase() ?? '';
export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
