import { ChangeEvent, FC, FormEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, formControlClasses, Icon, styled, TextField } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { AllowedFileExtension, EMAIL_REGEXP, IMAGE_FORMATS } from '@/consts';
import Button from '@/components/Button';
import { useSession } from 'next-auth/react';
import { handleInputCharacterLimit } from '@/utils/input';
import { contactFormApi } from '@/cmd/ContactFormApi';
import { secondsToMilliseconds } from 'date-fns';
import useToggle from '@/hooks/useToggle';
import { DropArea } from '@/components/Uploader';
import { AnimatePresence, m } from 'framer-motion';
import { formatByteSize } from '@/utils';

const StyledForm = styled('form')`
  width: 100%;
  display: grid;
  padding-block: ${({ theme }) => theme.spacing(3)};
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing(3)};
  grid-template-areas: 'name email' 'subject subject' 'description description';
  .${formControlClasses.root}:nth-of-type(3),
  .${formControlClasses.root}:nth-of-type(4) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &:has(#drop-area) {
    grid-template-areas: 'name email' 'subject subject' 'description description' 'uploader uploader';
  }

  &:has(#contact-files-preview) {
    grid-template-areas: 'name email' 'subject subject' 'description description' 'preview preview' 'uploader uploader';
    .${formControlClasses.root}:nth-of-type(5) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

const GridPreview = styled(m.div)`
  grid-area: preview;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: ${({ theme }) => theme.spacing(4)};
  max-height: 240px;
  overflow-y: auto;
  margin-inline: -8px;
  padding: 8px;

  div {
    width: 100%;
    aspect-ratio: 1;
    position: relative;
  }

  img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.spacing(2)};
  }
`;

const ClearAllContainer = styled(m.div)`
  display: flex;
`;

const GridVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const getItemVariants = (index: number) => ({
  hidden: { scale: 0, opacity: 0 },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 260,
      damping: 20,
      delay: index * 0.05,
    },
  },
});

const RemoveBtn = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  padding: ${({ theme }) => theme.spacing()};
  min-width: 28px;
`;

interface Props {
  open: boolean;
  onClose: () => void;
}

const DESCRIPTION_LIMIT = 500;
const SIZE_LIMIT = 25_000_000; // 25MB

interface FormContentProps {
  onSubmit: () => void;
  onLoading: () => void;
  hideAttachment?: boolean;
  autoFocus?: boolean;
}
export const ContactFormContent: FC<FormContentProps> = ({ onSubmit, onLoading, hideAttachment = false, autoFocus }) => {
  const { t } = useTranslation(['common']);
  const { data: session } = useSession();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [prevCount, setPrevCount] = useState(previews.length);

  const filesSize = useMemo(() => files.reduce((previousValue, currentValue) => previousValue + currentValue.size, 0), [files]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setDescription(handleInputCharacterLimit(newValue, DESCRIPTION_LIMIT));
  };

  const validate = (e: ChangeEvent<HTMLInputElement>) => {
    setIsValidEmail(!!e.target.value.toLowerCase().match(EMAIL_REGEXP));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (isValidEmail) {
      onLoading();
      const form = e.target;
      const formData = new FormData(form as HTMLFormElement);
      files.forEach((file) => formData.append('files', file));
      try {
        await contactFormApi().send(formData);
        onSubmit();
      } catch (e) {
        console.error(e);
      } finally {
        onLoading();
      }
    }
  };

  const handleFileChange = (addedFiles: File[]) => {
    let finalFiles: File[] = [];
    setPrevCount(files.length);
    setFiles((prev) => {
      const files = [...addedFiles];
      const currentSize = prev.reduce((previousValue, currentValue) => previousValue + currentValue.size, 0);
      let incomingSize = files.reduce((previousValue, currentValue) => previousValue + currentValue.size, 0);
      while (currentSize + incomingSize > SIZE_LIMIT) {
        files.pop();
        incomingSize = files.reduce((previousValue, currentValue) => previousValue + currentValue.size, 0);
      }
      finalFiles = files;

      return [...prev, ...finalFiles];
    });
    setPreviews((prev) => [...prev, ...finalFiles.map((file) => URL.createObjectURL(file))]);
  };

  const handleRemove = (index: number) => () => {
    setFiles((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
    setPreviews((prev) => {
      const updated = [...prev];
      URL.revokeObjectURL(updated[index]);
      updated.splice(index, 1);
      return updated;
    });
  };

  const handleClearAll = () => {
    setFiles([]);
    setPreviews((prev) => {
      prev.forEach((p) => URL.revokeObjectURL(p));
      return [];
    });
  };

  return (
    <StyledForm id="contact_form" onSubmit={handleSubmit}>
      <TextField name="name" label={t('common:name')} required defaultValue={session?.user.fullname} autoFocus={autoFocus && !session} />
      <TextField
        name="email"
        label={t('common:your_email')}
        required
        onChange={validate}
        error={!isValidEmail}
        defaultValue={session?.user.email}
      />
      <TextField name="subject" label={t('common:subject_inquiry')} required autoFocus={autoFocus && !!session} />
      <TextField
        name="description"
        label={t('common:detailed_description')}
        required
        multiline
        minRows={4}
        maxRows={10}
        value={description}
        onChange={handleChange}
        helperText={`${description.length}/${DESCRIPTION_LIMIT}`}
        FormHelperTextProps={{ sx: [{ textAlign: 'end' }] }}
      />
      {!hideAttachment && (
        <>
          <AnimatePresence>
            {previews.length > 0 && (
              <GridPreview id="contact-files-preview" variants={GridVariants} initial="hidden" animate="visible" exit="hidden">
                <AnimatePresence>
                  <ClearAllContainer variants={getItemVariants(0)} initial="hidden" animate="visible" exit="hidden">
                    <Button variant="outlined" color="error" sx={{ flex: 1 }} onClick={handleClearAll}>
                      Clear all
                    </Button>
                  </ClearAllContainer>
                  {previews.map((preview, index) => (
                    <m.div key={preview} variants={getItemVariants(index + 1 - prevCount)} initial="hidden" animate="visible" exit="hidden">
                      <img src={preview} alt={files[index].name} />
                      <RemoveBtn size="small" variant="contained" color="error" onClick={handleRemove(index)}>
                        <Icon fontSize="small">close</Icon>
                      </RemoveBtn>
                    </m.div>
                  ))}
                </AnimatePresence>
              </GridPreview>
            )}
          </AnimatePresence>
          <DropArea
            fileTypes={IMAGE_FORMATS}
            onChange={handleFileChange}
            inputRef={inputRef}
            label={t('common:contact_form_upload', {
              size: formatByteSize(filesSize),
              limit: formatByteSize(SIZE_LIMIT),
            })}
          />
        </>
      )}
    </StyledForm>
  );
};

interface ContentProps {
  onClose: () => void;
}

const CustomContent: FC<ContentProps> = ({ onClose }) => {
  const { t } = useTranslation(['common']);
  const [formSent, toggleFormSent] = useToggle();
  const [isLoading, toggleLoading] = useToggle();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (formSent) {
      timer = setTimeout(() => {
        onClose();
      }, secondsToMilliseconds(3));
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [formSent]);

  return (
    <>
      {formSent ? (
        <DialogContent>
          <Alert variant="outlined" severity="success">
            {t('common:thank_for_contact')}
          </Alert>
        </DialogContent>
      ) : (
        <>
          <DialogTitle>{t('common:contact_us')}</DialogTitle>
          <DialogContent>
            <ContactFormContent onLoading={toggleLoading} onSubmit={toggleFormSent} autoFocus />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t('common:close')}</Button>
            <Button form="contact_form" type="submit" loading={isLoading}>
              {t('common:send')}
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
};

const ContactForm: FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <CustomContent onClose={onClose} />
    </Dialog>
  );
};

export default ContactForm;
