import { singleton } from '@/utils/decorators/singleton';
import { Notification, NotificationId } from '@/models';
import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, RequestPromise } from './Api';

interface Filter {
  read?: boolean;
}

@singleton
class NotificationsApi extends Api {
  endpoint: string = '/api/notifications';
  static getInstance: () => NotificationsApi;

  getList(filter?: Filter, pagination?: PageableRequest): RequestPromise<PageableResponse<Notification>> {
    return this.request(METHOD.GET, '', { ...filter, ...DEFAULT_PAGINATION, ...pagination });
  }

  markRead(ids: NotificationId[]) {
    return this.request(METHOD.PUT, '/viewed', ids);
  }

  markReadAll() {
    return this.request(METHOD.PUT, '/viewed/all');
  }

  getCount(): RequestPromise<number> {
    return this.request(METHOD.GET, '/count');
  }
}

export const notificationsApi = () => NotificationsApi.getInstance();
