import { useMutation, keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { notificationsApi } from '@/cmd/NotificationsApi';
import { secondsToMilliseconds } from 'date-fns';
import useVirtuosoInfiniteQuery from '@/hooks/useVirtuosoInfiniteQuery';
import { getNextPageParam, initialPageParam } from '@/hooks/queries/utils';
import { modelSubscriptionApi } from '@/cmd/ModelSubscriptionApi';
import { marketplaceKeys } from '@/components/Marketplace/queries';
import { KeyValue } from '@/models';

export const NOTIFICATION_UPDATE_INTERVAL = 5 * 60;

export const notificationKeys = {
  all: ['notification_list'] as const,
  count: ['notification_count'] as const,
  list: (filter?: KeyValue, pageSize?: number) => [notificationKeys.all, filter, pageSize],
};

export const useNotificationCount = () => {
  return useQuery({
    queryKey: notificationKeys.count,
    queryFn: () => notificationsApi().getCount(),
    refetchInterval: secondsToMilliseconds(NOTIFICATION_UPDATE_INTERVAL),
  });
};

export const useNotificationList = (enabled: boolean, filter?: any, pageSize?: number) =>
  useVirtuosoInfiniteQuery({
    queryKey: notificationKeys.list(filter, pageSize),
    queryFn: ({ pageParam }) => notificationsApi().getList(filter, { ...pageParam, pageSize }),
    placeholderData: keepPreviousData,
    getNextPageParam,
    initialPageParam,
    enabled,
    gcTime: 0,
  });

export const useApproveModelSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: string; modelId: string }) => modelSubscriptionApi().approve(id),
    onSuccess: (_data, variables) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.subscriptionsAll() }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.single(variables.modelId) }),
      ]);
    },
  });
};

export const useRejectModelSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: string; modelId: string }) => modelSubscriptionApi().reject(id),
    onSuccess: (_data, variables) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.subscriptionsAll() }),
        queryClient.invalidateQueries({ queryKey: marketplaceKeys.single(variables.modelId) }),
      ]);
    },
  });
};
