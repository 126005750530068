import { FC, useRef } from 'react';
import dynamic from 'next/dynamic';
import { Badge, Icon, IconButton, Tooltip } from '@mui/material';
import useToggle from '@/hooks/useToggle';
import { useNotificationCount } from '@/components/Notifications/queries';
import { NotificationDialog } from '@/components/Notifications/NotificationDialog';
import { useTranslation } from 'next-i18next';

const NotificationIndicator: FC = () => {
  const { t } = useTranslation('common');
  const { data: count, refetch } = useNotificationCount();
  const color = count ? 'action.active' : 'text.primary';
  const [showNotifications, toggleNotifications] = useToggle();
  const ref = useRef<HTMLButtonElement | null>(null);
  const handleToggle = () => {
    if (!showNotifications) {
      const promise = refetch();
      if (!count) {
        promise.then((res) => (res.data || 0) > 0 && toggleNotifications());
        return promise;
      }
    }

    toggleNotifications();
  };

  return (
    <>
      <Tooltip title={t('common:notifications')} placement="right">
        <IconButton ref={ref} sx={{ color }} aria-label="open drawer" onClick={handleToggle}>
          <Badge badgeContent={count || undefined} color="primary">
            <Icon>notifications</Icon>
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationDialog open={showNotifications} anchorEl={ref.current} />
    </>
  );
};

export default dynamic(() => Promise.resolve(NotificationIndicator), { ssr: false });
