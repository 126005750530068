import { singleton } from '@/utils/decorators/singleton';
import { cache, cacheKeyFn, ClearCacheFn } from '@/utils/decorators/cache';
import { ModuleId, Settings, Tenant, TenantBasic, TenantCategory, TenantId, UserTenant } from '@/models';
import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, RequestPromise, SearchFilter } from './Api';
import { removeEmpty } from '@/utils';

export interface Filter extends SearchFilter {
  active?: boolean;
  tenantCategories?: TenantCategory[];
}

@singleton
class TenantApi extends Api {
  endpoint: string = '/api/tenants';
  static getInstance: () => TenantApi;

  clearCache!: ClearCacheFn;

  @cache(cacheKeyFn, 'clearCache', 900)
  getList(filter?: Filter, pagination?: PageableRequest): RequestPromise<PageableResponse<Tenant>> {
    return this.request(METHOD.GET, '', { ...filter, ...DEFAULT_PAGINATION, ...pagination });
  }

  getListInfo(tenantIds?: (TenantId | undefined)[]): RequestPromise<TenantBasic[]> {
    return this.request(METHOD.GET, '/list', removeEmpty({ ids: tenantIds }));
  }

  getTenantInfo = (tenantId: TenantId): Promise<Tenant> => this.request(METHOD.GET, '/' + tenantId);

  create(tenant: Partial<Tenant>): Promise<Tenant> {
    return this.request(METHOD.POST, '', tenant).then(this.responseClearCachePromise);
  }

  update(tenantId: TenantId, tenant: Partial<Tenant>): Promise<Tenant> {
    return this.request(METHOD.PATCH, '/' + tenantId, tenant).then(this.responseClearCachePromise);
  }

  delete(tenantId: TenantId) {
    return this.request(METHOD.DELETE, '/' + tenantId).then(this.responseClearCachePromise);
  }

  updateModules(tenantId: TenantId, modules: ModuleId[]) {
    return this.request(METHOD.PUT, '/' + tenantId + '/modules', modules).then(this.responseClearCachePromise);
  }

  getSettings(tenantId: TenantId): RequestPromise<Settings> {
    return this.request(METHOD.GET, '/' + tenantId + '/settings');
  }
  createSettings(tenantId: TenantId, settings: Partial<Settings>): Promise<Tenant> {
    return this.request(METHOD.POST, '/' + tenantId + '/settings', settings);
  }

  getMyTenantInfo = (): Promise<UserTenant> => this.request(METHOD.GET, '/me');

  private responseClearCachePromise = <T = any>(res: T): T => {
    this.clearCache();
    return res;
  };
}

export const tenantApi = () => TenantApi.getInstance();
