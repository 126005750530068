import React, { FC, useState } from 'react';
import AnimatedIcon, { Variant } from '@/components/AnimatedIcon';
import { IconButton, InputAdornment, InputProps, StandardTextFieldProps, TextField } from '@mui/material';
import useToggle from '@/hooks/useToggle';
import { useTranslation } from 'next-i18next';
import { signOut } from 'next-auth/react';
import { LOGOUT_REDIRECT, userApi } from '@/cmd/UserApi';
import { getConfig } from '@/config';
import { uaePassApi } from '@/cmd/UaePassApi';
import { logout } from '@/auth-provider/helper';

export enum LoginState {
  DEFAULT = 'DEFAULT',
  AUTHORIZED = 'AUTHORIZED',
  SIGNUP_GUEST = 'SIGNUP_GUEST',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESTORE_PASSWORD = 'RESTORE_PASSWORD',
  UAE_PASS_WAITING = 'UAE_PASS_WAITING',
  UAE_PASS_NO_LINK = 'UAE_PASS_NO_LINK',
  UAE_PASS_LINKING = 'UAE_PASS_LINKING',
  UAE_PASS_NO_ACCOUNT = 'UAE_PASS_NO_ACCOUNT',
}

export const useDetectCapsLock = (): [boolean, (e: any) => void] => {
  const [isCapslockOn, setIsCapslockOn] = useState(false);
  const detectCapsLock = (e: any) => setIsCapslockOn(e.getModifierState('CapsLock'));
  return [isCapslockOn, detectCapsLock];
};

interface PasswordInputProps extends StandardTextFieldProps {
  value: string;
  name: string;
  label: string;
  error: boolean;
}

export const handleSignOut = async () => {
  const { showUaePass } = getConfig();
  try {
    await userApi().logout();
  } catch (e) {
    console.error(e);
  }
  logout({ callbackUrl: showUaePass ? uaePassApi().getLogoutUrl(LOGOUT_REDIRECT) : LOGOUT_REDIRECT }).catch(() => {
    // UAEPASS signout fail workaround
    if (showUaePass) signOut({ callbackUrl: LOGOUT_REDIRECT });
  });
};

export const handleChange = (setter: Function) => (e: React.ChangeEvent<HTMLInputElement>) => setter(e.target.value);

// Password length should be at least 12 characters, and must contain: uppercase, lowercase, digits & special characters
export const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*()<>{}\]\[=#?&/\\^.,:_-]).{12,}$/;

export const getIconProps = (icon: string, variant: Variant = 'outlined') =>
  ({
    startAdornment: (
      <InputAdornment position="start">
        <AnimatedIcon variant={variant} color="action">
          {icon}
        </AnimatedIcon>
      </InputAdornment>
    ),
  }) as InputProps;

export const PasswordInput: FC<PasswordInputProps> = (props) => {
  const { inputProps = {}, ...restProps } = props;
  const { t } = useTranslation();
  const [visible, toggleVisibility] = useToggle();
  const [isCapslockOn, detectCapsLock] = useDetectCapsLock();
  const helperText = props.helperText ? [props.helperText].flat() : [];
  if (isCapslockOn) {
    helperText.push([
      <span key="capslock" style={{ marginInlineStart: 'auto' }}>
        {t('common:capslock_on')}
      </span>,
    ]);
  }
  const InputProps = {
    endAdornment: (
      <InputAdornment position="end">
        {isCapslockOn && (
          <AnimatedIcon variant="outlined" color="action">
            keyboard_capslock
          </AnimatedIcon>
        )}
        <IconButton onClick={toggleVisibility} tabIndex={-1}>
          <AnimatedIcon variant="outlined" color="action">
            {visible ? 'visibility_off' : 'visibility'}
          </AnimatedIcon>
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <TextField
      {...restProps}
      margin="none"
      type={visible ? 'text' : 'password'}
      InputProps={{ ...getIconProps('lock'), ...InputProps, inputProps }}
      onKeyDown={detectCapsLock}
      onKeyUp={detectCapsLock} //because onKeyDown only detects capsLock Enable
      onClick={detectCapsLock}
      required
      helperText={helperText}
      FormHelperTextProps={{ sx: { display: 'flex' } }}
      fullWidth
    />
  );
};
