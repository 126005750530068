import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Box, ListItemIcon, ListItemText, MenuItem, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import AnimatedIcon from '@/components/AnimatedIcon';
import Link from '@/components/Link';
import { usePathname } from 'next/navigation';
import { MultiNavigation, Navigation } from '@/models';
import { CornerIcon } from '@/components/Navigation/CornerIcon';
import clsx from 'clsx';

const NavLink = styled(Link)(({ theme }) => ({
  position: 'relative',
  width: '56px',
  height: '56px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  textDecoration: 'none',
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.short,
  }),
  '&:hover, &:focus': {
    '.icon-wrapper': {
      backgroundColor: `rgb(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.hoverOpacity} )`,
      '&.selected': {
        backgroundColor: `rgb(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.selectedOpacity} )`,
      },
    },
  },
  '@container (height <= 1000px)': {
    height: '32px',
    '.MuiTypography-root': {
      display: 'none',
    },
  },
}));
const IconWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBlock: theme.spacing(1),
  borderRadius: 16,
  transition: theme.transitions.create(['background-color', 'border-radius'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.short,
  }),
  backgroundColor: 'transparent',
  '&.selected': {
    backgroundColor: `rgb(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.selectedOpacity} )`,
  },
}));

const modifiers = [
  {
    name: 'offset',
    options: {
      offset: [-12, 0],
    },
  },
];

export const NavigationButton: FC<Navigation> = ({ path, shortLabel, label, icon, route }) => {
  const { t } = useTranslation(['navigation']);
  const { route: nextRoute } = useRouter();
  const nextPathName = usePathname();
  const selected: boolean = route ? nextRoute === route : nextPathName.startsWith(path);

  return (
    <Tooltip title={t(label)} placement="right" PopperProps={{ modifiers }}>
      <NavLink href={path}>
        <IconWrapper className={clsx('icon-wrapper', { selected })}>
          <AnimatedIcon fontSize="medium" variant={selected ? 'filled' : 'outlined'} color="action">
            {icon}
          </AnimatedIcon>
        </IconWrapper>
        <Typography variant="caption" color={selected ? 'text.primary' : 'text.secondary'}>
          {t(shortLabel ?? label)}
        </Typography>
      </NavLink>
    </Tooltip>
  );
};

const StyledMenu = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      padding: theme.spacing(2, 0),
      backgroundColor: theme.vars.palette.background.paper,
      backgroundImage: theme.vars.overlays[8],
      boxShadow: theme.vars.shadows[8],
    },
  }),
);

export const MultiNavigationButton: FC<MultiNavigation> = ({ subNavigation, shortLabel, label, icon, route }) => {
  const { t } = useTranslation(['navigation']);
  const { route: nextRoute } = useRouter();
  const nextPathName = usePathname();
  const selected: boolean = nextRoute === route || nextRoute.includes(route);

  const subSelected = (nav: Omit<Navigation, 'id'>) => (nav.route ? nextRoute === nav.route : nextPathName.includes(nav.path));

  return (
    <>
      <StyledMenu
        title={
          <>
            {/*<Box sx={{ paddingInlineStart: 2 }}>
              <Typography variant="caption">{t(label)}</Typography>
            </Box>*/}
            {subNavigation.map((item) => (
              <MenuItem key={item.id} selected={subSelected(item)} component={Link} href={item.path}>
                <ListItemIcon>
                  <AnimatedIcon variant={subSelected(item) ? 'filled' : 'outlined'} color="action">
                    {item.icon}
                  </AnimatedIcon>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{t(item.label)}</ListItemText>
              </MenuItem>
            ))}
          </>
        }
        placement="left-start"
      >
        <NavLink href={subNavigation[0].path}>
          <IconWrapper className={clsx('icon-wrapper', { selected })}>
            <AnimatedIcon fontSize="medium" variant={selected ? 'filled' : 'outlined'} color="action">
              {icon}
            </AnimatedIcon>
            <CornerIcon sx={{ position: 'absolute' }} />
          </IconWrapper>
          <Typography variant="caption" color={selected ? 'text.primary' : 'text.secondary'}>
            {t(shortLabel ?? label)}
          </Typography>
        </NavLink>
      </StyledMenu>
    </>
  );
};
