import { MapTool, SensorCheckRuleProperties, Temporal3dModel, TemporalSettings } from './models';
import { SupportedColorScheme } from '@mui/material/styles';
import { getConfig } from '@/config';

export const PAGE_SIZE_BIG = 5000;

export const UAE_X = 11439529;
export const UAE_Y = 17724830;
export const UAE_Z = 8493532;
export const UAE_PITCH = -1.57;

export const CONTROL_BAR_HEIGHT = 30;
export const FULL_DIALOG_TOOLBAR = 44;
export const MAX_MERCATOR_LATITUDE = 85.0511;
export const GEO_PRECISION = 6;
export const WORLD_GEODETIC_SYSTEM = 'EPSG:4326';
export const PSEUDO_MERCATOR = 'EPSG:3857';
export const WGS = WORLD_GEODETIC_SYSTEM;
export const MERCATOR = PSEUDO_MERCATOR;
export const WGS_TO_MERCATOR = { dataProjection: WORLD_GEODETIC_SYSTEM, featureProjection: PSEUDO_MERCATOR };
export const MERCATOR_TO_WGS = { dataProjection: PSEUDO_MERCATOR, featureProjection: WORLD_GEODETIC_SYSTEM };

export const ModelFileTypes = ['onnx', 'pt', 'pth', 'tf'];
export const YAMLFileTypes = ['yaml', 'yml'];
export const YAMLMediaType = ['application/x-yaml', 'application/yaml', 'text/yaml'];

export const KEYS = {
  BACKSPACE: 'Backspace',
  TAB: 'Tab',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  LEFT: 'ArrowLeft',
  UP: 'ArrowUp',
  RIGHT: 'ArrowRight',
  DOWN: 'ArrowDown',
  DELETE: 'Delete',
  NUM_PLUS: 'NumpadAdd',
  NUM_MINUS: 'NumpadSubtract',
  PLUS: 'Equal',
  MINUS: 'Minus',
};

export const KEY_CODE = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  DELETE: 46,
  NUM_PLUS: 107,
  NUM_MINUS: 109,
  PLUS: 187,
  MINUS: 189,
};

export const NBSP = '\u00A0'; // NO-BREAK SPACE (U+00A0)

export enum KeyModifier {
  ALT = 'Alt',
  ALT_GRAPH = 'AltGraph',
  CAPS_LOCK = 'CapsLock',
  CTRL = 'Control',
  NUM_LOCK = 'NumLock',
  SHIFT = 'Shift',
  OS = 'Os',
}

export enum AllowedFileExtension {
  ZIP = 'zip',
  TAR = 'tar',
  TIF = 'tif',
  TIFF = 'tiff',
  NTF = 'ntf',
  ECW = 'ecw',
  JP2 = 'jp2',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  MP4 = 'mp4',
  GEOJSON = 'geojson',
  KML = 'kml',
  PDF = 'pdf',
  CSV = 'csv',
  GRB = 'grb',
  GRB2 = 'grb2',
  GRIB = 'grib',
  GRIB2 = 'grib2',
  MPG = 'mpg',
  MPEG = 'mpeg',
  NETCDF = 'nc',
  CZML = 'czml',
}

export enum HeightmapDataType {
  UINT8 = 'uint8',
  UINT16 = 'uint16',
  INT8 = 'int8',
  INT16 = 'int16',
  FLOAT32 = 'float32',
}

export const TILE_SERVER_DEFAULTS = {
  tileWidth: 256,
  tileHeight: 256,
  minimumLevel: 0,
  maximumLevel: 19,
};

export const SUPPORTED_RASTER_FORMATS = [AllowedFileExtension.TIF, AllowedFileExtension.TIFF, AllowedFileExtension.ZIP] as string[];
export const MANUAL_TYPE_SELECTION_FORMATS = [AllowedFileExtension.TIF, AllowedFileExtension.TIFF] as string[];
export const DATA_MAPPING_FORMATS = [AllowedFileExtension.CSV, AllowedFileExtension.GEOJSON] as string[];
export const COMPRESSED_FILE_FORMATS = [AllowedFileExtension.ZIP, AllowedFileExtension.TAR] as string[];
export const VIDEO_FORMATS = [AllowedFileExtension.MP4, AllowedFileExtension.MPG, AllowedFileExtension.MPEG] as string[];
export const IMAGE_FORMATS = [AllowedFileExtension.JPEG, AllowedFileExtension.JPG, AllowedFileExtension.PNG] as string[];
export const DOCUMENT_FORMATS = [...IMAGE_FORMATS, AllowedFileExtension.JP2, AllowedFileExtension.PDF] as string[];
export const GRIB_FORMATS = [AllowedFileExtension.GRB, AllowedFileExtension.GRB2, AllowedFileExtension.GRIB, AllowedFileExtension.GRIB2];
export const SHAPE_FORMATS = [AllowedFileExtension.GEOJSON, AllowedFileExtension.ZIP] as string[];
export const DOCUMENT_AND_VIDEO_FORMATS = [...DOCUMENT_FORMATS, ...VIDEO_FORMATS] as string[];
export const SUPPORTED_FILE_FORMAT = Object.values(AllowedFileExtension) as string[];
export const ACCEPTED_UPLOAD_FILE_TYPES = SUPPORTED_FILE_FORMAT.map((ext) => '.' + ext).join(',');

export const EMAIL_REGEXP =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// TODO this is hardcoded for now, in future should come from approval flow configuration
export const ORDER_APPROVAL_STEPS = ['organization_approval', 'uae_space_agency_approval', 'bayanat_approval'];

export const INPUT_CHARACTER_LIMIT = 150;
export const SENSOR_CHECK_PROPERTIES: (keyof SensorCheckRuleProperties)[] = ['infrared', 'hyperspectral'];
export const THUMBNAIL_SIZE = 60;
export const THUMBNAIL_ICON_SIZE = 52;
export const THUMBNAIL_SMALL_SIZE = 28;

export enum VERTICAL_ALIGNMENT {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export enum HORIZONTAL_ALIGNMENT {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export const POPPER_MOD_8_8 = [
  {
    name: 'offset',
    options: {
      offset: [8, 8],
    },
  },
];

export const MAP_TOOLBAR = 'map_toolbar';
export const PLAY_ANCHOR = 'play_anchor';
export const STORY_PANEL = 'story_panel';

export enum ColumnDelimiter {
  COMMA = ',',
  SEMICOLON = ';',
  TAB = '\t',
  SPACE = ' ',
  PIPE = '|',
}

export enum ColumnDelimiterOption {
  COMMA = 'comma',
  SEMICOLON = 'semicolon',
  TAB = 'tab',
  SPACE = 'space',
  PIPE = 'pipe',
  OTHER = 'other',
}

export enum TimelineStep {
  MINUTES,
  HOURS,
  DAYS,
  WEEKS,
  MONTHS,
  YEARS,
  DECADES,
  CENTURIES,
}

export const DEFAULT_COLOR_SCHEME: SupportedColorScheme = getConfig().defaultColorScheme;
export const ATTITUDE_KEYS = ['heading', 'pitch', 'roll'] as const;
export const TIME_SERIES_LAYER_ID = '_time_series_';
export const TIME_SERIES_TRACK_ID = 'track';
export const TIME_SERIES_3D_MODEL_ID = '3d_model';
export const TIME_SERIES_ENTITIES = [TIME_SERIES_TRACK_ID, TIME_SERIES_3D_MODEL_ID];
export const TIME_SERIES_IMPUTATIONS = ['average', 'zero', 'previous_value'];
export const TIME_SERIES_MODELS = ['holtwinters', 'sarimax', 'seq2seq', 'sktime_naive', 'arima'];

export const ALL_TEMPORAL_3D_MODELS = Object.values(Temporal3dModel);

export const DEFAULT_TEMPORAL_SETTINGS: TemporalSettings = {
  accumulate: false,
  showTrack: false,
  showPoints: true,
  show3dModel: false,
  followModel: false,
  model: Temporal3dModel.BOAT,
  hpr: {
    heading: '',
    pitch: '',
    roll: '',
  },
};

export const DEFAULT_ALPHA = 1.0;

export const DEFAULT_TRANSLATIONS = ['common', 'navigation', 'upload'];

export const MEASUREMENTS_LAYER_ID = 'measurements_layer';
export const IMAGE_TYPES: string = ['.png', '.jpg', '.jpeg'].join(','); //, '.gif'
export const IMAGE_MIMES: string = ['image/jpeg', 'image/jpg', 'image/png'].join(','); //'image/gif',

export const pageSizeOptions = [10, 20, 30];

export const DEFAULT_TOOLS = [MapTool.SELECT, MapTool.RECTANGLE, MapTool.POLYGON, MapTool.DELETE];
export const TASKING_TOOLS = [MapTool.SELECT, MapTool.RECTANGLE, MapTool.POLYGON, MapTool.POINT, MapTool.DELETE];
export const EMPTY = '---';
export const UTC_ENDING = ' UTC';
