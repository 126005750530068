import { Api, DEFAULT_PAGINATION, METHOD, PageableRequest, PageableResponse, prepareParams, RequestPromise, SORT_DIRECTION } from './Api';
import { singleton } from '@/utils/decorators/singleton';
import { CategoryAggregation, CategoryId, Detection, DetectionId, InferenceJob, Model, ModelId, SourceDataId } from '@/models';

export interface DetectionsFilter {
  categoryIds?: CategoryId[];
  modelId?: ModelId;
}

@singleton
class InferenceApi extends Api {
  endpoint: string = '/api/inference';
  clearJobsCache!: (key?: string) => void;
  static getInstance: () => InferenceApi;

  getDetection(detectionId: DetectionId): RequestPromise<Detection> {
    return this.request(METHOD.GET, '/detection/' + detectionId);
  }

  getDetectionsList(
    sourceDataId: SourceDataId,
    filter?: DetectionsFilter,
    pagination?: PageableRequest,
    minScore?: number,
    maxScore?: number,
  ): RequestPromise<PageableResponse<Detection>> {
    return this.request(METHOD.GET, '/' + sourceDataId + '/detections', {
      ...filter,
      ...DEFAULT_PAGINATION,
      ...pagination,
      minScore,
      maxScore,
    });
  }

  getCategoriesAggregation(sourceDataId: SourceDataId, filter?: DetectionsFilter): RequestPromise<CategoryAggregation[]> {
    return this.request(METHOD.GET, '/' + sourceDataId + '/categories/aggregation', { ...filter });
  }

  getJobs(sourceDataId: SourceDataId, pagination?: PageableRequest): Promise<PageableResponse<InferenceJob>> {
    return this.request(METHOD.GET, '/' + sourceDataId + '/jobs', { ...pagination, sort: 'startedAt', direction: SORT_DIRECTION.DESC });
  }

  getModels(sourceDataId: SourceDataId, pagination?: PageableRequest): RequestPromise<PageableResponse<Model>> {
    return this.request(METHOD.GET, '/' + sourceDataId + '/models', { ...DEFAULT_PAGINATION, ...pagination });
  }

  downloadDetections(sourceDataId: SourceDataId, fileName: string, filter?: DetectionsFilter) {
    return this.request(METHOD.GET, '/' + sourceDataId + '/detections/download', { fileName, ...filter }, { responseType: 'blob' });
  }

  runManual(modelId: ModelId, sourceId: SourceDataId) {
    return this.request(METHOD.POST, '/detection' + prepareParams({ modelId, sourceId }));
  }
}

export const inferenceApi = () => InferenceApi.getInstance();
