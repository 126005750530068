import { createContext, FC, SyntheticEvent, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  SelectChangeEvent,
  styled,
  Typography,
} from '@mui/material';
import Button from '@/components/Button';
import { useTranslation } from 'next-i18next';
import useFileUploader from '@/hooks/useFileUploader';
import { Select } from '@/components/Select';
import DataGrid from '@/components/DataGrid';
import { getDataGridUtilityClass, GridColDef } from '@mui/x-data-grid';
import useToggle from '@/hooks/useToggle';
import { ColumnMapping, DataMappingPreset, DataMappingPresetId, DataMappingRow } from '@/models';
import Presets from '@/components/FileUpload/DataMapping/Presets';
import { AllowedFileExtension } from '@/consts';
import {
  getOptions,
  LocationFormats,
  LocationOptions,
  partialClearMapping,
  TimestampFormats,
  TimestampOptions,
  toColumnMapping,
  toDataMapping,
} from '@/components/FileUpload/DataMapping/utils';
import { ReadFileOptions } from '@/components/FileUpload/DataMapping/ReadFileOptions';
import { useDataMappingPreset, useUpdateDataMappingPreset } from '@/components/FileUpload/DataMapping/queries';
import { isObjectSameAs } from '@/utils';

const StyledDataGrid = styled(DataGrid)`
  .${getDataGridUtilityClass('columnHeaderTitleContainerContent')} {
    width: 100%;
  }
  .${getDataGridUtilityClass('columnHeader')} {
    height: 120px !important;
  }
  .${getDataGridUtilityClass('columnHeaders')} {
    max-height: 120px !important;
    min-height: 120px !important;
  }
  .${getDataGridUtilityClass('footerContainer')} {
    display: none;
  }
`;

interface DataMappingContext {
  timestampFormat: TimestampFormats | '';
  locationFormat: LocationFormats | '';
  hasHeight: boolean;
  mapping: Record<string, TimestampOptions | LocationOptions>;
  handleMapping: (mapping: ColumnMapping) => void;
  toggleDisabled: () => void;
}

export const DataMappingContext = createContext<DataMappingContext | null>(null);

const DataMappingDialogContent: FC = () => {
  const { t } = useTranslation(['common', 'upload']);
  const updateDataMappingPreset = useUpdateDataMappingPreset();
  const [selected, setSelected] = useState<DataMappingPresetId>();
  const { data: selectedPreset } = useDataMappingPreset(selected);
  const { toggleOpenDataMappingDialog, filesUpload, fileToMapName, setDataMapping } = useFileUploader();
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<DataMappingRow[]>([]);
  const [isFirstRowHeader, , setIsFirstRowHeader] = useToggle(true);
  const [timestampFormat, setTimestampFormat] = useState<TimestampFormats | ''>('');
  const [locationFormat, setLocationFormat] = useState<LocationFormats | ''>('');
  const [hasHeight, , setHasHeight] = useToggle();
  const [mapping, setMapping] = useState<Record<string, TimestampOptions | LocationOptions>>({});
  const [isLoading, toggleLoading] = useToggle();
  const [disabled, toggleDisabled] = useToggle();
  const [mounted, setMounted] = useState(false);

  const mappedCount = useMemo(() => Object.keys(mapping).filter((k) => !!mapping[k]).length, [mapping]);
  const requiredCount = useMemo(
    () => getOptions(timestampFormat, locationFormat, hasHeight).length,
    [timestampFormat, locationFormat, hasHeight],
  );
  const meetsRequirement = mappedCount === requiredCount;

  const isCSV = useMemo(
    () => filesUpload.find((f) => f.name === fileToMapName)?.ext === AllowedFileExtension.CSV,
    [fileToMapName, filesUpload],
  );

  const isDirty = useMemo(() => {
    if (selectedPreset && !!selectedPreset.dataMapping) {
      const presetMapping = toColumnMapping(
        selectedPreset.dataMapping,
        columns.map((c) => c.field),
      );
      return (
        !isObjectSameAs(mapping, presetMapping) ||
        selectedPreset.dataMapping.timestampFormat?.type !== timestampFormat ||
        selectedPreset.dataMapping.locationFormat?.type !== locationFormat
      );
    }
    return selectedPreset && !selectedPreset.dataMapping && Object.values(mapping).some((v) => !!v);
  }, [selectedPreset, mapping, columns, timestampFormat, locationFormat]);

  const applyMapping = (preset: Partial<DataMappingPreset>, columns: string[]) => {
    if (preset.id) setSelected(preset.id);
    if (!!preset.dataMapping) {
      setIsFirstRowHeader(preset.dataMapping.isFirstRowHeaders);
      setTimestampFormat(preset.dataMapping.timestampFormat?.type ?? '');
      setLocationFormat(preset.dataMapping.locationFormat?.type ?? '');
      setHasHeight(!!preset.dataMapping.locationFormat?.['heightColumn'] ?? '');
      setMapping(toColumnMapping(preset.dataMapping, columns));
    }
  };

  const handleSelect = (preset?: Partial<DataMappingPreset>) => {
    if (preset) {
      applyMapping(
        preset,
        columns.map((c) => c.field),
      );
    }
  };

  const handleFirstRow = (e: SyntheticEvent, checked: boolean) => {
    setIsFirstRowHeader(checked);
  };

  const handleHeight = (e: SyntheticEvent, checked: boolean) => {
    setHasHeight(checked);
    setMapping(partialClearMapping([LocationOptions.HEIGHT]));
  };

  const handleReady = (values: { rows: DataMappingRow[]; columns: GridColDef[] }) => {
    setColumns(values.columns);
    setRows(values.rows);
    if (!mounted) {
      const file = filesUpload.find((f) => f.name === fileToMapName);
      if (file?.dataMappingInfo?.dataMapping) {
        applyMapping(
          file.dataMappingInfo,
          values.columns.map((c) => c.field),
        );
      }
      setMounted(true);
    } else {
      setMapping({});
    }
    toggleLoading();
  };

  const handleTimestampFormat = (e: SelectChangeEvent<TimestampFormats>) => {
    setTimestampFormat(e.target.value as TimestampFormats);
    setMapping(partialClearMapping(Object.values(TimestampOptions)));
  };

  const handleLocationFormat = (e: SelectChangeEvent<LocationFormats>) => {
    setLocationFormat(e.target.value as LocationFormats);
    let options = Object.values(LocationOptions);
    if (!e.target.value) {
      setHasHeight(false);
    } else {
      options = options.filter((o) => o !== LocationOptions.HEIGHT);
    }
    setMapping(partialClearMapping(options));
  };

  const handleMapping = (mapping: Record<string, TimestampOptions | LocationOptions>) => {
    setMapping((prev) => ({ ...prev, ...mapping }));
  };

  const handleClearMapping = () => {
    setMapping({});
  };

  const handleUpdateApply = () => {
    if (selected && (timestampFormat || locationFormat)) {
      updateDataMappingPreset.mutate({
        id: selected,
        preset: { dataMapping: toDataMapping(mapping, timestampFormat, locationFormat, isFirstRowHeader) },
      });
    }
    handleApply();
  };

  const handleApply = () => {
    const file = filesUpload.find((f) => f.name === fileToMapName)!;
    setDataMapping(file, {
      dataMapping: toDataMapping(mapping, timestampFormat as TimestampFormats, locationFormat as LocationFormats, isFirstRowHeader),
    });
    toggleOpenDataMappingDialog();
  };

  return (
    <>
      <DialogTitle>{t('upload:data_mapping')}</DialogTitle>
      <DialogContent>
        <DataMappingContext.Provider
          value={{
            timestampFormat,
            locationFormat,
            hasHeight,
            mapping,
            handleMapping,
            toggleDisabled,
          }}
        >
          <Box display="flex" gap={6}>
            <Box width={320}>
              <Presets selected={selected} onSelect={handleSelect} disabled={isLoading} />
            </Box>
            <Box width="calc(100% - 320px)" display="flex" flexDirection="column" gap={3}>
              <ReadFileOptions
                isCSV={isCSV}
                isFirstRowHeader={isFirstRowHeader}
                onReady={handleReady}
                onLoading={toggleLoading}
                disabled={isLoading || disabled}
              >
                <FormControlLabel
                  checked={isFirstRowHeader}
                  onChange={handleFirstRow}
                  control={<Checkbox />}
                  label={t('upload:first_row_headers')}
                  disabled={isLoading || disabled}
                />
              </ReadFileOptions>
              <Typography>{t('upload:select_data_formats')}</Typography>
              <Box display="flex" gap={4} justifyContent="flex-start">
                <Select
                  label={t('upload:timestamp')}
                  value={timestampFormat}
                  onChange={handleTimestampFormat}
                  sx={{ maxWidth: 240 }}
                  disabled={disabled}
                >
                  <MenuItem value="">{t('common:none')}</MenuItem>
                  {Object.values(TimestampFormats).map((ts) => (
                    <MenuItem key={ts} value={ts}>
                      {t('upload:format_' + ts)}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  label={t('upload:location')}
                  value={locationFormat}
                  onChange={handleLocationFormat}
                  sx={{ maxWidth: 240 }}
                  disabled={disabled}
                >
                  <MenuItem value="">{t('common:none')}</MenuItem>
                  {Object.values(LocationFormats)
                    .filter((v) => v !== LocationFormats.HEIGHT)
                    .map((l) => (
                      <MenuItem key={l} value={l}>
                        {t('upload:format_' + l)}
                      </MenuItem>
                    ))}
                </Select>
                <FormControlLabel
                  control={<Checkbox />}
                  label={t('upload:has_height')}
                  checked={hasHeight}
                  onChange={handleHeight}
                  disabled={disabled || !locationFormat}
                />
              </Box>
              <Box flex={1} display="flex" flexDirection="column">
                <Typography>{t('upload:fields_mapped', { mappedCount, fieldsCount: requiredCount })}</Typography>
                <Box width="100%" height={320}>
                  <StyledDataGrid
                    getRowId={(row) => row.internalGridId}
                    hideFooterPagination
                    columns={columns}
                    rows={rows}
                    loading={isLoading}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </DataMappingContext.Provider>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={toggleOpenDataMappingDialog}>
          {t('common:cancel')}
        </Button>
        {mappedCount > 0 && (
          <Button variant="text" color="primary" onClick={handleClearMapping} disabled={disabled}>
            {t('upload:clear_mapping')}
          </Button>
        )}
        {!!selected && isDirty && (
          <Button variant="text" color="primary" onClick={handleUpdateApply} disabled={isLoading || disabled || !meetsRequirement}>
            {t('upload:update_preset_apply')}
          </Button>
        )}
        <Button variant="text" color="primary" onClick={handleApply} disabled={isLoading || disabled || !meetsRequirement}>
          {t('common:apply')}
        </Button>
      </DialogActions>
    </>
  );
};

const DataMappingDialog: FC = () => {
  const { openDataMappingDialog } = useFileUploader();
  return (
    <Dialog open={openDataMappingDialog} maxWidth="xl" fullWidth>
      <DataMappingDialogContent />
    </Dialog>
  );
};

export default DataMappingDialog;
