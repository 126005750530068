import { Fragment, useEffect, useRef } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Button from '@/components/Button';
import { FileItem } from './FileItem';
import useFileUploader from '@/hooks/useFileUploader';
import { UploadStatus } from '@/models';
import { UploadValidationError, validateFileForUploading } from '@/components/FileUpload/utils';
import { DEFAULT_TRANSLATIONS, SUPPORTED_FILE_FORMAT } from '@/consts';
import { DropArea } from '@/components/Uploader';
import { useRouter } from 'next/router';

const UploadDialogContent = () => {
  const { t } = useTranslation(DEFAULT_TRANSLATIONS);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const workspaceId: string | undefined = router.query.workspaceId as any;

  const {
    toggleOpenDialog,
    filesUpload,
    availableToUpdate,
    isValidFileUpload,
    uploadFiles,
    updateStatus,
    pendingFiles,
    uploadInProgress,
    removeFileUpload,
    addFilesToUpload,
  } = useFileUploader();

  useEffect(() => {
    filesUpload.forEach((file) =>
      validateFileForUploading(file).then((error) => {
        switch (error) {
          case UploadValidationError.ENCRYPTED_FILE:
            updateStatus(file, UploadStatus.SKIPPED, t('upload:cant_process_encrypted'));
            break;
        }
      }),
    );
    if (pendingFiles && !uploadInProgress) {
      void uploadFiles();
    }
  }, [pendingFiles, uploadFiles, uploadInProgress]);

  const handleClose = () => {
    toggleOpenDialog();
    // Clean all completed uploads
    filesUpload.forEach((f) => {
      if (f.status === UploadStatus.UPLOADED) {
        removeFileUpload(f);
      }
    });
  };

  const handleDropFile = (files: File[]) => {
    addFilesToUpload(files);
  };

  const handleStart = () => {
    void uploadFiles();
  };

  return (
    <>
      <DialogTitle>{t('upload:dialog_title')}</DialogTitle>
      <DialogContent>
        <DropArea fileTypes={SUPPORTED_FILE_FORMAT} onChange={handleDropFile} inputRef={fileInputRef} />
        <Box display="flex" flexDirection="column" gap={1} mt={3} maxHeight="50vh" overflow="auto">
          {filesUpload.map((f, index) => (
            <Fragment key={f.name}>
              <FileItem file={f} workspaceId={workspaceId} />
              {index < filesUpload.length - 1 && <Divider />}
            </Fragment>
          ))}
          {filesUpload.length === 0 && <Typography sx={{ textAlign: 'center' }}>{t('upload:empty')}</Typography>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Tooltip title={t('upload:close_notice')}>
          <Button variant="text" color="primary" onClick={handleClose}>
            {t('close')}
          </Button>
        </Tooltip>
        {availableToUpdate && (
          <Button variant="text" color="primary" onClick={handleStart} disabled={!isValidFileUpload}>
            {t('upload:upload')}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

const UploadDialog = () => {
  const { openDialog } = useFileUploader();
  return (
    <Dialog open={openDialog} maxWidth="sm" fullWidth>
      <UploadDialogContent />
    </Dialog>
  );
};

export default UploadDialog;
