import { jobKeys, keys } from '@/hooks/queries';
import { FC, useEffect } from 'react';
import { addEventListener } from '@/utils/events';
import {
  InferenceJobMessage,
  IngestionJobMessage,
  JobMessage,
  MessageAction,
  MessageType,
  NotificationMessage,
  SourceDataMessage,
} from '@/messages';
import { useQueryClient } from '@tanstack/react-query';
import { Callback, SourceDataId } from '@/models';
import { notificationKeys } from '@/components/Notifications/queries';
import { inferenceJobsKeys, ingestionJobsKeys } from '../FileUpload/queries';
import { useThrottle } from '@/hooks/useThrottle';

export const Handlers: FC = () => {
  const queryClient = useQueryClient();

  const refreshFilesList = useThrottle(() => {
    void queryClient.invalidateQueries({ queryKey: ingestionJobsKeys.all });
  }, 1000);

  const refreshInferenceJobsList = useThrottle((sourceId: SourceDataId) => {
    void queryClient.invalidateQueries({ queryKey: inferenceJobsKeys.list(sourceId) });
  }, 5000);

  useEffect(() => {
    const listeners: Callback[] = [];
    listeners.push(
      // RelationsAccordion, DataSourceItem
      addEventListener<MessageEvent<JobMessage>>(MessageType.ANALYTICS_JOB, (e) => {
        e.data.properties.sourceIds.forEach((sourceId) => {
          void queryClient.invalidateQueries({ queryKey: keys.relations(sourceId) });
          void queryClient.invalidateQueries({ queryKey: jobKeys.source(sourceId) });
        });
      }),

      // NotificationList, NotificationDialog, NotificationIndicator
      addEventListener<MessageEvent<NotificationMessage>>(MessageType.NOTIFICATION, (e) => {
        void queryClient.invalidateQueries({ queryKey: notificationKeys.all });
        void queryClient.invalidateQueries({ queryKey: notificationKeys.count });
      }),

      // FileUpload/index
      addEventListener<MessageEvent<SourceDataMessage>>(MessageType.SOURCE, (e) => {
        if ([MessageAction.CREATE, MessageAction.DELETE].includes(e.data.action)) {
          refreshFilesList();
        }
      }),
      // FileUpload/index
      addEventListener<MessageEvent<IngestionJobMessage>>(MessageType.INGESTION_JOB, (e) => {
        refreshFilesList();
      }),
      // FileUpload/Models, ModelRunActions, DetectionsTab/index
      addEventListener<MessageEvent<InferenceJobMessage>>(MessageType.INFERENCE_JOB, (e) => {
        e.data?.properties?.sourceId && refreshInferenceJobsList(e.data.properties.sourceId);
      }),
    );

    return () => listeners.forEach((removeCb) => removeCb());
  }, []);

  return null;
};
