// noinspection JSUnusedGlobalSymbols
import { SimpleLine } from './SimpleLine';
import { Numbers } from './Numbers';
import { PSEUDO_MERCATOR, WORLD_GEODETIC_SYSTEM } from '@/consts';
import { round } from '..';
import { Coordinate, GeometryType, IGeometry, ParseProps, Polygon, Projection, Size } from '.';
import { WKT } from '@/models';

const diff = 0;
const EARTH_IN_MITERS = 20037508.34;
const DEGREES_IN_EARTH = 180;
const MITERS_IN_ONE_DEGREE = EARTH_IN_MITERS / DEGREES_IN_EARTH;
const DEGREES_IN_ONE_MITER = DEGREES_IN_EARTH / EARTH_IN_MITERS;
const PI_TO_DEGREE = Math.PI / 180;

export class Point implements IGeometry {
  x: number = 0;
  y: number = 0;
  z?: number = undefined;
  m?: number = undefined;
  properties: { [key: string]: any } = {};

  readonly type = GeometryType.POINT;
  private _projection?: string;

  constructor(x: number = 0, y: number = 0, z?: number, m?: number) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.m = m;
  }

  static Zero(): Point {
    return new Point(0, 0);
  }

  static Parse(c: Coordinate | [number, number, number | undefined, number]) {
    const [x, y, z, m] = c;
    return new Point(x, y, z, m);
  }

  static isPoint(p: any): boolean {
    return p instanceof Point;
  }

  static ParseFromWKT(wkt: WKT, optProps?: ParseProps | Projection): Point {
    const data = wkt.trim().toUpperCase();
    let point;
    if (data === 'POINT EMPTY') {
      point = new Point(0, 0);
    } else {
      const regexp = new RegExp('POINT\\s?([Z|M]{0,2})\\s?\\(((?:(?!\\)).)*?)\\)$', 'mi');
      const res = regexp.exec(data) as RegExpExecArray;
      if (!res) {
        throw Error('Cannot parse WKT: ' + wkt);
      }
      const modifiers = res[1].split('');
      const values = res[2].split(' ').map(Number);
      if (values.length !== modifiers.length + 2) {
        throw Error('Cannot parse WKT. Modifiers values does not match ' + wkt);
      }
      const [x, y] = values;
      const z = (modifiers.includes('Z') && values[2]) || undefined;
      const m = (modifiers.includes('M') && (values[3] || values[2])) || undefined;
      return new Point(x, y, z, m);
    }

    if (optProps) {
      point = typeof optProps === 'string' ? point.setProjection(optProps) : point.transform(optProps);
    }

    return point;
  }

  static CreateFromSize(size: Size): Point {
    if (!size && size !== 0) {
      throw Error('Size is not set');
    }
    if (size instanceof Point) {
      return size;
    }
    if (Array.isArray(size)) {
      return new Point(size[0], size[1]);
    }
    return new Point(size, size);
  }

  static Random(): Point {
    return new Point(Math.random(), Math.random());
  }

  findLine(p: Point): SimpleLine {
    const a = this.y - p.y - diff;
    const b = p.x - this.x - diff;
    const c = this.x * p.y - p.x * this.y - diff;
    if (a === 0) {
      return new SimpleLine(0, 1, c / b, this, p);
    }
    if (b === 0) {
      return new SimpleLine(1, 0, c / a, this, p);
    }
    return new SimpleLine(a, b, c, this, p);
  }

  findInnerAngle(p1: Point, p3: Point): number {
    const a1 = this.findLine(p1).getFi();
    const a2 = this.findLine(p3).getFi();
    if (a2 >= a1) {
      return a2 - a1;
    } else {
      return a2 + Math.PI * 2 - a1;
    }
  }

  transformTo(to: Projection) {
    if (!this._projection) {
      throw Error('Source projection is not specified');
    }
    return this.transform(this._projection, to);
  }

  transform(from: Projection | ParseProps = PSEUDO_MERCATOR, to: Projection = WORLD_GEODETIC_SYSTEM) {
    if (typeof from === 'object') {
      to = from.featureProjection;
      from = from.dataProjection;
    }
    if (this._projection) {
      if (from !== this._projection) {
        throw Error('Source projection mismatch');
      }
      if (to === this._projection) {
        return this;
      }
    }
    this._projection = to;
    if (from === to) {
      return this;
    }
    if (from === PSEUDO_MERCATOR && to === WORLD_GEODETIC_SYSTEM) {
      return this.meters2degrees();
    }
    if (from === WORLD_GEODETIC_SYSTEM && to === PSEUDO_MERCATOR) {
      return this.degrees2meters();
    }
    throw Error('Unknown projection');
  }

  transformBy(transformer: (p: Point) => Point) {
    return transformer(this);
  }

  toString(forceDepth = false) {
    const { x, y, z = (forceDepth && 0) || undefined, m } = this;
    if (typeof z !== 'undefined' && typeof m !== 'undefined') {
      return `${x} ${y} ${z} ${m}`;
    }
    if (typeof z !== 'undefined') {
      return `${x} ${y} ${z}`;
    }
    if (typeof m !== 'undefined') {
      return `${x} ${y} ${m}`;
    }
    return `${x} ${y}`;
  }

  getValue() {
    const { x, y, z, m } = this;
    return [x, y, z, m];
  }

  depth(z: number | undefined) {
    this.z = z;
    return this;
  }

  removeDepth = () => {
    return this.depth(undefined);
  };

  getCoordinates(forceDepth = false): Coordinate {
    if (forceDepth || this.z !== undefined) {
      return [this.x, this.y, this.z || 0];
    } else {
      return [this.x, this.y];
    }
  }

  toWKT(): string {
    const { z, m } = this;
    const coord = this.toString();
    if (typeof z !== 'undefined' && typeof m !== 'undefined') {
      return `POINT ZM (${coord})`;
    }
    if (typeof z !== 'undefined') {
      return `POINT Z (${coord})`;
    }
    if (typeof m !== 'undefined') {
      return `POINT M (${coord})`;
    }
    return `POINT (${coord})`;
  }

  setX(x: number): Point {
    this.x = x;
    return this;
  }

  setY(y: number): Point {
    this.y = y;
    return this;
  }

  clone(): Point {
    const p = new Point(this.x, this.y, this.z, this.m);
    p.properties = { ...this.properties };
    p.setProjection(this.projection);
    return p;
  }

  gt(p: Point): boolean {
    return this.x > p.x && this.y > p.y;
  }

  lt(p: Point): boolean {
    return this.x < p.x && this.y < p.y;
  }

  gtOrEqual(p: Point): boolean {
    return this.gt(p) || this.equal(p);
  }

  ltOrEqual(p: Point): boolean {
    return this.lt(p) || this.equal(p);
  }

  rotate(a: number) {
    this.x = this.x * Math.cos(a) - this.y * Math.sin(a);
    this.y = this.x * Math.sin(a) + this.y * Math.cos(a);
    return this;
  }

  move(x: number | Point = 0, y?: number, z?: number) {
    if (x instanceof Point) {
      this.x += x.x;
      this.y += x.y;
      this.z = x.z !== undefined ? (this.z || 0) + x.z : this.z;
    } else if (typeof y === 'number') {
      this.x += x;
      this.y += y;
      this.z = z !== undefined ? (this.z || 0) + z : this.z;
    }
    return this;
  }

  moveTo(point: Point, distance: number): Point {
    const t = distance / this.distance(point);
    this.x = (1 - t) * this.x + t * point.x;
    this.y = (1 - t) * this.y + t * point.y;
    return this;
  }

  distance(p: Point): number {
    const dx = p.x - this.x;
    const dy = p.y - this.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  midPoint(p: Point): Point {
    const dx = p.x - this.x;
    const dy = p.y - this.y;

    return new Point(this.x + dx / 2, this.y + dy / 2);
  }

  asRadians() {
    return this.divide(180).multiply(Math.PI);
  }

  asDegrees() {
    return this.divide(Math.PI).multiply(180);
  }

  round(precision: number = 0) {
    this.x = round(this.x, precision);
    this.y = round(this.y, precision);
    this.z = this.z !== undefined ? round(this.z, precision) : this.z;
    return this;
  }

  ceil() {
    this.x = Math.ceil(this.x);
    this.y = Math.ceil(this.y);
    this.z = this.z !== undefined ? Math.ceil(this.z) : this.z;
    return this;
  }

  abs() {
    this.x = Math.abs(this.x);
    this.y = Math.abs(this.y);
    this.z = this.z !== undefined ? Math.abs(this.z) : this.z;
    return this;
  }

  multiply(x: number | Point = 0, y?: number, z?: number) {
    if (x instanceof Point) {
      this.x *= x.x;
      this.y *= x.y;
      if (this.z !== undefined && x.z !== undefined) {
        this.z *= x.z;
      }
    } else {
      this.x *= x;
      this.y *= y === undefined ? x : y;
      if (this.z !== undefined) {
        this.z = z === undefined ? this.z * x : this.z * z;
      }
    }

    return this;
  }

  divide(x: number | Point = 0, y?: number, z?: number) {
    if (x instanceof Point) {
      this.x /= x.x;
      this.y /= x.y;
      this.z = this.z !== undefined && x.z !== undefined ? this.z / x.z : this.z;
    } else {
      this.x /= x;
      this.y /= y === undefined ? x : y;
      if (this.z !== undefined) {
        this.z = z === undefined ? this.z / x : this.z / z;
      }
    }

    return this;
  }

  equal(p: Point) {
    return this.x === p.x && this.y === p.y && this.z === p.z && this.m === p.m;
  }

  like(p: Point, delta?: number) {
    const likeX = Numbers.like(this.x, p.x, delta);
    const likeY = Numbers.like(this.y, p.y, delta);
    const likeZ = this.z === p.z || Numbers.like(this.z!, p.z!, delta);
    return likeX && likeY && likeZ;
  }

  minus() {
    return this.multiply(-1);
  }

  mirrorY() {
    return this.multiply(1, -1);
  }

  getFi = (point: Point) => this.findLine(point).getFi();

  get w() {
    return this.x;
  }

  set w(x: number) {
    this.x = x;
  }

  get width() {
    return this.x;
  }

  set width(x: number) {
    this.x = x;
  }

  get height() {
    return this.y;
  }

  set height(y: number) {
    this.y = y;
  }

  get h() {
    return this.y;
  }

  set h(y: number) {
    this.y = y;
  }

  get area(): number {
    return this.w * this.h;
  }

  get hip(): number {
    return Math.sqrt(this.w * this.w + this.h * this.h);
  }

  get min(): number {
    return Math.min(this.x, this.y);
  }

  get max(): number {
    return Math.max(this.x, this.y);
  }

  get hipPoint(): Point {
    const { hip } = this;
    return new Point(hip, hip);
  }

  get xPoint(): Point {
    const { x } = this;
    return new Point(x, x);
  }

  get yPoint(): Point {
    const { y } = this;
    return new Point(y, y);
  }

  get wPoint(): Point {
    return this.xPoint;
  }

  get hPoint(): Point {
    return this.yPoint;
  }

  get projection(): Projection | undefined {
    return this._projection;
  }

  get center(): Point {
    return this;
  }

  get points(): Point[] {
    return [this];
  }

  get extent(): Polygon {
    const points = [this.clone(), this.clone(), this.clone(), this.clone(), this.clone()];
    return new Polygon(points).setProjection(this.projection);
  }

  setProjection(value: Projection | undefined) {
    this._projection = value;
    return this;
  }

  simple(xKey: string = 'x', yKey: string = 'y'): { [key: string]: number } {
    return {
      [xKey]: this.x,
      [yKey]: this.y,
    };
  }

  private degrees2meters(): Point {
    this.x = this.x * MITERS_IN_ONE_DEGREE;
    this.y = (Math.log(Math.tan((90 + this.y) * (Math.PI / 360))) / PI_TO_DEGREE) * MITERS_IN_ONE_DEGREE;
    return this;
  }

  private meters2degrees(): Point {
    this.x = this.x * DEGREES_IN_ONE_MITER;
    this.y = Math.atan(Math.pow(Math.E, (this.y / MITERS_IN_ONE_DEGREE) * PI_TO_DEGREE)) * (360 / Math.PI) - 90;
    return this;
  }
}
