import { Api, METHOD } from './Api';
import { singleton } from '@/utils/decorators/singleton';

@singleton
class ContactFormApi extends Api {
  endpoint: string = '/api/contactform';
  static getInstance: () => ContactFormApi;

  send(request: FormData) {
    return this.request(METHOD.POST, '', request);
  }
}

export const contactFormApi = () => ContactFormApi.getInstance();
