import { Box, Icon, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import {
  useCreateDataMappingPreset,
  useDeleteDataMappingPreset,
  useInfiniteDataMappingPresets,
  useUpdateDataMappingPreset,
} from '@/components/FileUpload/DataMapping/queries';
import { getFlatInfiniteContent, useIsPlaceholderData } from '@/hooks/queries/utils';
import { Virtuoso } from 'react-virtuoso';
import { MotionComponents } from '@/components/Virtuoso';
import { DataMappingPreset, DataMappingPresetId } from '@/models';
import { FC, useContext, useState } from 'react';
import useToggle from '@/hooks/useToggle';
import ConfirmationDialog from '@/components/ConfirmationDialog';
import { useTranslation } from 'next-i18next';
import { PlaceholderItem, PresetItem } from '@/components/FileUpload/DataMapping/PresetItem';
import { AnimatePresence } from 'framer-motion';
import EmptyMessage from '@/components/AnnotationProject/EmptyMessage';
import { InfiniteData, Updater, useQueryClient } from '@tanstack/react-query';
import { PageableResponse } from '@/cmd/Api';
import { isPresetBeingEdited } from '@/components/FileUpload/DataMapping/utils';
import { DataMappingContext } from '@/components/FileUpload/DataMapping/DataMappingDialog';

const updater: Updater<
  InfiniteData<PageableResponse<DataMappingPreset>> | undefined,
  InfiniteData<PageableResponse<DataMappingPreset>> | undefined
> = (oldData) => {
  if (oldData) {
    const [firstPage, ...restPages] = oldData.pages;
    return {
      ...oldData,
      pages: [
        {
          ...firstPage,
          content: [{}, ...firstPage.content],
        },
        ...restPages,
      ],
    } as InfiniteData<PageableResponse<DataMappingPreset>>;
  } else {
    return {
      pages: [
        {
          content: [
            {
              name: 'placeholder',
            },
          ] as DataMappingPreset[],
          totalElements: 1,
          pageable: { offset: 0, pageNumber: 0, pageSize: 0 },
          sort: { unsorted: false, sorted: false, empty: false },
          number: 1,
          totalPages: 1,
          numberOfElements: 1,
          size: 1,
          last: true,
          first: true,
          empty: false,
        },
      ],
      pageParams: {},
    } as InfiniteData<PageableResponse<DataMappingPreset>>;
  }
};

interface Props {
  selected?: DataMappingPresetId;
  onSelect: (preset: DataMappingPreset) => void;
  disabled: boolean;
}

const Presets: FC<Props> = ({ selected, onSelect, disabled }) => {
  const { t } = useTranslation(['common', 'upload']);
  const [open, toggleOpen] = useToggle();
  const queryClient = useQueryClient();
  const createDataMappingPreset = useCreateDataMappingPreset();
  const updateDataMappingPreset = useUpdateDataMappingPreset();
  const deleteDataMappingPreset = useDeleteDataMappingPreset();
  const { isLoading, data, loadMore } = useInfiniteDataMappingPresets();
  const isPlaceholderData = useIsPlaceholderData(data);
  const presets = getFlatInfiniteContent(data);
  const { toggleDisabled } = useContext(DataMappingContext)!;
  const [toDelete, setToDelete] = useState<DataMappingPreset>();
  const [toEditId, setToEditId] = useState<DataMappingPresetId>();
  const [editMode, toggleEditMode] = useToggle();

  const handleAdd = () => {
    handleEditPreset();
    queryClient.setQueryData(['data_mapping_presets'], updater);
  };

  const handleDelete = async (item?: DataMappingPreset) => {
    if (item) {
      await deleteDataMappingPreset.mutateAsync(item.id);
    }
    setToDelete(undefined);
    toggleOpen();
  };
  const handleDeletePreset = (item: DataMappingPreset) => {
    setToDelete(item);
    toggleOpen();
  };

  const handleEditPreset = (id?: DataMappingPresetId) => {
    toggleEditMode();
    toggleDisabled();
    setToEditId(id);
  };

  return (
    <>
      <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
        <Box
          sx={{
            backgroundColor: 'background.container',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingInline: 4,
            // paddingBlock: 2,
          }}
        >
          <Typography variant="body2">{t('upload:presets')}</Typography>
          <Tooltip title={t('upload:add_preset')}>
            <IconButton size="small" color="action" onClick={handleAdd} disabled={editMode || disabled}>
              <Icon fontSize="small">add</Icon>
            </IconButton>
          </Tooltip>
        </Box>
        <AnimatePresence mode="wait" initial={false}>
          {!isLoading && presets.length === 0 ? (
            <EmptyMessage icon="table_rows" primary={t('upload:no_presets')} secondary={t('upload:no_presets_info')} />
          ) : (
            <Box flex={1}>
              <Virtuoso
                style={{ height: '100%' }}
                data={presets}
                endReached={loadMore}
                components={MotionComponents}
                itemContent={(index, item) =>
                  isPlaceholderData ? (
                    <PlaceholderItem />
                  ) : (
                    <PresetItem
                      key={item.id}
                      item={item}
                      selected={(!editMode && !!selected && selected === item.id) || isPresetBeingEdited(item.id, editMode, toEditId)}
                      onSelect={onSelect}
                      onDelete={handleDeletePreset}
                      onEdit={handleEditPreset}
                      edit={isPresetBeingEdited(item.id, editMode, toEditId)}
                      disabled={disabled || (editMode && toEditId !== item.id)}
                    />
                  )
                }
              />
            </Box>
          )}
        </AnimatePresence>
      </Paper>
      <ConfirmationDialog
        id={'deleteConfirmation'}
        title={t('upload:delete_preset')}
        content={t('upload:delete_preset_content', { name: toDelete?.name })}
        value={toDelete}
        open={open}
        onClose={handleDelete}
        confirmationButton="delete"
      />
    </>
  );
};

export default Presets;
