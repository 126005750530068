import { FileUpload } from '@/models';
import { CustomPromise, CustomPromiseProps } from '@/utils/CustomPromise';
import { AllowedFileExtension } from '@/consts';

const ZIP_ENCRYPTION_BIT = 0x06;
const ZIP_ENCRYPTION_FLAG = 0x01;

export enum UploadValidationError {
  NO_ERROR,
  ENCRYPTED_FILE,
}

export const validateFileForUploading = (file: FileUpload): Promise<UploadValidationError> => {
  switch (file.ext) {
    case AllowedFileExtension.ZIP:
      return validateZip(file.file);
    case AllowedFileExtension.PDF:
      return validatePdf(file.file);
  }

  return Promise.resolve(UploadValidationError.NO_ERROR);
};

const validateZip = (file: File): Promise<UploadValidationError> => {
  const promise = CustomPromise<UploadValidationError>();
  const reader = file.stream().getReader();
  if (reader) {
    reader
      .read()
      .then((res) => {
        const { value } = res;
        if (!value) {
          return promise.reject('Can not validate file');
        }
        const content = value.slice(0, 7);
        void promise.resolve(
          content[ZIP_ENCRYPTION_BIT] === ZIP_ENCRYPTION_FLAG ? UploadValidationError.ENCRYPTED_FILE : UploadValidationError.NO_ERROR,
        );
        void reader.cancel();
      })
      .catch((e) => promise.reject(e));
  } else {
    void promise.reject('Can not validate file');
  }

  return promise;
};

const validatePdf = (file: File): Promise<UploadValidationError> => {
  const promise = CustomPromise<UploadValidationError>();
  const reader = file.stream().getReader();
  if (reader) {
    let prevChunk = '';
    const decoder = new TextDecoder();
    const process = (res: ReadableStreamReadResult<any>): Promise<any> => {
      const { done, value } = res;
      if (done) {
        return promise.resolve(UploadValidationError.NO_ERROR);
      }
      if (!value) {
        return promise.reject('Can not validate file');
      }
      const currentChunk = decoder.decode(value, { stream: true });
      const content = prevChunk + currentChunk;
      if (content.indexOf('/Encrypt') >= 0) {
        return promise.resolve(UploadValidationError.ENCRYPTED_FILE);
      }
      prevChunk = currentChunk;
      return reader.read().then(process);
    };
    reader.read().then(process).catch(promise.reject);
  } else {
    void promise.reject('Can not validate file');
  }

  return promise;
};
