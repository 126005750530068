// noinspection JSUnusedGlobalSymbols

import { GeometryType, IGeometry, ParseProps, Point, Polygon, Projection, SimpleLine } from '.';
import { KeyValue, WKT } from '@/models';
import { PSEUDO_MERCATOR, WORLD_GEODETIC_SYSTEM } from '@/consts';

// TODO: Parse from WKT
export class Circle implements IGeometry {
  readonly type = GeometryType.CIRCLE;
  protected _projection?: string;
  radius: number = 0;
  center: Point = Point.Zero();
  properties: KeyValue = {};

  constructor(center: Point = Point.Zero(), radius: number = 0) {
    this.radius = radius;
    this.center = center;
    this.center.properties = { radius };
    this.center.m = radius;
  }

  get extent(): Polygon {
    const [minX, minY, maxX, maxY] = [
      this.center.x - this.radius,
      this.center.y - this.radius,
      this.center.x + this.radius,
      this.center.y + this.radius,
    ];
    const points = [new Point(minX, minY), new Point(maxX, minY), new Point(maxX, maxY), new Point(minX, maxY), new Point(minX, minY)];
    return new Polygon(points);
  }

  static findCurve(k: number = 128): null | SimpleLine | Circle {
    if (k < 0 || k > 256) {
      return null;
    }
    const p1 = new Point(0, 0);
    const p3 = new Point(256, 256);
    if (k === 128) {
      return p1.findLine(p3);
    }
    const p2 = new Point(256 - k, k);
    const l1 = p1.findLine(p2);
    const l2 = p2.findLine(p3);
    const d1 = p2.move(p1.minus()).divide(2);
    const d2 = p2.move(p3.move(p2.minus()).divide(2));
    const per1 = l1.findPerpendicular(d1);
    const per2 = l2.findPerpendicular(d2);
    const center = per1.intersection(per2, Infinity);
    if (!center) {
      return null;
    }
    const r = center.distance(p1);
    return new Circle(center, r);
  }

  static ParseFromWKT(wkt: WKT, optProps?: ParseProps | Projection): Circle | undefined {
    const point = Point.ParseFromWKT(wkt, optProps);
    if (!point) {
      return undefined;
    }
    return new Circle(point, point.m || 0);
  }

  toString(): string {
    return `(${this.center.toString()}, ${this.radius})`;
  }

  getValue() {
    return { center: this.center, r: this.radius };
  }

  findPoints(c: Circle): Point[] {
    const {
      center: { x: x0, y: y0 },
      radius: r0,
    } = this;
    const {
      center: { x: x1, y: y1 },
      radius: r1,
    } = c;

    const r02 = r0 * r0;

    const d = this.center.distance(c.center);
    const a = (r02 - r1 * r1 + d * d) / (2 * d);
    const h = Math.sqrt(r02 - a * a);

    const ad = a / d;
    const dy = y1 - y0;
    const dx = x1 - x0;
    const hd = h / d;

    const x2 = x0 + ad * (x1 - x0);
    const y2 = y0 + ad * (y1 - y0);

    const x31 = x2 + hd * dy;
    const y31 = y2 - hd * dx;
    const x32 = x2 - hd * dy;
    const y32 = y2 + hd * dx;

    return [new Point(x31, y31), new Point(x32, y32)];
  }

  findPolygonInside(pointCount: number = 64): Polygon {
    const preAngle = (2 * Math.PI) / pointCount;
    const points: Point[] = [];
    for (let i = 0; i < pointCount; i++) {
      const angle = preAngle * i;
      const x = this.radius * Math.cos(angle) + this.center.x;
      const y = this.radius * Math.sin(angle) + this.center.y;
      points.push(new Point(x, y));
    }
    return new Polygon([...points, points[0]]);
  }

  clone(): Circle {
    const circle = new Circle(this.center, this.radius);
    circle.properties = { ...this.properties };
    circle.setProjection(this.projection);
    return circle;
  }

  toWKT(): WKT {
    return this.center.toWKT();
  }

  transformBy(transformer: (p: Point) => Point): Circle {
    this.center.transformBy(transformer);
    return this;
  }

  transform(from: Projection | ParseProps = PSEUDO_MERCATOR, to: Projection = WORLD_GEODETIC_SYSTEM): Circle {
    this.center.transform(from, to);
    return this;
  }

  transformTo(toProjection: Projection): Circle {
    if (!this._projection) {
      throw Error('Source projection is not specified');
    }
    return this.transform(this._projection, toProjection);
  }

  move(x: number | Point, y?: number): Circle {
    this.center.move(x, y);
    return this;
  }

  multiply(x: number | Point, y?: number): Circle {
    this.center.multiply(x, y);
    return this;
  }

  mirrorY(): Circle {
    return this.multiply(1, -1);
  }

  round(precision: number = 0): Circle {
    this.center.round(precision);
    return this;
  }

  setProjection(value: Projection | undefined): Circle {
    this._projection = value;
    this.center.setProjection(value);
    return this;
  }

  get projection(): Projection | undefined {
    return this._projection;
  }

  get points(): Point[] {
    return [this.center];
  }
}
