import { createContext, ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface MotionProvider {
  children: ReactNode;
}

interface MotionState {
  routeTransition: {
    from: string;
    to: string;
  };
}

interface MotionContext {
  routeTransition: {
    from: string;
    to: string;
  };
}

export const MotionContext = createContext<MotionContext | undefined>(undefined);

const MotionProvider = ({ children }: MotionProvider) => {
  const router = useRouter();
  const [motionState, setMotionState] = useState<MotionState>({ routeTransition: { from: '', to: router.route } });

  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        setMotionState((prev) => ({ ...prev, routeTransition: { to: url, from: prev.routeTransition.to } }));
      }
    };

    const handleRouteChangeError = (err: { cancelled: boolean }, url: string) => {
      // TODO need to handle in case of quick change to another route
      if (err.cancelled) {
        // console.log(`Route to ${url} was cancelled!`);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeError', handleRouteChangeError);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, []);

  return <MotionContext.Provider value={{ routeTransition: motionState.routeTransition }}>{children}</MotionContext.Provider>;
};

export default MotionProvider;
