import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useState, useSyncExternalStore } from 'react';
import { DataMappingPreset, FileUpload, RasterSourceType, UploadStatus, WorkspaceId } from '@/models';
import { Geometry } from '@/utils/geometry';
import useToggle from '@/hooks/useToggle';
import { useQueryClient } from '@tanstack/react-query';
import { fileUploadStore } from '@/stores/file-uploader.store';

export interface FileUploaderContext {
  filesUpload: FileUpload[];
  openDialog: boolean;
  toggleOpenDialog: () => void;
  openDataMappingDialog: boolean;
  toggleOpenDataMappingDialog: () => void;
  fileToMapName: string | undefined;
  setFileToMapName: Dispatch<SetStateAction<string | undefined>>;
  availableToUpdate: boolean;
  uploadInProgress: boolean;
  isUploadComplete: boolean;
  pendingFiles: boolean;
  isAllSuccess: boolean;
  isValidFileUpload: boolean;
  checkFileStatus: (file: FileUpload) => UploadStatus;
  addFilesToUpload: (files: File[]) => void;
  updateStatus: (file: FileUpload, status: UploadStatus, details?: string) => void;
  updateProgress: (file: FileUpload, progress: number) => void;
  setSourceType: (file: FileUpload, sourceType: RasterSourceType) => void;
  setGeometry: (file: FileUpload, geometry: Geometry | undefined) => void;
  addToWorkspace: (file: FileUpload, workspaceId: WorkspaceId | undefined) => void;
  removeFileUpload: (file: FileUpload) => void;
  setDataMapping: (file: FileUpload, dataMappingPreset: Partial<DataMappingPreset>) => void;
  uploadFiles: () => Promise<void>;
}
export const FileUploaderContext = createContext<FileUploaderContext | undefined>(undefined);

const FileUploaderProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();
  const store = useSyncExternalStore(fileUploadStore.subscribe, fileUploadStore.getSnapshot, fileUploadStore.getServerSnapshot);
  const [openDialog, toggleOpenDialog] = useToggle();
  const [openDataMappingDialog, toggleOpenDataMappingDialog] = useToggle();
  const [fileToMapName, setFileToMapName] = useState<string | undefined>();

  const uploadFiles = useCallback(async () => {
    fileUploadStore.prepareUpload();
    void fileUploadStore.uploadFiles(queryClient);
  }, [queryClient]);

  return (
    <FileUploaderContext.Provider
      value={{
        ...store,
        openDialog,
        toggleOpenDialog,
        openDataMappingDialog,
        toggleOpenDataMappingDialog,
        fileToMapName,
        setFileToMapName,
        checkFileStatus: fileUploadStore.checkFileStatus,
        addFilesToUpload: fileUploadStore.addFilesToUploader,
        updateStatus: fileUploadStore.updateStatus,
        updateProgress: fileUploadStore.updateProgress,
        setSourceType: fileUploadStore.setSourceType,
        setGeometry: fileUploadStore.setGeometry,
        removeFileUpload: fileUploadStore.removeFileUpload,
        setDataMapping: fileUploadStore.setDataMapping,
        addToWorkspace: fileUploadStore.addToWorkspace,
        uploadFiles,
      }}
    >
      {children}
    </FileUploaderContext.Provider>
  );
};

export default FileUploaderProvider;
