// noinspection JSUnusedGlobalSymbols

import type { Geometry, GeometryType } from './utils/geometry';
import type {
  Cartesian3,
  Cesium3DTileset,
  Color,
  Rectangle,
  SplitDirection,
  TextureMagnificationFilter,
  TextureMinificationFilter,
} from 'cesium';
import { ImageryLayer } from 'cesium';
import { ATTITUDE_KEYS, HeightmapDataType } from './consts';
import { Provider } from './components/Cesium/tileServer';
import { PageableRequest } from '@/cmd/Api';
import { HistoricalImagerySettings } from '@/components/HistoricalImagery/Settings';
import { RasterAdjustment } from '@/components/Cesium/utils';
import { LocationFormats, LocationOptions, TimestampFormats, TimestampOptions } from '@/components/FileUpload/DataMapping/utils';
import { SupportedColorScheme } from '@mui/material/styles';
import type { TerrainCorrection } from '@/cmd/DataSourceApi';
import { Dem } from '@/cmd/DataSourceApi';
import type { InferenceVectorLayer } from '@/components/Cesium/DataSource/InferenceVectorLayer';
import type { AnnotationsVectorLayer } from '@/components/Cesium/DataSource/AnnotationsVectorLayer';
import type { SourceDataLayer } from '@/components/Cesium/DataSource/SourceDataLayer';
import type { RasterLayer } from '@/components/Cesium/Layers/RasterLayer';
import type { SourceData3DTilesLayer } from '@/components/Cesium/Layers/SourceData3DTilesLayer';
import type { FeaturesLayer } from '@/components/Cesium/DataSource/FeaturesLayer';
import type { DrawingLayer } from '@/components/Cesium/DataSource/DrawingLayer';
import type { CzmlVectorLayer } from '@/components/Cesium/DataSource/CzmlVectorLayer';
import type { WindLayer } from '@/components/Cesium/Layers/WindLayer';
import { WKTType } from '@/utils/geometry/Geometry';
import type { StyleStore } from './stores/vector-styles.store';
import CustomPrimitive from '@/components/Cesium/utils/wind/CustomPrimitive';
import { CesiumPrimitivesLayer } from '@/components/Cesium/Layers/models';

export type Product = 'giq' | 'aid';
export type TimeoutId = ReturnType<typeof setTimeout>;
export type IntervalId = ReturnType<typeof setInterval>;
export type UnixTime = number;
export type FormattedDate = string;
export type FormattedDateTime = string;
export type SourceDataId = string;
export type LayerId = string;
export type LayerInteractionId = string;
export type UserId = string;
export type InferenceJobId = string;
export type ModelId = string;
export type ModelLabelMappingId = string;
export type CategoryId = string;
export type UploadedFileId = string;
export type IngestionJobId = string;
export type DetectionId = string;
export type EntityId = string;
export type FeatureId = string;
export type WKT = string;
export type UrlString = string;
export type HexColor = string;
export type SourceGeometryType = Exclude<GeometryType, GeometryType.MULTIPOLYGON>;
export type AcquisitionRequestId = string;
export type WorkspaceId = string;
export type StoryId = string;
export type StoryPinId = string;
export type TileServerLayerId = string;
export type DataAcquisitionVendorId = string;
export type TenantId = string;
export type DataAcquisitionProductId = string;
export type DataAcquisitionOrderId = string;
export type DataAcquisitionOrderProgressId = string;
export type ApprovalId = string;
export type PermissionId = string;
export type RoleId = string;
export type PresetExpressiond = string;
export type ModuleId = string;
export type SensitivityRuleFlowId = string;
export type SensitivityCheckRuleId = string;
export type AreaCheckGeometryId = string;
export type JobId = string;
export type AnnotationProjectId = string;
export type AnnotationDatasetId = string;
export type ExperimentId = string;
export type ExperimentJobId = string;
export type DataMappingPresetId = string;
export type NotificationId = string;
export type MarketplaceItemId = string;
export type MarketplaceItemSubscriptionId = string;
export type JsonString = string;
export type AnnotationRules = Record<CategoryId, FeatureStyle>;
export type WithDateAndUser = WithDate & WithUser;
export type CheckRuleTypeActive = 'isSensorCheckActive' | 'isAreaCheckActive' | 'isUserCheckActive' | 'isTemporalCheckActive';
export type CheckRuleType = 'sensorCheck' | 'temporalCheck' | 'areaCheck' | 'userCheck';
export type SensorCheckRuleProperties = Omit<SensorCheckRule, keyof CheckRule>;
export type TemporalSpatialCheckRuleProperties = Omit<TemporalSpatialCheckRule, keyof CheckRule>;
export type SensitivityCheckRule = SensorCheckRule | UserCheckRule | AreaCheckRule | TemporalSpatialCheckRule;
export type VectorStyles = GeometryStyles | PropertyStyle | AnnotationStyles;
export type BandColorRamp = BandGrayColorRamp | BandPseudoColorRamp;
export type ColorRamp = BandColorRamp | PropertyColorPreset;
export type BandMapping = [number, number, number, number]; // [red,green,blue,alpha]
export type BandColorRange = HexColor[];
export type BandsSettings = BandsSettingsMultiband | BandsSettingPseudocolor | BandsSettingGray;
export type SingleBandsSettings = BandsSettingPseudocolor | BandsSettingGray;
export type BandLevels = [number, number, number, number, number, number]; // [rMin, rMax, gMin, gMax, bMin, bMax]
export type BandRanges = [number, number, number, number, number, number]; // [rStart, rStop, gStart, gStop, bStart, bStop]
export type ColumnMapping = Record<string, TimestampOptions | LocationOptions>;
export type MarketplaceItem = MarketplaceModelItem | MarketplaceApplicationItem;
export type FollowTenant = 'same_as_tenant';
export type VectorLayer = SourceDataLayer | InferenceVectorLayer | AnnotationsVectorLayer | DrawingLayer;
export type UserLayer = VectorLayer | RasterLayer | CzmlVectorLayer | CesiumPrimitivesLayer;
export type Layer = FeaturesLayer | ImageryLayer | CzmlVectorLayer | CesiumPrimitivesLayer;
export type StoryLayerAdjustments = Record<SourceDataId, RasterAdjustment>;
export type StoryLayerBandsSettings = Record<SourceDataId, BandsSettings>;
export type Callback = (...args: any[]) => void;
export type ArchiveSearchField = 'acquisitionDate' | 'intersects' | 'product' | 'resolution';
export type TaskingField = 'acquisitionDate' | 'intersects' | 'product' | 'coverages';
export type Sort = Pick<PageableRequest, 'sort' | 'direction'>;
export type Attitude = (typeof ATTITUDE_KEYS)[number];
export type AttitudeRecord = Record<Attitude, string>;
export type AnyObject<T = unknown> = Record<string, T>;
export type FeatureProperties = Partial<Record<string, string | number | boolean | null>>;
export type DataAcquisitionOrderResponse = ArchiveAcquisitionOrder | TaskingAcquisitionOrder;
export type DataAcquisitionOrder = DataAcquisitionOrderResponse & { orderProgress: DataAcquisitionOrderProgress[] };
export type DataAcquisitionOrderItem = ArchiveDataAcquisitionOrderItem & TaskingDataAcquisitionOrderItem;
export type Job =
  | SarJob
  | ResamplingJob
  | CoRegistrationJob
  | OpticalChangeDetectionJob
  | SarChangeDetectionJob
  | TimeSeriesForecastingJob
  | MosaickingJob;

export type JobProperties =
  | SARJobProperties
  | ResamplingJobProperties
  | CoRegistrationJobProperties
  | OpticalChangeDetectionJobProperties
  | SarChangeDetectionJobProperties
  | BandsMathJobProperties
  | TimeSeriesForecastJobProperties
  | MosaicJobProperties;

export type DataMappingFormats =
  | DateTimeTogetherFormat
  | DateTimeSeparateFormat
  | UnixTimestampFormat
  | LatLongFormat
  | LatLongTogetherFormat;

export type SourceType =
  | RasterSourceType
  | VectorSourceType
  | VideoSourceType
  | DocumentSourceType
  | AnnotationSourceType
  | SpatialSourceType
  | NetCDFSourceType
  | UnknownSourceType;

export type GeometryRules = {
  [key in SourceGeometryType]?: FeatureStyle;
};

type BaseSubscriptionModel = {
  marketId: MarketplaceItemId;
  title: string;
  type: MarketplaceItemType;
  status: ModelSubscriptionStatus;
};

export type SubscribersModel = {
  organization: string;
  receivedOn: number;
  submittedBy: UserId;
  modelSubscriptionRequestId: string;
} & BaseSubscriptionModel;

export type MySubscriptionsModel = {
  cost: number;
  subscribedOn: number;
} & BaseSubscriptionModel;

export enum Temporal3dModel {
  BOAT = 'boat',
  PLANE = 'plane',
  CAR = 'car',
}

export enum Type {
  UNDEFINED = 'undefined',
  STRING = 'string',
  INTEGER = 'integer',
  BIGINT = 'bigint',
  FLOAT = 'float',
  BOOLEAN = 'boolean',
  OBJECT = 'object',
  ARRAY = 'array',
  FUNCTION = 'function',
  SYMBOL = 'symbol',
  NULL = 'null',
  NAN = 'nan',
}

export enum Language {
  EN = 'EN',
  AR = 'AR',
}

export enum InferenceJobStatus {
  SUBMITTED = 'SUBMITTED',
  RUNNING = 'RUNNING',
  GETTING_RESULTS = 'GETTING_RESULTS',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export enum ModelType {
  AERIAL = 'AERIAL',
  MULTISPECTRAL = 'MULTISPECTRAL',
  SAR = 'SAR',
  FULL_MOTION_VIDEO = 'FULL_MOTION_VIDEO',
  OPTICAL_CHANGE_DETECTION = 'OPTICAL_CHANGE_DETECTION',
}

export enum ModelJobType {
  DETECTION = 'detection',
  CHANGE_DETECTION = 'change_detection',
  SEGMENTATION = 'segmentation',
  VIDEO_OBJECT_DETECTION = 'video_object_detection',
  CLASSIFICATION = 'classification',
}

export enum RasterSourceType {
  PANCHROMATIC = 'PANCHROMATIC',
  MULTISPECTRAL = 'MULTISPECTRAL',
  HYPERSPECTRAL = 'HYPERSPECTRAL',
  SAR = 'SAR',
  THERMAL = 'THERMAL',
  HEIGHTMAP = 'HEIGHTMAP',
}

export enum VectorSourceType {
  POINT = 'POINT',
  LINESTRING = 'LINESTRING',
  POLYGON = 'POLYGON',
  COMPLEX = 'COMPLEX',
}

export enum VideoSourceType {
  FULL_MOTION_VIDEO = 'FULL_MOTION_VIDEO',
}
export enum DocumentSourceType {
  IMAGE = 'IMAGE',
  PDF = 'PDF',
}

export enum AnnotationSourceType {
  ANNOTATION = 'ANNOTATION',
}

export enum UnknownSourceType {
  UNKNOWN = 'UNKNOWN',
}

export enum SpatialSourceType {
  TILES3D = 'TILES3D',
  CZML = 'CZML',
}

export enum NetCDFSourceType {
  NETCDF = 'NETCDF',
}

export enum DataAcquisitionOrderType {
  ARCHIVE = 'ARCHIVE',
  TASKING = 'TASKING',
  ARCHIVE_REQUEST = 'ARCHIVE_REQUEST',
}

// https://doc.api.satellogic.com/tasking-service/description.html#task-states
export enum SatellogicTaskingState {
  RECEIVED = 'received',
  PENDING = 'pending',
  REJECTED = 'rejected',
  PLANNING = 'planning',
  IN_PROGRESS = 'in_progress',
  CANCELED = 'canceled',
  FAILED = 'failed',
  COMPLETED = 'completed',
  PARTIALLY_COMPLETED = 'partially_completed',
}

// https://doc.api.satellogic.com/tasking-service/description.html#task-captures
export enum SatellogicCaptureState {
  QUEUED = 'queued',
  FAILED = 'failed',
  PROCESSING = 'processing',
  PUBLISHED = 'published',
}

export enum JobType {
  SAR_BASIC = 'SAR_BASIC',
  RESAMPLING = 'RESAMPLING',
  CO_REGISTRATION = 'CO_REGISTRATION',
  OPTICAL_CHANGE_DETECTION = 'OPTICAL_CHANGE_DETECTION',
  SAR_CHANGE_DETECTION = 'SAR_CHANGE_DETECTION',
  TIME_SERIES_FORECASTING = 'TIMESERIES_FORECAST',
  BANDS_MATH = 'BAND_MATHEMATICS',
  MOSAIC = 'MOSAIC',
}

export enum JobPropertyType {
  SAR = 'SARJobProperties',
  RESAMPLING = 'ResamplingJobProperties',
  CO_REGISTRATION = 'CoRegistrationJobProperties',
  OPTICAL_CHANGE_DETECTION = 'OpticalChangeDetectionJobProperties',
  SAR_CHANGE_DETECTION = 'SARChangeDetectionJobProperties',
  TIME_SERIES_FORECASTING = 'TimeSeriesForecastJobProperties',
  BANDS_MATH = 'BandMathJobProperties',
  MOSAIC = 'MosaicJobProperties',
}

export enum JobStatus {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export enum SourceDataType {
  RASTER = 'RASTER',
  VECTOR = 'VECTOR',
  ANNOTATION = 'ANNOTATION',
  DRAWING = 'DRAWING',
  SPATIAL = 'SPATIAL',
  NETCDF = 'NETCDF',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
  UNKNOWN = 'UNKNOWN',
}

export enum SourceDataRelationType {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export enum WorkSpaceStatus {
  ACTIVE,
  INACTIVE,
}

export enum WorkspaceMode {
  EXPLORATION,
  ANNOTATION,
  DASHBOARD,
  FORECAST,
  STORY,
  REPORTS,
  UPLOAD,
}

export enum MapTool {
  RECTANGLE = 'RECTANGLE',
  POLYGON = 'POLYGON',
  CIRCLE = 'CIRCLE',
  LINESTRING = 'LINESTRING',
  POINT = 'POINT',
  SELECT = 'SELECT',
  DELETE = 'DELETE',
  TEXT = 'TEXT',
  MEASURE_LENGTH = 'MEASURE_LENGTH',
  MEASURE_AREA = 'MEASURE_AREA',
  MEASURE_RADIUS = 'MEASURE_RADIUS',
  MEASURE_BEARING = 'MEASURE_BEARING',
  MEASURE_ANGLE = 'MEASURE_ANGLE',
}

export enum DataAcquisitionOrderStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export enum ApprovedRejectedStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum Permission {
  USERS_ADMIN = 'users_admin',
  SENSITIVITY_ADMIN = 'sensitivity_admin',

  FILES_ADMIN = 'files_admin',
  FILES_UPLOAD = 'files_upload',

  MODELS_ADMIN = 'models_admin',
  MODELS_UPLOAD = 'models_upload',

  ACQUISITIONS_ADMIN = 'acquisitions_admin',
  ACQUISITIONS_CREATOR = 'acquisitions_creator',
  ACQUISITIONS_VIEW_ALL = 'acquisitions_view_all',
  ACQUISITIONS_APPROVER = 'acquisitions_approver',

  WORKSPACES_ADMIN = 'workspaces_admin',
  WORKSPACES_CREATOR = 'workspaces_creator',

  AMD_ADMIN = 'automodeldevelopment_admin',
  AMD_CREATOR = 'automodeldevelopment_creator',

  MARKET_ADMIN = 'market_admin',
  MARKET_CREATOR = 'market_creator',
  PLATFORM_ADMIN = 'platform_admin',

  CATEGORIES_ADMIN = 'categories_admin',
  CATEGORIES_CREATOR = 'categories_creator',

  MILITARY_SYMBOLOGY = 'military_symbology',
  JOBS_ADMIN = 'jobs_admin',

  LABS_VIEW = 'labs_view',
}

export enum SarChangeDetectionWorkflow {
  AMPLITUDE = 'amplitude_change_detection',
  COHERENT = 'coherent_change_detection',
}

export enum SarWorkflow {
  SPECKLE_FILTER = 'speckle_filter',
  DB_CONVERSION = 'decibel_conversion',
  INTENSITY_CONVERSION = 'intensity_conversion',
  GRD = 'GRD_processing',
}

export enum GrdWorkflow {
  TERRAIN_CORRECTION = 'GRD_processing/terrain_correction',
  ELLIPSOID_CORRECTION = 'GRD_processing/ellipsoid_correction',
}

export enum UploadStatus {
  NOT_STARTED = 'not_started',
  UPLOADING = 'uploading',
  QUEUED = 'queued',
  UPLOADED = 'uploaded',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  SKIPPED = 'skipped',
}

export enum IngestionStatus {
  DOWNLOADED = 'DOWNLOADED',
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

// MUI
export enum Status {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum ModelUploadStatus {
  INIT = 'INIT',
  COMPLETED = 'COMPLETED',
}

export enum StylesMode {
  Property = 'Property',
  Geometry = 'Geometry',
  Annotation = 'Annotation',
}

export enum AcquisitionRequestStatus {
  SUBMITTED = 'submitted',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export enum WorkspacePrivacy {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  GROUP = 'GROUP',
}

export enum TenantCategory {
  FACILITATOR = 'FACILITATOR',
  CONSUMER = 'CONSUMER',
  RESELLER = 'RESELLER',
  GUEST = 'GUEST',
}

export enum ExperimentStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  TRAINING = 'TRAINING',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  // ACTION_REQUIRED = 'ACTION_REQUIRED'
}

export enum BandRenderType {
  MULTIBAND = 'MULTIBAND',
  GRAY = 'GRAY',
  PSEUDOCOLOR = 'PSEUDOCOLOR',
}

export enum BandInterpolation {
  DISCRETE = 'DISCRETE',
  LINEAR = 'LINEAR',
  EXACT = 'EXACT',
}

export enum PropertyColorPreset {
  DIV_SUNSET = 'DivSunset',
  DIV_RAINBOW = 'DivRainbow',
  SEQ_YELLOWS = 'SeqYellows',
  SEQ_REDS = 'SeqReds',
  SEQ_BLUES = 'SeqBlues',
  SEQ_GREENS = 'SeqGreens',
}

export enum BandGrayColorRamp {
  BLACK_WHITE = 'BlackWhite',
  WHITE_BLACK = 'WhiteBlack',
}

export enum BandPseudoColorRamp {
  BLUES = 'Blues',
  CIVIDIS = 'Cividis',
  GREENS = 'Greens',
  GREYS = 'Greys',
  MAGMA = 'Magma',
  MAKO = 'Mako',
  RD_GY = 'RdGy',
  REDS = 'Reds',
  ROCKET = 'Rocket',
  SPECTRAL = 'Spectral',
  TURBO = 'Turbo',
  VIRIDIS = 'Viridis',
}

export enum IndicatorStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  DISABLED = 'disabled',
  READY = 'ready',
}

export enum NotificationEntityType {
  DATA_ACQUISITION_ORDER = 'DATA_ACQUISITION_ORDER',
  MODEL_SUBSCRIPTION_REQUESTED = 'MODEL_SUBSCRIPTION_REQUESTED',
  MODEL_SUBSCRIPTION_APPROVED = 'MODEL_SUBSCRIPTION_APPROVED',
  MODEL_SUBSCRIPTION_REJECTED = 'MODEL_SUBSCRIPTION_REJECTED',
}

export enum NotificationEntityOperation {
  CREATED = 'CREATED',
}

export enum ModelSubscriptionStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export enum PinType {
  STANDARD_AOI = 'STANDARD_AOI',
  COMPARISON_SLIDER = 'COMPARISON_SLIDER',
  HISTORICAL_IMAGERY = 'HISTORICAL_IMAGERY',
}

export enum MarketplaceItemType {
  APPLICATION = 'APPLICATION',
  MODEL = 'MODEL',
}

export enum ResamplingMethod {
  NEAR = 'near',
  BILINEAR = 'bilinear',
  CUBIC = 'cubic',
  CUBICSPLINE = 'cubicspline',
  LANCZOS = 'lanczos',
  AVERAGE = 'average',
  MODE = 'mode',
  MAX = 'max',
  MIN = 'min',
}

export enum DrawingFeatureStyleType {
  TEXT = 'TEXT',
  POINT = 'POINT',
  ICON = 'ICON',
  LINE = 'LINE',
  POLYGON = 'POLYGON',
  CIRCLE = 'CIRCLE',
}

export enum DrawingFeatureLinePointer {
  NONE = 'NONE',
  ARROW = 'ARROW',
  ARROW_NOTCH = 'ARROW_NOTCH',
  SQUARE = 'SQUARE',
  CIRCLE = 'CIRCLE',
  DIAMOND = 'DIAMOND',
}

export enum DrawingFeatureStrokeStyle {
  SOLID = 'SOLID',
  DASHED = 'DASHED',
}

export enum DataAcquisitionVendors {
  PLANET = 'Planet',
  SATELLOGIC = 'Satellogic',
}

export enum OrganizationEntity {
  GOVERNMENTAL = 'GOVERNMENTAL',
  COMMERCIAL = 'COMMERCIAL',
  ACADEMIC = 'ACADEMIC',
  NON_PROFIT = 'NON_PROFIT',
}

export enum AttributeTypes {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  // BOOLEAN = 'BOOLEAN',
  // OBJECT = 'OBJECT',
  // ARRAY = 'ARRAY',
  // UNKNOWN = 'UNKNOWN',
}

export enum Localisations {
  en = 'en',
  ar = 'ar',
  id = 'id',
}

export interface KeyValue<T = any> {
  [key: string]: T;
}

export interface CesiumImageryLayerOptions {
  rectangle?: Rectangle;
  alpha?: number | ((...params: any[]) => any);
  nightAlpha?: number | ((...params: any[]) => any);
  dayAlpha?: number | ((...params: any[]) => any);
  brightness?: number | ((...params: any[]) => any);
  contrast?: number | ((...params: any[]) => any);
  hue?: number | ((...params: any[]) => any);
  saturation?: number | ((...params: any[]) => any);
  gamma?: number | ((...params: any[]) => any);
  splitDirection?: SplitDirection | ((...params: any[]) => any);
  minificationFilter?: TextureMinificationFilter;
  magnificationFilter?: TextureMagnificationFilter;
  show?: boolean;
  maximumAnisotropy?: number;
  minimumTerrainLevel?: number;
  maximumTerrainLevel?: number;
  cutoutRectangle?: Rectangle;
  colorToAlpha?: Color;
  colorToAlphaThreshold?: number;
}

export interface Pageable<T> {
  list: T[];
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  loading: boolean;
  error: boolean;
}

export interface WithUser {
  createdBy?: UserId;
  modifiedBy?: UserId | null;
}

export interface WithDate {
  createdAt?: UnixTime;
  modifiedAt?: UnixTime | null;
}

export interface RasterProperties extends KeyValue {
  resolution?: number;
  // isHeightmap?: boolean;
  heightmapDataType?: HeightmapDataType;
  heightmapMultiplier?: number;
  maxZoomLevel?: number;
  bands?: Band[];
  width: number;
  height: number;
}

export interface SpatialProperties extends KeyValue {
  rootS3Path: string;
  type: string; //'TILES3D';
}

export interface NetCDFProperties extends KeyValue {
  dimensions: { name: string; size: number }[];
  variables: KeyValue;
}

export interface VectorProperties extends Record<string, any> {}
export interface VideoProperties extends Record<string, any> {}
export interface DocumentProperties extends Record<string, any> {}

export interface SourceData extends WithDateAndUser {
  id: SourceDataId;
  name: string;
  description?: string;
  ingestionDate: UnixTime;
  acquisitionStartDate: UnixTime;
  boundary: WKT;
  properties?: Record<string, any>;
  source?: string;
  sourceType: SourceType;
  type: SourceDataType;
  acquisitionDateFromSource: boolean;
  fileId: UploadedFileId;
  rasterProperties: RasterProperties | null;
  vectorProperties?: VectorProperties;
  videoProperties?: VideoProperties;
  spatialProperties?: SpatialProperties;
  documentProperties?: DocumentProperties;
  sarProperties?: KeyValue;
  netCDFProperties?: NetCDFProperties;
  parentId?: SourceDataId;
  vectorPropertiesMappingResponseDTO?: VectorPropertiesMappingResponseDTO;
  childrenCount: number;
  jobsCount: number;
  incompleteJobsCount: number;
  temporary?: boolean;
  fileName: string;
}
export interface VectorPropertiesMappingResponseDTO {
  mappings: KeyValue<LayerAttribute>;
}
export interface SourceDataRelation {
  id: string;
  job?: Job;
  source: SourceData;
  sourceRelations: SourceData[];
  relationType: SourceDataRelationType;
}

export interface SourceDataSearch {
  search?: string;
  layerName?: string;
  ids?: SourceDataId[];
  contains?: WKT | null;
  intersects?: WKT | null;
  minCreationDate?: FormattedDateTime | null;
  maxCreationDate?: FormattedDateTime | null;
  minAcquisitionDate?: FormattedDateTime | null;
  maxAcquisitionDate?: FormattedDateTime | null;
  source?: string;
  sourceTypes?: SourceType[] | null;
  type?: SourceDataType;
  categoryIds?: CategoryId[] | null;
  isAcquisitionDateFromSource?: boolean;
  validLayerIds?: SourceDataId[];
  minResolution?: number;
  maxResolution?: number;
  isChild?: boolean;
  isParent?: boolean;
  parentId?: SourceDataId;
  temporary?: boolean; // Include temporary layers
  propertyIsNotNullList?: string[];
  propertyMatchMap?: KeyValue<string>;
}

export interface SavedSourceDataSearch extends SourceDataSearch {
  isEnabled: boolean;
  name: string;
}

export interface SourceDataTimestamp {
  id: SourceDataId;
  acquisitionStartDate: UnixTime;
}

export interface Category extends WithDateAndUser {
  id: CategoryId;
  name?: string;
  parent?: Category | null;
  color?: string;
  children?: Category[]; // from tree
  documentAttachmentsIds?: null | string[];
  imageAttachmentIds?: null | string[];
  properties?: null | Record<string, any>[];
}

export interface CategoryAggregation extends Category {
  parent?: CategoryAggregation | null;
  children?: CategoryAggregation[];
  count: number;
}

export interface UserBasic {
  id: UserId;
  tenantId: TenantId;
  fullname: string;
  username: string;
  hasAvatar?: boolean;
  active: boolean;
}

export interface User extends UserBasic, WithDateAndUser {
  email: string;
  roles: Role[];
  language: Language;
  password?: string;
  forceResetPassword: boolean;
  expireAt?: number;
  lastLogin?: UnixTime;
  settings?: UserSettings;
  // eid?: string;
}

export interface UserWithToken extends UserBasic {
  token: string;
  provider: string;
}

export interface Statistics {
  featuresTotal: number;
  averageTime: number;
  inferred: number;
  ingested: number;
  sqkmAvgTime: number;
  totalTime: number;
}

export interface InferenceJob extends WithDateAndUser {
  type: string;
  id: InferenceJobId;
  inferenceJobId: string;
  sourceId: SourceDataId;
  modelName: string;
  modelId: ModelId;
  modelVersion: string;
  status: InferenceJobStatus;
  progress: number;
  startedAt?: UnixTime;
  finishedAt?: UnixTime;
}

export interface InferenceJobWithCategoriesAggregation extends InferenceJob {
  categories: CategoryAggregation[];
}

export interface InferenceParameters extends Record<string, string | number> {
  confidenceThreshold: number;
  nmsThreshold: number;
  resolutionDefault: number;
  resolutionMinimum: number;
  resolutionMaximum: number;
}

export interface UploadedFile extends WithDateAndUser {
  id: UploadedFileId;
  originalFilename: string;
  fileSize: number;
  uploadId: string | null;
  contentType: string | null;
  uploadStatus: ModelUploadStatus;
}

export interface UploadedResource extends WithDateAndUser {
  id: UploadedFileId;
  originalResourceName: string;
  fileSize: number;
  uploadId: string | null;
  contentType: string | null;
  uploadStatus: ModelUploadStatus;
}

export interface ModelGroup {
  name: string;
  hasActive: boolean;
  hasRegistered: boolean;
  hasUploaded: boolean;
  hasSubscriptions: boolean;
  hasSubscribed: boolean;
  hasPublished: boolean;
  allPublished: boolean;
  numberOfVersions: number;
  latestVersionDate: UnixTime;
}

export interface Model extends WithDateAndUser {
  id: ModelId;
  name: string;
  version: string;
  description: string | null;
  family: string | null;
  jobType: ModelJobType;
  tileSize: number | null;
  labels: string[];
  type: ModelType;
  alias: string | null;
  inferenceParam: InferenceParameters;
  isActive: boolean;
  isRegistered: boolean;
  isUploaded: boolean;
  isAutoRun: boolean;
  isPublished: boolean;
  isSubscribed: boolean;
  hasSubscriptions: boolean;
  fileModel: UploadedFile | null;
  fileModelMeta: UploadedFile;
  tenant: Tenant;
  parentId: ModelId;
}

export interface ModelLabelMapping extends WithDateAndUser {
  id: ModelLabelMappingId;
  model: Model;
  name: string;
  category?: Category;
}

// export interface StyleConfig {
//   fill?: FillConfig;
//   stroke?: StrokeConfig;
//   circle?: CircleConfig;
//   regularShape?: RegularShapeConfig;
//   icon?: IconConfig;
// }

export interface FillConfig {
  color?: string;
  pattern?: any;
}

export interface StrokeConfig {
  width: number;
  color: string;
  lineDash?: number[];
  lineDashOffset?: number;
  lineCap?: string;
  lineJoin?: string;
  miterLimit?: number;
}

export interface CircleConfig {
  radius: number;
  fill?: FillConfig;
  stroke?: StrokeConfig;
  scale?: number[];
  rotation?: number;
  rotateWithView?: boolean;
}

export interface RegularShapeConfig extends CircleConfig {
  radius1?: number;
  radius2?: number;
  points: number;
  angle?: number;
  displacement?: number[];
}

export interface IconConfig {
  anchor?: number[];
  anchorOrigin?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
  anchorXUnits?: 'fraction' | 'pixels';
  anchorYUnits?: 'fraction' | 'pixels';
  displacement?: number[];
  offset?: number[];
  offsetOrigin?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
  color?: string;
  opacity?: number;
  src?: string;
  size?: number[];
  scale?: number[];
  rotation?: number;
  rotateWithView?: boolean;
}

export interface IngestionUploadResponse extends WithDateAndUser {
  id: IngestionJobId;
  fileId: UploadedFileId;
  originalFilename: string;
  ingestionStatus?: IngestionStatus;
}

export interface ErrorResponse {
  error: string;
  message: string;
  path: string;
  status: number;
}

export interface FileUpload {
  id?: string;
  name: string;
  size: number;
  status: UploadStatus;
  ext: string;
  progress: number;
  preview?: string;
  statusDetails?: string;
  file: File;
  sourceType?: SourceType; // For Files only
  geometry?: Geometry;
  dataMappingInfo?: Partial<DataMappingPreset>;
  layerProperties?: LayerPropertiesUpload;
}

export interface LayerPropertiesUpload {
  workspaceId?: WorkspaceId;
  parentId?: SourceDataId;
}

export interface FileWithPreview extends File {
  preview?: string;
}

export interface IngestionJob extends WithDateAndUser {
  canRestartIngestion: boolean;
  id: IngestionJobId;
  fileId: UploadedFileId;
  sourceId?: SourceDataId;
  ingestionStatus: IngestionStatus;
  originalFilename: string;
  error?: string;
  message?: string;
  progress?: number;
  fileSize: number;
}

export interface IngestionJobWithSourceData extends IngestionJob {
  sourceData?: SourceData;
}

export interface DrawingFeatureAttachment {
  id: UploadedFileId;
  name: string;
  title?: string;
  type: 'IMAGE' | 'FILE';
}

export interface DrawingFeatureProperties extends FeatureProperties {
  type?: DrawingFeatureStyleType;
  label?: string;
  labelFont?: string;
  labelFontSize?: number;
  labelStrokeColor?: HexColor;
  labelStrokeWidth?: number;
  labelFillColor?: HexColor;
  labelFillDisabled?: boolean;
  labelStrokeDisabled?: boolean;
  labelBackground?: HexColor;
  labelBackgroundPadding?: number;
  strokeColor?: HexColor;
  strokeWidth?: number;
  strokeDisabled?: boolean;
  strokeStyle?: DrawingFeatureStrokeStyle;
  fillColor?: HexColor;
  fillDisabled?: boolean;
  description?: string;
  pointSize?: number;
  icon?: string;
  iconColor?: HexColor;
  rotation?: number;
  startPointer?: DrawingFeatureLinePointer;
  endPointer?: DrawingFeatureLinePointer;
  attachments?: JsonString;
}

export interface Feature extends WithDateAndUser {
  id: FeatureId;
  sourceId: SourceDataId;
  geometry: WKT;
  properties: FeatureProperties;
  timeStamp?: UnixTime;
  type: WKTType;
  is3d?: boolean;
}

export interface EntityFeature extends Feature {
  entityId?: EntityId;
}

export interface DrawingFeature extends EntityFeature {
  properties: DrawingFeatureProperties;
}

export interface AnnotationFeature extends EntityFeature {
  categoryId?: CategoryId;
}

export interface Detection {
  id: DetectionId;
  categoryId: CategoryId;
  geometry: WKT;
  sourceId: SourceDataId;
  score: number;
  label: string;
  validationStatus?: ApprovedRejectedStatus;
}

export interface VideoDetection extends Detection {
  fps: number;
  frameNumber: number;
  area?: number;
}

export interface FeatureStyle {
  fill?: HexColor;
  stroke?: HexColor;
  strokeWidth?: number;
  fillDisabled?: boolean;
  strokeDisabled?: boolean;
  outlineColor?: HexColor;
  outlineWidth?: number;
}

export interface PropertyRule {
  value: string | number | boolean | null;
  style?: FeatureStyle;
}

export interface LabelStyle {
  property: string;
  show: boolean;
  fill: string;
  outline: string;
  outlineDisable: boolean;
  size: number;
  eyeOffset: Cartesian3;
}

export interface PropertyStyle {
  mode: StylesMode.Property;
  property: string;
  colorPreset?: PropertyColorPreset;
  colorPresetInverted?: boolean;
  strokeWidth?: number; // Global value
  rules: PropertyRule[];
  labelStyle?: LabelStyle;
}

export interface GeometryStyles {
  mode: StylesMode.Geometry;
  labelStyle?: LabelStyle;
  rules: GeometryRules;
}

export interface AnnotationStyles {
  mode: StylesMode.Annotation;
  rules: AnnotationRules;
  labelStyle?: LabelStyle;
}

export interface Navigation {
  id: string;
  label: string;
  shortLabel?: string;
  icon: string;
  path: string;
  permission?: Permission | Permission[];
  route?: string;
}

export interface MultiNavigation extends Omit<Navigation, 'path' | 'route'> {
  route: string;
  subNavigation: Navigation[];
}

export interface AcquisitionRequest extends WithDateAndUser {
  id: AcquisitionRequestId;
  name: string;
  type: 'new' | 'archive';
  startDate: UnixTime;
  endDate: UnixTime;
  source: string;
  resolution: number[];
  status: AcquisitionRequestStatus;
  location: string;
  comments?: string;
}

export interface Workspace extends WithDateAndUser {
  id: WorkspaceId;
  name: string;
  privacy: WorkspacePrivacy;
  archived: boolean;
  userIds: UserId[] | null;
  savedSearches: SavedSourceDataSearch[];
  // aoi: WKT | null;
  // acquisitionStartDate: UnixTime | null;
  // acquisitionEndDate: UnixTime | null;
  // creationStartDate: UnixTime | null;
  // creationEndEndDate: UnixTime | null;
  // sourceTypes: SourceType[] | null;
  // categoryIds: CategoryId[] | null;
  tempLayerIds?: SourceDataId[];
  numFiles: number;
  thumbnailPath: string | null;
}

export interface WorkspaceModifier
  extends Partial<Omit<Workspace, 'creationStartDate' | 'acquisitionStartDate' | 'creationEndEndDate' | 'acquisitionEndDate'>> {
  acquisitionStartDate?: UnixTime | FormattedDateTime | null;
  acquisitionEndDate?: UnixTime | FormattedDateTime | null;
  creationStartDate?: UnixTime | FormattedDateTime | null;
  creationEndEndDate?: UnixTime | FormattedDateTime | null;
}

export interface TileServerLayer {
  id: TileServerLayerId;
  name?: string;
  description?: string;
  xyzUrl: UrlString;
  provider: Provider;
  layers?: string[]; // ArcGis only
  previewUrl?: UrlString; // e.g. https://tile-server.ae/thumb/{minX},{minY},{maxX},{maxY}/{width}x{height}.png
  minZoomLevel?: number;
  maxZoomLevel?: number;
  isDark: boolean;
  isVector: boolean;
  isDefault: boolean;
  isAvailableOffline: boolean;
  type: SourceDataType;
  boundary?: WKT;
  tileType: string;
}

export interface TileServerSettings {
  url: string;
  provider?: Provider;
  layers?: string;
  thumbnailUrl?: string;
  copyright?: string;
}

export interface SourceDataPixelInfo {
  bandIndex: number;
  bandName: string;
  value: number | string;
}

export interface DataAcquisitionVendor {
  id: DataAcquisitionVendorId;
  name: string;
  supportedSourceTypes: RasterSourceType[];
  products: VendorProduct[];
  isArchiveAvailable: boolean;
  isArchiveRequestAvailable: boolean;
  isTaskingAvailable: boolean;
  enable: boolean;
  archiveSearchFields: ArchiveFieldItem[] | null;
  taskingFields: TaskingFieldItem[] | null;
  autoIngestion: boolean;
  userAgreement: string;
  mapTools?: MapTool[];
}

export interface ArchiveFieldItem {
  field: ArchiveSearchField;
  isRequired: 'true' | 'false';
}

export interface TaskingFieldItem {
  field: TaskingField;
  isRequired: 'true' | 'false';
}

export interface ArchiveOrderItem {
  id: string; // 'individual' OR 'satelliteId + _ + date'
  vendorImages: DataAcquisitionProduct[];
  singleZip: boolean;
  productType: string;
}

export interface ArchiveOrderRequestItem extends ArchiveOrderItem {
  vendorImageIds: DataAcquisitionProductId[];
}

export interface ArchiveOrderRequest {
  items: ArchiveOrderRequestItem[];
  description: string;
  vendorId: DataAcquisitionProductId;
}

export interface DataAcquisitionProduct {
  id: DataAcquisitionProductId;
  boundary?: WKT;
  previewUrl?: UrlString;
  price: number;
  productName: string;
  productType: string;
  resolution?: number;
  sourceType: RasterSourceType;
  vendorId: DataAcquisitionVendorId;
  properties?: Record<string, any> | PlanetProperties;
  acquisitionDate: UnixTime;
}

export interface ArchiveDataAcquisitionOrderItem extends DataAcquisitionProduct {
  orderId: DataAcquisitionOrderId;
  sourceId?: SourceDataId;
  fileId?: UploadedFileId;
  singleZip: boolean | null;
  vendorImages: DataAcquisitionProduct[] | DataAcquisitionProduct | null; // TODO to fix, as it should be always array or null
  vendorImageIds: DataAcquisitionProductId[] | null;
}

export interface TaskingDataAcquisitionOrderItem extends DataAcquisitionProduct {
  orderId: DataAcquisitionOrderId;
  sourceId?: SourceDataId;
  fileId?: UploadedFileId;
  requestProduct: string;
  requestProvider: string;
  coverageNumber?: number;
  satellogicCaptureStatus: SatellogicCaptureStatus[] | undefined;
}

export interface SummaryOrderItem {
  id: DataAcquisitionProductId;
  orderId: DataAcquisitionOrderId;
  sourceId?: SourceDataId;
  fileId?: UploadedFileId;
  boundary?: WKT;
  previewUrl?: UrlString;
  price: number;
  productName: string;
  productType: string;
  sourceType: RasterSourceType;
  vendorId: DataAcquisitionVendorId;
  properties?: Record<string, any> | PlanetProperties;
  acquisitionDate: UnixTime;
}

export interface SummaryOrderGroup {
  id: DataAcquisitionProductId;
  orderId: DataAcquisitionOrderId;
  sourceId?: SourceDataId;
  fileId?: UploadedFileId;
  price: number;
  productName: string;
  productType: string;
  sourceType: RasterSourceType;
  vendorId: DataAcquisitionVendorId;
  items: SummaryOrderItem[];
}

export interface DateGroupedPlanetProducts {
  date: number;
  products: GroupedPlanetProducts[];
}

export interface GroupedPlanetProducts {
  satelliteId: string;
  date: number;
  productType: string;
  products: DataAcquisitionProduct[];
}

export interface PlanetProperties {
  acquired: FormattedDateTime; //"2022-11-30T05:09:10.861093Z",
  anomalous_pixels: number; // 0
  clear_confidence_percent: number; //80
  clear_percent: number; //59
  cloud_cover: number; //0.4
  cloud_percent: number; //40
  ground_control: boolean; //true
  gsd: number; // 3.9
  heavy_haze_percent: number; //0
  instrument: string; // "PSB.SD"
  item_type: string; // "PSScene"
  light_haze_percent: number; // 0
  pixel_resolution: number; // 3
  provider: string; //"planetscope",
  published: FormattedDateTime;
  publishing_stage: string; //"finalized",
  quality_category: string; //"standard",
  satellite_azimuth: number; //277.5,
  satellite_id: string; //"2488",
  shadow_percent: number; //1,
  snow_ice_percent: number; //0,
  strip_id: string; //"6112031",
  sun_azimuth: number; //131.5,
  sun_elevation: number; //55.4,
  updated: FormattedDateTime; //"2022-12-01T08:21:33Z",
  view_angle: number; //4.6,
  visible_confidence_percent: number; //58,
  visible_percent: number; //60
}

export interface TaskOrderRequest {
  description: string;
  acquisitionEndDate: UnixTime | FormattedDateTime;
  acquisitionStartDate: UnixTime | FormattedDateTime;
  coverageNumber?: number;
  geometry?: WKT;
  productType?: string;
  vendorId: DataAcquisitionVendorId;
  type: DataAcquisitionOrderType;
  userAgreement: boolean;
}

export interface DataAcquisitionOrderBase extends WithDateAndUser {
  id: DataAcquisitionOrderId;
  number: number;
  status: DataAcquisitionOrderStatus;
  orderItems: ArchiveDataAcquisitionOrderItem[] | TaskingDataAcquisitionOrderItem[];
  totalAmount: number;
  description?: string;
  coverageNumber?: number;
  geometry?: any;
  type: DataAcquisitionOrderType;
  vendorId: DataAcquisitionVendorId;
  tenantId: TenantId;
}

export interface ArchiveAcquisitionOrder extends DataAcquisitionOrderBase {
  orderProgress: DataAcquisitionOrderProgress[];
  orderItems: ArchiveDataAcquisitionOrderItem[];
  type: DataAcquisitionOrderType.ARCHIVE;
}

export interface TaskingAcquisitionOrder extends DataAcquisitionOrderBase {
  // orderProgress: DataAcquisitionOrderProgress[];
  orderItems: TaskingDataAcquisitionOrderItem[];
  type: DataAcquisitionOrderType.TASKING | DataAcquisitionOrderType.ARCHIVE_REQUEST;
  acquisitionEndDate: string;
  acquisitionStartDate: string;
  productType: string;
  boundary: WKT;
  sourceType: RasterSourceType;
}

export interface DataAcquisitionOrderProgress extends WithDateAndUser {
  id: DataAcquisitionOrderProgressId;
  approvalStepId: ApprovalId;
  approvalStep: string;
  approvalStepSequence: number;
  approverTenantId: TenantId;
  comment: string;
  status?: ApprovedRejectedStatus;
}

export interface ApprovalStep {
  id: ApprovalId;
  name: string;
}

export interface SatellogicCaptureStatus {
  capture_id: string;
  start: FormattedDateTime;
  satellite_name: string;
  status: SatellogicCaptureState;
}

export interface TenantBasic extends WithDateAndUser {
  id: TenantId;
  name: string;
  code: string;
}

export interface Tenant extends TenantBasic {
  email: string;
  tenantCategory: TenantCategory;
  description?: string;
  userCount: number;
  isActive: boolean;
  modules?: ModuleId[];
  settings?: Settings;
  entity: OrganizationEntity;
  countryCode: string;
}

export interface UserTenant extends TenantBasic {
  tenantCategory: TenantCategory;
}

export interface Module extends WithDateAndUser {
  id: ModuleId;
  name: string;
  description?: string;
}

export interface Role extends WithDateAndUser {
  id: RoleId;
  name: string;
  permissions: PermissionItem[];
}

export interface PermissionItem extends WithDateAndUser {
  id: PermissionId;
  name: Permission;
}

export interface Band {
  index: number;
  name?: string;
  isAlpha?: boolean;
}

export interface SourceDataLink extends SourceData {
  status: UploadStatus;
}

export interface Story extends WithDateAndUser {
  id: StoryId;
  name: string;
  pins: StoryPin[];
  workspaceId: WorkspaceId;
}

export interface StoryPin extends WithDateAndUser {
  id: StoryPinId;
  autoPlay: boolean | null;
  storyId: StoryId;
  index: number;
  description: string;
  heading: number;
  layerIds: SourceDataId[];
  selectedLayerIds: SourceDataId[];
  name: string;
  pitch: number;
  position: number[];
  roll: number;
  type: PinType;
  settings: StoryPinSettings | null;
}

export interface StoryResource {
  url?: string;
  description?: string;
  id: string;
}

export interface StoryPinSettings {
  visibleHeightmapId?: SourceDataId | null;
  multiselect?: { selected?: SourceData[] };
  filter?: {};
  historicalImagery?: HistoricalImagerySettings;
  disableRasterAdjustment?: boolean;
  showVectorStyleLegend?: boolean;
  rasterAdjustments?: StoryLayerAdjustments;
  bandsSettings?: StoryLayerBandsSettings;
  searches?: SavedSourceDataSearch[];
  vectorStyles?: StyleStore;
  resources?: StoryResource[];
}

export interface CheckRule {
  id: SensitivityCheckRuleId;
}

export interface SensorCheckRule extends CheckRule {
  infrared: boolean;
  hyperspectral: boolean;
}
export interface UserCheckRule extends CheckRule {
  users: UserId[];
}
export interface AreaCheckRule extends CheckRule {
  areas: AreaCheckGeometry[];
}

export interface AreaCheckGeometry {
  id?: AreaCheckGeometryId;
  name: string;
  geometry: WKT;
}
export interface TemporalSpatialCheckRule extends CheckRule {
  resolution: number;
  acquisitionDays: number;
}

export interface SensitivityRuleFlow {
  id: SensitivityRuleFlowId;
  isSensorCheckActive: boolean;
  isAreaCheckActive: boolean;
  isUserCheckActive: boolean;
  isTemporalCheckActive: boolean;
  name: string;
  sensorCheck: SensorCheckRule;
  temporalCheck: TemporalSpatialCheckRule;
  users: UserId[];
  areas: AreaCheckGeometryId[];
}

export interface VendorProduct {
  name: string;
  resolution: number;
  type: string;
}

export interface WithReferenceLayer {
  referenceLayerId: SourceDataId;
}

export interface JobPropertiesBase {
  jobPropertyType: JobPropertyType;
  workspaceId?: WorkspaceId;
  outputLayerName: string;
}

export interface SARJobProperties extends JobPropertiesBase {
  workflow: SarWorkflow | GrdWorkflow;
  terrain_correction?: TerrainCorrection;
  dem?: Dem;
}

export interface ResamplingJobProperties extends JobPropertiesBase {
  jobPropertyType: JobPropertyType.RESAMPLING;
  width: number;
  height: number;
  method: ResamplingMethod;
}

export interface ForecastResponse {
  dates: string[];
  fcast: number[];
  rmse?: number;
}

export interface CoRegistrationJobProperties extends JobPropertiesBase, WithReferenceLayer {
  jobPropertyType: JobPropertyType.CO_REGISTRATION;
}

export interface TimeSeriesForecastJobProperties extends JobPropertiesBase, WithReferenceLayer {
  jobPropertyType: JobPropertyType.TIME_SERIES_FORECASTING;
  feature: string;
  startDate: string;
  endDate: string;
  model: string;
  forecastSteps: number;
  dataInterval: string;
  imputation: string;
  responseProperties?: ForecastResponse;
}

export interface OpticalChangeDetectionJobProperties extends JobPropertiesBase, WithReferenceLayer {
  jobPropertyType: JobPropertyType.OPTICAL_CHANGE_DETECTION;
  modelId: ModelId;
}

export interface SarChangeDetectionJobProperties extends JobPropertiesBase, WithReferenceLayer {
  workflow: SarChangeDetectionWorkflow;
  dem: Dem;
}

export interface MosaicJobProperties extends JobPropertiesBase {
  jobPropertyType: JobPropertyType.MOSAIC;
  layerIds: SourceDataId[];
}

interface BaseJob extends WithDateAndUser {
  id: JobId;
  jobProperties: JobProperties;
  jobType: JobType;
  originalLayerId: SourceDataId;
  originalFileId?: UploadedFileId;
  outputLayerId?: SourceDataId;
  outputFileId?: UploadedFileId;
  progress: number;
  status?: JobStatus;
  error?: string;
}

export interface BandMap {
  key: string;
  index: number;
  layerId: SourceDataId;
}

export interface BandsMathJobProperties extends JobPropertiesBase, WithReferenceLayer {
  layerBands: BandMap[];
  formula: string;
}

export interface BandsMathJob extends BaseJob {
  jobProperties: BandsMathJobProperties;
  jobType: JobType.BANDS_MATH;
}

export interface SarJob extends BaseJob {
  jobProperties: SARJobProperties;
  jobType: JobType.SAR_BASIC;
}

export interface ResamplingJob extends BaseJob {
  jobProperties: ResamplingJobProperties;
  jobType: JobType.RESAMPLING;
}

export interface TimeSeriesForecastingJob extends BaseJob {
  jobProperties: TimeSeriesForecastJobProperties;
  jobType: JobType.TIME_SERIES_FORECASTING;
}

export interface CoRegistrationJob extends BaseJob {
  jobProperties: CoRegistrationJobProperties;
  jobType: JobType.CO_REGISTRATION;
}

export interface OpticalChangeDetectionJob extends BaseJob {
  jobProperties: OpticalChangeDetectionJobProperties;
  jobType: JobType.OPTICAL_CHANGE_DETECTION;
}

export interface SarChangeDetectionJob extends BaseJob {
  jobProperties: SarChangeDetectionJobProperties;
  jobType: JobType.SAR_CHANGE_DETECTION;
}

export interface MosaickingJob extends BaseJob {
  jobProperties: MosaicJobProperties;
  jobType: JobType.MOSAIC;
}

export interface Experiment extends WithDateAndUser {
  id: ExperimentId;
  annotationDatasetVersionId: AnnotationDatasetId;
  annotationProjectId: AnnotationProjectId;
  name: string;
  modelArchitectureId: string;
  epoch: number;
  learningRate: number;
  optimizerId: string;
  confidence?: number | null;
  experimentJobId: ExperimentJobId | null;
}

export interface AnnotationProject extends WithDateAndUser {
  id: AnnotationProjectId;
  name: string;
  numberOfExperiments: number;
  lastDatasetValidatedAt: UnixTime;
}

export interface ExperimentOptimizer extends WithDateAndUser {
  id: string;
  name: string;
}

export interface ExperimentModel extends WithDateAndUser {
  id: string;
  name: string;
}

export interface ExperimentJob {
  id: ExperimentJobId;
  experimentId: ExperimentId;
  jobUrl: string;
  progress: number;
  status: ExperimentStatus;
  resultsPath: string;
  statistics: ExperimentJobStatistic;
}

export interface ExperimentJobStatistic {
  box_loss: number;
  cls_loss: number;
  dfl_loss: number;
  mAP50: number;
  'mAP50-95': number;
  performance: number;
  precision: number;
  recall: number;
}

export interface AnnotationDataset extends WithDateAndUser {
  id: AnnotationDatasetId;
  annotationProjectId: AnnotationProjectId;
  analyticsJobId: JobId;
  name: string;
  isValid: boolean | null;
  comment: string;
  validatedAt: UnixTime | null;
}

export interface DatasetRequest {
  sourceId: SourceDataId;
  categoryIds: CategoryId[];
}

export interface BandsMappingSettings {
  mapping: BandMapping;
  ranges?: BandRanges;
  levels?: BandLevels;
}

export interface BandsSettingsMultiband extends BandsMappingSettings {
  renderType: BandRenderType.MULTIBAND;
}

export interface BandsSettingPseudocolor extends BandsMappingSettings {
  renderType: BandRenderType.PSEUDOCOLOR;
  interpolation: BandInterpolation;
  colorRamp: BandColorRamp;
  colorRampValues?: HexColor[];
  colorRampInverted?: boolean;
}

export interface BandsSettingGray extends BandsMappingSettings {
  renderType: BandRenderType.GRAY;
  colorRamp: BandColorRamp;
  colorRampValues?: HexColor[];
  colorRampInverted?: boolean;
}

export interface ContactForm {
  name: string;
  email: string;
  subject: string;
  description: string;
}

export interface Camera {
  position: Cartesian3;
  heading: number;
  pitch: number;
  roll: number;
}
export interface StoryPinData {
  id?: StoryPinId;
  autoPlay: boolean | null;
  storyId?: StoryId;
  name: string;
  description: string;
  camera: Camera;
  layerIds: SourceDataId[];
  selectedLayerIds: SourceDataId[];
  type: PinType;
  settings: StoryPinSettings | null;
}

export interface ApprovalWorkflow {
  organizationApproval: boolean;
  facilitatorApproval: boolean;
  facilitatorTenantId: string;
  resellerTenantId: string;
}

export interface ApprovalWorkflowStep {
  id: string;
  stepNumber: string;
  approverTenantId: string;
  approverTenantCategory: TenantCategory;
  tenantId: string;
}

export interface DataMappingPreset extends WithDateAndUser {
  id: DataMappingPresetId;
  name: string;
  dataMapping: DataMapping;
}

export interface DataMapping {
  isFirstRowHeaders: boolean;
  timestampFormat: DateTimeTogetherFormat | DateTimeSeparateFormat | UnixTimestampFormat | null;
  locationFormat: LatLongFormat | LatLongTogetherFormat | null;
}

export interface DateTimeTogetherFormat {
  type: TimestampFormats.DATE_AND_TIME_TOGETHER;
  dateTimeColumn: string;
}

export interface DateTimeSeparateFormat {
  type: TimestampFormats.DATE_AND_TIME_SEPARATE;
  dateColumn: string;
  timeColumn: string;
}

export interface UnixTimestampFormat {
  type: TimestampFormats.UNIX_TIME;
  unixTimestampColumn: string;
}

interface LocationFormat {
  heightColumn?: string;
}

export interface LatLongFormat extends LocationFormat {
  type: LocationFormats.LAT_LONG_SEPARATE;
  latitudeColumn: string;
  longitudeColumn: string;
}

export interface LatLongTogetherFormat extends LocationFormat {
  type: LocationFormats.LAT_LONG_TOGETHER;
  latitudeLongitudeColumn: string;
}

export interface DataMappingRow extends Record<string, unknown> {
  internalGridId?: string;
}

interface BaseMarketplaceItem extends WithDateAndUser {
  id: MarketplaceItemId;
  title: string;
  description: string;
  logoPath?: string;
  shortDescription: string;
  tags: string[];
  price: 0;
  userAgreement: string;
  tenantId?: TenantId;
  isPinned?: boolean;
}

export interface MarketplaceApplicationItem extends BaseMarketplaceItem {
  type: MarketplaceItemType.APPLICATION;
  buttonText: string;
  url: string;
}

export interface MarketplaceModelItem extends BaseMarketplaceItem {
  type: MarketplaceItemType.MODEL;
  model: Model;
  modelSubscriptionStatus?: ModelSubscriptionStatus;
}

export interface MarketplaceItemSubscription {
  id: MarketplaceItemSubscriptionId;
  marketId: MarketplaceItemId;
  status: ModelSubscriptionStatus;
  tenantId: TenantId;
}

export interface NotificationLink {
  entity?: any;
}

export interface Notification extends WithDate {
  id: NotificationId;
  entityType: NotificationEntityType;
  entity?: any;
  operation: NotificationEntityOperation;
  message?: string;
  links: NotificationLink[];
  read?: boolean;
}

export interface ModelSubscriptionRequestNotification extends Notification {
  entityType: NotificationEntityType.MODEL_SUBSCRIPTION_REQUESTED;
  entity?: any;
}

export interface ModelSubscriptionRequest extends WithDateAndUser {
  user: User;
  market: MarketplaceModelItem;
  tenant: Tenant;
  status: ModelSubscriptionStatus;
}

export interface Settings {
  colorScheme: SupportedColorScheme;
}

export interface UserSettings extends Omit<Settings, 'colorScheme'> {
  colorScheme: SupportedColorScheme | FollowTenant;
  language: Localisations;
}

export interface Cesium3DFeature {
  primitive: Cesium3DTileset;
}

export interface TemporalSettings {
  accumulate: boolean;
  showTrack: boolean;
  showPoints: boolean;
  show3dModel: boolean;
  followModel: boolean;
  model: Temporal3dModel;
  hpr: AttitudeRecord;
  timeRange?: [UnixTime, UnixTime];
}

export interface LabelCategoryMapping {
  labelName: string;
  category: Category | null;
}

export interface LabelCategoryMappingRequest {
  labelName: string;
  categoryId: CategoryId;
}

export interface ConvertRequestDTO {
  columnName: string;
  mappings: LabelCategoryMappingRequest[];
  rasterSourceId: SourceDataId;
  vectorSourceId: SourceDataId;
}

export interface SubscriptionItem {
  runCount: number;
  subscribedDate: UnixTime;
  tenantId: TenantId;
}

export interface PresetExpression {
  id?: number;
  name: string;
  expression: string;
}
export interface SvgImportProps {
  width: number;
  height: number;
  blurWidth: number;
  blurHeight: number;
  src: string;
}

export interface ForecastConfig {
  colors: HexColor[];
  selectedFeatures: string[];
  range?: number[];
  startDate: Date;
  endDate: Date;
  model: string;
  steps: number;
  interval: string;
  imputation?: any;
}

export interface ForecastConfigProps {
  features: string[];
  config: ForecastConfig;
  onChange: (key: string, val: any) => void;
  reset: () => void;
  resetDate: () => void;
  sourceDataId: SourceDataId;
  disabled?: boolean;
}

export interface LayerAttribute {
  typeForced?: boolean;
  units?: string;
  defaultValue?: string;
  description?: string;
  mandatory?: boolean;
  name?: string;
  type?: AttributeTypes;
  id: string;
}

export const SearchMods = {
  name: 'name',
  coordinates: 'coordinates',
} as const;

export interface CategoryAttachmentUpload {
  id: string;
  name: string;
  categoryId: CategoryId;
  caption: string;
  size: number;
  status: UploadStatus;
  statusDetails?: string;
  progress: number;
  file: File;
  abortController: AbortController;
  type: AttachmentType;
}

export interface CategoryCaption {
  id: string;
  caption: string;
}

export enum CategoryCaptionStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface CategoryCaptionResponse {
  id: string;
  status: CategoryCaptionStatus;
}

export enum AttachmentType {
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
}

export interface CategoryAttachmentItem {
  id: string;
  attachmentPath: string;
  caption: string;
  categoryId: CategoryId;
  type: AttachmentType;
  createdBy: null | string;
  createdAt: null | string;
}
