import ParticleSystem from './ParticleSystem';
import DataProcess from './dataProcess';
import Util, { NetCDFWindOptions, ParticlesOptions, NetCDFFieldsMapping, JsonData, OperatingParams } from './utils';
import { defaultFields, defaultParticleOptions, defaultColorTable } from './options';
import { BoundingSphere, Camera, Cartesian2, Cartesian3, Viewer } from 'cesium';
import CustomPrimitive from '@/components/Cesium/utils/wind/CustomPrimitive';

export enum ColorBy {
  SPEED = 'speed',
  HEIGHT = 'height',
}

export interface ViewerParameters {
  lonRange: Cartesian2;
  latRange: Cartesian2;
  pixelSize: number;
  lonDisplayRange: Cartesian2;
  latDisplayRange: Cartesian2;
}

export default class Particle3D {
  _viewer: Viewer;
  userInput: ParticlesOptions;
  input: JsonData | Blob;
  colour: ColorBy;
  type: 'json' | 'file';
  fields: NetCDFFieldsMapping;
  primitives: CustomPrimitive[];
  particleSystem?: ParticleSystem;
  offset: { lon?: number; lat?: number; lev?: number };
  valueRange: { min: number; max: number };
  colorTable: number[][];
  viewerParameters: ViewerParameters;
  data: any;
  globeBoundingSphere: BoundingSphere;
  animationFrame?: number;
  moving = false;
  resized = false;

  /**
   * Create a Particle3D Object
   * @example
   * // load a JSON data
   * new Particle3D(viewer, {
   input: jsonData,
   type: 'json',
   userInput: {
   maxParticles: 64 * 64,
   particleHeight: 1000.0,
   fadeOpacity: 0.996,
   dropRate: 0.003,
   dropRateBump: 0.01,
   speedFactor: 1.0,
   lineWidth: 4.0,
   dynamic: true
   },
   colorTable: [
   [0.015686,
   0.054902,
   0.847059],
   [0.125490,
   0.313725,
   1.000000]
   ],
   colour: 'height'
   });
   * @example
   * // load a NC file
   * new Particle3D(viewer, {
   input: BolbFile("uv3z.nc"),
   fields: {
   U: 'water_u',
   V: 'water_v'
   }
   });
   * @param [viewer] - The cesium Viewer Object.
   * @param [options] - An object with the following properties:
   * @param [options.input] - Allow a NC file or organized particle system data.
   * @param [options.type = 'json'] - The input file's type, 'json' or 'file'.
   * @param [options.fields = defaultFields] - NC file field specification.
   * @param [options.valueRange = {min: -100, max: 100}] - UVWH dimension values range, out of range will be set to 0.
   * @param [options.offset = {lon: 0, lat: 0, lev: 0}] - lon/lat/lev dimension values offset.
   * @param [options.userInput = defaultParticleSystemOptions] - Particle system configuration item.
   * @param [options.colorTable = defaultColorTable] - Particle color ribbon.
   * @param [options.colour = 'speed'] - Particle coloring attribute.
   */
  constructor(viewer: Viewer, options: NetCDFWindOptions) {
    this._viewer = viewer;
    this.userInput = options.userInput || defaultParticleOptions;
    this.input = options.input;
    this.colour = options.colour || ColorBy.SPEED;
    this.type = options.type || 'file';
    this.fields = { ...defaultFields, ...options.fields };
    this.offset = options.offset || { lon: 0, lat: 0, lev: 0 };
    this.valueRange = options.valueRange || { min: -100, max: 100 };
    this.colorTable = options.colorTable || defaultColorTable;
    this.primitives = [];

    this.viewerParameters = {
      lonRange: new Cartesian2(),
      latRange: new Cartesian2(),
      pixelSize: 0.0,
      lonDisplayRange: new Cartesian2(),
      latDisplayRange: new Cartesian2(),
    };
    // use a smaller earth radius to make sure distance to camera > 0
    this.globeBoundingSphere = new BoundingSphere(Cartesian3.ZERO, 0.99 * 6378137.0);
  }

  moveStartFn = () => {
    this.moving = true;
    this.primitives.forEach((primitive) => (primitive.show = false));
  };

  moveEndFn = () => {
    this.moving = false;
    this.updateViewerParameters();
    this.particleSystem!.applyViewerParameters(this.viewerParameters);
    if (this.primitives) {
      this.primitives.forEach((primitive) => (primitive.show = true));
    }
  };

  preRenderFn = () => {
    if (this.resized) {
      this.resized = false;
      this.remove();
      this.particleSystem!.canvasResize(this.scene.context);
      this.addPrimitives();
      this.show();
    }
  };

  resizeFn = () => {
    this.resized = true;
  };

  async init() {
    try {
      const data = await DataProcess.loadData(this.input, this.type, {
        fields: this.fields,
        valueRange: this.valueRange,
        offset: this.offset,
        colorTable: this.colorTable,
      });
      this.data = data;
      this.updateViewerParameters();
      this.particleSystem = new ParticleSystem(
        this.scene.context,
        data,
        this.processUserInput(this.userInput),
        this.viewerParameters,
        this.colour,
      );
      this.addPrimitives();
      return data;
    } catch (e) {
      throw e;
    }
  }

  addPrimitives() {
    // the order of primitives.add() should respect the dependency of primitives
    this.primitives = [
      this.particleSystem!.particlesComputing.primitives.calculateSpeed,
      this.particleSystem!.particlesComputing.primitives.updatePosition,
      this.particleSystem!.particlesComputing.primitives.postProcessingPosition,
      this.particleSystem!.particlesRendering.primitives!.segments,
      this.particleSystem!.particlesRendering.primitives!.trails,
      this.particleSystem!.particlesRendering.primitives!.screen,
    ];
    for (const primitive of this.primitives) {
      this.scene.primitives.add(primitive);
    }
  }

  updateViewerParameters() {
    const viewRectangle = this.camera.computeViewRectangle(this.scene.globe.ellipsoid);
    const lonLatRange = Util.viewRectangleToLonLatRange(viewRectangle);
    this.viewerParameters.lonRange.x = Math.max(lonLatRange.lon.min, this.data.lon.min);
    this.viewerParameters.lonRange.y = Math.min(lonLatRange.lon.max, this.data.lon.max);
    this.viewerParameters.latRange.x = Math.max(lonLatRange.lat.min, this.data.lat.min);
    this.viewerParameters.latRange.y = Math.min(lonLatRange.lat.max, this.data.lat.max);

    const pixelSize = this.camera.getPixelSize(this.globeBoundingSphere, this.scene.drawingBufferWidth, this.scene.drawingBufferHeight);

    if (pixelSize > 0) {
      this.viewerParameters.pixelSize = pixelSize;
    }
  }

  setupEventListeners() {
    this.camera.moveStart.addEventListener(this.moveStartFn);
    this.camera.moveEnd.addEventListener(this.moveEndFn);
    this.scene.preRender.addEventListener(this.preRenderFn);
    window.addEventListener('resize', this.resizeFn);
  }

  removeEventListeners() {
    this.camera.moveStart.removeEventListener(this.moveStartFn);
    this.camera.moveEnd.removeEventListener(this.moveEndFn);
    this.scene.preRender.removeEventListener(this.preRenderFn);
    window.removeEventListener('resize', this.resizeFn);
  }

  processUserInput(userInput: ParticlesOptions): OperatingParams {
    // make sure maxParticles is exactly the square of particlesTextureSize
    const particlesTextureSize = Math.ceil(Math.sqrt(userInput.maxParticles || 0));
    const maxParticles = particlesTextureSize * particlesTextureSize;
    return { ...this.userInput, ...userInput, particlesTextureSize, maxParticles };
  }

  optionsChange(userInput: ParticlesOptions) {
    this.particleSystem!.applyUserInput(this.processUserInput(userInput));
  }

  animate = () => {
    this.scene?.requestRender();
    this.animationFrame = requestAnimationFrame(this.animate);
  };

  show() {
    this.primitives.forEach((primitive) => (primitive.show = true));
    this.removeEventListeners();
    this.setupEventListeners();
    this.animate();
  }

  hide() {
    this.primitives.forEach((primitive) => (primitive.show = false));
    this.scene?.requestRender();
    this.removeEventListeners();
    this.animationFrame !== undefined && window.cancelAnimationFrame(this.animationFrame);
  }

  remove() {
    this.hide();
    this.primitives.forEach((primitive) => this.scene.primitives.remove(primitive));
    this.primitives = [];
  }

  get viewer() {
    return this._viewer?.cesiumWidget && this._viewer;
  }

  get scene() {
    return this.viewer?.scene;
  }

  get camera() {
    return this.viewer?.camera;
  }
}
